export const Testimonials = {

    "mentors": [
        {
            name: 'Scindia Balasingh',
            quote: 'I\'ve faced gender biasness, lack of inspiration, and motivation from fellow women colleagues in my life. This pushed me to take women\'s empowerment into my agenda. I\'ve always wanted to see women shining in their careers. So when I interviewed Deepa in my podcast LeadHERs, I got to relate to many of my interests aligned with Shevolve. It started there; I got the opportunity to do a cohort session on a career in partnerships, then on an Instagram live by sharing my experience with the fellow women community. I am grateful to be a part of the Shevolve community and looking forward to contributing and learning more from Shevolve.',
            img: 'scindia-balasingh',
            role: 'Head of Partner Marketing'
        },
        {
            name: 'Shreedevi Ramesh',
            quote: 'I have been associated with Shevolve as a mentor since its inception. This is a very powerful community that really wants to empower women! Truly amazed to see how this community has evolved through the variety of initiatives and the truly accomplished women leaders who provide their valuable insights to those who seek. Through the mentoring platform I have had the opportunity to mentor a handful of women by sharing my experiences and providing guidance for their questions. Mentoring has a fantastic learning experience for me personally too! Shevolve is doing a phenomenal job in providing this platform for mid career women especially to those on a career break and telling them they are not alone. Best wishes!! I am proud to be a tiny part of the team! "',
            img: 'shreedevi-ramesh',
            role: "Entrepreneur, Independent Business Owner",
        },
        {
            name: 'Kaivalya',
            quote: 'Shevolve is an amazing community that gives a platform to all women irrespective of their work experience levels. This enables women across multiple experience levels to connect with each other. In a constantly changing environment, it is important to have such forums where women support women. As the saying goes, when women support women; incredible things happen. Looking forward to Shevolve making the desired impact in the near future.',
            img: 'kaivalya-gundu',
            role:'Senior Manager',
        }, {
            name: 'Samiksha Seth',
            quote: '"I was so excited to be part of the Shevolve Community, and when I interacted with mentees, they all had similar questions of what I had a few years back.Its an awesome feeling to connect and help and mostly give it back to the community.Shevolve has made sure to match with the right set of mentees and the ground work they do to aboard mentors and mentees is commendable.Great to be part of such an exciting journey."',
            img: 'samiksha-seth',
            role: "Product Manager",
        }, {
            name: 'Manvi Tyagi',
            quote: '"I served as a Mentor for ""Thrive with Shevolve"" Program which aimed to guide women who had been on a career break for long periods of time to come back to the industry.I talked to one woman who had a 97% score in 12th class in 2008, a good cgpa in bachelors and a great start to her career at a reputed organisation. She had been on a career break for many years and found it difficult to make her place again. I am super happy that they could be helped and guided with this program and hence very grateful to Shevolve not only for just onboarding me but for putting the foundation of Shevolve in first place."',
            img: 'manvi-tyagi',
            role:'Software Engineer',
        }, {
            name: 'Neeraja',
            quote: '"I had the opportunity to conduct a session on ""Networking For Results"" for the ""Thrive with Shevolve"" programme. It was impressive to see how this platform has so quickly identified the needs of women professionals(be it women who are new in the workforce, women on a career break or women in careers) and curated this beautiful programme. I also had the opportunity to be part of a panel discussion at the ""Mentorship for Women Conference"". I could see the same professionalism, thought process in putting together the various programmes, their outreach both to mentors as well as participants. I would urge every women to follow Shevolve on their website and social media and participate in all the programmes conducted by them. It will definitely take them to heights in their careers"',
            img: 'neeraja',
            role: 'Mentor and Consultant'
        },
    ],
    "mentees": [
        {
            name: 'Janani Porkodi',
            quote: "Shevolve is one of the best communities that I have joined. It gave me exposure and introduced me to many mentors who played a crucial role in my career. I wanted to change my career to Product Management from technical background, and I was able to get access to many leading product managers from different production companies, which gave me a lot of ideas about how to get into product management and what product management is all about. I was aligned and mentored by seasoned product managers from different companies and had an opportunity to learn a lot from them. The sessions were beneficial and made my career transition from a technical role to a product management role easier. I have recommended shevolve for many in my network looking for career transition or mentorship. Shevolve is a great platform to shape your career in the way you want it, and I am delighted to be a part of it.",
            img: 'janani-porkodi',
            // role: 'Mentor and Consultant'
        }, {
            name: 'Sahana Vasudevan',
            quote: '<p>SHEVOLVE is a platform for women on career development trajectory, Why do I want to attend this leadership program?</p> Leadership training programmes can help teach you the essential skills and techniques you need in order to look at challenges from a different perspective. Doing so can add clarity to a difficult situation, and also promotes wisdom and facilitates self-confidence. All these takeaways I found in this one power packed cohort! Kudos to the Shevolve team for organizing this program.',
            img: 'sahana-vasudevan',
            // role: 'Mentor and Consultant'
        }, {
            name: 'Lakshmi Sankaran',
            quote: "I am so glad that I have been a part of Shevolve's Cohort Thrive with Shevolve. Each and every session was a gem. Each and Every session was very useful and gave simple and easy to follow tips to the participants. The highlight of the cohort was the 1:1 mentoring discussions that was very useful to me. It gave me the clarity of thought that I needed and helped me identify the gaps and what is that I need to do to move ahead and succeed. Personally the entire cohort was very impactful on me and I had a lot of take aways and learnings. And the significant one being the importance of self care, that the time you need to invest on yourself, to improve upon yourself so that you can see the results and succeed. I wish Deepa and Bhaduri a very illustrious journey with Shevolve!",
            img: 'lakshmi-sankaran',
            // role: 'Mentor and Consultant'
        }, {
            name: 'Indhu Soundarajan',
            quote: "Recently I got confused and deviated from what I wanted. I kind of off tracked from my routine. Having joined shevolve cohort helped me to get back on the track which gives me some relief. Thank you !",
            img: 'indhu-soundarajan',
            // role: 'Mentor and Consultant'
        }, {
            name: 'Neha',
            quote: "<p>#EvolvewithShelvolve</p> <p>'Proud to be a part of Shevolve'</p> Thank you Team Shevolve for all the positivity and super energising approach. Connecting with so many powerful and inspiring women/mentors has taught me how important it is to believe in your self and your potential. A great and unique initiative! Special thanks to Bhaduri & Deepa for helping me boost up my confidence.",
            img: 'neha',
            // role: 'Mentor and Consultant'
        }
    ]

}