import React, {useState} from "react";
import { Link } from "react-router-dom";
import Styles from "../../../styles";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import { updateMenteeDetails } from "../../../NetworkRequest/requests/mentee";

const Survey = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleSkip = () => {
    setLoading(true);
    updateMenteeDetails({ isSurveySkipped: true })
      .then((res) => {
        setLoading(false);
        history.push("/");
      })
      .catch((e) => setLoading(false));
  };

  return (
    <section className="wrapper">
      <Styles />
      <Modal
        open={loading}
        onClose={(e) => {}}
        styles={{ modalContainer: { background: "transparent", boxShadow: "none", padding: 12 } }}
        showCloseIcon={false}
        center
      >
        {" "}
        <Loader
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
          // timeout={3000} //3 secs
        />
      </Modal>
      <section className="login-page signup-wrap">
        <div className="login-bg">
          <figure className="login-image mb-0">
            <img className="img-fluid" src={"/assets/images/login-image.png"} alt="login" style={{ height: "100vh", objectFit: "cover" }} />
          </figure>
        </div>
        <div className="login-form">
          <div className="form-content">
            <div className="login-form-content mb-3">
              <Link to="/">
                <div className="logo-img-bottom mb-4">
                  <img src="/assets/images/logo.png" width={120} height={48} alt="header-logo" />
                </div>
              </Link>
              <div className="sec-title flex-content m-b-md">
                <img className="mr-3" src="/assets/images/butterfly.svg" style={{ width: 22, height: 27.42 }} alt="banner" />
                {/*<h5 className="sec-title mb-0">Hey, Welcome back!</h5>*/}
                <h5 className="sec-title mb-0" style={{ color: "#0C4B6C" }}>
                  Quick survey and you are all set!
                </h5>
              </div>
              <div className="sec-content" style={{ fontSize: 14 }}>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                </p>
              </div>

              <div className="my-5">
                <img src="/assets/images/wannabe.svg" alt="" />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0">
                Do it later ?{" "}
                <span style={{ color: "#0C4B6C", cursor: "pointer" }} onClick={handleSkip}>
                  Skip Survey
                </span>
              </p>
              <button className="btn btn-gradient-primary" onClick={(e) => history.push("/knowMore")}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Survey;
