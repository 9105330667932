import React, { useState, useEffect } from "react";
import { getPurchasedCourses } from "../../NetworkRequest/requests/profile";
import Styles from "../../styles";
import { USER_TYPE } from "../Constants";
import { authenticated } from "../privateAuth/tokenExists";
import NewHeader from "./static/newHeader";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import MentorProfile from "./mentor/mentorProfile";
import moment from "moment";
import AvailabilityCalender from "./mentor/availability";
import { contentTotalDuration } from "../../global_fun";

const Profile = (props) => {
  const [myCourses, setMyCourses] = useState([]);
  const userType = localStorage.getItem(USER_TYPE);
  const { userData } = useSelector((state) => state.loginSignPopUp);
  const package1 = useSelector((state) => state.loginSignPopUp.package);
  // const packageInfo = useSelector((state) => state.loginSignPopUp.package);

  const history = useHistory();

  useEffect(() => {
    console.log("package is", package1);
    if (!authenticated()) {
      history.push({
        pathname: "/login",
        state: { from: "/profile" },
      });
    }
    getPurchasedCourses(userType)
      .then((res) => {
        if (res.courses) {
          setMyCourses(res.courses);
        }
      })
      .catch((e) => console.log(e));
  }, [userData]);

 

  return (
    <div className="wrapper container-width">
      <Styles />
      <NewHeader props={props} />        

      <AvailabilityCalender myCourses={myCourses} />
      
        {/* <div>
          {userType === "mentees" ? (
            <div>
              <AvailabilityCalender myCourses={myCourses} />
              {/* <MentorProfile history={history} />

              <section className="sh-section pb-0">
                <div className="blog-tab-wrap">
                  <div className="event-blog-wrap sec-pad pb-0">
                    <div className="container">
                      <div className="event-blog-content">
                        <div className="row">
                          {/* courses list here 
                          {myCourses.length > 0 ? (
                            myCourses.map(
                              (myCourse, i) =>
                                myCourse.course && (
                                  <div className="col-12 col-md-6 col-lg-3 col-xl-3 mb-lg-3 mb-3">
                                    <div
                                      className="blog-content-wrap"
                                      style={{ cursor: "pointer" }}
                                      onClick={(h) => {
                                        props.history.push(`/preview/${myCourse.course._id}`);
                                      }}
                                    >
                                      <figure className="image-wrap">
                                        <img className="img-fluid" style={{ objectFit: "cover", maxHeight: 200 }} src={myCourse.course.image} />
                                        <div className="tip-title">
                                          <span style={{ fontSize: 12, fontWeight: "bold" }}>
                                            {myCourse.course.isFree ? "Free" : `₹ ${myCourse.course.price}`}
                                          </span>
                                        </div>
                                      </figure>
                                      <div className="blog-text-content">
                                        <p
                                          className="sec-title text-black-color pl-3"
                                          style={{
                                            display: "-webkit-box",
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {myCourse.course.courseName}
                                        </p>
                                        <p
                                          className="sec-content"
                                          style={{
                                            height: 62.4,
                                            color: "#525252",
                                            fontSize: 14,
                                          }}
                                        >
                                          {myCourse.course.courseTitle}
                                        </p>

                                        <div className="meta-info flex-space">
                                          <div className="days" style={{ fontSize: 12 }}>
                                            {myCourse.course.content.length} Modules &bull; {contentTotalDuration(myCourse.course).hour}hr{" "}
                                            {contentTotalDuration(myCourse.course).minute}
                                            min
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )
                          ) : (
                            <div style={{ width: "100%", height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <p>No Course found</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {package1._id && (
                        <div className="package-container mt-3">
                          <div className="d-flex justify-content-between">
                            <h5 className="text-white">About My Package</h5>
                            {/* <button className="btn btn-info btn-sm">UPGRADE</button> 
                          </div>

                          <div className="package-inner mt-3">
                            <p className="mb-2">
                              <span className="sh-profile__label">Current Package</span> : <span className="sh-profile__data">{userData.packageInfo && userData.packageInfo.packageName}</span>
                            </p>
                            <p className="mb-2">
                              <span className="sh-profile__label">Bought On</span> : <span className="sh-profile__data">{userData.packageInfo && moment(userData.packageInfo.packagePurchasedOn).format("LLL")}</span>
                            </p>
                            <p className="mb-2">
                              <span className="sh-profile__label">Valid throught</span> : <span className="sh-profile__data">{userData.packageInfo && moment(userData.packageInfo.packageExpireOn).format("LLL")}</span>
                            </p>
                            <p className="mb-2">
                              <span className="sh-profile__label">Mentoring Sessions</span> : <span className="sh-profile__data">{package1.mentoringAvailable} available</span>
                            </p>
                            <p className="mb-2">
                              <span className="sh-profile__label">Coaching Session</span> : <span className="sh-profile__data">{package1.coachingAvailable} available</span>
                            </p>
                            {/* <p>Current Package : <span>Advanced</span></p> 
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ) : (
            <AvailabilityCalender myCourses={myCourses} />
          )}
        </div> */}
     



      </div>
  );
};

export default Profile;
