import { useState } from "react";
import { Modal } from 'react-responsive-modal'
import { toast } from "react-toastify";
import { rateSessionMentee } from "../../../NetworkRequest/requests/mentee";
import { rateSessionMentor } from "../../../NetworkRequest/requests/mentor";
import { USER_TYPE } from "../../Constants";


const ExperienceSubmit = ({ open, sessionId, sessionType, onClose = () => { }, reload }) => {
  const [rating, setRating] = useState(1);
  const userType = localStorage.getItem(USER_TYPE);

  const bg = {
    modal: { borderRadius: 16 },
    overlay: {},
  };

  const handleOnSubmit = async () => {
    try {
     userType === 'mentee' ? await rateSessionMentee({ sessionId, sessionType, rating }) : await rateSessionMentor({ sessionId, sessionType, rating })
      reload();
      onClose();
    } catch (e) {
      toast('error');
      onClose()
    }
  }


  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={bg}
    >
      <div
        style={{ display: "flex" }}
        id="news-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl form-size my-0">
          <div className="modal-content form-content basic-modal-content email-modal-content">
            <div
              className="news-wrap"
            >
              <div className="row no-gutters">
                <div className="col-md-12 form-padlr">
                  <div className="form-block">
                    <div className="basic-detail-header" style={{ marginTop: "-1px" }}>
                      <div className="flex-space">
                        <h6 className="sec-title">RATE YOUR SESSION </h6>
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <div className="col-md-12">
                        <div className="email-text-content detail-text-content text-align-center">
                          <p >Give rating according to your experience with our expert</p>
                          <div className="d-flex justify-content-center">
                            {[...new Array(5)].map((star, i) => (
                              rating > i ? (<img key={`img${i}`} style={{ width: 30, height: 30 }} onClick={e => setRating(i + 1)} src="./assets/images/star-active.jpg" alt="star" />) : (<img key={`img${i}`} onClick={e => setRating(i + 1)} style={{ width: 30, height: 30 }} src="./assets/images/star-inactive.jpg" alt="star" />)
                            ))}
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="btn-wrap">
                                <button
                                  type="submit"
                                  className="btn btn-gradient-primary w-100"
                                  // data-dismiss="modal"
                                  onClick={handleOnSubmit}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}


export default ExperienceSubmit;
