import AxiosBase from "../axios/AxiosBase";

export const getSubsctiption = (email) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/subscription", {email})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


export const submitContactUs = (form) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/contact_us", form)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


export const submitFreeTrial= (form) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/freeTrial", form)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
