import React, { useState, useEffect } from "react";
import NewHeader from "../static/newHeader";
import SessionCard from "./sessionCard";
import EventCard from "./eventCard";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { addNote, getNote } from "../../../NetworkRequest/requests/mentee";
import moment from "moment";
import Assignments from "../small/assignment";
import { useSelector } from "react-redux";
import QA from "../small/QA";
import { contentTotalDuration } from "../../../global_fun";

const ProfileHome = (props) => {
  const purchasedPackage = useSelector((state) => state.loginSignPopUp.package);
  const [tab, setTab] = useState("Package Content");
  const [notesList, setNotesList] = useState([]);
  const [noteText, setNoteText] = useState({
    title: "",
    description: "",
  });
  // const [loading, setLoading] = useState(false);

  const _getNotes = () => {
    getNote().then((res) => {
      setNotesList(res);
    });
  };

  useEffect(() => {
    _getNotes();
    console.log('purchased package is ', purchasedPackage)
  }, []);

  const handleNoteText = (evt) => {
    const s = { ...noteText };
    s[evt.target.name] = evt.target.value;
    setNoteText(s);
  };

  const handlePost = () => {
    if (noteText.title !== "" && noteText.description !== "") {
      addNote(noteText).then((res) => {
        setNoteText({ title: "", description: "" });
        _getNotes();
      });
    }
  };

  return (
    <div className="wrapper container-width">
      {/*header*/}
      <NewHeader props={props} from="courses" />
      <div
        className="banner-wrap blog-banner-wrap blog-detail-image"
        style={{ backgroundImage: 'url("/assets/images/profile_banner.png")' }}
      >
        <div className="banner-overlay">
          <div className="container">
            <div className="banner-content">
              <div
                style={{
                  background: "linear-gradient(180deg, #113946 0%, #162843 100%)",
                  maxWidth: "380px",
                  color: "white",
                  padding: 20,
                  borderRadius: 10,
                }}
              >
                <div className="d-flex justify-content-between">
                  <h6>{purchasedPackage.packageTitle}</h6>
                  <i class="fas fa-angle-down" />
                </div>
                <p className="f-size-12 text-white mb-4">
                  Valid Through :<br />
                  {moment(purchasedPackage.packageExpiry).format('LL')}
                </p>
                {/* <p className="f-size-12 text-white">
                  My Relationship Manager :<br />
                  Natalie Dormen 9815984937
                </p> */}
                {/* <p>Progress 74%</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* banner sec close */}

      <div className="content pt-0">
        <div className="blog-tab-wrap">
          <div className="event-blog-wrap sec-pad p-0">
            <div className="container">
              <div>
                <ul className="profile-nav-list">
                  <li style={{ color: tab === "Package Content" ? "#007A87" : "" }} onClick={(e) => setTab("Package Content")}>
                    Package Content
                  </li>
                  <li style={{ color: tab === "Notes" ? "#007A87" : "" }} onClick={(e) => setTab("Notes")}>
                    Notes
                  </li>
                  <li style={{ color: tab === "Assignments" ? "#007A87" : "" }} onClick={(e) => setTab("Assignments")}>
                    Assignments
                  </li>
                  <li style={{ color: tab === "Q&A" ? "#007A87" : "" }} onClick={(e) => setTab("Q&A")}>
                    Q&A
                  </li>
                </ul>
              </div>

              {tab === "Notes" && (
                <div style={{ width: "75%" }}>
                  <h3 className="my-5">Notes</h3>
                  <div class="form-group">
                    <label for="title" className="f-size-12">
                      Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={noteText.title}
                      onChange={handleNoteText}
                      class="form-control"
                      id="title"
                      aria-describedby="emailHelp"
                      placeholder="this is my title"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="title" className="f-size-12">
                      Your Notes
                    </label>
                    <div style={{ border: "1px solid #c4c4c4", borderRadius: "0.625rem", padding: 10 }}>
                      <TextareaAutosize
                        value={noteText.description}
                        name="description"
                        onChange={handleNoteText}
                        className="notes-textArea"
                      />
                      <div className="d-flex justify-content-end split">
                        <button onClick={handlePost} className="btn btn-gradient-primary">
                          Post
                        </button>
                      </div>
                    </div>
                    <div style={{ border: "1px solid #EDEDED", height: "2px", width: "100%", marginBlock: 30 }}></div>
                  </div>

                  <div className="">
                    {notesList.map((no) => (
                      <div>
                        <p style={{ fontSize: 16, color: "#525252", marginBottom: 0 }}>{no.title}</p>
                        <p className="f-size-12">Posted on : {moment(no.createdAt).format("DD MMM YYYY")}</p>
                        <p className="f-size-12" style={{ color: "#727272" }}>
                          {no.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {tab === "Assignments" && <Assignments />}
              {tab === "Q&A" && <QA />}

              {tab === "Package Content" && (
                <>
                  <h3 className="my-5">Program Courses</h3>
                  <div className="event-blog-content">
                    <div className="row">
                      {/* courses list here  */}
                      {purchasedPackage.courses &&
                        purchasedPackage.courses.map((course, inde) => (
                          <div key={`course${inde}`} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-lg-3 mb-3">
                            <div className="blog-content-wrap" style={{ cursor: "pointer" }} onClick={e => {
                              props.history.push(`/preview/${course.course[0]._id}`)
                            }}>
                              <figure className="image-wrap">
                                <img className="img-fluid" style={{ objectFit: "cover", maxHeight: 200 }} src={course.course[0].image} alt='course' />
                              </figure>
                              <div className="blog-text-content">
                                <p
                                  className="sec-title text-black-color"
                                  style={{
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                  }}
                                >
                                  {course.course[0].courseName}
                                </p>
                                <p
                                  className="sec-content"
                                  style={{
                                    height: 56,
                                    color: "#525252",
                                    fontSize: 12,
                                  }}
                                >
                                  {course.course[0].courseTitle}
                                </p>

                                <div className="meta-info flex-space">
                                  <div className="days" style={{ fontSize: 12 }}>
                                    {course.course[0].content.length} Modules &bull; {contentTotalDuration(course.course[0]).hour}hr{" "}
                                    {contentTotalDuration(course.course[0]).minute}
                                    min
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-lg-3 mb-3">
                        <div className="blog-content-wrap programe-add-courses">
                          <div className="p-5 text-center d-flex flex-column h-100 justify-content-between">
                            <p />
                            <p>Enroll into any course available on the platform</p>

                            <button
                              className="btn btn-gradient-primary btn-lg"
                              onClick={(e) => props.history.push(`/courses?packageId=${purchasedPackage._id}`)}
                            >
                              <i class="fas fa-plus" />
                              Add Courses
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="mt-2 mb-5">Mentoring Sessions</h3>
                  <div className="event-blog-content">
                    <div className="row">
                      {purchasedPackage.mentoringSessions &&
                        purchasedPackage.mentoringSessions.map((session, index) => (
                          <div key={`session${index}`} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-lg-3 mb-3">
                            <SessionCard type={"session"} {...session} index={index + 1} />
                          </div>
                        ))}
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-lg-3 mb-3">
                        <SessionCard
                          type="session"
                          packageId={purchasedPackage._id}
                          index={purchasedPackage.mentoringSessions.length + 1}
                        />
                      </div>
                    </div>
                  </div>
                  <h3 className="mt-2 mb-5">Coaching Sessions</h3>
                  <div className="event-blog-content">
                    <div className="row">
                      {purchasedPackage.coachingSessions &&
                        purchasedPackage.coachingSessions.map((coaching, index) => (
                          <div key={`coaching${index}`} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-lg-3 mb-3">
                            <SessionCard type="coaching" {...coaching} index={index + 1} />
                          </div>
                        ))}
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-lg-3 mb-3">
                        <SessionCard
                          type="coaching"
                          packageId={purchasedPackage._id}
                          index={purchasedPackage.coachingSessions.length + 1}
                        />
                      </div>
                    </div>
                  </div>
                  <h3 className="mt-2 mb-5">Workshops</h3>
                  <div className="row">
                    
                      {purchasedPackage.workshops.map((evt) => (
                        <div key={evt._id} className="col-md-6 col-lg-4 col-xl-4 mb-lg-0 mb-3">
                        <EventCard {...evt.event[0]} />
                        </div>

                      ))}
                    <div className=" col-md-6 col-lg-4 col-xl-4">
                      <div className="blog-content-wrap ">
                        <div className="p-5 text-center d-flex flex-column h-100 justify-content-between">
                          {/* <p></p>
                          <p>Enroll into any course available on the platform</p> */}
                          <div>
                            <button
                              className="btn btn-gradient-primary btn-lg"
                              onClick={(e) => props.history.push(`/events?packageId=${purchasedPackage._id}`)}
                            >
                              <i class="fas fa-plus" />
                              Add Workshops
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHome;
