import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Styles from "../../styles";
import { resetPassword } from "../../NetworkRequest/requests/login";



function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class Reset extends Component {
    state = { password: "", confirmPassword: '' };

    email = getParameterByName('email', this.props.history.search)
    token = getParameterByName('token', this.props.history.search)
    userType = getParameterByName('userType', this.props.history.search)




    componentDidMount() {
        console.log('props1', this.props.history)
    }

    handlePasswordChange = (e) => {
        e.preventDefault();
        let password = e.target.value;
        this.setState({ password });
    };

    handleConfirmChange = (e) => {
        e.preventDefault();
        let confirmPassword = e.target.value;
        this.setState({ confirmPassword });
       // console.log(email);
    };


    handleSubmit = e => {
        e.preventDefault()
        if(this.email && this.userType && this.token) {
            console.log(this.state.confirmPassword)
            if(this.state.password === '') {
                toast.error('Password should not be empty')
                return ;
            }
            if(!(this.state.password === this.state.confirmPassword)) {
                toast.error('Password not match')
                return ;
            }

            resetPassword(this.email, this.userType, this.token, this.state.password)
            .then(res => {
                console.log('res', res)
                if(res.statusCode === 200) {
                    toast.success(res.customMessage)
                } else {
                    toast.error(res.customMessage)
                }
            })
            .catch(e => toast.error(e));
        }else {
            toast.error('Invalid link')
        }
    }


    render() {
        console.log("props", this.props.location);
        return (
            <section className="wrapper">
                <Styles />
                <section className="login-page signup-wrap">
                    <div className="login-bg">
                        <figure className="login-image mb-0" >
                            <img
                                className="img-fluid"
                                src={"/images/entry/entry-image.png"}
                                alt="login"
                                style={{ height: '100vh', objectFit: 'cover' }}
                            />
                        </figure>
                    </div>
                    <div className="login-form">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-content">
                                <div className="login-form-content mb-3">
                                    <Link to='/'>
                                        <div className="logo-img-bottom mb-4">
                                            <img
                                                src="/images/logo.svg"
                                                width={120}
                                                height={48}
                                                alt="header-logo"
                                            />
                                        </div>
                                    </Link>
                                    <div className="sec-title flex-content m-b-md">
                                        <img
                                            className="mr-3"
                                            src="/assets/images/butterfly.svg"
                                            alt="banner"
                                        />
                                        {/*<h5 className="sec-title mb-0">Hey, Welcome back!</h5>*/}
                                        <h5 className="sec-title sh-para font-weight-bold">Hello Mentor/Mentee</h5>
                                    </div>
                                    <div className="sec-content">
                                        <p>
                                            Welcome back to Shevolve!  
                                            Please enter the new password of your choice
                                        </p>
                                    </div>


                                    <div className="form-group">
                                        <label className="fz-12">Password</label>
                                        <input
                                            className="form-control"
                                         //   placeholder="Lokeshnpl3213@gmail.com"
                                            type="password"
                                            onChange={this.handlePasswordChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className="fz-12">Confirm password</label>
                                        <input
                                            className="form-control"
                                          //  placeholder="Lokeshnpl3213@gmail.com"
                                            type="password"
                                            onChange={this.handleConfirmChange}
                                        />
                                    </div>
                                

                                    <div className="btn-grp mt-4">
                                        <button
                                            type="submit"
                                            className="sh-button sh-button__no-radius btn-block"
                                        // onClick={this._login}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                                <div className="sec-content text-align-center">
                                    <p>
                                        Back to <Link to="/login" style={{ fontSize: 18, color: "#007a87", paddingLeft: 6 }}>Login</Link>
                                    </p>
                                </div>
                              
                            </div>
                        </form>
                    </div>
                </section>

            </section>
        );
    }
}

export default Reset;
