import AxiosBase from "../Axiosbase";

export const getFirstQuestion = ( ) =>
  new Promise((resolve, reject) => {
     AxiosBase.get("/mentee/getFirstQuestion")
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });

  export const getSurveyQuestion = (questionId) =>
  new Promise((resolve, reject) => {
    const data = {questionId };

    AxiosBase.post("/mentee/getSurveyQuestion", data)
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });

  export const addResponseOfQuestion = (questionId , answerId , packageType , nextQuestionId , surveyProgress ) =>
  new Promise((resolve, reject) => {
    const data = {questionId , answerId , packageType ,nextQuestionId , surveyProgress  };

    AxiosBase.post("/mentee/addResponseOfQuestion", data)
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });

  export const addResponseOfQuestionIfSkip = (questionId , skip , nextQuestionId , surveyProgress) =>
  new Promise((resolve, reject) => {
    const data = {questionId , skip , nextQuestionId , surveyProgress};

    AxiosBase.post("/mentee/addResponseOfQuestion", data)
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });

  export const addResponseOfQuestionIfSubmit = (questionId , answerId , packageType  , surveyProgress , isSurveyDone) =>
  new Promise((resolve, reject) => {
    const data = {questionId , answerId , packageType  , surveyProgress ,isSurveyDone };

    AxiosBase.post("/mentee/addResponseOfQuestion", data)
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });