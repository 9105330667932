import React, { useEffect, useState } from "react";
import { domains, industries, USER_TYPE } from "../../Constants";
import Styles from "../../../styles";
import NewHeader from "../static/newHeader";
import { Avatar } from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateMentorProfile } from "../../../NetworkRequest/requests/mentor";
import { updateMentee, updateMenteeDetails, updateMentorProfilePic } from "../../../NetworkRequest/requests/mentee";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateProfileAction } from "../../../reducer/action";
import { uploadFileOnAWS } from "../../../adminPanel/src/helper/aws";
import Layout from '../../layout/default';

const EditProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userType = localStorage.getItem(USER_TYPE);
  const { userData } = useSelector((state) => state.loginSignPopUp);
  const isLogin = localStorage.getItem("accessToken");
  const [user, setUser] = useState({});
  const [file, setFile] = useState("");
  const [resumeFile, setResume] = useState("");

  useEffect(() => {
    setUser(userData);
    if (!isLogin) {
      history.push("/login");
    }
  }, [userData]);

  const validate = async (evt) => {
    evt.preventDefault();
    const formData = new FormData();
    let { name, role, phoneNumber, industry, experience, domain, linkedInLink, image, resume } = user;
    if (name === "") {
      toast.error("Name should not be empty");
      return;
    }
    if (userType === "mentor" && linkedInLink === "") {
      toast.error("LinkedIn link should not be empty");
      return;
    }

    if (experience === "") {
      toast.error("Experiance should not be empty");
      return;
    }

    if(userType === 'mentee' && resume === '' && resumeFile === '') {
      toast.error("Resume is compulsory");
      return;
    }

    try {
      if (file && file !== "") {
        formData.append("image", file);
        userType === "mentee" ? await updateMentee(formData) : updateMentorProfilePic(formData);
      }

      if (resumeFile !== '' && userType === 'mentee') {
        resume = await uploadFileOnAWS(resumeFile)
        // updateMenteeDetails(fData).then(res => console.log(res))
      }

      userType === "mentee"
        ? await updateMenteeDetails({ name, role, phoneNumber, industry, experience, domain, linkedInLink, resume })
        : await updateMentorProfile({ name, role, phoneNumber, industry, experience, domain, linkedInLink });
      toast.success("Updated successfully");
      dispatch(updateProfileAction());
      history.push("/profile");
      window.location.href = "/profile";
    } catch (e) {
      toast.log("failed");
    }
  };

  const handleOnChange = (evt) => {
    const u = { ...user };
    u[evt.target.name] = evt.target.value;
    setUser(u);
  };

  const handleFileUpload = (evt) => {
    if (evt.target.files[0]) {
      setFile(evt.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        const u = { ...user };
        u.profilePic = reader.result;
        setUser(u);
      };
      reader.readAsDataURL(evt.target.files[0]);
    }
  };

  return (
    <Layout>
        {/* <Styles />
        <NewHeader /> */}

        <section className="sh-section sh-section--green pt-0">
            <div className="sh-section__inwrap">
              <div className="sh-profile__cover-background"></div>
              <div className="container my-2 sh-profile__details">
                <div className="d-lg-flex align-items-start">
                  <div className="sh-profile__image">
                    <div className="d-flex justify-content-center">
                      <div style={{ position: "relative" }}>
                        {/* <Avatar variant="rounded" style={{ height: 300, width: 300, borderRadius: 20 }} src={user.profilePic}></Avatar> */}
                        <img  className="img-fluid" style={{ objectFit: "cover", objectPosition: "center", width: "100%", height: "100%"}} src={user.profilePic ? user.profilePic : "/assets/images/casual_profile.jpg"} alt="profile " />
                        <label className="sh-input__upload-img d-flex align-items-center justify-content-center flex-column" htmlFor="image-upload">
                          <CameraAltIcon
                            style={{
                              width: 30,
                              height: 30,
                              color: "#fff",
                              // backgroundColor: "rgba(255, 255, 255, 1)",
                              borderRadius: 5,
                            }}
                          />
                          <span className="sh-para sh-para--white text-center">Update <br /> Profile Image</span>
                        </label>
                        <input type="file" onChange={handleFileUpload} style={{ display: "none" }} id="image-upload" />
                      </div>
                    </div>
                  </div>
                  <div className="sh-profile__right">
                    <div className="sh-profile__right-inner">
                      <div className="sh-profile__edit-title">Update Profile</div>
                      <form onSubmit={validate}>
                        <div className="form-content">
                          <div className="login-form-content mb-0">
                            <div className="signup-wrap-content basic-detail m-3 m-b-2 ">
                              <div className="row">
                                <div className="col-12 col-md-6">
                                  <div className="form-group sh-input__col custom-label">
                                    <label className="sh-input__label">
                                      Name <sup className="sh-input__sub">*</sup>
                                    </label>
                                    <input
                                      className="form-control"
                                      // placeholder="John Doe"
                                      onChange={handleOnChange}
                                      value={user.name}
                                      type="text"
                                      name="name"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className="form-group sh-input__col  custom-label">
                                    <label className="sh-input__label">Email ID</label>
                                    <input
                                      className="form-control"
                                      disabled
                                      //  placeholder="Yogeshnpl@gmail.com"
                                      value={user.email}
                                      type="email"
                                      name="email"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className="form-group sh-input__col  custom-label">
                                    <label className="sh-input__label">Phone Number</label>
                                    <input
                                      className="form-control"
                                      // placeholder={9815984207}
                                      onChange={handleOnChange}
                                      value={user.phoneNumber}
                                      type="number"
                                      name="phoneNumber"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className="form-group sh-input__col select-group  custom-label">
                                    <label className="sh-input__label" htmlFor="industry1">
                                      Industry <sup className="sh-input__sub">*</sup>
                                    </label>
                                    <select
                                      className="form-control"
                                      id="industry1"
                                      onChange={handleOnChange}
                                      //  placeholder="Entry value 1"
                                      value={user.industry}
                                      name="industry"
                                    >
                                      <option value="" disabled>
                                        --select--
                                      </option>
                                      {industries.map((industry) => (
                                        <option key={industry} value={industry}>
                                          {industry}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className="form-group sh-input__col select-group  custom-label">
                                    <label className="sh-input__label" htmlFor="exampleFormControlSelect12">
                                      Years of Experience <sup className="sh-input__sub">*</sup>
                                    </label>
                                    <select
                                      onChange={handleOnChange}
                                      value={user.experience}
                                      className="form-control"
                                      id="exampleFormControlSelect12"
                                      name="experience"
                                    >
                                      <option value="">Select Option</option>
                                      <option value="0">0-8</option>
                                      <option value="8">8-12</option>
                                      <option value="12">{">12"}</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className="form-group sh-input__col  custom-label">
                                    <label className="sh-input__label">Role</label>
                                    <input
                                      className="form-control"
                                      // placeholder="Role "
                                      onChange={handleOnChange}
                                      type="text"
                                      name="role"
                                      value={user.role}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className="form-group sh-input__col select-group  custom-label">
                                    <label className="sh-input__label" htmlFor="domain1">
                                      Domain <sup className="sh-input__sub">*</sup>
                                    </label>
                                    <select
                                      id="domain1"
                                      className="form-control"
                                      //  placeholder="Domain"
                                      onChange={handleOnChange}
                                      value={user.domain}
                                      type="text"
                                      name="domain"
                                    >
                                      <option value="" disabled>
                                        --select--
                                      </option>
                                      {domains.map((d) => (
                                        <option key={d} value={d}>
                                          {d}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                {userType === "mentee" && (
                                  <div className="col-12 col-md-6">
                                    <div className="form-group sh-input__col  custom-label">
                                      <label className="form-label sh-input__label" htmlFor="resume">
                                        Resume<sup className="sh-input__sub">*</sup>
                                      </label>
                                      <input
                                        className="form-control sh-input__label"
                                        id="resume"
                                        onChange={e => {
                                          if (e.target.files[0]) {
                                            setResume(e.target.files[0])
                                          }
                                        }}
                                        type="file"
                                      // name="email"
                                      />
                                    </div>
                                  </div>
                                )}

                                <div className="col-12">
                                  <div className="form-group sh-input__col  custom-label">
                                    <label className="sh-input__label">
                                      LinkedIn Profile Link {userType === 'mentor' && (<sup className="sh-input__sub">*</sup>)}
                                    </label>
                                    <input
                                      onChange={handleOnChange}
                                      className="form-control"
                                      value={user.linkedInLink}
                                      type="text"
                                      name="linkedInLink"
                                    />
                                  </div>
                                </div>
                              </div>
                          <div className="text-right">
                          <button onClick={()=>{history.push('/profile')}} className="sh-button sh-button__plain-arrow mr-5"  type="button">
                                  Cancel
                                </button>
                                <button className="sh-button  mt-3" type="submit">
                                  Update
                            </button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

      </Layout>

  );
};

export default EditProfile;
