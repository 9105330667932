import React, { Component } from "react"; 

import NewHeader from "./static/newHeader";
import moment from "moment";
import { subscription } from "../../NetworkRequest/requests/freeUser";
import { getOneBlog } from "../../NetworkRequest/requests/blogs";
// import ReactHtmlParser, {
//   processNodes,
//   convertNodeToElement,
//   htmlparser2,
// } from "react-html-parser";

class BlogDetails1 extends Component {
  state = {
    allBlogs: [],
    blog: "",
    email:""
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    console.log(`this.props.match.params.blogId = ${this.props.match.params.blogId}`);

    getOneBlog(this.props.match.params.blogId).then((res) => {
      this.setState({ blog: res });
      window.scrollTo(0, 0);
    });
  }
  static getDerivedStateFromProps() {
    window.scrollTo(0, 0);
  }
  handleEmailChange = (e) => {
    e.preventDefault();
    let email = e.target.value;
    this.setState({ email });
  };

  onClick = () => {
    subscription(this.state.email).then((res) => {
      console.log({ res });
    });
  };

  render() {
    let blog1 = /* JSON.parse(localStorage.getItem("blog")); */ this.state.blog;

    return (
      <div className="wrapper container-width">
        <NewHeader props={this.props} />

        <section className="sh-section mt-5 pb-0">
          <div className="sh-section__inwrap">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="sh-title">{blog1.title}</div>
                  <div className="sh-blog__detail-text sh-text"><span className="font-weight-bold">Posted On</span> : {moment(blog1.created_on).format("ll")}</div>
                  <div className="my-4">
                    <img className="img-fluid" src={blog1.image} alt={blog1.title + " | Shevolve - SheWrites"} />
                  </div>
                  <div className="sh-blog__detail-para sh-text">
                        {blog1.description ? (
                          <p
                            className="sec-content"
                            dangerouslySetInnerHTML={{ __html: blog1.description }}
                          >
                            {/* {ReactHtmlParser(blog1.description)} */}
                          </p>
                        ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        
       
      </div>
    );
  }
}

export default BlogDetails1;