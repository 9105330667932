import NewHeader from "../Components/static/newHeader";
import ScrollToTop from "react-scroll-to-top";

const Layout = (props) => {
    return (
        <div className={`wrapper container-width ${props.className}`} style={{ paddingTop: 67 }}>
            <NewHeader/>
            <main>
                {props.children}
                {/* <ScrollToTop smooth color="#ffffff" style={{ backgroundColor: "#3696a0" }} width="18" /> */}
            </main>
        </div>
    )
}

export default Layout;