import React, { useEffect, useState } from "react";
import { domains, industries, USER_TYPE } from "../../Constants";
import Styles from "../../../styles";
import NewHeader from "../static/newHeader";
import { Avatar } from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateMentorProfile } from "../../../NetworkRequest/requests/mentor";
import { updateMentee, updateMenteeDetails, updateMentorProfilePic } from "../../../NetworkRequest/requests/mentee";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateProfileAction } from "../../../reducer/action";
import { uploadFileOnAWS } from "../../../adminPanel/src/helper/aws";
import axios from "axios";
import { BASE_URL } from "../../../constant";
import Layout from '../../layout/default';
import { useLocation } from "react-router-dom";
import moment from "moment";

const ListOfSessions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userType = localStorage.getItem(USER_TYPE);
  const { userData } = useSelector((state) => state.loginSignPopUp);
  const isLogin = localStorage.getItem("accessToken");
  const [user, setUser] = useState({});
  const [file, setFile] = useState("");
  const [resumeFile, setResume] = useState("");
  const [allSchedules, SetallSchedules] = useState([]);
  const location = useLocation()
  const packageDetails = location.state;
  const userId = location.state.id;
  console.log(location.state.startdate,"package")

  function tConvert (time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }
  useEffect(() => {
    getSchedule();
    window.scrollTo(0, 0)

  }, []);
  const getSchedule = async () => {


    try {
      const items = [];
      let menteeSchedule = await axios.get(`${BASE_URL}mentee/getMenteeschedule?mentee=${userId}`).then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error)//Logs a string: Error: Request failed with status code 404
      });
      SetallSchedules(menteeSchedule);

    }
    catch (err) {
      console.log(err)
    }
  }


  return (
    <Layout>
        {/* <Styles />
        <NewHeader /> */}

        <section className="sh-section sh-section--green pt-0">
            <div className="sh-section__inwrap">
              <div className="sh-profile__cover-background"></div>
                <div className="container my-2 sh-profile__details">
                  <div className="sh-profile__sessions-wrap">
              <div className="mb-4 w-100 col-12 sh-profile__agenda-wrap sh-profile__right-inner">
                <div>
                <>
                      <div className="sh-profile__section-title">Packages Details</div>
                      <div className="sh-profile__package sh-profile__row d-flex align-items-center mt-4">
                        <span className="sh-profile__package-icon">
                        <img  src={packageDetails.Package=="Rise Up"?"/images/profile/rise-up.svg":packageDetails.Package=="Fly High"?"/images/profile/fly-high.svg":packageDetails.Package=="She Tribe"?"/images/profile/she-tribe.svg":"alt"} className="w-100" alt="rise-up" />
                        </span>
                        <div className="sh-profile__name position-relative">{packageDetails.Package}</div>
                      </div>
                  </>
                  {(packageDetails.Package == "Rise Up" || packageDetails.Package == "Fly High" || packageDetails.Package == "She Tribe") &&
                        <div className="row sh-profile__row mb-0">
                      {(packageDetails.startdate && packageDetails.enddate) &&
                        <>
                          <div className="sh-profile__col col-md-6">
                            <div className="sh-profile__label">Duration</div>
                            <div className="sh-profile__item"><span>From {packageDetails.startdate} To {packageDetails.enddate}</span>
                            </div>
                          </div>
                        </>
                      }
                      {packageDetails.mentor != "" &&
                          <div className="sh-profile__col col-md-3">
                            <div className="sh-profile__label">Mentor</div>
                            {packageDetails.mentor &&
                              <div className="sh-profile__item">{packageDetails.mentor} </div>}
                          </div>
                          }
                    </div>}


                      {/* Here to Paste */}
                </div>
                <div>
                  <div className="sh-profile__label mb-4">List of sessions</div>
                        <table class="table table-striped sh-profile__agenda-table" style={{fontSize:"17px"}}>
                          <thead>
                            <tr className="sh-profile__agenda-row">
                              <th>#</th>
                              <th style={{width:"30%"}}>Title</th>
                              <th>Session Date</th>
                              <th>Session Start time</th>
                              <th>Session End time</th>
                            </tr>
                          </thead>

                          {allSchedules.map((ele, index = 0) =>
                            <tbody>
                              <tr className="sh-profile__agenda-row">

                                <td>{index + 1}</td>
                                <td>{ele.title}</td>
                                <td>{moment(ele.sessiondate).format("LL")}</td>
                                <td>{tConvert(ele.starttime)}</td>
                                <td>{tConvert(ele.endtime)}</td>
                              </tr>


                            </tbody>
                          )
                          }
                  </table>
                </div>
                        <div className="text-right">
                          <button className="sh-button  mt-3" onClick={()=>{history.push("/profile")}} type="button">
                                          Back
                          </button>
                        </div>

                      </div>
                  </div>
                </div>
            </div>
          </section>

      </Layout>

  );
};

export default ListOfSessions;
