import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import { addDays } from "date-fns";
import moment from "moment";
import { toast } from "react-toastify";

const bg = {
  modal: { borderRadius: 16 },
  overlay: {},
};

const AddAvailabilityDialog = ({ type, title, open, onDate, onClose, defaultDate }) => {
  const [selectedDate, setSelectedDate] = useState(moment().format("DD-MM-YYYY"));
  const [time, setTime] = useState("08:00");
  const [from, setfrom] = useState("08:00");
  const [to, setto] = useState("9:00");

  const handleSubmitClicked = (e) => {
    e.preventDefault();
    try {
      if (type == "single") {
        const dates = selectedDate.split("-");
        const t = time.split(":");
        const newDate = new Date(+dates[2], +dates[1] - 1, +dates[0], +t[0], +t[1]);
        if (newDate !== "Invalid Date") {
          onDate(newDate);
        } else {
          toast("Invalid Date");
        }
      } else {
        console.log("else is called");
        if (from === "") {
          toast("From should not be empty");
          return;
        }
        if (to === "") {
          toast("To should not be empty");
          return;
        }
        const t = to.split(":");
        const f = from.split(":");
        const newtoDate = new Date(defaultDate);
        newtoDate.setHours(t[0] );
        newtoDate.setMinutes(0);
        // const newtoDate = new Date(+dates[2], +dates[1] - 1, +dates[0], +t[0], +t[1]);
        const newFromDate = new Date(defaultDate);
        newFromDate.setHours(f[0]);
        newFromDate.setMinutes(0);
        // const newFromDate = new Date(+dates[2], +dates[1] - 1, +dates[0], +f[0], +f[1]);
        onDate && onDate(newFromDate, newtoDate);
        // onDate()
      }
    } catch (e) {
      console.log(e);
      toast("Invalid Date", e);
    }
  };

  const next30days = () => {
    const days = [];
    for (let i = 0; i <= 30; i++) {
      days.push(addDays(new Date(), i));
    }
    return days;
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
      // showCloseIcon={false}
      center
      styles={bg}
    >
      <div
        style={{ display: "flex" }}
        // className={"modal fade"}
        id="news-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl form-size my-0">
          <div className="modal-content form-content basic-modal-content email-modal-content">
            <div
              //style={{borderRadius : 5}}
              className="news-wrap"
            >
              <div className="row no-gutters">
                <div className="col-md-12 form-padlr">
                  <div className="form-block">
                    <div className="basic-detail-header" style={{ marginTop: "-1px" }}>
                      <div className="flex-space">
                        <h6 className="sec-title">{title || "Add availability"}</h6>
                        {/* <a href="javascript:void(0);">
											<i className="fas fa-times" />
										</a> */}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <div className="col-md-12">
                        <div className="email-text-content detail-text-content">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group select-group">
                                <label htmlFor="exampleInputDate">Date</label>
                                {defaultDate ? (
                                  <input
                                    type="text"
                                    disabled
                                    value={moment(defaultDate).format("DD MMMM YYYY")}
                                    className="form-control"
                                  ></input>
                                ) : (
                                  <select
                                    className="form-control"
                                    id="exampleInputDate"
                                    value={selectedDate}
                                    onChange={(e) => {
                                      setSelectedDate(e.target.value);
                                    }}
                                  >
                                    {next30days().map((d) => (
                                      <option key={"dd" + d}>{moment(d).format("DD-MM-YYYY")}</option>
                                    ))}
                                  </select>
                                )}
                              </div>

                              {type === "single" ? (
                                <div>
                                  <div className="form-group select-group">
                                    <label htmlFor="exampleInputName">Time</label>
                                    <input
                                      type="time"
                                      defaultValue={"08:00"}
                                      value={time}
                                      onChange={(e) => setTime(e.target.value)}
                                      className="form-control"
                                      id="exampleInputName"
                                      name="name"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="form-group select-group">
                                      <label htmlFor="exampleInputName">From</label>
                                      <input
                                        type="time"
                                        value={from}
                                        onChange={(e) => setfrom(e.target.value)}
                                        className="form-control"
                                        id="exampleInputName"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="form-group select-group">
                                      <label htmlFor="ex">To</label>
                                      <input
                                        type="time"
                                        value={to}
                                        onChange={(e) => setto(e.target.value)}
                                        className="form-control"
                                        id="ex"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="btn-wrap">
                                <button
                                  type="submit"
                                  className="btn btn-gradient-primary w-100"
                                  // data-dismiss="modal"
                                  onClick={handleSubmitClicked}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddAvailabilityDialog;
