import axios from "axios";
import { BASE_URL } from "../constant";
import {USER_TYPE} from "../website/Constants";

const AxiosBase = axios.create({
    baseURL: BASE_URL
    // baseURL:"https://api.shevolve.in/"
    //baseURL: "http://13.233.214.122:8000/"
});

AxiosBase.interceptors.request.use(
    async (config) => {
        const token = await localStorage.getItem("accessToken");
        
        config.headers["accesstoken"] = token;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

AxiosBase.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error && error.response && error.response.status === 401) {
            // localStorage.removeItem("accessToken");
            // localStorage.removeItem(USER_TYPE);
            // localStorage.removeItem('userData');
            // // window.location = "localhost:3000";
            // window.location.href = "/"
            // console.log('unauth called');
        }
        return error;
    }
);

export default AxiosBase;

export const handleError = (error) => {
    alert("handle Error");
    console.log("HandleError : ", JSON.stringify(error));
    if (error.response.status === 404) {
        // showMessage(error.response.data.Message)
    }
};
