export const BASE_URL = 
// 'https://api.shevolve.in/';
'https://staging-apis.shevolve.in/';
//  "http://13.233.214.122:8000/";
// 'http://localhost:8000/';
export const ROZER_PAY_KEY =
 'rzp_live_bSS19msKOtDwcf';
//  'rzp_test_IlRUPUo4llGD0j';

// export const LOCAL_URL='http://localhost:8000/';
