import React from "react";
import AsyncStorage from "@react-native-community/async-storage";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AdminApp from "./adminPanel/src/App";
import {Provider} from "react-redux"
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import {persistReducer} from 'redux-persist'

import rootReducer from './reducer/rootreducer';
import logger from "redux-logger";

import './scss/style.scss'


const persistConfig = {
  key: 'root',
  storage: AsyncStorage
}

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = createStore(
    persistedReducer,
   compose(applyMiddleware(thunk, logger))
  );

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <App />      */}
      <AdminApp/> 
      <Provider store={store}><App /></Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
); 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
  