import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getAllCourses } from "../../adminPanel/networkRequests/requests/course";
import NewHeader from "./static/newHeader";

const Courses = (props) => {
  const [courseList, setCourseList] = useState([]);

  useEffect(() => {
    console.log("course is ", props.history);
    getAllCourses()
      .then((res) => setCourseList(res))
      .catch((e) => toast.error("Loading failed"));
  }, []);

  const contentTotalDuration = (course) => {
    let hour = 0;
    let minute = 0;
    let second = 0;

    for (let i of course.content) {
      const splitTime = i.videoDuration.split(":");
      if (splitTime.length >= 2) {
        hour += parseInt(splitTime[0]);
        minute += parseInt(splitTime[1]);
        second += parseInt(splitTime[2]);
      }
    }

    if (second > 59) {
      minute += Math.floor(second / 60);
      // minute = minute % 60;
    }

    if (minute > 59) {
      hour += Math.floor(minute / 60);
      minute = minute % 60;
    }
    return { hour, minute };
  };

  return (
    <div className="wrapper container-width">
      {/*header*/}
      <NewHeader props={props} from="courses" />
      <div className="banner-wrap blog-banner-wrap blog-detail-image" style={{ backgroundImage: 'url("/assets/images/courses.png")' }}>
        <div className="banner-overlay">
          <div className="container">
            <div className="banner-content" />
          </div>
        </div>
      </div>

      {/* banner sec close */}

      <div className="content pt-0">
        <div className="blog-tab-wrap">
          <div className="event-blog-wrap sec-pad pb-0">
            <div className="container">
              <div className="event-blog-content">
                <div className="row">
                  {/* courses list here  */}
                  {courseList.map((course) => (
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-lg-3 mb-3">
                      <div
                        className="blog-content-wrap"
                        style={{ cursor: "pointer" }}
                        onClick={(h) => {
                          props.history.push(`/courses-details/${course._id}${props.history.location.search}`);
                        }}
                      >
                        <figure className="image-wrap">
                          <img className="img-fluid" style={{ objectFit: "cover", maxHeight: 200 }} src={course.image} />
                          <div className="tip-title">
                            <span style={{ fontSize: 12, fontWeight: "bold" }}>{course.isFree ? "Free" : "₹" + course.price}</span>
                          </div>
                        </figure>
                        <div className="blog-text-content">
                          <p
                            className="sec-title text-black-color"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {course.courseName}
                          </p>
                          <p
                            className="sec-content"
                            style={{
                              height: 62.4,
                              color: "#525252",
                              fontSize: 14,
                            }}
                          >
                            {course.courseTitle}
                          </p>

                          <div className="meta-info flex-space">
                            <div className="days" style={{ fontSize: 12 }}>
                              {course.content.length} Modules &bull; {contentTotalDuration(course).hour}hr{" "}
                              {contentTotalDuration(course).minute}min
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
