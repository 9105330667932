import { useSelector } from "react-redux";
import { USER_TYPE } from "../../Constants";

const MentorProfile = ({ history, noEdit = false , userData }) => {
  const userType = localStorage.getItem(USER_TYPE);
  const mentor =  useSelector((state) => state.loginSignPopUp.userData);
  const state = userData || mentor;

  return (
    <section className="sh-section sh-section--green">
        <div className="sh-section__inwrap">
          <div className="mentor-home-banner">
            <div className="container my-2 ">
              <div className="d-md-flex align-items-start">
                <div className="sh-profile__image">
                  <img className="img-fluid w-100 h-100" style={{ objectFit: "cover", objectPosition: "center"}}  src={state.profilePic ? state.profilePic : "/assets/images/casual_profile.png"} alt="profile" />
                </div>
                <div className="ml-md-5 mt-5 mt-md-0">
                  <div className="d-flex">
                    <img src="/assets/images/butterfly.svg" alt="" />
                    <h4 className="ml-3 sh-profile__name" style={{ color: "#0D5975", textTransform: "capitalize" }}>
                      {state.name}
                    </h4>
                  </div>
                  {/* <p className="f-size-12">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's. Lorem Ipsum is
                    simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.
                  </p> */}
                  <div className="mentor-home-content mt-3">
                    <p className="mb-3">
                      <span className="sh-profile__label">Phone Number</span> : <span className="sh-profile__data">{state.phoneNumber}</span>
                    </p>
                    <p className="mb-3">
                      <span className="sh-profile__label">Industry</span> : <span className="sh-profile__data">{state.industry}</span>
                    </p>
                    <p className="mb-3">
                      <span className="sh-profile__label">Role </span> : <span className="sh-profile__data">{state.role}</span>
                    </p>
                    <p className="mb-3">
                      <span className="sh-profile__label">Domain </span> : <span className="sh-profile__data">{state.domain}</span>
                    </p>
                    <p className="mb-3">
                      <span className="sh-profile__label">Years of Experience </span> : <span className="sh-profile__data">{state.experience}</span>
                    </p>
                    {userType === 'mentee' &&  state.resume && (
                      <p className="mb-3">
                      <span className="sh-profile__label">Resume </span> : <span className="sh-profile__data">{state.resume.split('/').pop() }</span>
                    </p>
                    )}
                
                  </div>
                  {!noEdit && (
                    <button className="sh-button" onClick={(e) => history.push("/profile/edit")}>
                      <i class="fas fa-pen mr-2"></i> Edit Profile
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
};

export default MentorProfile;
