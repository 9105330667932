import React, { useState, useRef, useEffect } from 'react'
import './mentor.scss';
import {Helmet} from "react-helmet"
import OwlCarousel from "react-owl-carousel";
import { useLayoutEffect } from "react";
import {AnimWrap} from "../animation-gsap/Animation"
import { gsap } from "gsap";
import Layout from '../../layout/default';


const techMentor = [
    {
        image:
            "/assets/images/tech mentor/Ramya Narasimhan_Leader - Product and Industrtimetrik.jpg",
        name: "Ramya Narasimhan",
        role: "Leader - Product and Industry Practice",
        company: "/assets/images/Company Logo_s/Altimetric logo.jpg",
    },
    {
        image:
            "/assets/images/tech mentor/Haridhra Shankar_Subject Metter Expert_Amazon.jpg",
        name: "Haridhra Shankar",
        role: "Subject Matter Expert",
        company: "/assets/images/Company Logo_s/amazon.png",
    },
    {
        image:
            "/assets/images/tech mentor/Kaivalya Gundu_Senior Manager_EY GDS.jpg",
        name: "Kaivalya Gundu",
        role: "Senior Manager",
        company: "/assets/images/Company Logo_s/EY.png",
    },
    // {
    //     image:
    //         "/assets/images/tech mentor/Kuppulakshmi Krishnamoorthy_Global Head—Zoho for Startups_Zoho Corporation.jpg",
    //     name: "Kuppulakshmi Krishnamoorthy",
    //     role: "Global Head",
    //     company: "/assets/images/Company Logo_s/zoho.svg",
    // },
    {
        image:
            "/assets/images/tech mentor/Manvi Tyagi_Software Engineer_Twitter.jpg",
        name: "Manvi Tyagi",
        role: "Software Engineer",
        company: "/assets/images/Company Logo_s/google-logo.jpg",
    },

    // {
    //     image:
    //         "/assets/images/tech mentor/Nirali Upadhyaya Shah_Senior Product Manager_Media.net.jpg",
    //     name: "Nirali Upadhyaya Shah",
    //     role: "Senior Product Manager",
    //     company: "/assets/images/Company Logo_s/media.net.png",
    // },

    {
        image:
            "/assets/images/tech mentor/Preethi Guruswamy_Group Development Manager at Intuit_Network Leader at Lean In.jpg",
        name: "Preethi Guruswamy",
        role: "Network Leader",
        company: "/assets/images/Company Logo_s/lean_in.png",
    },

    // {
    //     image:
    //         "/assets/images/tech mentor/Rajashree Rao_Head of AI Innovation Hub _ Partnerships _ Ecosystem (APAC)_Rolls Royce.jpg",
    //     name: "Rajashree Rao",
    //     role: "Head of AI Innovation Hub",
    //     company: "/assets/images/Company Logo_s/Rolls Royce logo.jpg",
    // },

    // {
    //     image:
    //         "/assets/images/tech mentor/Ramya Narasimhan_Leader - Product and Industry Practice_Altimetrik.jpg",
    //     name: "Ramya Narasimhan",
    //     roll: "Leader - Product and Industry",
    //     company: "/assets/images/Company Logo_s/Altimetric logo.jpg",
    // },

    {
        image:
            "/assets/images/tech mentor/Revathy Kotteeswaran_Principal Consultant_PwC India.jpg",
        name: "Revathy Kotteeswaran",
        role: "Principal Consultant",
        company: "/assets/images/Company Logo_s/PwC India logo.jpg",
    },

    {
        image:
            "/assets/images/tech mentor/Scindia Balasingh_Head Partner Marketing , Global ISV _ Strategic Partnerships_Freshworks.jpg",
        name: "Scindia Balasingh",
        role: "Head of Partner Marketing",
        company:
            "/assets/images/Company Logo_s/multiplier.jpg",
    },
    {
        image:
            "/assets/images/International mentors/Shrinedhi Rajan_Product Manager_PointsBet.jpg",
        name: "Shrinedhi Rajan",
        role: "Product Manager",
        company: "/assets/images/Company Logo_s/pointsbet-logo.png",
    },
    {
        image:
            "/assets/images/Aspirational mentors/Ramya Lakshmanan_Director of Account Management_Plum.jpg",
        name: "Ramya Lakshmanan",
        role: "Director",
        company: "/assets/images/Company Logo_s/Plum logo.jpg",
    },
    // {
    //     image:
    //         "/assets/images/Aspirational mentors/Samina Ali_Product Manager_nurture.farm (A UPL group company).jpg",
    //     name: "Samina",
    //     role: "Product Manager",
    //     company: "/assets/images/Company Logo_s/nurture.farm (A UPL group company)_logo.jpg",
    // },
    {
        image:
            "/assets/images/Sumana Mukherjee_Aerospace & Space engineer,  Diversity & inclusion champion, and Arts & Culture enthusiast.jpg",
        name: "Sumana Mukherjee",
        role: "Aerospace & Space engineer,  Diversity & inclusion champion, and Arts & Culture enthusiast",
        company: "/assets/images/Company Logo_s/Darbar.jpg",
    },
    {
        image:
            "/assets/images/Sangeeta.jpg",
        name: "Sangeeta Rajan Vernekar",
        role: "Architect, Designer, HR, Mentor & Strategist",
        company: "/assets/images/Company Logo_s/Ravedesign.jpg",
    },
    {
        image:
            "/assets/images/Vijaya.jpg",
        name: "Vijaya Yalamarthi",
        role: "Senior Director",
        company: "/assets/images/Company Logo_s/S & P Global.jpg",
    },
    {
        image:
            "/assets/images/naksha-erappa.png",
        name: "Naksha Erappa",
        role: "Strategy and Operations Manager",
        company: "/assets/images/Company Logo_s/boeing.jpg",
    },
    {
        image:
            "/assets/images/havilah.png",
        name: "Havilah Gunasekar",
        role: "Sr QA Manager ",
        company: "/assets/images/Company Logo_s/amazon-labs.jpg",
    },
    {
        image:
            "/assets/images/urvashi-vaid-fortnet.png",
        name: "Urvashi Vaid Chhibber",
        role: "Head of Fortinet’s Technology Vertical for India and SAARC.",
        company: "/assets/images/Company Logo_s/fortinet.jpg",
    },
    {
        image:
            "/assets/images/riaz-paypal.png",
        name: "Riaz Ibrahim Mohamed",
        role: "Information Security Manager",
        company: "/assets/images/Company Logo_s/paypal.jpg",
    },
    {
        image:
            "/assets/images/srinivasan-krishnan-freshworks.png",
        name: "Srinivasan Krishnan",
        role: "Senior Director of Engineering",
        company: "/assets/images/Company Logo_s/freshworks.jpg",
    },
    {
        image:
            "/assets/images/gopinath.png",
        name: "Gopinath Krishnamoorthy",
        role: "Principal Technical Program Manager",
        company: "/assets/images/Company Logo_s/nvidia.jpg",
    },
    {
        image:
            "/assets/images/vidhya-paypal.png",
        name: "Vidhya",
        role: "Senior Manager - Machine Learning",
        company: "/assets/images/Company Logo_s/paypal.jpg",
    },

];

// const internationalMentors = [


//     {
//         image:
//             "/assets/images/International mentors/Shubham Saloni_Wireless Software Developer_Aruba Networks.jpg",
//         name: "Shrinedhi Rajan",
//         role: "Wireless Software Developer",
//         company: "/assets/images/Company Logo_s/Aruba Networks logo.jpg",
//     },
// ];

const industryMentors = [
    // {
    //     image:
    //         "/assets/images/Industry mentors/Awantika Bhardwaj_Vice President - Employee Success_Abzooba.jpg",
    //     name: "Awantika Bhardwaj",
    //     role: "Vice President",
    //     company: "/assets/images/Company Logo_s/abzooba.png",
    // },

    // {
    //     image:
    //         "/assets/images/Industry mentors/Bhaduri SaravanaKumar_Co-Founder_Shevolve.jpg",
    //     name: "Bhaduri SaravanaKumarj",
    //     role: "Co-Foundert",
    //     company: "/assets/images/ShevolveLogo.png",
    // },

    // {
    //     image:
    //         "/assets/images/Industry mentors/Deepa Vijayaraghavan_Founder_Shevolve.jpeg",
    //     name: "Deepa Vijayaraghavan",
    //     role: "Founder",
    //     company: "/assets/images/ShevolveLogo.png",
    // },

    {
        image:
            "/assets/images/Industry mentors/Jayashri Ravi_General Manager - Product_Matrimony.com Limited.jpg",
        name: "Jayashri Ravi",
        role: "General Manager",
        company: "/assets/images/Company Logo_s/Matrimony.com Limited logo.jpg",
    },

    {
        image:
            "/assets/images/Industry mentors/Kanchan Bhonde_Assistant Vice President_Tech Mahindra.jpg",
        name: "Kanchan Bhonde",
        role: "Assistant Vice President",
        company: "/assets/images/Company Logo_s/Tech Mahindra logo.jpg",
    },

    {
        image:
            "/assets/images/Industry mentors/Lakshmi RR_Head Diversity _ Inclusion and HR Site Lead_Fidelity Investments.jpg",
        name: "Lakshmi RR",
        role: "Head Diversity & Inclusion",
        company: "/assets/images/Company Logo_s/Fidelity Investments logo.jpg",
    },

    {
        image:
            "/assets/images/Industry mentors/Nrithya Rajagopalan_Vice President, Product Development and Services_LeanSwift Solutions India Pvt. Ltd..jpg",
        name: "Nrithya Rajagopalan",
        role: "Vice President",
        company:
            "/assets/images/Company Logo_s/LeanSwift Solutions India Pvt. Ltd._logo.jpg",
    },

    {
        image:
            "/assets/images/Industry mentors/Padmini Janaki_CEO_Mind and Mom.jpg",
        name: "Padmini Janaki",
        role: "CEO",
        company: "/assets/images/Company Logo_s/Mind and Mom logo.jpg",
    },

    {
        image:
            "/assets/images/Industry mentors/Rani Pangam_Sr. Dir. Strategy Realization Office, Enterprise Architecture _ PMO_ServiceNow.jpg",
        name: "Rani Pangam",
        role: "Sr. Dir. Strategy Realization",
        company: "/assets/images/Company Logo_s/ServiceNow logo.jpg",
    },

    {
        image:
            "/assets/images/Industry mentors/Sanchita Ganguly_TitleBrand Building Head - Vice President_UltraTech Cement.jpg",
        name: "Sanchita Ganguly",
        role: "Vice President",
        company: "/assets/images/Company Logo_s/UltraTech Cement logo.jpg",
    },

    {
        image:
            "/assets/images/Industry mentors/Shreedevi Ramesh_Co-Founder_Gurus360 Knowledge Services.jpg",
        name: "Shreedevi Ramesh",
        role: "Co-Founder",
        company: "/assets/images/Company Logo_s/Gurus360 logo.jpg",
    },

    // {
    //     image:
    //         "/assets/images/Industry mentors/Tina Rastogi_VP-Human Capital Management_Cybage Software.jpg",
    //     name: "Tina Rastogi",
    //     role: "VP-Human Capital Management",
    //     company: "/assets/images/Company Logo_s/Cybage Software logo.jpg",
    // },

    // {
    //     image:
    //         "/assets/images/Industry mentors/Vanipriya_Director _ Head of Co-Creation_Founder at Zeekh.jpg",
    //     name: "Vanipriya",
    //     role: "Director and Head of Co-Creation",
    //     company: "/assets/images/Company Logo_s/exotel logo.jpg",
    // },
    {
        image:
            "/assets/images/coping mentors/Janet Rajan_Founder_Principal at Growth Collective_Executive Coach at Righ Management.jpg",
        name: "Janet Rajan",
        role: "Founder - Principal",
        company: "/assets/images/Company Logo_s/Growth collective logo.jpg",
    },

    {
        image: "/assets/images/coping mentors/Samiksha Seth_Product Manager.jpeg",
        name: "Samiksha Seth",
        role: "Product Manager",
        // company: "/assets/images/Company Logo_s/Growth collective logo.jpg",
    },

//////////////////////////////////



    {
        image: "/assets/images/Nupur.jpg",
        name: "Nupur Bartwal",
        role: "Mentor, Certified Life Coach, & Consultant",
        company: "/assets/images/Company Logo_s/Aspire.jpg",
    },
    {
        image: "/assets/images/Vikas.jpg",
        name: "Vikas Jain",
        role: "Head of Growth, Author, and Speaker",
        company: "/assets/images/Company Logo_s/Vecto Scalar.jpg",
    },
    {
        image: "/assets/images/Nancy.jpg",
        name: "Nancy Shah",
        role: "Founder",
        company: "/assets/images/Company Logo_s/Speaker's circle.jpg",
    },
    {
        image: "/assets/images/DSC_2436_edited-1.jpg",
        name: "Shyleswari",
        role: "Founder/Director",
        company: "/assets/images/Company Logo_s/Ved Vyas Inner Space.png",
    },
    {
        image: "/assets/images/Mansi.jpg",
        name: "Mansi Biyani",
        role: "CEO & Founder",
        company: "/assets/images/Company Logo_s/MBDH LOGO large background.jpg",
    },
    {
        image: "/assets/images/Shyam Alok.jpg",
        name: "Shyam Alok",
        role: "Director, Digital Transformation and Head of Product Management",
        company: "/assets/images/Company Logo_s/OTSI.jpg",
    },
    {
        image: "/assets/images/Meera.jpg",
        name: "Meera Srivastava",
        role: "Head of Business",
        company: "/assets/images/Company Logo_s/Woop.jpg",
    },
    {
        image: "/assets/images/Shreya.jpg",
        name: "Dr. Shreya Govind",
        role: "Sr. Consultant. Performance Coach, Life Skill Trainer",
        company: "/assets/images/Company Logo_s/Ladder.jpg",
    },
    {
        image: "/assets/images/sangeeta-ifb.png",
        name: "Sangeeta Shankaran Sumesh ",
        role: "CFO Turned Business & Leadership Coach, Author, & Speaker",
        company: "/assets/images/Company Logo_s/ifb.jpg",
    },
    {
        image: "/assets/images/shelly-saini.png",
        name: "Shelly Saini",
        role: "Entrepreneur in Residence",
        company: "/assets/images/Company Logo_s/growth-school.jpg",
    },
    {
        image: "/assets/images/priya-medawar.png",
        name: "Priya G Medewar",
        role: "Head OD",
        company: "/assets/images/Company Logo_s/panasonic.jpg",
    },
    {
        image: "/assets/images/suman-verma-hamdard.png",
        name: "Suman Varma",
        role: "Chief Marketing Officer",
        company: "/assets/images/Company Logo_s/hamdard.jpg",
    },
    {
        image: "/assets/images/kavya-freshworks.png",
        name: "Kavya Hemachander",
        role: "Global Partner Marketing Manager",
        company: "/assets/images/Company Logo_s/freshworks.jpg",
    },
];

// const industryMentor = [
//     '/assets/images/Industry mentors/Awantika Bhardwaj_Vice President - Employee Success_Abzooba.jpg',
//     '/assets/images/Industry mentors/Bhaduri SaravanaKumar_Co-Founder_Shevolve.jpg',
//     '/assets/images/Industry mentors/Deepa Vijayaraghavan_Founder_Shevolve.jpeg',
//     '/assets/images/Industry mentors/Jayashri Ravi_General Manager - Product_Matrimony.com Limited.jpg',
//     '/assets/images/Industry mentors/Kanchan Bhonde_Assistant Vice President_Tech Mahindra.jpg',
//     '/assets/images/Industry mentors/Lakshmi RR_Head Diversity _ Inclusion and HR Site Lead_Fidelity Investments.jpg',
//     '/assets/images/Industry mentors/Nrithya Rajagopalan_Vice President, Product Development and Services_LeanSwift Solutions India Pvt. Ltd..jpg',
//     '/assets/images/Industry mentors/Padmini Janaki_CEO_Mind and Mom.jpg',
//     '/assets/images/Industry mentors/Rani Pangam_Sr. Dir. Strategy Realization Office, Enterprise Architecture _ PMO_ServiceNow.jpg',
//     '/assets/images/Industry mentors/Sanchita Ganguly_TitleBrand Building Head - Vice President_UltraTech Cement.jpg',
//     '/assets/images/Industry mentors/Shreedevi Ramesh_Co-Founder_Gurus360 Knowledge Services.jpg',
//     '/assets/images/Industry mentors/Tina Rastogi_VP-Human Capital Management_Cybage Software.jpg',
//     '/assets/images/Industry mentors/Vanipriya_Director _ Head of Co-Creation_Founder at Zeekh.jpg'
// ];



// console.log('techmentor', industryMentors.length)

export const NewMentor = () => {

    // const imgScroll = useRef()

    // useEffect(() => {
    //     const imgScrollVal = imgScroll.current.offsetHeight + 500;
    //     console.log("imgScrollVal", imgScrollVal);
    //     var tl = gsap.timeline({repeat: -1});
    //     // tl.to(".sh-mentor__banner-imgA", 15, {y: "0", ease: "linear"})
    //     tl.to(".sh-mentor__banner-imgA", 15, {y:  -imgScrollVal, ease: "linear"}, "0")
    //     tl.to(".sh-mentor__banner-imgA", 0, {y:  0, ease: "none"})
    //     tl.to(".sh-mentor__banner-imgB", 15, {y: -imgScrollVal, ease: "linear"},">-=1.5")
    //     tl.to(".sh-mentor__banner-imgB", 0, {y: 0, ease: "none"})
    //     // tl.to(".sh-mentor__banner-imgA", 0, {}, "<-=1.5")
    // }, [imgScroll.current])

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const [ip, setIp] = useState([]);

    useEffect(() => {
        setTimeout(
            () => setIp('res.data'),
            100
          );

    }, []);


    return (
        <Layout>
            {/* <div className='position-relative new-banner d-none d-lg-block'>
                <img className='w-100' src="assets/images/mentor_banner.png" alt="" />
            </div> */}

          <Helmet>
                    <title>Join Shevolve Mentors and Empower Women in Their Careers</title>
                    <meta name="description" content="Discover the power of mentorship with Shevolve. Join our global network of mentors dedicated to empowering women in their careers. Transform lives, inspire leadership, and make a difference today" />
                    <meta name="keywords" content="Shevolve, Mentorship, Empowerment, Women's careers, Leadership, Career goals, Professional development, Workforce participation, Global collaboration, Resilience" />

                    <meta property="og:title" content="Join Shevolve Mentors and Empower Women in Their Careers" />
<meta property="og:description" content="Discover the power of mentorship with Shevolve. Join our global network of mentors dedicated to empowering women in their careers. Transform lives, inspire leadership, and make a difference today" />
<meta property="og:image" content="https://shevolve.in/images/home/slide/slide1.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Join Shevolve Mentors and Empower Women in Their Careers" />
<meta name="twitter:description" content="Discover the power of mentorship with Shevolve. Join our global network of mentors dedicated to empowering women in their careers. Transform lives, inspire leadership, and make a difference today" />
<meta name="twitter:image" content="https://shevolve.in/images/home/slide/slide1.png" />

                </Helmet>

            <AnimWrap />

            {/*=========================================
            ===================== Banner ================
            ============================================ */}
            {/* <section className="sh-section sh-section__banner pb-0">
                <div className="sh-section__inwrap">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="sh-banner__content">
                            <h1 className="sh-banner__title sh-anim__stagger-fade-top">
                                Mentors Making A Difference
                            </h1>
                            <div className="sh-para sh-banner__para sh-banner__para--grey sh-anim__stagger-fade-top">Shevolve is proud to collaborate with mentors across the globe who play a vital role in not only increasing the workforce participation of women but also aspire women to become leaders while reaching their career goals with perseverance, resilience and motivation!</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="text-center sh-anim__stagger-fade-right">
                        <img className="img-fluid" src="./images/mentors/banner-image.svg" alt="" />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="position-absolute sh-section__curved-bottom w-100"><img className="img-fluid w-100" src="./images/home/curve-divider.png" /></div>
            </section> */}
{/*
            <section className="sh-section sh-mentor__section position-relative p-0" style={{ backgroundImage: "url()" }}>
                <div className="sh-mentor__banner-background d-none d-md-block"><img rel="preload" className='img-fluid w-100' src="./images/mentors/banenr-image.jpg" alt="" /></div>
                <div className="sh-mentor__banner-background d-md-none"><img rel="preload" className='img-fluid' src="./images/mentors/mobile-banner.jpg" alt="" /></div>
                <div className="sh-section__inwrap sh-mentor__banner-inwrap">
                    <div className="container sh-mentor__banner-container">
                        <div className="sh-mentor__row row align-items-center px-3 p-md-0">
                            <div className="sh-mentor__banner-left col-lg-6">
                                <div className="sh-mentor__banner-content position-relative sh-anim__stagger-fade-top">
                                    <div className="sh-mentor__banner-title sh-anim__stagger-fade-top">
                                        <div className="sh-banner__title mt-0 sh-banner__title--white">Mentors Making A Difference</div>
                                    </div>
                                    <div className="sh-para sh-banner__para sh-banner__para--white sh-anim__stagger-fade-top">Shevolve is proud to collaborate with mentors across the globe who play a vital role in not only increasing the workforce participation of women but also aspire women to become leaders while reaching their career goals with perseverance, resilience and motivation!</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            {/* <div className="container my-5">
                <h4 className="sh-title">MENTORS MAKING A DIFFERENCE</h4>
                <p className='sc my-4 sh-para'>Shevolve is proud to collaborate with mentors across the globe who play a vital role in not only increasing the workforce participation of women but also aspire women to become leaders while reaching their career goals with perseverance, resilience and motivation!</p>
            </div> */}


            <section className="sh-section position-relative p-md-0" style={{ backgroundImage: "url()" }}>
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="sh-banner__content">
                                    <h1 className="sh-banner__title sh-anim__stagger-fade-top">
                                        Mentors Making A Difference For Women
                                        {/* <div className="sh-banner__title-medium">that builds a community </div> */}
                                    </h1>
                                    <div className="sh-para sh-banner__para sh-banner__para--grey sh-anim__stagger-fade-top">Shevolve is proud to collaborate with mentors across the globe who play a vital role in not only increasing the workforce participation of women but also aspire women to become leaders while reaching their career goals with perseverance, resilience and motivation!</div>
                                    {/* <a className="sh-button mt-3 sh-anim__stagger-fade-top" onClick={scrollHandler}>Read More</a> */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="text-center sh-anim__stagger-fade-top">
                                    <div className="sh-mentor__banner-img" style={{ background: "url(./images/mentors/our-mentors.png)", backgroundSize: "cover" }}>
                                        {/* <img ref={imgScroll} className="img-fluid sh-mentor__banner-imgA" src="./images/mentors/our-mentor-banner.jpg" alt="" /> */}
                                        {/* <img className="img-fluid sh-mentor__banner-imgB" src="./images/mentors/our-mentor-banner.jpg" alt="" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sh-section  sh-section--blue sh-section__progress">
                <div className="container">
                    <h4 className='font-weight-bold sh-title sh-title__underline text-center sh-section__title-wrap sh-anim__stagger-fade-top'>Tech Mentors</h4>
                    <div className="row my-3">
                        <div className="col-md-12">
                            <OwlCarousel className="owl-theme sh-slider"
                                // width={90}
                                // items={3}
                                margin={80}
                                responsive={{
                                    "0": {
                                        items: "1"
                                    },
                                    "640": {
                                        items: "2"
                                    },
                                    "1170": {
                                        items: "3"
                                    }

                                }}

                            >
                                {techMentor.map((mentor, index) => {
                                    return (
                                        <div key={index} className="sh-mentor__slide sh-anim__stagger-fade-top">
                                            <div className="sh-mentor__slide-image mb-4" style={{ backgroundImage: `url( '${mentor.image}' )` }}></div>
                                            <div className="sh-mentor__slide-details d-flex flex-wrap align-items-start justify-content-between">
                                                <div className="sh-mentor__slide-name w-100"> {mentor.name} </div>
                                                <div className="sh-mentor__slide-details-left">
                                                    <div className="sh-mentor__slide-position"> {mentor.role} </div>
                                                </div>
                                                <div className="sh-mentor__slide-details-right text-right">
                                                    <img className=" sh-mentor__slide-company" src={mentor.company} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sh-section pb-0">
                <div className='container'>
                    <h4 className=' font-weight-bold sh-title sh-title__underline sh-section__title-wrap text-center sh-anim__stagger-fade-top'>Industry Mentors</h4>
                    <OwlCarousel className="owl-theme sh-slider"
                        // width={90}
                        // items={3}
                        margin={80}
                        responsive={{
                            "0": {
                                items: "1"
                            },
                            "640": {
                                items: "2"
                            },
                            "1170": {
                                items: "3"
                            }

                        }}

                    >
                        {industryMentors.map((mentor, index) => {
                            return (
                                <div key={index} className="sh-mentor__slide sh-anim__stagger-fade-top">
                                    <div className="sh-mentor__slide-image mb-4" style={{ backgroundImage: `url( '${!mentor.image ? "./assets/images/Industry mentors/placeholder.jpg" : mentor.image }' )` }}></div>
                                    <div className="sh-mentor__slide-details d-flex flex-wrap align-items-start justify-content-between">
                                        <div className="sh-mentor__slide-name w-100"> {mentor.name} </div>
                                        <div className="sh-mentor__slide-details-left">
                                            <div className="sh-mentor__slide-position" style={{height:"80px"}}> {mentor.role} </div>
                                        </div>
                                        <div className="sh-mentor__slide-details-right text-right">
                                            <img className=" sh-mentor__slide-company" src={mentor.company} alt="" />
                                        </div>
                                    </div>
                                </div>
                            )
                        }) }
                    </OwlCarousel>
                </div>
            </section>







                {/* {Object.keys(allMentors).map(category => (
                    <div key={category}>
                        <h4 className='mt-5 mb-3'>
                            {category}
                        </h4>
                        <div className="row my-3">
                            <div id="carousel111" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators" style={{ bottom: -25 }}>
                                    <li data-target="#carousel111" data-slide-to="0" className="active"></li>
                                    <li data-target="#carousel111" data-slide-to="1"></li>
                                    {/* <li data-target="#carouselExampleSlidesOnly" data-slide-to="2"></li> */}
                {/* </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        {[...new Array(allMentors[category].length)].map((c, i) => (
                                            <div className="col-md-3 featured-member-card cp mb-4 mb-md-0">
                                                {console.log(cate, 'cate')}
                                                <img src={cate.image} alt="" />
                                                <div className='member-company'>
                                                    <img src={cate.company} alt="company_logo" />
                                                </div>
                                                <p className='primary-txt'>{cate.name}</p>
                                                <p className='secondary-txt'>{cate.role}</p>
                                            </div>
                                        ))}
                                    </div>



                                </div>
                            </div> */}
                {/*
                        </div>

                    </div>

                ))} */}
        </Layout>
    )
}