import React, { useState, useEffect } from "react";
import Divider from '@material-ui/core/Divider'
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import moment from 'moment';
import { BASE_URL } from "../../../constant";
import Styles from "../../../styles";
import Assignments from "../small/assignment";
import QA from "../small/QA";
import NewHeader from "../static/newHeader";
import Room from "./Room";
import { addMenteeNotes, endSessionMentor, getMenteeNotes } from "../../../NetworkRequest/requests/mentor";
import { useHistory, useParams } from 'react-router-dom'
import { USER_TYPE } from "../../Constants";
import { toast } from "react-toastify";
import ConfirmationModal from "../Modals/confirmationModal";
const { connect, createLocalTracks } = require("twilio-video");

const VideoBox = () => {
  const { location, push } = useHistory();
  const { id } = useParams();
  const [room, setroom] = useState(null);
  const userType = localStorage.getItem(USER_TYPE)
  const tabs = ['Notes', 'Assignments', 'Q&A'];
  const [notesList, setNotesList] = useState([]);
  const [leave, setLeave] = useState(false);
  const [active, setactive] = useState("Notes");
  const [noteText, setNoteText] = useState({
    title: "",
    description: "",
  });


  useEffect(() => {
    console.log(location)

    if (location.state) {
      startMeeting();
    } else {
      returnToLobby();
      // goBack();
    }

  }, [])

  const startMeeting = async () => {
    try {
      const tracks = await createLocalTracks({
        video: {
          width: { min: 240, ideal: 1920, max: 1920 },
          height: { min: 100, ideal: 1080 },
          aspectRatio: 1.777777778,
          frameRate: { max: 30 },
        },
        audio: {
          sampleSize: 16,
          channelCount: 2
        }
      });
      const room1 = await connect(location.state.accessToken, {
        name: location.state.name,
        tracks
      });
      console.log(room1)
      setroom(room1);
    } catch (e) {
      console.log(e)
      toast('Camera not Found')
    }

  }

  const handleNoteText = (evt) => {
    const s = { ...noteText };
    s[evt.target.name] = evt.target.value;
    setNoteText(s);
  };


  const _getNotes = () => {
    getMenteeNotes(id).then((res) => {
      setNotesList(res);
    });
  };


  const handlePost = () => {
    if (noteText.title !== "" && noteText.description !== "") {
      addMenteeNotes({ menteeId: id, ...noteText }).then((res) => {
        setNoteText({ title: "", description: "" });
        _getNotes();
      });
    }
  };

  const returnToLobby = () => {
    if (userType === 'mentor') {
      setLeave(true)
    } else {
      setroom(null);
      push(userType === 'mentee' ? '/' : `/menteeDetails/${id}`)
      // this.setState({ room: null });
    }
  };

  return (
    <div className="">
      <Styles />
      <NewHeader />
      <div className="container">
        {room !== null && (
          <Room returnToLobby={returnToLobby} room={room} />
        )}
      </div>

      <ConfirmationModal 
      open={leave}
      onClose={() => setLeave(false)}
      title="Confirmation"
      description="Do you want to end this session?"
      onAccept={() => {
        endSessionMentor({sessionId: location.state.room, sessionType: location.state.sessionType})
        .then(res => {
          console.log(
            'res is', res
          )
        })
        .catch(e => {
          console.log('exception is', e)
        })
      }}
      onCancel={() => setLeave(false)}
       />


      <div className="mentee-detail-tabs container">
        <ul>
          {tabs.map((tab) => (
            <li key={tab} onClick={() => setactive(tab)} style={{ color: tab === active ? "#007A87" : "" }}>
              {tab}
            </li>
          ))}
        </ul>
        <Divider />

        {active === "Notes" && (
          <div style={{ width: "75%" }}>
            <h3 className="my-5">Notes</h3>
            <div class="form-group">
              <label for="title" className="f-size-12">
                Title
              </label>
              <input
                type="text"
                name="title"
                value={noteText.title}
                onChange={handleNoteText}
                class="form-control"
                id="title"
                aria-describedby="emailHelp"
                placeholder="this is my title"
              />
            </div>

            <div className="form-group">
              <label htmlFor="title" className="f-size-12">
                Your Notes
              </label>
              <div style={{ border: "1px solid #c4c4c4", borderRadius: "0.625rem", padding: 10 }}>
                <TextareaAutosize value={noteText.description} name="description" onChange={handleNoteText} className="notes-textArea" />
                <div className="d-flex justify-content-end split">
                  <button onClick={handlePost} className="btn btn-gradient-primary">
                    Post
                  </button>
                </div>
              </div>
              <div style={{ border: "1px solid #EDEDED", height: "2px", width: "100%", marginBlock: 30 }}></div>
            </div>

            <div className="">
              {notesList.map((no) => (
                <div key={no._id}>
                  <p style={{ fontSize: 16, color: "#525252", marginBottom: 0 }}>{no.title}</p>
                  <p className="f-size-12">Posted on : {moment(no.createdAt).format("DD MMM YYYY")}</p>
                  <p className="f-size-12" style={{ color: "#727272" }}>
                    {no.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}

        {active === "Assignments" && <Assignments />}
        {active === "Q&A" && <QA />}
      </div>
    </div>
  );
};

export default VideoBox;
