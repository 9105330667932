import React, { useState, useMemo, useEffect } from "react";
import { startOfMonth, format, addMonths, addDays } from "date-fns";
import { MonthlyCalendar } from "./Monthly/MonthlyCalendar";
import { MonthlyBody, MonthlyDay } from "./Monthly/MonthlyBody";
// import "@zach.codes/react-calendar/dist/calendar-tailwind.css";
import Styles from "../../../styles";
import AddAvailabilityDialog from "../Modals/addAvailabilityDialog";
import { addAvailability, getAvailability } from "../../../NetworkRequest/requests/mentor";
import moment, { months } from "moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AvailabilityList from "../Modals/availability-list";
import { contentTotalDuration } from "../../../global_fun";
import Layout from '../../layout/default';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import formatDistance from 'date-fns/formatDistance'
import axios from "axios";
import { element } from "prop-types";
import { BASE_URL } from "../../../constant";

// import CustomWeekView from './CustomWeekView'
// import DateComp from "./dateComp"

// function TestComponent() {
//    const dateStr = "2021-10-26T12:24:33.433+00:00";
//    const str = formatDistance(
//        new Date(dateStr),
//        new Date()
//    );
//    return <h1>{str}</h1>
// }



// import moment from 'moment'
// import 'react-big-calendar/lib/sass/styles';
// import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD

const localizer = momentLocalizer(moment)

const AvailabilityCalender = ({ myCourses }) => {
  const history = useHistory();
  const [currentMonth, setCurrentMonth] = useState(startOfMonth(new Date()));
  const userdata = JSON.parse(localStorage.getItem("userData")) || {};
  const [open, setOpen] = useState(false);
  const [available, setAvailable] = useState([]);
  const [selectedDate, setSelectedDate] = useState({ day: 0, events: [] });
  const [Events, setEvents] = useState([]);
  const [Mentordata, setMentordata] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [isEvent, setIsEvent] = useState(false);
  const [Mentorname, setMentorname] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(undefined)
  const [modalState, setModalState] = useState(false)
  const [upcomingsession, Setupcomingsessions] = useState([])
  // const [bcView, setBCView] = useState({});



  useEffect(() => {
    _getAvailability();
  }, [currentMonth]);

  const handleNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const handlePrevMonth = () => {
    if (Date.now() < currentMonth) {
      setCurrentMonth(addMonths(currentMonth, -1));
    }
  };

  const onDateRecieved = (from, to) => {
    addAvailability({ from, to }).then((res) => {
      setOpen(false);
      setSelectedDate({ ...selectedDate, day: 0 });
      toast(res.customMessage);
      _getAvailability();
    });
  };

  const _getAvailability = () => {
    getAvailability(moment(currentMonth).format("MM/YYYY")).then((res) => setAvailable(res));
  };

  const handleCalenderClick = (day, events) => {
    if (addDays(Date.now(), -1) < day) {
      setSelectedDate({ day, events });
    } else {
      toast("Past Date is not allowed");
    }
  };
  //this is calender handle
  const handleSelectedEvent = (event) => {
    setSelectedEvent(event)
    setModalState(true)
  }
  const Modal = () => {
    return (
      <div className={`modal-${modalState == true ? 'show' : 'hide'}`}>
          // Here you define your modal, what you want it to contain.
      // Event title for example will be accessible via 'selectedEvent.title'
      </div>
    )
  }
  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }



  const getSchedule = async () => {

    try {
      const items = [];
      let item = await axios.get(`${BASE_URL}mentee/getMenteeschedule?mentee=${userdata._id}`).then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error)//Logs a string: Error: Request failed with status code 404
      });

      let upcomingSessionUnfiltered = item;
      let upcomingSession = upcomingSessionUnfiltered.filter((session) => new Date(session.sessiondate) > new Date())
      console.log(upcomingSession, "upcomingsession");
      Setupcomingsessions(upcomingSession)



      item.map(data => {

        //  console.log(Actualdate(new Date(data.sessiondate)),"temme11");

        //  dates.push(new Date(data.sessiondate));
        //  let temp = allDates.filter(sessions(data.sessiondate));
        //  console.log(temp,"temm");
        const temp = new Date(data.sessiondate);
        let startHours = data.starttime.slice(0, 2);
        let startMinutes = data.starttime.slice(3, 5);
        let startTime = moment(startHours + startMinutes, 'hh:mm A').format('hh:mm A')
        let endHours = data.endtime.slice(0, 2);
        let endMinutes = data.endtime.slice(3, 5);
        let endTime = moment(endHours + endMinutes, 'hh:mm A').format('hh:mm A')
        // console.log(startHours,"startHours")
        // console.log(startMinutes,"startMinutes")
        if (data.sessiondate) {
          setStartDate(data.sessiondate);
          setIsEvent(true);
        }
        items.push(
          {
            start: new Date(temp.getFullYear(), temp.getMonth(), temp.getDate(), startHours, startMinutes),
            end: new Date(temp.getFullYear(), temp.getMonth(), temp.getDate(), endHours, endMinutes),
            title: startTime + " - " + endTime + " - " + data.title,
            description: 'This is a test description of an event',
            data: 'you can add what ever random data you may want to use later',
          })

        // console.log(temp);

      })
      //  console.log(items);


      // console.log(temp);


      console.log(items);

      //  start: new Date(2022, 7, 5, 19, 0, 0),
      setEvents(items);
    }
    catch (err) {
      console.log(err)
    }
  }
  // let Mentorname=Mentordata.mentor.name;
  let startdata = new Date();
  let enddata = new Date();
  // let start="";
  // let end="";
  const getAssignedmentor = async () => {
    const items = [];
    let data = await axios.get(`${BASE_URL}Mentee/getAssignedMentee?_id=${userdata._id}`).then((response) => {
      console.log(response.data, "response")
      return response.data;
    })
      .catch((error) => {
        return Promise.reject(error)//Logs a string: Error: Request failed with status code 404
      });
    if (data.mentor) {
      setMentordata(data);
      setMentorname(data.mentor.name)
    }
    else {
      setMentordata(data);
    }
  }
  // console.log(userdata.Package,+"Package");

  //  console.log(Mentordata.mentorname,"name");
  const start = new Date(Mentordata.startdate);
  const end = new Date(Mentordata.enddate);

  const diff1 = moment([start.getFullYear(), start.getMonth(), start.getDate()]);
  const diff2 = moment([end.getFullYear(), end.getMonth(), end.getDate()]);
  // //  console.log(diff2.diff(diff1,"months"));
  // console.log(userdata.startdate);
  const actualdate = diff2.diff(diff1, "months");
  // console.log(userdata.enddate);

  let startdate = moment(Mentordata.startdate).format("DD-MM-YYYY");
  let enddate = moment(Mentordata.enddate).format("DD-MM-YYYY");


  console.log(Events, "items.title")

  useEffect(() => {
    getSchedule();
  }, []);

  useEffect(() => {
    getAssignedmentor();
  }, []);



// const Addevent=()=>{
//   // http://localhost:8000/Mentee/updateOneMentee?_id=605060eb8d6e6f0f9409c661
//   fetch(`http://localhost:8000/mentee/getMenteeschedule?mentee=${userdata._id}`,{

//         method:'GET',
//         headers:{
//           'Accept' : 'application/json',
//           'Content-Type' : 'application/json'
//         },
//         body:JSON.stringify(item)
//   }).then((result)=>{
//         console.log(result);
//         alert("DATA ADDED");
//   })
// }

  //  const Duration=moment.format(userdata.startdate);
  //  console.log(Duration);

// function TestComponent() {
//    const dateStr = "2021-10-26T12:24:33.433+00:00";
//    const str = formatDistance(
//        new Date(dateStr),
//        new Date()
//    );
//    return <h1>{str}</h1>
// }

// function dateFormat(date) {
//   const month = date.getMonth();
//   const day = date.getDate();
//   const monthString = month >= 10 ? month : `0${month}`;
//   const dayString = day >= 10 ? day : `0${day}`;
//   return `${date.getFullYear()}-${monthString}-${dayString}`;
// }
  // console.log((Mentorname,"mentorname")


// function dateFormat(date) {
//   const month = date.getMonth();
//   const day = date.getDate();
//   const monthString = month >= 10 ? month : `0${month}`;
//   const dayString = day >= 10 ? day : `0${day}`;
//   return `${date.getFullYear()}-${monthString}-${dayString}`;
// }





//    console.log(Duration)
//    function monthDiff(d1, d2) {
//     var months;
//     months = (d2.getFullYear() - d1.getFullYear()) * 12;
//     months -= d1.getMonth();
//     months += d2.getMonth();
//     return months <= 0 ? 0 : months;
// }

  const events = [
    // {
    //     start: '2022-08-20',
    //     end: '2022-08-21',
    //     time: "4:00 pm",
    //     eventClasses: 'optionalEvent',
    //     title: 'test event',
    //     description: 'This is a test description of an event',
    // },
    // {
    //     startDate: new Date(2022, 8, 28, 19, 0, 0),
    //     endDate: new Date(2022, 8, 29, 23, 0, 0),
    //     title: 'EMERGE - A 14 Day Digital Transformation Cohort for Women Entrepreneurs',
    //     description: 'This is a test description of an event',
    //     data: 'you can add what ever random data you may want to use later',
    // },
    {
      start: new Date(2022, 7, 26, 10, 0, 0),
      end: new Date(2022, 7, 26, 13, 0, 0),
      title: "Test event" ,
      description: 'This is a test description of an event',
      data: 'you can add what ever random data you may want to use later',
  },
  {
    start: new Date(2022, 8, 9, 10, 30, 0),
    end: new Date(2022, 8, 9, 13, 0, 0),
    title: new Date(2022, 8, 9, 10, 30, 0) + "-" + ' 14 Day Digital Transformation Cohort for Women Entrepreneurs',
    description: 'This is a test description of an event',
    data: 'you can add what ever random data you may want to use later',
},
{
  start: new Date(2022, 8, 9, 10, 0, 0),
  end: new Date(2022, 8, 9, 13, 0, 0),
  title: new Date(2022, 8, 9, 10, 0, 0) + "-" + ' 14 Day Digital Transformation Cohort for Women Entrepreneurs',
  description: 'This is a test description of an event',
  data: 'you can add what ever random data you may want to use later',
},
  {
    start: new Date(2022, 8, 9, 10, 0, 0),
    end: new Date(2022, 8, 9, 13, 0, 0),
    title: new Date(2022, 8, 9, 10, 0, 0) + "-" + ' 14 Day Digital Transformation Cohort for Women Entrepreneurs',
    description: 'This is a test description of an event',
    data: 'you can add what ever random data you may want to use later',
  },
  //   {
  //     start: '2022-08-20',
  //     end: '2022-08-25',
  //     title: 'EMERGE - A 14 Day Digital Transformation Cohort for Women Entrepreneurs',
  //     description: 'This is a test description of an event',
  //     data: 'you can add what ever random data you may want to use later',
  // },
];

console.log(events.title, "events.Events")





// const DateComp = () => {
//   return (
//       <div>
//         {events.map(( index, event ) => {
//           return (
//             <div key={index}>{event.start} - {event.title}</div>
//           )
//         })}
//       </div>
//   );
// };




  return (
    <Layout>
      <link rel="stylesheet" href="assets\css\calendar-tailwind.css" />
      <Styles/>
      <AvailabilityList
        {...selectedDate}
        onAddClick={() => {
          setOpen(true);
        }}
        currentMonth={currentMonth}
        onDelete={(index) => {
          const ev = [...selectedDate.events];
          ev.splice(index, 1);
          setSelectedDate({ ...selectedDate, events: ev });
          _getAvailability();
        }}
        open={selectedDate.day !== 0}
        onClose={(e) => setSelectedDate({ ...selectedDate, day: 0 })}
      />
      <AddAvailabilityDialog open={open} defaultDate={selectedDate.day} onDate={onDateRecieved} onClose={(e) => setOpen("")} />
      <section className="sh-section sh-section--green pt-0">
        <div className="sh-section__inwrap">
          <div className="mentor-home-banner">
            <div className="sh-profile__cover-background"></div>
            <div className="container my-2 sh-profile__details">
              <div className="d-lg-flex align-items-start">
                <div className="sh-profile__image">
                  <img  className="img-fluid" style={{ objectFit: "cover", objectPosition: "center", width: "100%", height: "100%"}} src={userdata.profilePic ? userdata.profilePic : "/assets/images/casual_profile.jpg"} alt="profile " />
                </div>
                <div className="sh-profile__right">
                  <div className="sh-profile__right-inner">
                    <div className="sh-profile__row row align-items-start justify-content-between flex-column-reverse flex-md-row">
                      <div className="sh-profile__col col-md-10 d-md-flex flex-wrap align-items-start">
                        <div className="sh-profile__name position-relative">{userdata.name}</div>
                        {/* <a href="tel:9896578530" className="sh-profile__number d-flex align-items-center">
                          <span className="sh-profile__number-icon mr-2"><img src="/images/profile/phone-icon.svg" alt="" /></span>
                          9896578530
                        </a> */}
                      </div>
                      <div className="sh-profile__col col-md-2 text-right" onClick={(e) => history.push("/profile/edit")}>
                        <button className="sh-profile__btn-edit">Edit</button>
                      </div>
                    </div>
                    <div className="sh-profile__row row">
                      <div className="sh-profile__col col-md-3">
                        <div className="sh-profile__label">Role</div>
                        <div className="sh-profile__item">{userdata.role}</div>
                      </div>
                      <div className="sh-profile__col col-md-4">
                        <div className="sh-profile__label">Industry</div>
                        <div className="sh-profile__item">{userdata.industry}</div>
                      </div>
                      <div className="sh-profile__col col-md-5">
                        <div className="sh-profile__label">Domain</div>
                        <div className="sh-profile__item">{userdata.domain}</div>
                      </div>
                      <div className="sh-profile__col col-md-3">
                        <div className="sh-profile__label">Contact Details</div>
                        <a href="tel:9896578530" className="sh-profile__item">{userdata.phoneNumber}</a>
                      </div>
                      <div className="sh-profile__col col-md-9">
                        <div className="sh-profile__label">Years of Experience</div>
                        <div className="sh-profile__item">{userdata.experience} Years</div>
                      </div>
                      {userdata.YourEvolveJourney &&

                        <div className="sh-profile__col col-md-3">
                          <div className="sh-profile__label">Your Evolve Journey</div>
                          <a href={userdata.YourEvolveJourney} className="sh-profile__item sh-profile__item-link" target="_black">Update Your Progress</a>
                        </div>
                      }
                      {userdata.MenteeEvaluationIndex&&
                        <div className="sh-profile__col col-md-4">
                          <div className="sh-profile__label">Mentee Evaluation Index</div>
                          <a href={userdata.MenteeEvaluationIndex} className="sh-profile__item sh-profile__item-link" target="_black">Update Now</a>
                        </div>
                      }
                      {userdata.MenteeTransformationIndex &&
                        <div className="sh-profile__col col-md-5">
                          <div className="sh-profile__label">Mentee Transformation Index</div>
                          <a href={userdata.MenteeTransformationIndex} className="sh-profile__item sh-profile__item-link" target="_black">Update Now</a>
                        </div>
                      }
                      </div>

                    {(userdata.Package=="Rise Up"|| userdata.Package=="Fly High"|| userdata.Package=="She Tribe" ) &&
                    <>
                      <div className="sh-profile__section-title">Packages Details</div>
                      <div className="sh-profile__package sh-profile__row d-flex align-items-center mt-4">
                        <span className="sh-profile__package-icon">
                        <img  src={userdata.Package=="Rise Up"?"/images/profile/rise-up.svg":userdata.Package=="Fly High"?"/images/profile/fly-high.svg":userdata.Package=="She Tribe"?"/images/profile/she-tribe.svg":"alt"} className="w-100" alt="rise-up" />
                        </span>
                        <div className="sh-profile__name position-relative">{userdata.Package}</div>
                      </div>
                      </>
                       }

                    {(userdata.Package == "Rise Up" || userdata.Package == "Fly High" || userdata.Package == "She Tribe") &&
                      <div className="row sh-profile__row mb-0">
                        {(Mentordata.startdate && Mentordata.enddate) &&
                          <>
                            <div className="sh-profile__col col-md-6">
                              <div className="sh-profile__label">Duration</div>
                              <div className="sh-profile__item"><span>From {startdate} To {enddate}</span>
                              </div>
                            </div>
                          </>
                        }
                        {Mentorname != "" &&

                          <div className="sh-profile__col col-md-3">
                            <div className="sh-profile__label">Mentor</div>
                            {Mentorname &&
                              <div className="sh-profile__item">{Mentorname} </div>}
                          </div>
                        }

                        {isEvent &&
                          <div className="col-md-3"> <div className="sh-profile__col text-md-right" onClick={(e) => history.push({ pathname: "/profile/view-sessions", state: { id: userdata._id,Package:userdata.Package,startdate:startdate,enddate:enddate,mentor:Mentorname } })} >
                            <button className="sh-profile__item sh-profile__item-link">View Sessions</button></div></div>
                        }

                        {(userdata.Package == "Rise Up" || userdata.Package == "Fly High" || userdata.Package == "She Tribe") &&
                          <div className="sh-profile__col col-md-12">
                            <div className="sh-profile__label mb-4">Your Session Calendar View</div>
                            {isEvent == true ?
                              <Calendar
                                // defaultDate={defaultDate}
                                onChange={getSchedule}
                                localizer={localizer}
                                views={["month","week","day"]}
                                events={Events}
                                style={{ height: 500 }}
                                // onSelectSlot={(e) => handleSelect(e)}
                             onSelectEvent={(e) => handleSelectedEvent(e)}
                                step={15}
                                timeslots={8}
                              // components={{
                              //   event:
                              // }}
                              // popup
                              // month = {localizer.range(start, end)}
                              /> : <p style={{ fontSize: "20px" }}>No Schedules</p>}
                          </div>
                        }
                      </div>


                    }

                  </div>
                </div>

                {/* <div className="ml-md-5 mt-5 mt-md-0">
                  <div className="d-flex">
                    <img src="/assets/images/butterfly.svg" alt="" />
                    <h4 className="ml-3 sh-profile__name" style={{ color: "#0D5975", textTransform: "capitalize" }}>
                      {userdata.name}
                    </h4>
                  </div>

                  <div className="mentor-home-content mt-3">
                    <p className="mb-3">
                      <span className="sh-profile__label">Phone Number</span> : <span className="sh-profile__data">{userdata.phoneNumber}</span>
                    </p>
                    <p className="mb-3">
                      <span className="sh-profile__label">Industry</span> : <span className="sh-profile__data">{userdata.industry}</span>
                    </p>
                    <p className="mb-3">
                      <span className="sh-profile__label">Role </span> : <span className="sh-profile__data">{userdata.role}</span>
                    </p>
                    <p className="mb-3">
                      <span className="sh-profile__label">Domain </span> : <span className="sh-profile__data">{userdata.domain}</span>
                    </p>
                    <p className="mb-3">
                      <span className="sh-profile__label">Years of Experience </span> : <span className="sh-profile__data">{userdata.experience} years</span>
                    </p>

                  </div>

                  <div className="sh-button">
                    <button onClick={(e) => history.push("/profile/edit")}>
                      <i class="fas fa-pen mr-2"></i> Edit Profile
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
            </div>
        </div>
      </section>


      {/* <div className="container">
        <div className="my-5">
          <div className="d-flex align-items-center mb-3">
            <h2 className="sh-title mb-0">
              {format(currentMonth, "LLL")}’ {format(currentMonth, "	yyyy")}
            </h2>
            <div style={{ backgroundColor: "rgba(12, 75, 108, 0.1)", height: 36, borderRadius: 8, padding: "7px 10px", marginLeft: 20, display: "flex", alignItems: "center" }}>
              <i class="fas fa-angle-left p-1" onClick={handlePrevMonth} style={{ cursor: "pointer" }}></i>
              <i class="fas fa-angle-right p-1" onClick={handleNextMonth} style={{ cursor: "pointer" }}></i>
            </div>
          </div>
          <p className="text-black-color sh-text font-weight-medium">Here are all you planned events. You can manage your availability and scheduled calls.</p>
        </div>
        <div className="mb-5">
          <MonthlyCalendar currentMonth={currentMonth} onCurrentMonthChange={(date) => setCurrentMonth(date)}>
            <MonthlyBody
              events={available.map((x) => {
                return {
                  title: "name",
                  date: new Date(x.endDate) > Date.now() ? new Date(x.startDate) : "",
                  id: x._id,
                  endDate: x.endDate,
                };
              })}
            >
              <MonthlyDay
                onClick={handleCalenderClick}
                renderDay={(data) =>
                  data.map((item, index) => (
                    <div className="rc-flex  rc-flex-1 rc-justify-between">
                      <p className="mb-0" style={{ color: "#0C4B6C", fontSize: 12 }}>
                        Availability
                      </p>
                      <p className="rc-text-gray-500 mb-0 f-size-12">{moment.utc(item.date).format("hh:mm a")}</p>
                    </div>
                  ))
                }
              />
            </MonthlyBody>
          </MonthlyCalendar>
        </div>
        <div className="blog-tab-wrap">
            <div className="event-blog-wrap sec-pad pb-0">
              <div className="">
                <div className="event-blog-content">
                  <div className="row">
                    {/* courses list here
                    {myCourses.length > 0 ? (
                      myCourses.map(
                        (myCourse, i) =>
                          myCourse.course && (
                            <div className="col-12 col-md-6 col-lg-3 col-xl-3 mb-lg-3 mb-3">
                              <div
                                className="blog-content-wrap"
                                style={{ cursor: "pointer" }}
                                onClick={(h) => {
                                 history.push(`/preview/${myCourse.course._id}`);
                                }}
                              >
                                <figure className="image-wrap">
                                  <img className="img-fluid" style={{ objectFit: "cover", maxHeight: 200 }} src={myCourse.course.image} />
                                  <div className="tip-title">
                                    <span style={{ fontSize: 12, fontWeight: "bold" }}>
                                      {myCourse.course.isFree ? "Free" : `₹ ${myCourse.course.price}`}
                                    </span>
                                  </div>
                                </figure>
                                <div className="blog-text-content">
                                  <p
                                    className="sec-title text-black-color"
                                    style={{
                                      display: "-webkit-box",
                                      WebkitLineClamp: 1,
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {myCourse.course.courseName}
                                  </p>
                                  <p
                                    className="sec-content"
                                    style={{
                                      height: 62.4,
                                      color: "#525252",
                                      fontSize: 14,
                                    }}
                                  >
                                    {myCourse.course.courseTitle}
                                  </p>

                                  <div className="meta-info flex-space">
                                    <div className="days" style={{ fontSize: 12 }}>
                                      {myCourse.course.content.length} Modules &bull; {contentTotalDuration(myCourse.course).hour}hr{" "}
                                      {contentTotalDuration(myCourse.course).minute}
                                      min
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                      )
                    ) : (
                      <div style={{ width: "100%", height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <p>No Course found</p>
                      </div>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>

      </div> */}
    </Layout>

  );
};

export default AvailabilityCalender;
