import React, { Component } from "react";
import Header from "./static/header";
import { Link } from 'react-router-dom';
import { order } from "../../NetworkRequest/requests/package";
import axios from "axios";
import { toast } from "react-toastify";
import Styles from '../../styles';

class CourseInfo extends Component {
  state = {};
  async razorPayPaymentHandler() {
    // let packageInfo = JSON.parse(localStorage.getItem("packageData"));
    const API_URL = `http://13.235.74.178:8000/mentee/`;
    order(5000).then((res) => {
      const options = {
        key: "",
        name: "Course1",
        description: "Course description",
        order_id: res.id,
        handler: async (response) => {
          try {
            console.log("bhujgbujhbj", response);
            const paymentId = response.razorpay_payment_id;
            const url = `${API_URL}capture/${paymentId}`;
            const captureResponse = await axios.post(url, {
              amount: 5000,
            });
            const successObj = JSON.parse(captureResponse.data);
            const captured = successObj.captured;
            console.log(
              "App -> razorPayPaymentHandler -> captured",
              successObj
            );
            if (captured) {
              console.log("success");
              toast.success("Successfully done payment");
              // updatePaymentStatus(
              //   packageInfo._id,
              //   packageInfo.name,
              //   Date.now()
              // ).then((res) => {
              //   console.log("544444444444", res);
              //   localStorage.setItem("paymentDone", true);
              //   localStorage.setItem("userData", JSON.stringify(res));
              this.props.history.push("/");
              // });
            }
          } catch (err) {
            console.log(err);
          }
        },
        theme: {
          color: "#007a87",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    });
  }
  render() {
    return (
      <div className="wrapper">
        {/* header wrap */}
        {/* <Header props={this.props} /> */}
        <Styles/>
        {/* header close */}
        <div className="content ">
          <div className="program-info-wrap course-info-wrap">
            <div className="program-info-text-content-wrap course-info-text-content-wrap">
              <div className="container">
                <div className="program-info-text-content course-info-text-content">
                  <div className="flex-space brdr-btm d-sm-flex d-block">
                    <div className="mb-sm-0 mb-3">
                      <div className="program-title">
                        <h6 className="title">
                          Java Delopment Course (₹24,999)
                        </h6>
                      </div>
                      <div className="program-rating flex-content">
                        <div className="flex-content">
                          <img
                            width={20}
                            height={20}
                            src="/assets/images/star-active.jpg"
                            alt=""
                          />
                          <img
                            width={20}
                            height={20}
                            src="/assets/images/star-active.jpg"
                            alt=""
                          />
                          <img
                            width={20}
                            height={20}
                            src="/assets/images/star-active.jpg"
                            alt=""
                          />
                          <img
                            width={20}
                            height={20}
                            src="/assets/images/star-active.jpg"
                            alt=""
                          />
                          <img
                            width={20}
                            height={20}
                            src="/assets/images/star-inactive.jpg"
                            alt=""
                          />
                        </div>
                        <div className="rating-text">
                          <span className="m-l-sm">4.5/5 (23,347)</span>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <button
                        className="btn btn-primary mb-sm-0 mb-2"
                        onClick={this.razorPayPaymentHandler}
                      >
                        Buy Course
                      </button>
                    </div>
                  </div>
                  <div className="program-text-content">
                    <h6 className="title">
                      Learn iOS 11 App Development From Beginning to End. Using
                      Xcode 9 and Swift 4. Includes Full ARKit and CoreML
                      Modules!
                    </h6>
                    <p className="sec-content">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>
                    <p className="sec-content">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* courses-blog-wrap */}
            <div className="courses-blog-wrap sec-sm-pad pb-0">
              <div className="container">
                <div className="courses-blog-content-wrap">
                  <div className="sec-heading-wrap flex-space">
                    <div className="sec-title">
                      <h5 className="mb-0">Courses Contents</h5>
                    </div>
                    <div className="link-wrap">
                      <Link to="" className="link-text add-list fz-20">
                        Full schedule
                      </Link>
                    </div>
                  </div>
                  <div className="courses-blog-content">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-3 mb-lg-0 mb-3">
                        <div className="blog-content-wrap">
                          <figure className="image-wrap">
                            <img
                              className="img-fluid"
                              src="/assets/images/online-courses.jpg"
                              alt=""
                            />
                          </figure>
                          <div className="blog-text-content">
                            <p className="sec-title">UI/UX Desinging</p>
                            <span className="meta-info">
                              Duration : 1-2 months
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3 mb-lg-0 mb-3">
                        <div className="blog-content-wrap">
                          <figure className="image-wrap">
                            <img
                              src="/assets/images/online-courses.jpg"
                              alt=""
                            />
                          </figure>
                          <div className="blog-text-content">
                            <p className="sec-title">Java Development</p>
                            <span className="meta-info">
                              Duration : 1-2 months
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3 mb-lg-0 mb-3">
                        <div className="blog-content-wrap">
                          <figure className="image-wrap">
                            <img
                              src="/assets/images/online-courses.jpg"
                              alt=""
                            />
                          </figure>
                          <div className="blog-text-content">
                            <p className="sec-title">Kotlin Development</p>
                            <span className="meta-info">
                              Duration : 1-2 months
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3 mb-lg-0 mb-3">
                        <div className="blog-content-wrap">
                          <figure className="image-wrap">
                            <img
                              src="/assets/images/online-courses.jpg"
                              alt=""
                            />
                          </figure>
                          <div className="blog-text-content">
                            <p className="sec-title">UI/UX Desinging</p>
                            <span className="meta-info">
                              Duration : 1-2 months
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* courses-blog-wrap close */}
              {/* acchievement-wrap */}
              <div className="acchievement-wrap sec-sm-pad">
                <div className="container">
                  <div className="sec-heading-wrap flex-space">
                    <div className="sec-title">
                      <h5 className="mb-0">Achievements &amp; Trophies</h5>
                    </div>
                  </div>
                  <div className="acchievement-wrap-content">
                    <div className="row">
                      <div className="col-6 col-md-4 col-lg-3 mb-lg-0 mb-3">
                        <div className="acchievement-list">
                          <figure className="image-wrap">
                            <img src="/assets/images/tro-dark-img.png" alt="" />
                          </figure>
                          <button className="btn pad-none text-left dark-field-text">
                            Achieved Iron Programmer level
                          </button>
                          <p className="sec-content">
                            Complete 3 tasks to achieve this
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 mb-lg-0 mb-3">
                        <div className="acchievement-list">
                          <figure className="image-wrap">
                            <img src="/assets/images/tro-red-img.png" alt="" />
                          </figure>
                          <button className="btn pad-none text-left red-field-text">
                            Achieved Bronze Programmer Level
                          </button>
                          <p className="sec-content">
                            Complete 3 tasks to achieve this
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 mb-lg-0 mb-3">
                        <div className="acchievement-list">
                          <figure className="image-wrap">
                            <img src="/assets/images/tro-light-img.png" alt="" />
                          </figure>
                          <button className="btn pad-none text-left light-field-text">
                            Achieved Silver Programmer Level
                          </button>
                          <p className="sec-content">
                            Complete 3 tasks to achieve this
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 mb-lg-0 mb-3">
                        <div className="acchievement-list">
                          <figure className="image-wrap">
                            <img
                              src="/assets/images/tro-yellow-img.png"
                              alt=""
                            />
                          </figure>
                          <button className="btn pad-none text-left yellow-field-text">
                            Achieved Gold Programmer Level
                          </button>
                          <p className="sec-content">
                            Complete 3 tasks to achieve this
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* acchievement-wrap close */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CourseInfo;
