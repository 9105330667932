import AxiosBase from "../axios/AxiosBase";

export const AddYTVideo = (title, description, videoId, videoDuration) =>
    new Promise((resolve, reject) => {
        const data = { title, description, videoId, videoDuration };
        AxiosBase.post("admin/addYTVideo", data)
            .then((res) => {
                console.log("RESPONSE", res);

                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });

export const GetYTVideo = () =>
    new Promise((resolve, reject) => {
        AxiosBase.get("admin/getYTVideo")
            .then((res) => {
                console.log("RESPONSE", res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });

export const DeleteYTVideo = (id) =>
    new Promise((resolve, reject) => {
        AxiosBase.delete("admin/deleteYTVideo?ytVideoId=" + id)
            .then((res) => {
                console.log("RESPONSE", res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });
export const UpdateYTVideo = (id, title, description, videoId, videoDuration) =>
    new Promise((resolve, reject) => {
        const data = { title, description, videoId, videoDuration };
        AxiosBase.put("admin/updateYTVideo?ytVideoId=" + id, data)
            .then((res) => {
                console.log("RESPONSE", res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });

export const CreateEvent = (
    name,
    hostedBy,
    price,
    startTime,
    endTime,
    isFree,
    image,
    description,
    questionnaire,
    sub_events,
    lable,
    venue,
    venueType,
    meetingId,
    meetingPassword
) =>
    new Promise((resolve, reject) => {
        const data = new FormData();
        data.append("name", name);
        data.append("hostedBy", hostedBy);
        data.append("startTime", (new Date(startTime)).toUTCString());
        data.append("price", price);
        data.append("image", image);
        data.append("endTime", (new Date(endTime)).toUTCString());
        data.append("isFree", isFree);
        data.append("description", description);
        data.append("lable", lable);
        if(venue.length > 0) {
            data.append("venue", venue);
        }
        data.append("eventType", venueType);
        if(venueType === 'ONLINE') {
            data.append('meetingId', meetingId);
            data.append('meetingPassword', meetingPassword)
        }

        data.append("questionnaire", JSON.stringify(questionnaire));
        
        sub_events.map((sub_event, i) => {
            sub_event.sub_events_speakers.map((speaker, j) => {
                data.append('speaker_image', speaker.speaker_image_raw)
                // data.append('speaker_image[]', speaker.speaker_image_raw)
            });
        })
        data.append("sub_events", JSON.stringify(sub_events));

        for (var [key, value] of data.entries()) {
            console.log("qqqqqqqqqqqqqqqqqqqqqqqqqq", key, value);
        }
        console.log("11111111111111111", data, image);
        AxiosBase.post("admin/createEvent", data)
            .then((res) => {
                console.log("RESPONSE", res);

                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });

export const getAllEvents = (uri) =>
    new Promise((resolve, reject) => {
        AxiosBase.get(`admin/getAllEvents${uri || ''}`)
            .then((res) => {
                console.log("RESPONSE", res);

                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });

export const deleteEvent = (id) =>
    new Promise((resolve, reject) => {
        AxiosBase.delete("admin/deleteEvent?eventId=" + id)
            .then((res) => {
                console.log("RESPONSE", res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });

export const updateEvent = (
    eventId,
    name,
    hostedBy,
    price,
    startTime,
    endTime,
    isFree,
    image,
    description,
    questionnaire,
    sub_events,
    lable,
    venue,
    venueType,
    meetingId,
    meetingPassword
) =>
    new Promise((resolve, reject) => {
        console.log(endTime, 'endTime')
        const data = new FormData();
        data.append("name", name);
        data.append("hostedBy", hostedBy);
        data.append("startTime", (new Date(startTime)).toUTCString());
        data.append("price", price);
        data.append("image", image);
        data.append("endTime", (new Date(endTime)).toUTCString());
        data.append("isFree", isFree);
        data.append("description", description);
        data.append("lable", lable);
        if(venue && venue.length > 0) {
            data.append("venue", venue);
        }
        //data.append("venue", venue);
        data.append("eventType", venueType);
        data.append("questionnaire", JSON.stringify(questionnaire));

        sub_events.map((sub_event, i) => {
            sub_event.sub_events_speakers.map((speaker, j) => {
                data.append('speaker_image', speaker.speaker_image_raw)
            });
        })
        data.append("sub_events", JSON.stringify(sub_events));

        if(venueType === 'ONLINE') {
            data.append('meetingId', meetingId);
            data.append('meetingPassword', meetingPassword)
        }
        for (var [key, value] of data.entries()) {
            console.log("qqqqqqqqqqqqqqqqqqqqqqqqqq", key, value);
        }

        // console.log("11111111111111111",data);
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin" : '*'
            },
        };
        AxiosBase.post("admin/updateEvent?eventId=" + eventId, data, config)
            .then((res) => {
                console.log("RESPONSE", res);

                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });

export const getEvent = (id) =>
    new Promise((resolve, reject) => {
        AxiosBase.get("admin/getEvent?eventId=" + id)
            .then((res) => {
                console.log("RESPONSE", res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });




export const getEventParticipate = (id) =>
    new Promise((resolve, reject) => {
        AxiosBase.get("admin/getEventParticipate?eventId=" + id)
            .then((res) => {
                console.log("RESPONSE", res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });

export const getOneYTVideo = (id) =>
    new Promise((resolve, reject) => {
        AxiosBase.get("admin/getOneYTVideo?ytVideoId=" + id)
            .then((res) => {
                console.log("RESPONSE", res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });


export const sendEventReminder = eventId => {
    return new Promise((resolve, reject) => {
        AxiosBase.put(`/admin/sendEventReminder?eventId=${eventId}`)
        .then(res => resolve(res.data))
        .catch(e => reject(e))
    })
}

export const markEventSold = eventId => {
    return new Promise((resolve, reject) => {
        AxiosBase.put(`/admin/markEventSold?eventId=${eventId}`)
        .then(res => resolve(res.data))
        .catch(e => reject(e))
    })
}