import { contentTotalDuration } from "../coursesDetails";
import EventCard from "../home/eventCard";
import SessionCard from "./sessionCardMentor";

const PackageDetails = ({ purchasedPackage }) => {
  return (
    <>
      <h3 className="my-5">Program Courses</h3>
      <div className=" pt-0">
        <div className="blog-tab-wrap">
          <div className="event-blog-wrap sec-pad p-0">
            <div className="container">
              <div className="event-blog-content">
                <div className="row">
                  {/* courses list here  */}
                  {purchasedPackage.courses &&
                    purchasedPackage.courses.map((course, inde) => (
                      <div key={`course${inde}`} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-lg-3 mb-3">
                        <div className="blog-content-wrap">
                          <figure className="image-wrap">
                            <img
                              className="img-fluid"
                              style={{ objectFit: "cover", maxHeight: 200 }}
                              src={course.course[0].image}
                              alt="imag"
                            />
                          </figure>
                          <div className="blog-text-content">
                            <p
                              className="sec-title text-black-color"
                              style={{
                                display: "-webkit-box",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                            >
                              {course.course[0].courseName}
                            </p>
                            <p
                              className="sec-content"
                              style={{
                                height: 56,
                                color: "#525252",
                                fontSize: 12,
                              }}
                            >
                              {course.course[0].courseTitle}
                            </p>

                            <div className="meta-info flex-space">
                              <div className="days" style={{ fontSize: 12 }}>
                              {course.course[0].content.length} Modules &bull; {contentTotalDuration(course.course[0]).hour}hr{" "}
                                {contentTotalDuration(course.course[0]).minute}min
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 className="mt-2 mb-5">Mentoring Sessions</h3>
      <div className="event-blog-content">
        <div className="row">
          {purchasedPackage.mentoringSessions &&
            purchasedPackage.mentoringSessions.map((session, index) => (
              <div key={`session${index}`} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-lg-3 mb-3">
                <SessionCard type={"session"} {...session} index={index + 1} />
              </div>
            ))}
        </div>
      </div>
      <h3 className="mt-2 mb-5">Coaching Sessions</h3>
      <div className="event-blog-content">
        <div className="row">
          {purchasedPackage.coachingSessions &&
            purchasedPackage.coachingSessions.map((coaching, index) => (
              <div key={`coaching${index}`} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-lg-3 mb-3">
                <SessionCard type="coaching" {...coaching} index={index + 1} />
              </div>
            ))}
        </div>
      </div>
      <h3 className="mt-2 mb-5">Workshops</h3>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-lg-0 mb-3">
          {purchasedPackage.workshops.map((evt) => (
            <EventCard {...evt.event[0]} />
          ))}
        </div>
      </div>
    </>
  );
};
export default PackageDetails;
