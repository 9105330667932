import React, { useRef, useLayoutEffect, useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import Layout from "../../layout/default";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Testimonials } from "../testimonials";
import {Helmet} from "react-helmet"
import {AnimWrap} from "../animation-gsap/Animation"
import { toast } from 'react-toastify';
import { submitContactUs } from '../../../adminPanel/networkRequests/requests/subscription';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';

// Initializing the Swiper Core Module
SwiperCore.use([Autoplay]);

export default function MentorTestimonials() {
    
    const [selectedTestimonial, setSelectedTestimonial] = useState(Testimonials.mentors[0]);

    return (        
            <section className="sh-section sh-section--green overflow-hidden">
                <div className="sh-section__inwrap">
                    <div className="container pr-md-0">
                        <div className="row justify-content-end">
                            <div className="col-md-6 mb-lg-0 sh-mentor__testimonial-left mr-4">
                                <div className="sh-content__wrap text-center text-lg-left">                                    
                                    <div className="sh-mentor__testimonial-title sh-title sh-title__font-secondary font-weight-bold sh-anim__stagger-fade-top">Why Mentors Love <span className="sh-mentor__testimonial-subtitle">Shevolve</span> ?</div>
                                    <p className="sh-para sh-anim__stagger-fade-top">There are many reasons why our mentors love to work with us.</p>
                                    <p className="sh-para sh-anim__stagger-fade-top mb-5">Here's what our mentors have to say about us!</p>

                                    {/* Added the SwiperJS with Vertical Direction Scroll, with the delay of 2 seconds for each mentor card in a loop */}
                                    <Swiper
                                        spaceBetween={40}
                                        slidesPerView={3}
                                        direction='vertical'
                                        autoplay={{
                                            delay: 2000,
                                            pauseOnMouseEnter: true,
                                            disableOnInteraction: false
                                        }}
                                        loop={true}
                                    >
                                        {/* Looping through the Mentor Testimonial Data List */}
                                        {Testimonials.mentors.map((mentor, index) => {
                                            return (
                                                // Individual Mentor Slide
                                                <SwiperSlide key={index}>
                                                    {/* On click of Single Mentor Card, the corresponding Mentor detail will be updated in the Featured Mentor Card Block */}
                                                    <div className="sh-mentor__slide sh-anim__stagger-fade-top mb-4" onClick={() => setSelectedTestimonial(mentor)}>
                                                        <div className="sh-mentor__testimonial-cards d-flex flex-wrap align-items-start justify-content-between">                                                            
                                                            <div className="sh-mentor__testimonial-details d-flex">
                                                                <div className="sh-mentor__testimonial-cards-profile" style={{ backgroundImage: `url(./images/testimonials/${mentor.img}.png)` }}></div>
                                                                <div className="sh-mentor__testimonial-cards-content">
                                                                    <div className="sh-mentor__testimonial-cards-name sh-text">{mentor.name}</div>
                                                                    <div className="sh-mentor__testimonial-role">{mentor.role}</div>
                                                                    {/* Mentor Description rendered with HTML backquotes to support Formatting tags like <p>, <b>, and <img> inside this parent tag */}
                                                                    <p className="sh-mentor__testimonial-cards-para" dangerouslySetInnerHTML={{ __html: mentor.quote }}></p>
                                                                </div>
                                                            </div>
                                                        </div>                                                                                                               
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        }) }
                                    </Swiper>
                                </div>
                            </div>
                            <div className="col-md-5">   
                            {/* Featured Mentor Card Block*/}
                                    <div className="sh-mentor__testimonial-highlighted d-flex flex-column justify-content-between sh-anim__stagger-fade-top active-card">
                                        <div className="sh-mentor__testimonial-details d-block">
                                            <div className="sh-mentor__testimonial-highlighted-profile" style={{ backgroundImage: `url(./images/testimonials/${selectedTestimonial.img}.png)` }}></div>
                                        </div>
                                        <div className='p-4'>
                                            <div className="sh-mentor__testimonial-highlighted-content">
                                                <div className="sh-mentor__testimonial-highlighted-name sh-text">{selectedTestimonial.name}</div>
                                                <div className="sh-mentor__testimonial-role">{selectedTestimonial.role}</div>
                                            </div>
                                            <div className="my-3">
                                                <svg className="sh-mentor__testimonial-icon" width="28" height="32" viewBox="0 0 34 44.252">
                                                    <g id="Group_62912" data-name="Group 62912" transform="translate(0 0)">
                                                        <path id="Path_82207" data-name="Path 82207" d="M51.281,42.247a.293.293,0,0,1,.135.566A12.522,12.522,0,0,0,49.2,43.951,13.249,13.249,0,0,0,46.146,46.7a17.988,17.988,0,0,0-1.183,1.664,19.239,19.239,0,0,0-.974,1.8c-.3.618-.561,1.256-.8,1.9l-.724,2.047a29.106,29.106,0,0,1-4.328,7.926c-.451.6-.954,1.169-1.452,1.731-.261.272-.521.543-.786.81s-.547.517-.82.773a16.566,16.566,0,0,1-3.663,2.573c.511-.539.976-1.106,1.441-1.665s.9-1.141,1.317-1.728S35,63.356,35.4,62.765c.187-.306.381-.606.572-.906.1-.149.191-.3.278-.457l.271-.46a45.611,45.611,0,0,0,3.61-7.679l.37-1c.134-.361.258-.725.4-1.082a20.046,20.046,0,0,1,1-2.1,16.363,16.363,0,0,1,1.266-1.966,15.093,15.093,0,0,1,1.561-1.756,11.952,11.952,0,0,1,3.93-2.5,10.094,10.094,0,0,1,2.227-.571q.2-.027.4-.046" transform="translate(-17.605 -23.674)" fill="#007b87"/>
                                                        <path id="Path_82208" data-name="Path 82208" d="M27.7,14.3A21.046,21.046,0,0,0,13.448,0a21.068,21.068,0,0,0,9.933,34.027A21.046,21.046,0,0,0,27.7,14.3" transform="translate(-4.638 0)" fill="#007b87"/>
                                                        <path id="Path_82209" data-name="Path 82209" d="M7.392,74.676A11.381,11.381,0,0,0,0,82.713a11.392,11.392,0,0,0,18.149-6.165A11.38,11.38,0,0,0,7.392,74.676" transform="translate(0 -41.507)" fill="#007b87"/>
                                                    </g>
                                                </svg>
                                            </div>
                                            {/* Mentor Description rendered with HTML backquotes to support Formatting tags like <p>, <b>, and <img> inside this parent tag */}
                                            <p className="sh-mentor__testimonial-para" dangerouslySetInnerHTML={{ __html: selectedTestimonial.quote }}></p>
                                        </div>                                        
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}