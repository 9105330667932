import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Styles from "../../styles";
import { forgetPassword, login, mentorLogin } from "../../NetworkRequest/requests/login";
import { Modal } from "react-responsive-modal";
import {Helmet} from "react-helmet";

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


class Forget extends Component {
    state = { email: "", loginAs: '', success: false };

    componentDidMount() {
        console.log('props1', this.props.history)
    }

    handleEmailChange = (e) => {
        e.preventDefault();
        let email = e.target.value;
        this.setState({ email });
        console.log(email);
    };

    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.state.loginAs === '') {
            toast.error('Please Select who u are ? ');
            return
        }
        if (this.state.email === '' || !validateEmail(this.state.email)) {
            toast.error('Enter Valid Email ID');
            return
        }

        forgetPassword(this.state.email, this.state.loginAs.toUpperCase())
            .then(res => {
                this.setState({success: true})
                toast.success(res.customMessage)
                console.log('forget', res)
            })
            .catch(e => toast.error('Email not Exists'));

    }


    render() {
        console.log("props", this.props.location.pathname);
        const bg = {
            modal: { borderRadius: 16 },
            overlay: {},
        };
        return (
            
            <section className="wrapper">
                <Helmet>
                    <title>Reset Your Shevolve Account Password</title>
                    <meta name="description" content="Reset your Shevolve account password and regain access to a community fostering women's career growth worldwide" />
                    <meta name="keywords" content="password reset, account recovery, Shevolve, women empowerment, career growth, mentors, workforce participation, leadership development, perseverance, resilience, motivation" />

                    <meta property="og:title" content="Reset Your Shevolve Account Password<" />
<meta property="og:description" content="Reset your Shevolve account password and regain access to a community fostering women's career growth worldwide" />
<meta property="og:image" content="https://shevolve.in/images/home/slide/slide1.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Reset Your Shevolve Account Password" />
<meta name="twitter:description" content="Reset your Shevolve account password and regain access to a community fostering women's career growth worldwide" />
<meta name="twitter:image" content="https://shevolve.in/images/home/slide/slide1.png" />


                </Helmet>
                <Styles />
                <section className="login-page signup-wrap">
                    <div className="login-bg">
                        <figure className="login-image mb-0 w-100" >
                            <img
                                className="img-fluid"
                                src={"/images/entry/entry-image.png"}
                                alt="login"
                                style={{ height: '100vh', objectFit: 'cover' }}
                            />
                        </figure>
                    </div>
                    <div className="login-form">
                        <form onSubmit={this.handleFormSubmit}>
                            <div className="form-content">
                                <div className="login-form-content mb-3">
                                    <Link to='/'>
                                        <div className="logo-img-bottom mb-4">
                                            <img
                                                src="/images/logo.svg"
                                                width={120}
                                                alt="header-logo"
                                            />
                                        </div>
                                    </Link>
                                    <div className="sec-title flex-content m-b-md">
                                        <img
                                            className="mr-3"
                                            src="/assets/images/butterfly.svg"
                                            alt="banner"
                                        />
                                        {/*<h5 className="sec-title mb-0">Hey, Welcome back!</h5>*/}
                                        <h1 className="sec-title sh-para font-weight-bold">Forgot Password</h1>
                                    </div>
                                    <div className="sec-content">
                                        <p>
                                            Welcome back to Shevolve. 
                                         To reset your password, please enter your email ID.
                                        </p>
                                    </div>


                                    <div className="form-group">
                                        <label className="fz-12">Email</label>
                                        <input
                                            className="form-control"
                                          //  placeholder="Lokeshnpl3213@gmail.com"
                                            type="text"
                                            onChange={this.handleEmailChange}
                                        />
                                    </div>
                                    {/* <div className="form-group">
                                        <label>Password</label>
                                        <input
                                            className="form-control"
                                            placeholder="28HJG88293"
                                            type="password"
                                            onChange={this.handlePasswordChange}
                                        />
                                    </div> */}
                                    <div className="form-group select-group">
                                        <label>You are </label>
                                        <select
                                            className="form-control"
                                            //  placeholder="Lokeshnpl3213@gmail.com"
                                            type="text"
                                            value={this.state.loginAs}
                                            onChange={e => this.setState({ loginAs: e.target.value })}
                                        >
                                            <option value=''>Choose</option>
                                            <option value='mentor'>Mentor</option>
                                            <option value='mentee'>Mentee</option>
                                        </select>
                                    </div>

                                    <div className="btn-grp mt-4">
                                        <button
                                            type="submit"
                                            className="sh-button sh-button__no-radius btn-block"
                                        // onClick={this._login}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                                <div className="sec-content text-align-center">
                                    <p>
                                        Back to <Link to="/login" style={{ fontSize: 18, color: "#007a87", paddingLeft: 6 }}>Login</Link>
                                    </p>
                                </div>
                                {/* <div className="sec-content text-align-center">
                                    <p>
                                        Don’t have an account? Lets create one!
                                        <Link to="/WhoIsSigning"
                                              style={{fontSize: 18, color: "#007a87", paddingLeft: 6}}> Signup</Link>
                                    </p>
                                </div> */}
                            </div>
                        </form>
                    </div>
                </section>

                <Modal
                    open={this.state.success}
                    // onClose={this.closeModal}
                    showCloseIcon={false}
                    center
                    styles={bg}
                >
                    <div
                        style={{ display: "flex" }}
                        // className={"modal fade"}
                        id="news-modal"
                        tabIndex="-1"
                        role="dialog"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-xl form-size">
                            <div className="modal-content form-content email-modal-content">
                                <div
                                    className="news-wrap"
                                >
                                    <div className="row no-gutters">
                                        <div className="col-md-12 form-padlr">
                                            <div className="form-block">

                                                <div className="row no-gutters">
                                                    <div className="col-md-12">
                                                        <div className="news-text-content">
                                                            <div className="row">
                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                    <div className="news-img">
                                                                        <img src="assets/images/undraw-news-img.png" />
                                                                    </div>
                                                                    <div className="news-sec-title">
                                                                        <h5>A password recovery link has been sent to your email. Please check your inbox and follow the steps to reset your password.</h5>
                                                                        {/* <p>Your email has been verified. Welcome to the Shevolve family.</p> */}
                                                                        <p className="text-danger">
                                                                            Note: If you cannot find the confirmation mail in your
                                                                            inbox, please check your spam folder.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="btn-wrap">
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-primary w-100"
                                                                            // data-dismiss="modal"
                                                                            onClick={e => this.props.history.push('/login')}
                                                                        >Back to login</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>


            </section>
        );
    }
}

export default Forget;
