import React, {useState} from "react";
import { Modal } from "react-responsive-modal";
import GoogleLogin from "react-google-login";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { FaGooglePlusG } from "react-icons/fa";
import { ImLinkedin2 } from "react-icons/im";
import { testGuestLoginUrl, USER_TYPE } from "../Constants";
import { useHistory } from "react-router-dom";
import { getLinkedInAccessToken, guestLogin } from "../../NetworkRequest/requests/login";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { LOGIN } from "../../reducer/action";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


const bg = {
  modal: { borderRadius: 16 },
  overlay: {},
};

const LoginPopUp = ({ onClose, open, googleSuccess, routeName, onerror }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const hosturl = `${window.location.origin}/linkedin`;
  const responseGoogle = (response) => {
    console.log('linkedin data is google called');
    setLoading(true)
    guestLogin(response.profileObj.email, response.profileObj.name, response.profileObj.googleId, "GOOGLE")
      .then((res) => {
        localStorage.setItem(USER_TYPE, "mentee");
        localStorage.setItem("userData", JSON.stringify(res.data));
        localStorage.setItem("accessToken", res.accessToken);
        // setLoading(false);git 
        dispatch({
          type: LOGIN,
          payload: res.data,
          accessToken: res.accessToken,
          userType: "mentee",
        });
        googleSuccess();
      })
      .catch((err) => {
        onerror();
        setLoading(false);
        console.log("google login err", err);
      });
  };
  const log_in = () => {
    //  Hide_PopUp();
    localStorage.setItem("from", routeName);
    history.push({
      pathname: "/login",
      state: {
        from: routeName,
      },
    });
  };
  const handleSuccess = (response) => {
    console.log("linkedin data", response);
    let redirectURI = testGuestLoginUrl;
    setLoading(true)
    getLinkedInAccessToken(response.code, redirectURI, "78w0f1y4y16908", "Wtm7zwsSuQD08Cpz")
      .then((res) => {
        if (res.statusCode === 200) {
          localStorage.setItem(USER_TYPE, "mentee");
          localStorage.setItem("userData", JSON.stringify(res.data));
          localStorage.setItem("accessToken", res.accessToken);
          // setLoading(false)
          googleSuccess();
          dispatch({
            type: LOGIN,
            payload: res.data,
            accessToken: res.accessToken,
            userType: "mentee",
          });
        } else {
          setLoading(false);
          return toast.info("Invalid Linkedin ID");
        }
       
        // window.location.href = "/";
      })
      .catch((err) => {
        // onerror();
        setLoading(false);
        console.log("linkedIn error is ", err);
        return toast.info("Invalid Linkedin ID");
      });
  };

  const handleFailure = (error) => {
    console.log("linkedin error", error);
    toast.error("login with linkedIn failed");
  };
  return (
    <Modal
      open={open}
      // onClose={this.closeModal}
      showCloseIcon={false}
      center
      onClose={onClose}
      styles={bg}
    >
      {loading ? (
        <Loader
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
          // timeout={3000} //3 secs
        />
      ) : (
        <div className="login_sign_popup">
          <div className="up_pop" style={{ background: "none" }}>
            <p className="sh-modal__heading-text--plain">Login/Signup to reserve your place</p>
            <span onClick={onClose}>
              <i class="fas fa-times"></i>
            </span>
          </div>
          <div className="down_pop">
            <button
                onClick={() => history.push('/WhoIsSigning')}
              className=" sh-button sh-button__no-radius w-100 mb-3"
              style={{
                // outline: "none",
                // width: "100%",
                // padding: "10.5px 0px",
                // border: "none",
                // fontWeight: "600",
                // fontSize: "14px",
                // lineHeight: " 22px",
                // textAlign: "center",
                // color: "#ffffff",
                marginBottom: "20px",
              }}
            >
              I don’t have an account
            </button>
            <button
              onClick={log_in}
              className=" sh-button sh-button__no-radius w-100"
              style={{
                // outline: "none",
                // width: "100%",
                // padding: "10.5px 0px",
                // border: "none",
                // fontWeight: "600",
                // fontSize: "14px",
                // lineHeight: " 22px",
                // textAlign: "center",
                // color: "#ffffff",
              }}
            >
              I already have an account
            </button>

            <p style={{ fontSize: "14px", color: "#727272", textAlign: "center", margin: "20px 0px" }}>Or Continue as a guest using</p>
            <div className="Social_btn" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <GoogleLogin
                clientId="636947503335-kqiir94o0ua8t33mbohqrulpocrdo5qi.apps.googleusercontent.com"
                buttonText="google"
                render={(renderProps) => (
                  <button
                    style={{
                      outline: "none",
                      background: "#F81D00",
                      color: "#fff",
                      border: "none",
                      fontSize: 14,
                      borderRadius: "6px",
                      Outline: "none",
                    }}
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <FaGooglePlusG style={{ fontSize: "23px", marginRight: " 5px", height: "35px" }} /> Google
                  </button>
                )}
                onSuccess={responseGoogle}
                onFailure={e => console.log('google failure called')}
                cookiePolicy={"single_host_origin"}
                className="google_btn"
              />
              <LinkedIn
                clientId="78w0f1y4y16908"
                onFailure={handleFailure}
                onSuccess={handleSuccess}
                scope="r_emailaddress,r_liteprofile"
                state="testingLinkedinShevolve"
                redirectUri={hosturl}
                renderElement={({ onClick, disabled }) => (
                  <button
                    style={{
                      outline: "none",
                      backgroundColor: "#0E76A8",
                      color: "#fff",
                      border: "none",
                      fontSize: 14,
                      borderRadius: "6px",
                      Outline: "none",
                    }}
                    onClick={onClick}
                    disabled={disabled}
                  >
                    <ImLinkedin2 style={{ fontSize: "18px", marginRight: " 5px", verticalAlign: "sub" }} /> Linkedin
                  </button>
                )}
              />
              {/* <button style={{backgroundColor : "#0E76A8"  , color : "#fff" , border : "none" , borderRadius :"6px" , Outline:"none" }}> <ImLinkedin2     style={{ fontSize:"23px" , marginRight :" 5px" , verticalAlign :"inherit"}}/> Linked In</button> */}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default LoginPopUp;
