import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  addResponseOfQuestion,
  addResponseOfQuestionIfSkip,
  addResponseOfQuestionIfSubmit,
  getFirstQuestion,
  getSurveyQuestion,
} from "../../NetworkRequest/requests/surveyQuestionnaire";
import Header from "./static/newHeader";
import Styles from '../../styles';

class SurveyQuestionnair extends Component {
  state = {
    selectedAnswer: "",
    entryQuestion: true,
    QuestionData: "",
    nextQuestionId: "",
    lastQuestion: false,
    optionInfo: "",
    nextDisable: true,
  };
  selectedAnswer = async (e) => {
    e.preventDefault();
    let user = JSON.parse(localStorage.getItem("userData"));
    let authorization = localStorage.getItem("accessToken");
    if (!authorization) return this.props.history.push("/login");
    let { optionInfo } = this.state;
    addResponseOfQuestion(
      this.state.QuestionData._id,
      optionInfo._id,
      optionInfo.packageType,
      optionInfo.nextQuestionId,
      user.surveyProgress + 5.5
    ).then((res) => {
      console.log("res222222222", res);
      localStorage.setItem("userData", JSON.stringify(res));
      if (optionInfo.nextQuestionId) {
        getSurveyQuestion(optionInfo.nextQuestionId).then((res) => {
          console.log(res);
          this.setState({ QuestionData: res, nextDisable: true });
        });
        // localStorage.setItem("nextQuestionId", optionInfo.nextQuestionId);
      }
    });
  };

  selectedAnswer1 = async (o) => {
    this.setState({
      selectedAnswer: o._id,
      nextQuestionId: o.nextQuestionId,
      optionInfo: o,
      nextDisable: false,
    });
  };

  skipClick = () => {
    let user = JSON.parse(localStorage.getItem("userData"));
    addResponseOfQuestionIfSkip(
      this.state.QuestionData._id,
      true,
      this.state.QuestionData.nextQuestionIdIfSkip,
      user.surveyProgress + 5.5
    ).then((res) => {
      console.log("res", res);
      localStorage.setItem("userData", JSON.stringify(res));
      if (this.state.QuestionData.nextQuestionIdIfSkip) {
        getSurveyQuestion(this.state.QuestionData.nextQuestionIdIfSkip).then(
          (res) => {
            console.log(res);
            this.setState({ QuestionData: res });
          }
        );
      }
    });
  };
  submitClick = (e) => {
    e.preventDefault();
    let user = JSON.parse(localStorage.getItem("userData"));
    if (user.isSurveyDone) {
      return this.props.history.push("/onBoardPackages");
    }
    let { optionInfo } = this.state;
    addResponseOfQuestionIfSubmit(
      this.state.QuestionData._id,
      optionInfo._id,
      optionInfo.packageType,
      user.surveyProgress + 5.5,
      true
    ).then((res) => {
      console.log("res222222222", res);
      localStorage.setItem("userData", JSON.stringify(res));
      this.props.history.push("/onBoardPackages");
     
    });
  };
  componentDidMount() {
    this._getFirstQuestion();
  }
  _getFirstQuestion = () => {
    // getFirstQuestion().then((res) => {
    //   console.log("44444444444444444444", res);
    //   this.setState({ QuestionData: res, nextDisable: true });
    // });
  };

  render() {
    let { QuestionData /*  ,optionInfo */, nextDisable } = this.state;
    let count = localStorage.getItem("surveyCount");
    let user = JSON.parse(localStorage.getItem("userData"));
    // let info2 = JSON.parse(user);
    let authorization = localStorage.getItem("accessToken");

    console.log("1111111111111", count, nextDisable, user);
    return (
      <div className="wrapper">
       {/* <Header 
          props={this.props}
          
          /> */}
          <Styles/>
        {/* <HomeHeader /> */}
        <section className="login-page survey-page">
          <div className="login-bg">
            <figure className="login-image mb-0">
              <img
                className="img-fluid"
                src="/assets/images/login-image.png"
                alt="login"
              />
            </figure>
          </div>

          <div className="login-form">
            <form>
              <div className="form-content">
                <div className="login-form-content">
                  <div className="sec-title flex-content m-b-md">
                    <img
                      className="mr-3"
                      src="/assets/images/butterfly.svg"
                      alt="banner"
                    />
                    <h5 className="sec-title mb-0">
                      Quick survey and you are all set!
                    </h5>
                  </div>
                  <div className="sec-content m-b-xl">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. <br />
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s
                    </p>
                  </div>
                  <div className="progress mb-4">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${user.surveyProgress}%` }}
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>

                  <div className="survey-list">
                    <div className="list-title">
                      <h6 className="title">
                        {/* Which of the following reflect your career journey? */}
                        {QuestionData.question}
                      </h6>
                    </div>
                    {QuestionData
                      ? QuestionData.options.map((o) => (
                          <div className="list-field-wrap" key={o._id}>
                            <div
                              className="list-field flex-space"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.selectedAnswer1(o)}
                            >
                              <span>
                                {/* I'm learning new skills and early in my journey */}
                                {o.answerOption}
                              </span>
                              {this.state.selectedAnswer === o._id ? (
                                <img
                                  src="/assets/images/check-icon.jpg"
                                  alt="banner"
                                />
                              ) : null}
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                {/* <h5 onClick={this.skipClick}>Skip</h5> */}
                {!QuestionData.lastQuestion ? (
                  <div class="btn-grp mt-5">
                    <button
                      class="btn btn-primary btn-block"
                      disabled={nextDisable ? true : false}
                      onClick={this.selectedAnswer}
                    >
                      Next
                    </button>
                  </div>
                ) : (
                  <div class="btn-grp mt-5">
                    <button
                      class="btn btn-primary btn-block"
                      disabled={nextDisable ? true : false}
                      onClick={this.submitClick}
                    >
                      Submit
                    </button>
                  </div>
                )}
                {!QuestionData.lastQuestion ? (
                  <div
                    class="sec-content text-align-center"
                    style={{ marginTop: 16 }}
                  >
                    <p>
                      Don’t want to answer this?
                      <Link class="question-link" onClick={this.skipClick}>
                        skip question
                      </Link>
                    </p>
                  </div>
                ) : null}
                {/* <h5 onClick={this.submitClick}>Submit</h5> */}
                {authorization ? null : (
                  <div className="sec-content text-align-center">
                    <p>
                      Don’t have an account? Lets create one!
                      <Link to="signup">Signup</Link>
                    </p>
                  </div>
                )}
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  }
}

export default SurveyQuestionnair;
