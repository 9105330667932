import React from 'react';
import Styles from '../../styles';
import NewHeader from './static/newHeader';

const ContactUs = (props) => {
    return (
        <div className="wrapper container-width">
            <Styles />
            <NewHeader props={props} />
            <div
                className="banner-wrap blog-banner-wrap blog-detail-image"
                style={{ backgroundImage: 'url("/assets/images/MaskGroup.png")' }}>
                <div className="banner-overlay">
                    <div className="container">
                        <div className="banner-content" />
                    </div>
                </div>
            </div>


            <div className="content pt-0" style={{marginTop: 100}}>
                <div className='container'>
                    <div className='text-center my-5'>
                        <h3>Contact Us</h3>
                        <p className='my-5'>
                            If you want to know more about how to have your satisfying
                            dream career, how mentoring and coaching can help you navigate
                            your career path effectively, what courses to take and what webinars to
                            attend, you can connect with us here. We exclusively support early, mid
                            career and women intending to come back to workforce to realize their potential
                            and transform to get there.
                        </p>
                    </div>

                    <div className='row '>
                        <div className='col-md-4 my-5'>
                            <div className='w-100 text-center'>
                                <img src='/assets/images/post.png' className='mx-auto' />
                                <p className='sec-title mt-4 mb-3 text-dark'>Company Name</p>
                                <p style={{fontSize: 14}}>Shevolve Innovations<br /> India Pvt. LTD.</p>
                            </div>
                        </div>

                        <div className='col-md-4 my-5'>
                            <div className='w-100 text-center'>
                                <img src='/assets/images/phone.png' className='mx-auto' />
                                <p className='sec-title mt-4 mb-3 text-dark'>Phone Number</p>
                                <p style={{fontSize: 14}}>+91 9342153849</p>
                            </div>
                        </div>

                        <div className='col-md-4 my-5'>
                            <div className='w-100 text-center'>
                                <img src='/assets/images/mail.png' className='mx-auto' />
                                <p className='sec-title mt-4 mb-3 text-dark'>Email Address</p>
                                <p style={{fontSize: 14}}>reachus@shevolve.in</p>
                            </div>
                        </div>
                       
                    </div>
                </div>

            </div>

        

        </div>
    )
}

export default ContactUs;