import { useState, useEffect } from "react";
import { addMenteeQA, getMenteeQA, menteeReplyQA } from "../../../NetworkRequest/requests/mentee";
import moment from "moment";
import { toast } from "react-toastify";
import { USER_TYPE } from "../../Constants";
import { getMenteeQAForMentor, MentorAnswerQA } from "../../../NetworkRequest/requests/mentor";
import { useParams } from "react-router-dom";

const QA = () => {
  const { id } = useParams();
  const [questions, setQuestions] = useState([]);

  const userType = localStorage.getItem(USER_TYPE);
  const [text, setText] = useState("");
  const [skip, setskip] = useState(0);

  useEffect(() => {
    _getQA();
  }, []);

  const _getQA = async () => {
    const res = userType === "mentee" ? await getMenteeQA(10, skip) : await getMenteeQAForMentor(id, skip, 10);
    if (res) {
      setQuestions(res);
    }
  };

  const handleAddQA = () => {
    if (text !== "") {
      addMenteeQA(text).then((res) => {
        setText("");
        _getQA();
      });
    }
  };

  const handleAnswer = async (i) => {
    try {
      if (questions[i].answer && questions[i].answer !== "") {
        userType === "mentee"
          ? await menteeReplyQA({ questionId: questions[i]._id, answer: questions[i].answer })
          : await MentorAnswerQA({ questionId: questions[i]._id, answer: questions[i].answer });
        _getQA();
        const q = [...questions];
        q[i].answer = "";
        setQuestions(q);
      }
    } catch (e) {
      toast("error");
    }
  };

  const handleAnswerChange = (e, i) => {
    const ques = [...questions];
    ques[i].answer = e.target.value;
    setQuestions(ques);
  };

  return (
    <div>
      {" "}
      <div style={{ width: "75%" }}>
        <h3 className="my-5">Question And Answers</h3>

        {userType === 'mentee' && (
          <div className="form-group">
            <label htmlFor="title" className="f-size-12">
              Ask Your Question
            </label>
            <div style={{ border: "1px solid #c4c4c4", borderRadius: "0.625rem", padding: 10 }}>
              <div className="d-flex justify-content-between split  align-items-center">
                <input value={text} onChange={(e) => setText(e.target.value)} type="text" className="notes-textArea" />
                <button onClick={handleAddQA} className="btn btn-gradient-primary py-2">
                  Post
                </button>
              </div>
            </div>
            <div style={{ border: "1px solid #EDEDED", height: "1px", width: "100%", marginBlock: 30 }}></div>
          </div>
        )}

        <div className="mb-5">
          {questions.map((question, i) => (
            <div key={question._id} className="mt-3">
              <p style={{ fontSize: 16, color: "#525252", marginBottom: 0 }}>Question : {question.question}</p>
              <p className="f-size-12">Posted on : {moment(question.createdAt).format("DD MMM YYYY")}</p>
              {/* <p className="f-size-12" style={{ color: "#727272" }}>
                no.description
              </p> */}
              {question.replies.map((reply) => (
                <div className="mt-2">
                  <p className="f-size-12 text-black-color mb-0" key={reply._id}>
                    <span style={{ color: reply.repliedBy === "MENTEE" ? "#F0BC5E" : "#F0BC5E", textTransform: "capitalize" }}>
                      {reply.repliedBy.toLowerCase() === userType ? "You" : reply.repliedBy.toLowerCase()}
                    </span>{" "}
                    {" : "} {reply.answer}
                  </p>
                  <p className="f-size-12">Posted on : {moment(reply.repliedAt).format("DD MMM YYYY")}</p>
                </div>
              ))}
              <div style={{ border: "1px solid #c4c4c4", borderRadius: "0.625rem", padding: 10, backgroundColor: "#F3F3F3" }}>
                <div className="d-flex justify-content-between split  align-items-center">
                  <input
                    value={question.answer}
                    style={{ backgroundColor: "transparent", fontSize: 12 }}
                    placeholder="Write your reply here"
                    onChange={(e) => handleAnswerChange(e, i)}
                    type="text"
                    className="notes-textArea"
                  />
                  <button onClick={(e) => handleAnswer(i)} className="btn btn-gradient-primary py-1 f-size-12">
                    Post
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QA;
