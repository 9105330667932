import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import SubscriptionForm from "./subscription-form";

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

const Footer = (props) => {
    // const [email, setEmail] =useState('');


    // const handleEmailChange =(e) => {
    //     setEmail(e.target.value)
    // }
    // console.log("check", email)

    // const onSubscription = () => {
    //     if(validateEmail(email)) {
    //         getSubsctiption(email).then(res => {
    //             console.log(res);
    //             toast.success('successfully subscribed');
    //         })
    //     }
    // }


    const history = useHistory();
    return (
        <div className={`sh-footer ${props.additionClass}`}>
            <div className='sh-footer__inwrap'>
                <div className='sh-footer__cta-wrap position-relative'>
                    <div className="container">
                        <div className="sh-footer__cta">
                            <div className="sh-footer__cta-inwrap">
                                <div className="d-flex flex-wrap align-items-end">
                                    <div className="sh-footer__cta-content">
                                        <div className="sh-title sh-footer__cta-title sh-footer__cta--white sh-title">Get Your Career Journey Started With Shevolve</div>
                                        <div className="sh-footer__cta-actions d-flex flex-wrap">
                                            <span className="mr-3">Reach us at</span>
                                            <a href="mailto:reachus@shevolve.in" className="sh-footer__cta-action mr-3">
                                                <span className="mr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                                                    </svg>
                                                </span>
                                                <span>reachus@shevolve.in</span>
                                            </a>

                                            <span className="d-none d-lg-block">|</span>

                                            <span className="ml-lg-3 mt-3 mt-lg-0">Call us at</span>
                                            <a href="tel:+919841003516" className="sh-footer__cta-action ml-lg-3">
                                                <span className="mr-lg-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                                    </svg>
                                                </span>
                                                <span>+91 9841003516</span>
                                            </a>
                                        </div>
                                        <div className="sh-footer__cta-form mt-4">
                                            <div className="sh-para sh-footer__cta--white font-weight-bold mb-2">Subscribe to our newsletter.</div>
                                            <SubscriptionForm additionClass="sh-subscription__form-input--lined" />
                                        </div>
                                    </div>
                                    <div className="sh-footer__cta-image">
                                        <img className="img-fluid" src="./images/footer/footer-leaf.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sh-footer__main">
                        <div className="sh-footer__content">
                            <div className="container">
                                <div className="row">
                                    <div className="sh-footer__col col-xl-3">
                                        <Link to="/"><img src="./images/logo.svg" width={137} className='logo img-fluid' alt="" /></Link>
                                        <div className="sh-footer__social-links d-flex flex-wrap align-items-center mt-4">
                                            <a className="sh-footer__social-link" target="_blank" href="https://www.facebook.com/Shevolve-India-107206434950951">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10.844" height="26.541" viewBox="0 0 14.844 30.541">
                                                    <path id="facebook" d="M3.79,30.541V16.211H0v-5.16H3.79V6.644C3.79,3.181,6.028,0,11.186,0a31.318,31.318,0,0,1,3.632.2L14.7,5.019S13.122,5,11.4,5c-1.86,0-2.158.857-2.158,2.28v3.768h5.6l-.243,5.16H9.245V30.541H3.79" fill="#215ace"/>
                                                </svg>
                                            </a>
                                            <a className="sh-footer__social-link" target="_blank" href="https://www.linkedin.com/company/shevolve/">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="23.291" height="21.131" viewBox="0 0 26.291 25.131">
                                                    <path id="linkedin" d="M5.968,25.131V8.175H.332V25.131ZM3.151,5.858A2.938,2.938,0,1,0,3.188,0a2.937,2.937,0,1,0-.074,5.858ZM9.087,25.131h5.636V15.662a3.858,3.858,0,0,1,.185-1.375A3.085,3.085,0,0,1,17.8,12.226c2.039,0,2.855,1.555,2.855,3.834v9.071h5.636V15.408c0-5.208-2.781-7.631-6.489-7.631a5.613,5.613,0,0,0-5.117,2.856h.038V8.174H9.088c.074,1.591,0,16.956,0,16.956Z" fill="#0077b5"/>
                                                </svg>
                                            </a>
                                            <a className="sh-footer__social-link" target="_blank" href="https://www.instagram.com/shevolveindia/?hl=en">
                                                <img src="./images/icons/instagram.svg" alt="" />
                                            </a>
                                            <a className="sh-footer__social-link" target="_blank" href="https://www.youtube.com/channel/UCZtBvF6_7DatfMzZgEzddSQ/featured">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.235" height="17.927" viewBox="0 0 29.235 21.927">
                                                    <path id="iconmonstr-youtube-6" d="M23.894,3.224c-4.39-.3-14.168-.3-18.552,0C.594,3.548.035,6.416,0,13.963.035,21.5.59,24.377,5.342,24.7c4.385.3,14.162.3,18.552,0,4.747-.324,5.306-3.192,5.342-10.739C29.2,6.429,28.646,3.549,23.894,3.224ZM10.963,18.836V9.091l9.745,4.864Z" transform="translate(0 -3)" fill="red"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="sh-footer__col col-xl-8 offset-xl-1 mt-4 mt-xl-0 sh-footer__col-right">
                                        <div className="d-md-flex justify-content-between sh-footer__menu">
                                            <div className="sh-footer__menu-item">
                                                <Link className="sh-footer__menu-link" to="/subscription/individual">Subscription</Link>
                                                <ul className="sh-footer__submenu">
                                                    <li className="sh-footer__submenu-item">
                                                        <Link className="sh-footer__menu-link sh-footer__submenu-link" to="/subscription/individual">Individual</Link>
                                                    </li>
                                                    <li className="sh-footer__submenu-item">
                                                        <Link className="sh-footer__menu-link sh-footer__submenu-link" to="/subscription/corporate">Corporate</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="sh-footer__menu-item">
                                                <Link className="sh-footer__menu-link" to="/hermentor">HerMentor</Link>
                                            </div>
                                            <div className="sh-footer__menu-item">
                                                <Link className="sh-footer__menu-link" to="/events">Events</Link>
                                            </div>
                                            <div className="sh-footer__menu-item">
                                                <Link className="sh-footer__menu-link" to="/shewrites">SheWrites</Link>
                                                <ul className="sh-footer__submenu">
                                                    <li className="sh-footer__submenu-item">
                                                        <Link className="sh-footer__menu-link sh-footer__submenu-link" to="/shewrites#blogs" onClick={() => window.location.replace("/shewrites#blogs")}>Blogs</Link>
                                                    </li>
                                                    {/* <li className="sh-footer__submenu-item">
                                                        <Link className="sh-footer__menu-link sh-footer__submenu-link" to="/shewrites/#newsletter">Newsletters</Link>
                                                    </li> */}
                                                    <li className="sh-footer__submenu-item">
                                                        <Link className="sh-footer__menu-link sh-footer__submenu-link" to="/shewrites#ebooks" onClick={() => window.location.replace("/shewrites#ebooks")}>eBooks</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="sh-footer__menu-item">
                                                <Link className="sh-footer__menu-link" to="/ourmentors">OurMentors</Link>
                                            </div>
                                            <div className="sh-footer__menu-item">
                                            <Link className="sh-footer__menu-link" to="/about">About</Link>
                                            <ul className="sh-footer__submenu">
                                            <li className="sh-footer__submenu-item">
                                            <div className="sh-footer__menu-link sh-footer__submenu-link"><span className="sh-footer__credentials-link" onClick={() => history.push('/privacy-policy')}>Privacy</span>  </div>
                                                </li>
                                                <li className="sh-footer__submenu-item">
                                            <div className="sh-footer__menu-link sh-footer__submenu-link"><span className="sh-footer__credentials-link"  onClick={() => history.push('/terms-condition')}>Terms & Conditions</span></div>
                                                </li>
                                                </ul>
                                            </div>
                                            {/* <div className="sh-footer__menu-item">
                                                <a className="sh-footer__menu-link" target="_blank" href="https://shevolvecareers.com/">Careers</a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sh-footer__credentials">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 text-center text-md-left mb-2 mb-md-0">
                                        <span className="sh-footer__credentials-text w-100">© Shevolve - {(new Date().getFullYear())}</span>
                                    </div>
                                    {/* <div className="col-md-6 text-center text-md-right">
                                        <div className="sh-footer__credentials-links"><span className="sh-footer__credentials-link" onClick={() => history.push('/privacy-policy')}>Privacy</span> / <span className="sh-footer__credentials-link" onClick={() => history.push('/terms-condition')}>Terms & Conditions</span></div>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                </div>
            </div>
        </div>

    )
}

export default Footer;