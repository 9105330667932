// import AxiosBase from "../Axiosbase";

// export const getAllblogs = () =>
//   new Promise((resolve, reject) => {
//     AxiosBase.get("/mentee/getAllblogs")
//       .then((res) => {
//         console.log(" response", res.data);
//         resolve(res.data);
//       })
//       .catch((err) => {
//         console.log("error", err);
//         reject(err);
//       });
//   });

import AxiosBase from "../Axiosbase";
import { USER_TYPE } from "../../website/Constants";

export const EventReservation = ({ event, attendeeName, attendeePhone, attendeeEmail, questionnaireAnswered }) => {
  const userType = localStorage.getItem(USER_TYPE);
  const data = { event, attendeeEmail, attendeeName, attendeePhone, questionnaireAnswered };
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/${userType}/reserveEvent`, data)
      .then((res) => {
        res.status === 200 ? resolve(res.data) : reject("Failed");
      })
      .catch((e) => {
        reject(e);
        console.log(e);
      });
  });
};

export const AddPackageWorkshop = ({ event, attendeeName, attendeePhone, attendeeEmail, questionnaireAnswered, packagePurchaseId }) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/addPackageWorkshop", {
      event,
      attendeeName,
      attendeePhone,
      attendeeEmail,
      questionnaireAnswered,
      packagePurchaseId,
    })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};
