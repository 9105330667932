import AxiosBase from "../axios/AxiosBase";

export const createCourse = (course) =>
    new Promise((resolve, reject) => {
        let { courseName, courseTitle, aboutCourse, image, price, content, isFree } = course;
        const data = new FormData();
        if (isFree) {
            price = 0
        }
        data.append("courseName", courseName);
        data.append("aboutCourse", aboutCourse);
        // data.append("category", category);
        data.append("price", price);
        data.append("content", JSON.stringify(content));
        data.append("image", image);
        data.append("isFree", isFree);
        data.append("courseTitle", courseTitle);
        AxiosBase.post("admin/createCourse", /*qs.stringify(data),config*/ data)
            .then((res) => {
                console.log("RESPONSE", res);

                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });

export const getAllCourses = (id) =>
    new Promise((resolve, reject) => {
        AxiosBase.get("course/getAllCourses")
            .then((res) => {
                console.log("RESPONSE", res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });
export const DeleteCourse = (id) =>
    new Promise((resolve, reject) => {
        AxiosBase.delete("admin/deleteCourse?courseId=" + id)
            .then((res) => {
                console.log("RESPONSE", res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });
export const getCourse = (id) =>
    new Promise((resolve, reject) => {
        AxiosBase.get("admin/getCourse?courseId=" + id)
            .then((res) => {
                console.log("RESPONSE", res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });


export const updateCourse = (course) =>
    new Promise((resolve, reject) => {
        let { courseName, aboutCourse, image, price, courseTitle, content, isFree, id } = course
        if (price == null) {
            price = 0
        }

        if (isFree) {
            price = 0
        }

        const data = new FormData();
        data.append("courseName", courseName);
        data.append("aboutCourse", aboutCourse);
        // data.append("category", category);
        data.append("price", price);
        data.append("content", JSON.stringify(content));
        data.append("image", image);
        data.append("isFree", isFree);
        data.append("courseTitle", courseTitle);
        AxiosBase.put("admin/updateCourse?courseId=" + id, /*qs.stringify(data),config*/ data)
            .then((res) => {
                console.log("RESPONSE", res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });