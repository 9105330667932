import AxiosBase from "../Axiosbase";

export const updateMenteeDetails = (data) => {
  return new Promise((resolve, reject) => {
    AxiosBase.put("/mentee/updateMentee", data)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const addNote = ({ title, description }) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/addNote", { title, description })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const updateMentee = (formData) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/updateProfile", formData)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const updateMentorProfilePic = (formData) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentor/updateProfile", formData)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const getNote = () => {
  return new Promise((resolve, reject) => {
    AxiosBase.get("/mentee/getNotes")
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const getMenteeQA = (limit, skip) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/mentee/menteeQA?limit=${limit}&skip=${skip}`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const addMenteeQA = (question) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/addMenteeQA", { question })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const submitMenteeAssignment = ({ assignmentId, description, document }) => {
  const formData = new FormData();
  formData.append("assignmentId", assignmentId);
  formData.append("description", description);
  formData.append("document", document);
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/submitAssignment", formData)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const menteeReplyQA = ({ questionId, answer }) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/replyQA", { questionId, answer })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

// GET /mentee/assignments
// query: limit, skip

export const getAssignments = ({ limit, skip }) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/mentee/assignments?limit=${limit}&skip=${skip}`)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        }
      })
      .catch((e) => reject(e));
  });
};


export const startSessionMentee = ({ sessionId, sessionType}) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/mentee/sessionAccessToken`, { sessionId, sessionType })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const rateSessionMentee = ({ sessionId, sessionType, rating }) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/mentee/rateSession`, { sessionId, sessionType, rating })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
}


