import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Styles from '../../styles';
import { SignUp } from "../../NetworkRequest/requests/login";

class Signup extends Component {
  state = {
    name:"", email:"", experience:"", industry:"", domain:"", password:""
  };

  _signUp = (e)=>{
    e.preventDefault()
    let {name, email, experience, industry, domain, password} = this.state
    SignUp(name, email, experience, industry, domain, password).then(res=>{
      if(res.statusCode===200){
        localStorage.setItem("accessToken", res.accessToken)
        localStorage.setItem("userData", JSON.stringify(res.data))
        toast.success(res.customMessage)
        this.props.history.push("/")
      }
      else {
        toast.error(res.customMessage)
      }
    }).catch(err=>console.log(err))
  }
  
  handleExperienceChange = (e) => {
    e.preventDefault();
    let experience = e.target.value;
    this.setState({ experience });
    console.log(experience);
  };
  handleNameChange = (e) => {
    e.preventDefault();
    let name = e.target.value;
    this.setState({ name });
    console.log(name);
  };
  handleEmailChange = (e) => {
    e.preventDefault();
    let email = e.target.value;
    this.setState({ email });
    console.log(email);
  };
  handleIndustryChange = (e) => {
    e.preventDefault();
    let industry = e.target.value;
    this.setState({ industry });
    console.log(industry);
  };
  handleIDomainChange = (e) => {
    e.preventDefault();
    let domain = e.target.value;
    this.setState({ domain });
    console.log(domain);
  };
  handlePasswordChange = (e) => {
    e.preventDefault();
    let password = e.target.value;
    this.setState({ password });
    console.log(password);
  };
  render() {
    return (
      <section className="login-page">
        <Styles/>
        <div className="login-bg">
          <figure className="login-image mb-0">
            <img
              className="img-fluid"
              src="/assets/images/login-image.png"
              alt="login"
            />
          </figure>
        </div>
        <div className="login-form">
          <form>
            <div className="form-content">
              <div className="login-form-content">
                <div className="sec-title flex-content m-b-md">
                  <img
                    className="mr-3"
                    src="/assets/images/butterfly.svg"
                    alt="banner"
                  />
                  <h5 className="sec-title mb-0">
                    Lets get started. It absolutely free!
                  </h5>
                </div>
                <div className="sec-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. <br />
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s
                  </p>
                </div>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    className="form-control"
                    placeholder="John Doe"
                    type="text"
                    onChange={this.handleNameChange}
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    className="form-control"
                    placeholder="Lokeshnpl3213@gmail.com"
                    type="email"
                    required
                    onChange={this.handleEmailChange}
                  />
                </div>
                <div className="form-group select-group">
                  <label htmlFor="exampleFormControlSelect1">
                    Years of Experience
                  </label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    onChange={this.handleExperienceChange}
                  >
                    <option value="">Select Experience</option>
                    <option>0-3</option>
                    <option>3-5</option>
                    <option>5-8</option>
                    <option>8-12</option>
                    <option>12-15</option>
                    <option> {">"}15</option>
                  </select>
                </div>
                <div className="form-group select-group">
                  <label htmlFor="exampleFormControlSelect1">Industry</label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    onChange={this.handleIndustryChange}
                  >
                    <option value="">Select Industry</option>
                    <option>UI/UX</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>Others</option>
                  </select>
                </div>
                <div className="form-group select-group">
                  <label htmlFor="exampleFormControlSelect1">Domain</label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    onChange={this.handleIDomainChange}
                  >
                    <option value="">Select Domain</option>
                    <option>Option 2</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    className="form-control"
                    placeholder="28HJG88293"
                    type="password"
                    onChange={this.handlePasswordChange}
                  />
                </div>
                <div className="btn-grp mt-4">
                  <button
                    className="btn btn-primary btn-block"
                    href="javascript"
                    onClick={this._signUp}
                  >
                    Signup
                  </button>
                </div>
              </div>
              {/* <div className="sec-content text-align-center">
                <p>
                  Already have an account?
                  <Link
                    to={{ pathname: "/login", from: "signup" }}
                    style={{ fontSize: 18, color: "#007a87" ,paddingLeft:6}}
                  >
                      Login
                  </Link>
                </p>
              </div> */}
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default Signup;
