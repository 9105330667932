export const LOGIN_AS = "LOGIN_AS";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const USER_TYPE = "userType";
// export const testGuestLoginUrl = "http://localhost:3000/linkedin";
// export const testGuestLoginUrl = "http://13.233.214.122/linkedin";
export const testGuestLoginUrl = "https://www.shevolve.in/linkedin";
//export const testGuestLoginUrl = "https://shevolve.in/linkedin";

export const industries = [
  "Accounting/Finance",
  "Advertising/PR/MR/Events",
  " Agriculture/Dairy",
  "Animation",
  "Architecture/Interior Design",
  "Auto/Auto Ancillary",
  "Aviation/Aerospace firm",
  "Banking/Financial Services/Broking",
  "BPO/ITES",
  "Brewery/Distillery",
  "Chemicals/Petrochemicals/Plastic/Rubber",
  "Construction/ Engineering/Cement/Metals",
  "Consumer Goods",
  "Courier/Transportation/Freight",
  "Ceramics/Sanitaryware",
  "Defence/Government",
  "Education/Teaching/Training",
  "Electrical/Switchgear",
  "Export/Import",
  "Facility Management",
  "Fertilizers/Pesticides",
  "FMCG/Food/Beverage",
  "Food processing",
  "Fresher/Trainee",
  "Gems/Jewellery",
  "Glass",
  "Heat Ventilation Air Conditioning",
  "Hotels/Restaurants/Airlines/Travel",
  "Industrial Products/Heavy Machinery",
  "Insurance",
  "IT-Software- Software services",
  "IT-Hardware & Networking",
  "Teleccom/ISP",
  "KPO/Research/Analytics",
  "Legal",
  "Media/Dotcom/Entertainment",
  "Internet/E-commerce",
  "Medical/Healthcare/Hospital",
  "Mining",
  "NGO/Social Services",
  "Office Eqquipment/Automation",
  "Oil and Gas/Power/Infrastructure/Energy",
  "Paper",
  "Pharma/Biotech/Clinical Research",
  "Printing/Packaging",
  "Publishing",
  "Realestate/Property",
  "Recruitment",
  "Retail",
  "Security/Lawenforcement",
  "Semiconductors/Electronics",
  "Shipping/Maring",
  "Steel",
  "Strategy/Management consulting firms",
  "Textiles/Garments/Accessories",
  "Tyres",
  "Water Treatment/Waste Management",
  "Wellness/Fitness/Sports",
  "Other",
  "Medical Devices",
  "Equipments",
];

export const domains = [
  "Accounting",
  "Agent",
  "Airline/Reservation/Ticketing/Travel",
  "Analytics&Business Intelligence",
  "Ancoring/TV/Films/Productions",
  "Architect/Interior designing/Naval Arch",
  "Art Director/Graphic/ Web Designer",
  "Banking/Insurance",
  "Contents/Editors/Journalists",
  "Corporate Planning/Stratergy/Consulting",
  "Entrepreneur/Businessman/Outside Management Consultant",
  "Export/Import",
  "Fashion",
  "Front Office Operator/ Computer Operator/secretatial",
  "Hotels/Restaurant Manager",
  "HR/Admin/PM/IR/Training",
  "ITES/BPO/Customer Service/Tele calling",
  "Legal/Law",
  "Mediacal Professional/Healthcare Practenior/Technician",
  "Marketing/Advt/PR/MR/Media planning/Corp comm",
  "Packaging Development",
  "Production/Service Engineer/Manufacturing/Maintainance",
  "Project Management/Site Engineers",
  "Purchase/SCM",
  "R&D/Engineering Design",
  "Sales/Business Development/Client Servicing",
  "Security",
  "Shipping",
  "Software Development - All",
  "Software Development - Application Programming",
  "Software Development - Client Server",
  "Software Development - Database Administration",
  "Software Development - E-Commerce/Internet Technologies",
  "Software Development - Embedded Technologies",
  "Software Development - ERP/CRM",
  "Software Development - Network Administration",
  "Software Development - Others",
  "Software Development - QA and Testing",
  "Software Development - System Programming",
  "Software Development - Telecom Softwarre",
  "Software Development - Systems/EDP and MIS",
  "Teaching/Education & Language Specialist",
  "Telecom/IT Hardware/Tecch Support",
  "Top Management",
  "Any Other",
  "IT",
  "Investment Banking",
  "Medical Coding",
  "Techno Functional",
  "Operations",
];

export const allMentorsHome = [
  {
    image:'/assets/images/tech mentor/Aiswaria Balasubramanian_SAFe 5 Product Owner Product Manager_Tagit Pte Ltd.jpg',
    name: 'Aiswaria Balasubramanian',
    role:'Product Management Professional - Innovation Fintech',
    // company: '/assets/images/Company Logo_s/Tagit.png'
  },
  {
    image:
        "/assets/images/International mentors/Shrinedhi Rajan_Product Manager_PointsBet.jpg",
    name: "Shrinedhi Rajan",
    role: "Product Manager",
    company: "/assets/images/Company Logo_s/pointsbet-logo.png",
},

  {
    image:  '/assets/images/tech mentor/Kaivalya Gundu_Senior Manager_EY GDS.jpg',
    name: 'Kaivalya Gundu',
    role:'Senior Manager',
    company: '/assets/images/Company Logo_s/EY.png'
  },
  {
    image:  '/assets/images/tech mentor/Kuppulakshmi Krishnamoorthy_Global Head—Zoho for Startups_Zoho Corporation.jpg',
    name: 'Kuppulakshmi Krishnamoorthy',
    role:'Global Head',
    company: '/assets/images/Company Logo_s/zoho.svg'
  },
  {
    image: '/assets/images/tech mentor/Manvi Tyagi_Software Engineer_Twitter.jpg',
    name: 'Manvi Tyagi',
    role:'Software Engineer',
    company: '/assets/images/Company Logo_s/twitter.png'
  },

  {
    image:   '/assets/images/tech mentor/Nirali Upadhyaya Shah_Senior Product Manager_Media.net.jpg',
    name: 'Nirali Upadhyaya Shah',
    role:'Senior Product Manager',
    company: '/assets/images/Company Logo_s/media.net.png'
  },

  {
    image:   '/assets/images/tech mentor/Preethi Guruswamy_Group Development Manager at Intuit_Network Leader at Lean In.jpg',
    name: 'Preethi Guruswamy',
    role:'Network Leader',
    company: '/assets/images/Company Logo_s/lean_in.png'
  },
  {
    image: "/assets/images/coping mentors/Samiksha Seth_Product Manager.jpeg",
    name: "Samiksha Seth",
    role: "Product Manager",
    // company: "/assets/images/Company Logo_s/growth_collective.png",
},

  // {
  //   image: '/assets/images/tech mentor/Rajashree Rao_Head of AI Innovation Hub _ Partnerships _ Ecosystem (APAC)_Rolls Royce.jpg',
  //   name: 'Rajashree Rao',
  //   role:'Head of AI Innovation Hub',
  //   company: '/assets/images/Company Logo_s/Rolls Royce logo.jpg'
  // },
]



export const allMentors = {

  "International Mentor": [
    {
      image:
        "/assets/images/International mentors/Shrinedhi Rajan_Product Manager_PointsBet.jpg",
      name: "Shrinedhi Rajan",
      role: "Product Manager",
      company: "/assets/images/Company Logo_s/PointsBet logo.jpg",
    },

    {
      image:
        "/assets/images/International mentors/Shubham Saloni_Wireless Software Developer_Aruba Networks.jpg",
      name: "Shrinedhi Rajan",
      role: "Wireless Software Developer",
      company: "/assets/images/Company Logo_s/Aruba Networks.jpg",
    },
  ],

  "Industory Mentors": [
    {
      image:
        "/assets/images/Industry mentors/Awantika Bhardwaj_Vice President - Employee Success_Abzooba.jpg",
      name: "Awantika Bhardwaj",
      role: "Vice President",
      company: "/assets/images/Company Logo_s/Aruba Networks.jpg",
    },

    {
      image:
        "/assets/images/Industry mentors/Bhaduri SaravanaKumar_Co-Founder_Shevolve.jpg",
      name: "Bhaduri SaravanaKumarj",
      role: "Co-Foundert",
      company: "/assets/images/Company Logo_s/Shevolve.jpg",
    },

    {
      image:
        "/assets/images/Industry mentors/Deepa Vijayaraghavan_Founder_Shevolve.jpeg",
      name: "Deepa Vijayaraghavan",
      role: "Founder",
      company: "/assets/images/Company Logo_s/Shevolve.jpg",
    },

    {
      image:
        "/assets/images/Industry mentors/Jayashri Ravi_General Manager - Product_Matrimony.com Limited.jpg",
      name: "Jayashri Ravi",
      role: "General Manager",
      company: "/assets/images/Company Logo_s/Matrimony.com Limited logo.jpg",
    },

    {
      image:
        "/assets/images/Industry mentors/Kanchan Bhonde_Assistant Vice President_Tech Mahindra.jpg",
      name: "Kanchan Bhonde",
      role: "Assistant Vice President",
      company: "/assets/images/Company Logo_s/Tech Mahindra logo.",
    },

    {
      image:
        "/assets/images/Industry mentors/Lakshmi RR_Head Diversity _ Inclusion and HR Site Lead_Fidelity Investments.jpg",
      name: "Lakshmi RR",
      role: "Head Diversity",
      company: "/assets/images/Company Logo_s/Fidelity Investments.jpg",
    },

    {
      image:
        "/assets/images/Industry mentors/Nrithya Rajagopalan_Vice President, Product Development and Services_LeanSwift Solutions India Pvt. Ltd..jpg",
      name: "Nrithya Rajagopalan",
      role: "Vice President",
      company:
        "/assets/images/Company Logo_s/LeanSwift Solutions India Pvt. Ltd..jpg",
    },

    {
      image:
        "/assets/images/Industry mentors/Padmini Janaki_CEO_Mind and Mom.jpg",
      name: "Padmini Janaki",
      role: "CEO",
      company: "/assets/images/Company Logo_s/Mind and Mom.jpg",
    },

    {
      image:
        "/assets/images/Industry mentors/Rani Pangam_Sr. Dir. Strategy Realization Office, Enterprise Architecture _ PMO_ServiceNow.jpg",
      name: "Rani Pangam",
      role: "Sr. Dir. Strategy Realization",
      company: "/assets/images/Company Logo_s/PMO_ServiceNow.jpg",
    },

    {
      image:
        "/assets/images/Industry mentors/Sanchita Ganguly_TitleBrand Building Head - Vice President_UltraTech Cement.jpg",
      name: "Sanchita Ganguly",
      role: "Vice President",
      company: "/assets/images/Company Logo_s/UltraTech Cement.jpg",
    },

    {
      image:
        "/assets/images/Industry mentors/Shreedevi Ramesh_Co-Founder_Gurus360 Knowledge Services.jpg",
      name: "Shreedevi Ramesh",
      role: "Co-Founder",
      company: "/assets/images/Company Logo_s/Gurus360 Knowledge Services.jpg",
    },

    {
      image:
        "/assets/images/Industry mentors/Tina Rastogi_VP-Human Capital Management_Cybage Software.jpg",
      name: "Tina Rastogi",
      role: "VP-Human Capital Management",
      company: "/assets/images/Company Logo_s/Cybage Software.jpg",
    },

    {
      image:
        "/assets/images/Industry mentors/Vanipriya_Director _ Head of Co-Creation_Founder at Zeekh.jpg",
      name: "Vanipriya",
      role: "Director_Head ",
      company: "/assets/images/Company Logo_s/Zeekh.jpg",
    },
  ],

  "Coping Mentors": [
    {
      image:
        "/assets/images/coping mentors/Janet Rajan_Founder_Principal at Growth Collective_Executive Coach at Righ Management.jpg",
      name: "Janet Rajan",
      role: "Founder - Principal",
      company: "/assets/images/Company Logo_s/Righ Management.jpg",
    },

    {
      image: "/assets/images/coping mentors/Samiksha Seth_Product Manager.jpeg",
      name: "Samiksha Seth",
      role: "Product Manager",
      company: "/assets/images/Company Logo_s/.jpg",
    },
  ],

  "Aspirational mentors": [
    {
      image:
        "/assets/images/Aspirational mentors/Ramya Lakshmanan_Director of Account Management_Plum.jpg",
      name: "Ramya Lakshmanan",
      role: "Director",
      company: "/assets/images/Company Logo_s/Plum.jpg",
    },

    {
      image:
        "/assets/images/Aspirational mentors/Samina Ali_Product Manager_nurture.farm (A UPL group company).jpg",
      name: "Samina",
      role: "Director",
      company: "/assets/images/Company Logo_s/Plum.jpg",
    },
  ],
};

