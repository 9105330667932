import React, { useRef, useLayoutEffect, useState, useEffect } from 'react'
import './program.scss';
import { useHistory } from 'react-router-dom';
import Layout from "../../layout/default";
import {Helmet} from "react-helmet";
import {AnimWrap} from "../animation-gsap/Animation"


export default function Program() {
    const faq_data = [
        {
            question: "What are the current life, career, or organizational goals that you have?",
            answer: "Assess yourself and your career path with clarity with our Proprietary Career Evaluation Form."
        },
        {
            question: "What skills or experience gaps do I have that are keeping me from achieving those goals?",
            answer: "The Shevolve team will guide you in setting your career goals (short & long term), updating yourself with the industry changes with relevant courses and guide you along the path to a successful career. Our handcrafted courses are to ensure that you stay abreast with the expectations of the industry."
        },
        {
            question: "What type of mentor will help me achieve those goals or close those gaps?",
            answer: "Our team of experts will assign the right mentor for you from our Mentoring Panel ranging from peer mentoring, reverse mentoring to practical mentors. Our industry experts have the experience and knowledge to guide you to your dream career and with the support of our life coaches, you’ll be mentally prepared to face all kinds of challenges."
        },
        {
            question: "Will I be able to achieve my career goals and gain back my confidence?",
            answer: "<p>Our life coaches will provide you with the emotional support you may need during your journey.</p><p>Apart from our individualized sessions, Shevolve also keeps you updated with current and relevant topics through cohorts, workshops, webinars and initiatives on a regular basis. Our blogs and communities provide an insight to various technical situations and also inspiring stories of real women.</p>"
        }
    ]
    

    const history = useHistory();


    // const myRef = useRef(null);

    const sectionId = document.getElementById("subscriptionSection");
    // const secTopVal = sectionId

    console.log("sectionIDVal", sectionId && sectionId.offsetTop);

    useLayoutEffect(() => {
        // window.scrollTo(0, 0)
    });
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const secondSection = useRef(null)

    const scrollHandler = () => {
        secondSection.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const [ip, setIp] = useState([]);

    useEffect(() => {
        setTimeout(
            () => setIp('res.data'), 
            100
          );
        
    }, []);


    return (
        <Layout>
            <AnimWrap />
                <Helmet>
                    <title>Individual Subscription for Personalized Career Mentoring Programs | Shevolve</title>
                    <meta name="description" content="Discover Shevolve's Individual subscription for personalized 1:1 mentorship, customized courses and programs relevant to your vision board, and access to like-minded communities." />
                    <meta name="keywords" content="Career mentoring, Women's mentorship, Professional development, Individual subscription, Personalized guidance, Empowerment for women, Career growth, Goal setting, Accountability, coaching, Women entrepreneurs, Leadership development, Skill enhancement, Mentorship programs, Diversity in the workplace, Women in business, Career coaching, Workforce empowerment, Professional success, Gender equality, Entrepreneurial support" />

                    <meta property="og:title" content="Individual Subscription for Personalized Career Mentoring Programs | Shevolve" />
<meta property="og:description" content="Discover Shevolve's Individual subscription for personalized 1:1 mentorship, customized courses and programs relevant to your vision board, and access to like-minded communities." />
<meta property="og:image" content="https://shevolve.in/images/home/slide/slide1.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Individual Subscription for Personalized Career Mentoring Programs | Shevolve" />
<meta name="twitter:description" content="Discover Shevolve's Individual subscription for personalized 1:1 mentorship, customized courses and programs relevant to your vision board, and access to like-minded communities." />
<meta name="twitter:image" content="https://shevolve.in/images/home/slide/slide1.png" />


                </Helmet>
                
            {/*=========================================
            ===================== Banner ================
            ============================================ */}
            <section className="sh-section sh-section__banner pb-0">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="sh-banner__content">
                            <h1 className="sh-banner__title sh-anim__stagger-fade-top">Nurture your Talent with the Right Mentors through our Subscription Program</h1>
                            <p className="sh-para sh-banner__para sh-banner__para--grey sh-anim__stagger-fade-top">Gain an inside look at success through trusted mentors, life coaches and accountability coaches who help you set & achieve SMART professional goals.</p>
                            <a className="sh-button mt-3 sh-anim__stagger-fade-top" onClick={scrollHandler}>Read More</a>
                            {/* <div className="sh-banner__title"></div> */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-center sh-anim__stagger-fade-right">
                            <img className="img-fluid" src="./assets/program/new/banner-image.svg" alt="Individual Subscription for Personalized Career Mentoring Programs | Shevolve" />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="position-absolute sh-section__curved-bottom w-100"><img className="img-fluid w-100" src="./images/home/curve-divider.png" /></div>
            </section>

            {/*=========================================
            ===================== Lorem Ipsum dollar amet ================
            ============================================ */}
            <section ref={secondSection} className="sh-section ">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5">
                                <div className="sh-content__wrap sh-section__title-wrap">
                                    <div className="sh-subtitle sh-anim__stagger-fade-top">What is</div>
                                    <div className="sh-title sh-title--light sh-anim__stagger-fade-top"><b>Mentoring</b> for Women</div>
                                </div>
                            </div>
                            <div className="col-xl-7">
                                <div className="sh-text">
                                    <p className="mb-3 sh-anim__stagger-fade-top">With a steady increase in the number of mentorship organizations 
                                        for founders and employees, only 8% of women have access to mentors while working, 
                                        after a career break or during their entrepreneurship journey. Shevolve’s 
                                        subscription platform ensures working and aspiring to work women receive the 
                                        right type of mentor that is holistic, relatable, practical and objective. 
                                        The need for a mentoring platform focusing on only women drives motivation 
                                        to enable a workforce of women leaders, empathetic solutions for women specific 
                                        career roadblocks, fighting bias and encouraging diversity through emotional intelligence 
                                        and a community of women who let us grow as we rise.
                                    </p>
                                    <p className="mb-3 sh-anim__stagger-fade-top">
                                        Shevolve’s MFW Subscription ensures 1:1 mentors to provide a clear roadmap professionally 
                                        and emotionally, customized courses that are relevant to your vision board and access to 
                                        like-minded communities, programs and champions of entrepreneurship and diversity.
                                    </p>
                                    <p className="sh-anim__stagger-fade-top">
                                        With monthly, quarterly and yearly subscriptions, we ensure your simple but life altering 
                                        questions are answered, hand-hold and support you through your career journey, find meaningful 
                                        connections to build your brand while evolving into a Community of Role Models and Women Leaders! 
                                        MFW not only encourages you to be a femtee but also evolves you to become HerMentor as you progress 
                                        to achieve your professional goals.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*=========================================
            ===================== Packages ================
            ============================================ */}
            <section className="sh-section sh-section--green" id={`allPackages`}>
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="text-center sh-section__title-wrap">
                            <div className="sh-title sh-title__underline sh-anim__stagger-fade-top">Packages</div>
                            <p className="sh-para px-5 sh-anim__stagger-fade-top">Our Subscription packages are curated based on your career goals with personalized one month, quarterly and yearly packages starting with our Proprietary mentee evaluation and transformation index
. Apply for a Free Trial that assess your strengths along with a thirty minute mentoring session from the Shevolve Team. </p>
                        </div>
                        <div className="row sh-package__cards">
                            <div className="col-lg-4 mb-5 mb-lg-0">
                                <div className="sh-package__card sh-package__card--phoenix sh-anim__stagger-fade-top">
                                    <div className="sh-package__card-inwrap">
                                        <div className="sh-package__card-top">
                                            <div className="d-flex flex-lg-column flex-xl-row align-items-center justify-content-between">
                                                <div className="sh-package__card-icon"><img className="img-fluid" src="./images/program/phoenix.svg" alt="" /></div>
                                                <div className="sh-package__card-content">
                                                    <div className="sh-package__card-title sh-package__card-title--phonix">Rise Up</div>
                                                    <div className="">
                                                        <div className="sh-package__card-subtitle sh-para" style={{minHeight:"80px"}}>For Women on a Career Break</div>
                                                        <div className="sh-text"></div>
                                                    </div>
                                                </div>
                                                {/* <div className="pr-4">PHOENIX</div>
                                                <div className="sh-package__card-icon"><img className="img-fluid" src="/assets/program/phoenix.svg" alt="phoenix" /></div> */}
                                            </div>
                                            {/* <div className="sh-package__card-subtitle">ONLY STUDENTS <br/> 12 SESSIONS/MONTH</div> */}
                                            <div className="sh-package__card-items">
                                                <div className="sh-package__card-item sh-package__card-item--para">For Women returning to work after a break <br /> of more than a year</div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>1:1 Personalised Mentoring Sessions</div>
                                                </div>
                            
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Your Own Accountability Coach</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Goal Setting and Goal tracking</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Curated handouts based on your career goals</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Customized solutions to respond to your real-world situations</div>
                                                </div>
                                                
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Access to Shevolve Community</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Exclusive Program Discounts and Perks</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            {/* <button className='sh-package__button sh-package__button--light w-100' onClick={() => {
                                                history.push('/WhoIsSigning')
                                            }}>Apply for Free Trial
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                            </svg>
                                            </button>  */}
                                            <button className='sh-package__button sh-package__button--light w-100' data-toggle="modal" data-target="#exampleModalFreeTrial">Apply for Free Trial
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                            </svg>
                                            </button> 
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-5 mb-lg-0">
                                <div className="sh-package__card sh-package__card--fly-high sh-anim__stagger-fade-top">
                                    <div className="sh-package__card-inwrap">
                                        <div className="sh-package__card-top">
                                            <div className="d-flex flex-lg-column flex-xl-row align-items-center justify-content-between">
                                                <div className="sh-package__card-icon"><img className="img-fluid" src="./images/program/fly-high.svg" alt="" /></div>
                                                <div className="sh-package__card-content">
                                                    <div className="sh-package__card-title sh-package__card-title--fly-high">Fly High</div>
                                                    <div className="">
                                                        <div className="sh-package__card-subtitle sh-para" style={{minHeight:"80px"}}>For Working Women</div>
                                                        <div className="sh-text align-items-center"></div>
                                                        {/* <div className="sh-text">12 Sessions/Month</div> */}
                                                    </div>
                                                </div>
                                                {/* <div className="pr-4">PHOENIX</div>
                                                <div className="sh-package__card-icon"><img className="img-fluid" src="/assets/program/phoenix.svg" alt="phoenix" /></div> */}
                                            </div>
                                            {/* <div className="sh-package__card-subtitle">ONLY STUDENTS <br/> 12 SESSIONS/MONTH</div> */}
                                            <div className="sh-package__card-items">
                                                <div className="sh-package__card-item sh-package__card-item--para" >For women aspiring to become leaders in their industry or ambitious to switch to new roles</div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>1:1 Personalised Mentoring Sessions</div>
                                                </div>
                                                
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Your Own Accountability Coach</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Goal Setting and Goal Tracking</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Curated handouts based on your career goals</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Customized solutions to respond to your real-world situations</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Access to Shevolve Community </div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Exclusive Program Discounts and Perks</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            {/* <button className='sh-package__button sh-package__button--dark w-100' onClick={() => {
                                                history.push('/WhoIsSigning')
                                            }}>Apply for Free Trial
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                            </svg>
                                            </button>  */}
                                            <button className='sh-package__button sh-package__button--dark w-100' data-toggle="modal" data-target="#exampleModalFreeTrial">Apply for Free Trial
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                            </svg>
                                            </button> 
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-5 mb-lg-0">
                                <div className="sh-package__card sh-package__card--she-tribe sh-anim__stagger-fade-top">
                                    <div className="sh-package__card-inwrap">
                                        <div className="sh-package__card-top">
                                            <div className="d-flex flex-lg-column flex-xl-row align-items-center justify-content-between">
                                                <div className="sh-package__card-icon"><img className="img-fluid" src="./images/program/she-tribe.svg" alt="" /></div>
                                                <div className="sh-package__card-content">
                                                    <div className="sh-package__card-title sh-package__card-title--she-tribe">She Tribe</div>
                                                    <div className="">
                                                        <div className="sh-package__card-subtitle sh-para" style={{minHeight:"80px"}}>For Women Entrepreneurs and Women-led Startups</div>
                                                        {/* <div className="sh-text d-flex align-items-center">8- 10 Mentee Activities / Month</div> */}
                                                        {/* <div className="sh-text">12 Sessions/Month</div> */}
                                                    </div>
                                                </div>
                                                {/* <div className="pr-4">PHOENIX</div>
                                                <div className="sh-package__card-icon"><img className="img-fluid" src="/assets/program/phoenix.svg" alt="phoenix" /></div> */}
                                            </div>
                                            {/* <div className="sh-package__card-subtitle">ONLY STUDENTS <br/> 12 SESSIONS/MONTH</div> */}
                                            <div className="sh-package__card-items">
                                                <div className="sh-package__card-item sh-package__card-item--para">For Women Entrepreneurs who need support with Funding, Valuation, Business Model & Validation</div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>1:1 Personalised Mentoring Sessions</div>
                                                </div>
                    
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Your Own Accountability Coach</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Goal Setting and Goal Tracking</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Curated handouts based on your career goals</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Customized solutions to respond to your real-world situations</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Access to Shevolve Community</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Exclusive Program Discounts and Perks</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Pitch Session to Mentors</div>
                                                </div>
                                                <div className="sh-package__card-item">
                                                    <span className="sh-package__card-icon--tick mr-3 mt-2"><img src="/assets/program/list-icon.svg" alt="" /></span>
                                                    <div>Access to Aspirational Mentor</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button className='sh-package__button sh-package__button--dark w-100' data-toggle="modal" data-target="#exampleModalFreeTrial">Apply for Free Trial
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                            </svg>
                                            </button> 
                                            {/* <button className='sh-package__button sh-package__button--dark w-100' onClick={() => {
                                                history.push('/WhoIsSigning')
                                            }}>Apply for Free Trial
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                            </svg>
                                            </button>  */}
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*=========================================
            ===================== FAQ ================
            ============================================ */}
            <section className="sh-section">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="sh-title sh-section__title-wrap text-center sh-anim__stagger-fade-top">FAQ</div>
                        <div className="row">
                            <div className="col-md-12 sh-faq mb-3 mb-md-0">
                                <div className="sh-faq__wrap sh-anim__stagger-fade-top">
                                    {faq_data.map((data, index) => {
                                        return (
                                            <div key={index} className="sh-faq__card" data-toggle="collapse" data-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                                <div class="sh-faq__card-trigger btn-header-link w-100 sh-text text-left">
                                                    {data.question}
                                                </div>
                                                <div class="sh-faq__card-content collapse" id={`collapse${index}`}>
                                                    <div class="card-body pb-0">
                                                        {/* {data.answer} */}
                                                        <p
                                                            className="sh-faq__card-answer"
                                                            dangerouslySetInnerHTML={{ __html: data.answer }}
                                                        >
                                                            {/* {ReactHtmlParser(blog1.description)} */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            {/* <div className="col-md-4 col-xl-3 mt-4 mt-md-0">
                                <div className="sh-faq__cta sh-faq__cta--blue text-center h-100 sh-anim__stagger-fade">
                                    <div className="sh-faq__cta-image mb-2">
                                        <img className="img-fluid d-none d-md-block" src="./images/be-a-mentor/faq-cta.png" alt="" />
                                    </div>
                                    <div className="font-weight-bold mb-2 sh-faq__cta-title">Have more questions?</div>
                                    <p className="sh-text mb-2">Let us know and our experts will get in touch with you</p>
                                    <a className="sh-whatsapp__cta-button--whatsapp d-inline-flex align-items-center" href="https://wa.me/919342153849">
                                        <img style={{ height: 30 }} src="./images/program/whatsapp.png" alt="whatsapp" />
                                        <span className="ml-2">Chat with us</span>
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            {/*=========================================
            ===================== Whatsapp CTA ================
            ============================================ */}
            <section className="sh-section pb-0 pt-0">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="sh-whatsapp__cta-section sh-anim__stagger-fade-right" style={{ backgroundImage: "url(./images/whatsapp-cta/whatsapp-background.png)" }}>
                            <div className="row sh-whatsapp__cta-row align-items-lg-center align-items-end">
                                <div className="col-md-4 sh-whatsapp__cta-left order-2 order-md-1">
                                    <div className="text-center">
                                        <img className="img-fluid" src="./images/whatsapp-cta/whatsapp.svg" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-8 sh-whatsapp__cta-right order-1 order-md-2">
                                    <div className="text-center py-5">
                                        <div className="sh-whatsapp__cta-content">
                                            <div className="sh-whatsapp__cta-title mb-1">Want to have a word with us ?</div>
                                            <div className="sh-whatsapp__cta-subtitle">Chat with us on <span className="sh-whatsapp__cta-subtitle--whatsapp">Whatsapp</span></div>
                                        </div>
                                        <a className="sh-whatsapp__cta-button--whatsapp d-inline-flex align-items-center" href="https://wa.me/919841003516">
                                            <img style={{ height: 30 }} src="./images/program/whatsapp.png" alt="whatsapp" />
                                            <span className="ml-2">Chat with us</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}