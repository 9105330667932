import moment from "moment";
import { Modal } from "react-responsive-modal";
import { deleteMentorAvailability } from "../../../NetworkRequest/requests/mentor";

const bg = {
  modal: { borderRadius: 16 },
  overlay: {},
};

const AvailabilityList = (props) => {
  const {  open, day, onClose, events, onAddClick, onDelete } = props;


  const handleDeleteAvailability = (id,index ) => {
    console.log('deleteId',id);
    deleteMentorAvailability(id)
      .then((res) => {
       onDelete(index)
      })
      .catch((e) => console.log(e));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      // showCloseIcon={false}
      center
      styles={bg}
    >
      <div
        style={{ display: "flex" }}
        // className={"modal fade"}
        id="news-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl form-size my-0">
          <div className="modal-content form-content basic-modal-content email-modal-content">
            <div
              //style={{borderRadius : 5}}
              className="news-wrap"
            >
              <div className="row no-gutters">
                <div className="col-md-12 form-padlr">
                  <div className="form-block">
                    <div className="basic-detail-header" style={{ marginTop: "-1px" }}>
                      <div className="flex-space">
                        <h6 className="sec-title">{moment(day).format("DD MMMM YYYY")}</h6>
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <div className="col-md-12">
                        <div className="email-text-content detail-text-content">
                          <div className="row">
                            <div className="col-md-12">
                              {events.map((x,index) => (
                                <div key={`AVAIL${index}`} className="d-flex justify-content-between mt-3">
                                  <div>
                                    <p className="mb-0 text-black-color">Availability</p>
                                    <p className="mb-0 text-black-color f-size-12">{moment.utc(x.date).format("hh:mm a")} {" - "} {moment.utc(x.endDate).format("hh:mm a")}</p>
                                  </div>
                                  <button className="btn btn-gradient-primary" onClick={e => handleDeleteAvailability(x.id, index)}>Delete</button>
                                </div>
                              ))}

                              <div className="d-flex justify-content-between mt-3">
                                <div>
                                  <p className="mb-0 text-black-color">Mark Availability</p>
                                  <p className="mb-0 text-black-color f-size-12">00:00 </p>
                                </div>
                                <button className="btn btn-gradient-primary" onClick={onAddClick}>
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AvailabilityList;
