import { useEffect, useState } from "react";
import { sessionAndMentors } from "../../../NetworkRequest/requests/mentor";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { authenticated } from "../../privateAuth/tokenExists";
import NewHeader from "../static/newHeader";

const MentorHome = (props) => {
  const history = useHistory();
  const state = useSelector((state) => state.loginSignPopUp.userData);
  const [sessionAndCoaching, setsessionAndCoaching] = useState({
    coachingSessions: [],
    mentees: { Mentees: [] },
    mentoringSessions: [],
  });

  useEffect(() => {
    if (!authenticated()) {
      history.push({
        pathname: "/login",
        state: { from: "/profile" },
      });
    }
    _getMenteeAndMentors();
  }, []);

  const _getMenteeAndMentors = () => {
    sessionAndMentors().then((res) => {
      if (res) {
        setsessionAndCoaching(res);
      }
    });
  };

  return (
    <div className="wrapper container-width">
      <NewHeader />
      {/*header*/}
      {/* <NewHeader props={props} from="courses" /> */}
      <div className="mentor-home-banner py-5">
        <div className="container my-2 ">
          <div className="d-flex align-items-center">
            <div>
              <img className="img-fluid" style={{ objectFit: "cover", height: 250, width: 250 }} src={state.profilePic ? state.profilePic : "/assets/images/casual_profile.png"} alt="profile " />
            </div>
            <div className="ml-5">
              <div className="d-flex">
                <img src="/assets/images/butterfly.svg" alt="" />
                <h4 className="ml-3" style={{ color: "#0D5975", textTransform: "capitalize" }}>
                  {state.name}
                </h4>
              </div>
              {/* <p className="f-size-12">
                Lorem1 Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's. Lorem Ipsum
                is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.
              </p> */}
              <div className="mentor-home-content mt-3">
                <p>
                  <span>Phone Number</span> : {state.phoneNumber}
                </p>
                <p>
                  <span>Industry</span> : {state.industry}
                </p>
                <p>
                  <span>Role </span> : {state.role}
                </p>
                <p>
                  <span>Domain </span> : {state.domain}
                </p>
                <p>
                  <span>Years of Experience </span> : {state.experience} years
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div>
          <h4 className="my-4">Assinged  Mentees</h4>
          <div className="row">
            {sessionAndCoaching.mentees.length > 0 ? (
              sessionAndCoaching.mentees.map((x) => (
                <div style={{ cursor: "pointer" }} className=" col-md-4 col-lg-3 col-sm-12 ">
                  <img
                    style={{
                      width: "100%",
                      height: 182,
                    }}
                    src={x.profilePic || 'assets/images/female-placeholder.jpg'}
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "assets/images/female-placeholder.jpg";
                    }}
                  />
                  <p className="mb-0 mt-3 text-dark" onClick={(e) => history.push(`/menteeDetails/${x._id}`)}>
                    <b style={{ textTransform: "capitalize" }}>{x.name}</b>
                  </p>
                  <p className="f-size-12">{x.packageInfo && x.packageInfo.packageName}</p>
                </div>
              ))
            ) : (
              <p className="ml-3 text-black-color">Mentees not Assigned yet</p>
            )}
          </div>
        </div>

        <div>
          <h4 className="my-4">Upcoming Sessions</h4>
          <div className="row">
            {sessionAndCoaching.coachingSessions.length > 0 ? (
              sessionAndCoaching.coachingSessions.map((x) => (
                <div key={`s${x}`} className=" col-md-4 col-lg-3 col-sm-12 ">
                  <img
                    style={{
                      width: "100%",
                      height: 182,
                    }}
                    src={x.mentee[0].profilePic || 'assets/images/female-placeholder.jpg'}
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "assets/images/female-placeholder.jpg";
                    }}
                  />
                  <p className="mb-0 mt-3 text-dark">
                    <b>
                      Session with <span style={{ color: "#0C4B6C", cursor: 'pointer' }} onClick={(e) => history.push(`/menteeDetails/${x.mentee[0]._id}`)}>{x.mentee[0].name}</span>
                    </b>
                  </p>
                  <p className="f-size-12 mt-2 mb-4" style={{ color: "#525252" }}>
                    {moment.utc(x.date).format("DD MMMM YYYY")} &#9679; {moment.utc(x.date).format("hh:mm a")}
                  </p>
                </div>
              ))
            ) : (
              <p className="ml-3 text-black-color">no upcoming session</p>
            )}
          </div>
        </div>

        <div>
          <h4 className="my-4">Upcoming Mentoring Sessions</h4>
          <div className="row">
            {sessionAndCoaching.mentoringSessions.length > 0 ? (
              sessionAndCoaching.mentoringSessions.map((x) => (
                <div className=" col-md-4 col-lg-3 col-sm-12 ">
                  <img
                    style={{
                      width: "100%",
                      height: 182,
                    }}
                    src={x.mentee[0].profilePic || 'assets/images/female-placeholder.jpg'}
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "assets/images/female-placeholder.jpg";
                    }}
                  />
                  <p className="mb-0 mt-3 text-dark">
                    <b>
                      Session with <span style={{ color: "#0C4B6C", cursor: 'pointer' }} onClick={(e) => history.push(`/menteeDetails/${x.mentee[0]._id}`)}>{x.mentee[0].name}</span>
                    </b>
                  </p>
                  <p className="f-size-12 mt-2 mb-4" style={{ color: "#525252" }}>
                    {moment.utc(x.date).format("DD MMMM YYYY")} &#9679; {moment.utc(x.date).format("hh:mm a")}
                  </p>
                </div>
              ))
            ) : (
              <p className="ml-3 text-black-color">no upcoming session</p>
            )}
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default MentorHome;
