export const contentTotalDuration = (course) => {
    let hour = 0;
    let minute = 0;
    let second = 0;

    for (let i of course.content) {
      const splitTime = i.videoDuration.split(":");
      if (splitTime.length >= 2) {
        hour += parseInt(splitTime[0]);
        minute += parseInt(splitTime[1]);
        second += parseInt(splitTime[2]);
      }
    }

    if (second > 59) {
      minute += Math.floor(second / 60);
      // minute = minute % 60;
    }

    if (minute > 59) {
      hour += Math.floor(minute / 60);
      minute = minute % 60;
    }

    return { hour, minute };
  };