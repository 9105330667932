

export  const authenticated = () => {
    let isAuthenticated = false;
    const token = localStorage.getItem('accessToken')
    console.log(token)
    if (token && token !== '') {
        isAuthenticated = true
    }
    return isAuthenticated
}
