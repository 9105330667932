import AxiosBase from "../Axiosbase";
import moment from "moment";

export const getAssignMentees = () =>
  new Promise((resolve, reject) => {
    AxiosBase.get("/mentor/getAssignMentees")
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });

export const addAvailability = ({ from, to }) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentor/availability", {
      startDate: moment(from).format("DD/MM/YYYY HH:mm"),
      endDate: moment(to).format("DD/MM/YYYY HH:mm"),
    })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const getAvailability = (month) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/mentor/availability?month=${month}`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const rescheduleCoachingSession = ({ mentoringSessionId, mentorId, date }) => {
  // mentoringSessionId, mentorId, date (DD-MM-YYYY HH:mm), status
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/mentee/scheduleCoachingSession`, { coachingSessionId: mentoringSessionId, mentorId, date, status: "REQUESTED" })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const rescheduleMentoringSession = ({ mentoringSessionId, mentorId, date }) => {
  // mentoringSessionId, mentorId, date (DD-MM-YYYY HH:mm), status
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/mentee/scheduleMentoringSession`, { mentoringSessionId, mentorId, date, status: "REQUESTED" })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const confirmMentoringSession = ({ mentoringSessionId, mentorId, date }) => {
  // mentoringSessionId, mentorId, date (DD-MM-YYYY HH:mm), status
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/mentee/scheduleMentoringSession`, { mentoringSessionId, mentorId, status: "APPROVED_MENTEE" })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const confirmCoachingSession = ({ mentoringSessionId, mentorId, date }) => {
  // mentoringSessionId, mentorId, date (DD-MM-YYYY HH:mm), status
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/mentee/scheduleCoachingSession`, { coachingSessionId: mentoringSessionId, mentorId, status: "APPROVED_MENTEE" })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const sessionAndMentors = () => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/mentor/sessionsAndMentees`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const updateMentorProfile = (data) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentor/updateProfile", data)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const deleteMentorAvailability = (availabilityId) => {
  return new Promise((resolve, reject) => {
    AxiosBase.delete(`/mentor/availability?availabilityId=${availabilityId}`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const getMenteeQAForMentor = (menteeId, skip, limit) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/mentor/menteeQA?menteeId=${menteeId}&limit=${limit}&skip=${skip}`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const getMenteeAssignment = (menteeId, skip, limit) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/mentor/menteeAssignments?menteeId=${menteeId}&limit=${limit}&skip=${skip}`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const addMenteeAssignment = ({ title, menteeId, description, file }) => {
  const formData = new FormData();
  formData.append('title', title)
  formData.append('description', description)
  formData.append('menteeId', menteeId)
  formData.append('document', file)
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentor/addMenteeAssignment", formData)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const getMenteePackageInfo = (menteeId) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/mentor/getMenteePackageInfo?menteeId=${menteeId}`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const getMenteeNotes = (menteeId) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/mentor/menteeNotes?menteeId=${menteeId}`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const MentorAnswerQA = ({ questionId, answer }) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/mentor/answerQA`, { questionId, answer })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const addMenteeNotes = ({ menteeId, title, description}) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/mentor/addMenteeNote`, { menteeId, title, description })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const startSession = ({ sessionId, sessionType}) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/mentor/startSession`, { sessionId, sessionType })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};


// POST /mentee/rateSession
// body: sessionId, sessionType, rating

export const rateSessionMentor = ({ sessionId, sessionType, rating }) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/mentor/rateSession`, { sessionId, sessionType, rating })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
}


// POST /mentor/endSession
// body: sessionId, sessionType


export const endSessionMentor = ({ sessionId, sessionType }) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/mentor/endSession`, { sessionId, sessionType })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
}

