import moment from "moment";
import { useEffect, useState } from "react";
import { startSession } from "../../../NetworkRequest/requests/mentor";
import { useHistory } from 'react-router-dom'
import { toast } from "react-toastify";
import ExperienceSubmit from "../Modals/experienceSubmit";
const SessionCardMentor = (props) => {
  const { index, status, date, _id, type, mentee, mentorRating } = props;
  const history1 = useHistory();
  const [open, setOpen] = useState(false)
  const sessionType = type === 'session' ? 'MENTORING' : 'COACHING'

  const handleSessionJoin = async () => {
    try {

      const res = await startSession({ sessionId: _id, sessionType });
      if (res.customMessage || res.status === 400) {
        toast.success(res.customMessage || 'Error')
      } else {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
          .then(r => {
            history1.push(`/session/${mentee}`, {...res, sessionType})
          })
          .catch(e => {
            console.log(e)
            toast.success('Audio Video Not Found');
          })

      }
    } catch (e) {
      console.log(e)
    }

  }


  const handleSubmitRating = () => {
    setOpen(true);
  }


  useEffect(() => {
    console.log('props', props)
  }, [])

  return (
    <div
      className="blog-content-wrap"
      style={{ cursor: "pointer" }}
    // onClick={(h) => {
    //   props.history.push(`/courses-details/${course._id}`);
    // }}
    >
      {open && <ExperienceSubmit open={true} reload={e => window.location.reload()} sessionId={_id} sessionType={sessionType} onClose={e => setOpen(false)} />}

      <figure className="image-wrap">
        <img className="img-fluid" src="/assets/images/mentoring.png" style={{ objectFit: "cover", maxHeight: 200 }} alt="mentor" />
      </figure>
      <div className="blog-text-content">
        <p
          className="sec-title text-black-color"
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {type === 'session' ? 'Mentoring' : 'Coaching'} Session {index}
        </p>

        {(status === "APPROVED_MENTEE" || status === "ONGOING") && (
          <>
            <p className="f-size-12 text-black-color mb-2">
              <b>{moment.utc(date).format("DD MMMM YYYY ")}</b> &#9679; <b>{moment.utc(date).format("h:mm")}</b>
            </p>
            <p
              className="sec-content mt-1 mb-2"
              style={{
                color: "#525252",
                fontSize: 12,
              }}
            >
              {status === "APPROVED_MENTEE" && "todo"}
              {status === "ONGOING" && "Your session is ongoing"}
            </p>
          </>
        )}

        {(status === "APPROVED_MENTEE" || status === "ONGOING") && (
          <div className="meta-info ">
            <button className="btn btn-gradient-primary w-100" onClick={handleSessionJoin}>{status === "ONGOING" ? 'Join' : 'Start Session'} </button>
          </div>
        )}

        {status === "COMPLETED" && (
          <>
            <p
              className="sec-content mt-1 mb-2"
              style={{
                color: "#525252",
                fontSize: 12,
                height: 42.2
              }}
            >
              Session Completed Successfully
            </p>
            <div className="meta-info ">
              {mentorRating === 0 ?
                <button className="btn btn-gradient-primary w-100" onClick={handleSubmitRating}>Submit Rating</button> :
                <button className='btn btn-outline-info w-100 ' style={{ paddingBlock: 7 }} >
                  <div className="d-flex justify-content-center align-items-center">
                    <span className="mr-3">You Rated</span>
                    {[...new Array(5)].map((star, i) => (
                      mentorRating > i ? (<img key={`img${i}`} src="./assets/images/star-active.jpg" alt="star" />) : (<img key={`img${i}`} src="./assets/images/star-inactive.jpg" alt="star" />)
                    ))}
                  </div>
                </button>

              }
            </div>
          </>

        )}
      </div>
    </div>
  );
};

export default SessionCardMentor;
