import AxiosBase from "../Axiosbase"

export const getCourseDetail = ({courseId, userId, userType}) => {
    console.log('Api called', userId , userType);
    return new Promise((resolve, reject) => {
        if(userId) {
            AxiosBase.get(`/admin/getCourse?courseId=${courseId}&user=${userId}&userType=${userType.toUpperCase()}`)
            .then(res => resolve(res.data))
            .catch(e => reject(e))
        } else {
            AxiosBase.get(`/admin/getCourse?courseId=${courseId}`)
            .then(res => resolve(res.data))
            .catch(e => reject(e))
        }
    })
}