import React from "react";
import { Modal } from "react-responsive-modal";
import Loader from "react-loader-spinner";

const Loading = ({ open }) => {
  return (
    <Modal
      open={open}
      onClose={(e) => {}}
      styles={{ modalContainer: { background: "transparent", boxShadow: "none", padding: 12 } }}
      showCloseIcon={false}
      center
    >
      {" "}
      <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        // timeout={3000} //3 secs
      />
    </Modal>
  );
};

export default Loading;
