

import React, { Component } from "react";
import { toast } from "react-toastify";
import { sendEBookToMail } from "../../NetworkRequest/requests/freeUser";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

class EBook extends Component {
  state = {
    name: "",
    email: "",
    phoneNumber: null,
    status: false,
    modalState: false,
  };

  userData = JSON.parse(localStorage.getItem('userData')) || {};


  componentDidMount() {
    if (this.userData) {
      this.setState({ ...this.userData })
    }
  }


  onClick = (e) => {
    e.preventDefault();
    let { email, name, phoneNumber } = this.state;
    sendEBookToMail(email, name, `${phoneNumber}`).then((res) => {
      console.log({ res });
      if (res.statusCode === 200) {
        if(JSON.parse(localStorage.getItem('userData'))) {
          this.setState({
            modalState: true,
          });
        } else {
          this.setState({
            modalState: true,
            name: "",
            email: "",
            phoneNumber: null
          });
        }
        
      } else {
        toast.info(res.customMessage);
      }
    });
  };

  openModal = () => {
    this.setState({ modalState: true });
  };
  closeModal = () => {
    this.setState({ modalState: false });
  };

  handleNameChange = (e) => {
    e.preventDefault();
    let name = e.target.value;
    this.setState({ name });
    console.log(name);
  };

  handleEmailChange = (e) => {
    e.preventDefault();
    let email = e.target.value;
    this.setState({ email });
    console.log(email);
  };

  handleNumberChange = (e) => {
    e.preventDefault();
    let phoneNumber = e.target.value;
    this.setState({ phoneNumber });
    console.log(phoneNumber);
  };
  state = {};
  render() {
    const bg = {
      modal: { borderRadius: 16, boxShadow: 'none' },
      overlay: {},
    };
    return (
      <div>
        <div className="download-e-book-wrap sec-sm-pad pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 align-self-center">
                <div className="e-book-image-wrap">
                  <figure>
                    <img
                      width={540}
                      height={220}
                      // E:\dev-story\production_shevolve\public\assets\images\fathers day_banner image.jpg
                      src="assets/images/fathers day_banner image.jpg"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 align-self-center">
                <div className="e-book-sec-content">
                  <div className="sec-title">
                    <h4 className="title">Download the E-book Now</h4>
                    <p className="sec-content">
                    Fill in the below details to get our gift for you this father’s day – “WORK FROM HOME – THE GAME CHANGER” (eBook)
                      {/* Fill in the below details to get our gift for you this mother’s day – A MOTHER’S GUIDE TO A HAPPY CAREER (eBook) */}
                    </p>
                    <p className="text-danger" style={{fontSize:14}}>
                      Note: If you cannot find the eBook in your inbox, please
                      check your spam folder.
                    </p>
                  </div>
                  <form>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            placeholder="Name"
                            type="text"
                            value={this.state.name}
                            onChange={this.handleNameChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            placeholder="Email"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <input
                            className="form-control"
                            placeholder="Phone Number(Optional)"
                            type="text"
                            onChange={this.handleNumberChange}
                            value={this.state.phoneNumber}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="btn-grp">
                          <button
                            className="btn btn-primary btn-block"
                            // onClick={this.onClick}
                            // data-toggle="modal"
                            onClick={this.onClick}
                          >
                            Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.modalState}
          onClose={this.closeModal}
          center
          showCloseIcon={false}
          styles={bg}
        >
          <div
            style={{ display: "flex" }}
            // className={"modal fade"}
            id="news-modal"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl form-size">
              <div className="modal-content form-content email-modal-content">
                <div
                  //style={{borderRadius : 5}}
                  className="news-wrap"
                >
                  <div className="row no-gutters">
                    <div className="col-md-12 form-padlr">
                      <div className="form-block">
                        {/* <div className="form-close-btn" data-dismiss="modal">
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ modalState: false });
                          }}
                          className="close"
                          data-dismiss="modal"
                        >
                          ×
                        </button>
                      </div> */}

                        <div className="row no-gutters">
                          <div className="col-md-12">
                            <div className="news-text-content">
                              <div className="row">
                                <div className="col-12 col-md-12 col-lg-12">
                                  <div className="news-img">
                                    <img src="assets/images/undraw-news-img.png" />
                                  </div>
                                  <div className="news-sec-title">
                                    <h5>
                                      
                                    Thank you for choosing Shevolve to further your career. {" "}
                                    </h5>
                                    <p>
                                    Our eBook the current working trend – “WORK FROM HOME – THE GAME CHANGER” is sent to your email address.
                                    {/* Our eBook celebrating Motherhood – “A Mother’s Guide to a Happy Career” is sent to your email address. */}
                                    </p>
                                    <p className="text-danger">
                                      Note: If you cannot find the eBook in your
                                      inbox, please check your spam folder.
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="btn-wrap">
                                    <button
                                      type="submit"
                                      className="btn btn-primary w-100"
                                      // data-dismiss="modal"
                                      onClick={this.closeModal}
                                    >
                                      Okay
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EBook;
