import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  state = {};
  onClick = (e) => {
    e.preventDefault();
    console.log(this.props);
    localStorage.clear();
    this.props.props.history.push("/");
  };

  render() {
    console.log(this.props.props.location.pathname);
    let authorization = localStorage.getItem("accessToken");
    let user = /* JSON.parse( */ localStorage.getItem("userData"); /* ); */
    let info2 = JSON.parse(user);
    console.log("bcxikjsb", info2);
    let payment = JSON.parse(localStorage.getItem("paymentDone"));
    console.log(payment);
    return (
      <header id="header-sec" className="header-v1 header-transparent">
        <div className="header-wrap">
          <div>
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-dark theme-navbar">
                <Link className="navbar-brand" /* to="/" */>
                  <img
                    src="/assets/images/Shevolve Logo1.png"
                    width={137}
                    height={48}
                    alt="header-logo"
                  />
                </Link>
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#main-header"
                  aria-controls="main-header"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div className="navbar-collapse collapse" id="main-header">
                  <ul className="navbar-nav ml-auto">
                    <li
                      className={
                        this.props.from === "home"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to={payment ? "/home" : "/"}>
                        Home
                      </Link>
                    </li>
                    {/* <li
                      className={
                        this.props.from === "blogTab"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/blogTab">
                        Blog
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        // to="/"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="alarm-icon">
                          <i className="far fa-bell" />
                        </div>
                      </Link>
                    </li>
                  </ul>
                  {authorization ? (
                    <div className="dropdown">
                      <button
                        className="btn dropdown-toggle p-0"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="head-name-text green-bg-light-color">
                          <div className="flex-content">
                            <h6 className="sec-title mb-0">
                              {info2 ? info2.name : ""}
                            </h6>
                            <img
                              className="ml-4 brdr-100"
                              src={
                                info2 !== null &&
                                info2.profilePic !== undefined &&
                                info2.profilePic
                                  ? info2.profilePic
                                  : "/assets/images/thumg-img.png"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Link
                          className="dropdown-item"
                          to="/"
                          onClick={this.onClick}
                        >
                          Logout
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <Link classname="nav-link" to="/login">
                      <div className="head-name-text green-bg-light-color">
                        <div className="flex-content">
                          <h6 className="sec-title mb-0 nav-link">Login</h6>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
