import AxiosBase from "../Axiosbase";

export const getAllblogs = ( ) =>
  new Promise((resolve, reject) => {
     AxiosBase.get("/mentee/getAllblogs")
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });


  export const getOneBlog = (blogId ) =>
  new Promise((resolve, reject) => {
    const data = {blogId };

    AxiosBase.post("/mentee/getOneBlog", data)
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });

  export const getBlogsByCategory = (category) =>
    new Promise((resolve, reject) => {
      AxiosBase.get("mentee/getBlogsByCategory?category=" + category)
        .then((res) => {
          console.log("RESPONSE", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("error", err);
          reject(err);
        });
    });


  // getBlogsByCategory;