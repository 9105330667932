import { useState, useEffect } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Divider from "@material-ui/core/Divider";
import { USER_TYPE } from "../../Constants";
import { useParams } from "react-router-dom";
import moment from "moment";
import { addMenteeAssignment, getMenteeAssignment } from "../../../NetworkRequest/requests/mentor";
import { getAssignments, submitMenteeAssignment } from "../../../NetworkRequest/requests/mentee";
import { toast } from "react-toastify";

const Assignments = () => {
  const userType = localStorage.getItem(USER_TYPE);
  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [assignments, setAssignments] = useState([]);
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [index, setindex] = useState(-1);

  useEffect(() => {
    _getAssignment();
  }, []);

  const extractFilename = (url) => {
    const urlParts = url.split("/");
    return urlParts.pop();
  };

  const _getAssignment = async () => {
    const res = userType === "mentor" ? await getMenteeAssignment(id, 0, 10) : (await getAssignments({ limit: 10, skip: 0 })).notes;
    if (res) {
      setAssignments(res);
    }
  };

  const handlePost = async () => {
    try {
      await addMenteeAssignment({ title, menteeId: id, description, file });
      _getAssignment();
      setTitle("");
      setDescription("");
      setFile("");
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmission = () => {
    if (description === "") {
      toast.error("Description should not be empty");
      return;
    }
    if (file === "") {
      toast.error("Attachment file not available");
      return;
    }
    submitMenteeAssignment({ assignmentId: assignments[index]._id, description, document: file }).then((res) => {
      setDescription("");
      setFile("");
      setindex(-1);
    });
  };

  return (
    <div>
      {" "}
      <div style={{ width: "75%" }}>
        <h3 className="my-5">Assignment</h3>
        {userType === "mentor" && (
          <>
            <div class="form-group">
              <label for="title" className="f-size-12">
                Task Title
              </label>
              <input
                type="text"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                class="form-control"
                id="title"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="form-group">
              <label htmlFor="title" className="f-size-12">
                Describe your task
              </label>
              <div style={{ border: "1px solid #c4c4c4", borderRadius: "0.625rem", padding: 10 }}>
                <TextareaAutosize
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="notes-textArea"
                />
                <Divider />
                <div className="d-flex justify-content-between split mt-3 align-items-center">
                  <div>{file.name}</div>
                  <div className="d-flex align-items-center">
                    <label className="mb-0 mr-3" style={{ cursor: "pointer" }} htmlFor="doc-file">
                      {" "}
                      <span>Add attachment</span> <i class="fas fa-paperclip text-dark"></i>
                    </label>
                    <input
                      id="doc-file"
                      onChange={(e) => {
                        if (e.target.files[0]) {
                          setFile(e.target.files[0]);
                        }
                      }}
                      type="file"
                      style={{ display: "none" }}
                    />

                    <button className="btn btn-gradient-primary" onClick={handlePost}>
                      Post
                    </button>
                  </div>
                </div>
              </div>
              <Divider style={{ marginBlock: 30 }} />
            </div>
          </>
        )}

        {assignments.map((assignment, i) => (
          <div key={assignment._id} className="assignment-container my-4">
            <h5>Task : {assignment.title}</h5>
            <div>
              <p className="f-size-12">Posted on : {moment(assignment.createdAt).format("DD MMM YYYY")}</p>
              <p style={{ fontSize: 12, color: "#000", marginBottom: 0 }}>{assignment.description}</p>
              <div className="d-flex justify-content-between align-items-center">
                <a
                  className="rounded"
                  style={{
                    display: "inline-block",
                    margin: 10,
                    backgroundColor: "rgba(0, 122, 135, 0.15)",
                  }}
                  download
                  href={assignment.document}
                >
                  <div className="text-center p-2 d-flex align-items-center">
                    <div
                      style={{
                        backgroundColor: "rgba(0, 122, 135, 0.2",
                        padding: "7px 12px",
                        borderRadius: 5,
                        display: "inline-flex",
                      }}
                    >
                      <img src="/assets/images/attachment.svg" style={{ height: 15, width: 15 }} alt="" />
                    </div>
                    <p
                      style={{
                        fontSize: 12,
                        marginBottom: 0,
                        marginLeft: 7,
                        color: "rgba(62, 62, 62, 1)",
                        overflow: "hidden",
                      }}
                    >
                      {extractFilename(assignment.document)}
                    </p>
                  </div>
                </a>
                <div>
                  {(userType === 'mentee' && index !== i  && assignment.submission.description === null) && (
                    <button className="btn btn-gradient-primary" onClick={(e) => setindex(i)}>
                      Submit Assignment
                    </button>
                  )}
                </div>
              </div>
              {index === i && (
                <div className="form-group">
                  <label htmlFor="title" className="f-size-12">
                    Describe your task
                  </label>
                  <div style={{ border: "1px solid #c4c4c4", backgroundColor: "#FFF", borderRadius: "0.625rem", padding: 10 }}>
                    <TextareaAutosize
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="notes-textArea"
                    />
                    <Divider />
                    <div className="d-flex justify-content-between split mt-3 align-items-center">
                      <div>{file.name}</div>
                      <div className="d-flex align-items-center">
                        <label className="mb-0 mr-3" style={{ cursor: "pointer" }} htmlFor="doc-file">
                          {" "}
                          <span>Add attachment</span> <i class="fas fa-paperclip text-dark"></i>
                        </label>
                        <input
                          id="doc-file"
                          onChange={(e) => {
                            if (e.target.files[0]) {
                              setFile(e.target.files[0]);
                            }
                          }}
                          type="file"
                          style={{ display: "none" }}
                        />

                        <button className="btn btn-gradient-primary" onClick={handleSubmission}>
                          Post
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {assignment.submission.description && (
                <>
                  <p className="f-size-12">Posted on : {moment(assignment.submission.submittedAt).format("DD MMM YYYY")}</p>
                  <p style={{ fontSize: 12, color: "#000", marginBottom: 0 }}>
                    <span style={{ color: "#F0BC5E" }}>You : </span>
                    {assignment.submission.description}
                  </p>
                  <div>
                    <a
                      className="rounded"
                      style={{
                        display: "inline-block",
                        margin: 10,
                        backgroundColor: "rgba(0, 122, 135, 0.15)",
                      }}
                      href={assignment.submission.document}
                      download
                    >
                      <div className="text-center p-2 d-flex align-items-center">
                        <div
                          style={{
                            backgroundColor: "rgba(0, 122, 135, 0.2",
                            padding: "7px 12px",
                            borderRadius: 5,
                            display: "inline-flex",
                          }}
                        >
                          <img src="/assets/images/attachment.svg" style={{ height: 15, width: 15 }} alt="" />
                        </div>
                        <p
                          style={{
                            fontSize: 12,
                            marginBottom: 0,
                            marginLeft: 7,
                            color: "rgba(62, 62, 62, 1)",
                            overflow: "hidden",
                          }}
                        >
                          {extractFilename(assignment.submission.document)}
                        </p>
                      </div>
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Assignments;
