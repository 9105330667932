import AxiosBase from "../Axiosbase";

export const sendEBookToMail = (email, name, phoneNumber) =>
  new Promise((resolve, reject) => {
    const data = { email, name, phoneNumber };

    AxiosBase.post("/mentee/sendEBookToMail", data)
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });

export const subscription = (email) =>
  new Promise((resolve, reject) => {
    const data = { email };

    AxiosBase.post("/mentee/subscription", data)
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });
