import { getProfile, getProfileMentor } from "../NetworkRequest/requests/login";
import { getMenteePackageInfo } from "../NetworkRequest/requests/package";
import { USER_TYPE } from "../website/Constants";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_LOADING = 'LOADING';
export const LOGIN_ERROR = 'ERROR';

export const UPDATE_PACKAGE_INFO = "UPDATE_PACKAGE_INFO";

export function updateProfileAction() {
  const userType = localStorage.getItem(USER_TYPE);
  return (dispatch) => {
    if (userType === "mentor") {
      getProfileMentor().then((res) => {
        if (res) {
          console.log('getProfile is called')
          localStorage.setItem('userData', JSON.stringify(res))
          dispatch({
            type: LOGIN,
            payload: res,
            userType,
          });
        }
      });
    } else {
      getProfile().then((res) => {
        if (res) {
          console.log('get profile mentee is called', res)
          localStorage.setItem('userData', JSON.stringify(res))
          dispatch({
            type: LOGIN,
            payload: res,
            userType,
          });
          if (res.packageInfo) {
            console.log('dispatch profile is called');
            dispatch(updatePackageInfo());
          }
        }
      });
    }
  };
}

export function updatePackageInfo() {
  return (dispatch) => {
    getMenteePackageInfo().then((res) => {
      if (res[0]) {
        dispatch({
          type: UPDATE_PACKAGE_INFO,
          packageInfo: res[0],
        });
      }
    });
  };
}
