import React, { Component } from "react";
import { Modal } from "react-responsive-modal";
import { getAllEvents } from "../../adminPanel/networkRequests/requests/event";
import moment from "moment";
import { USER_TYPE } from "../Constants";
import { connect } from "react-redux";
import Layout from '../layout/default';
import {Helmet} from "react-helmet";
import {AnimWrap} from "./animation-gsap/Animation"
import { LazyLoadImage } from 'react-lazy-load-image-component';


class Events extends Component {
  constructor(props) {
    super(props);
    this.secondSection = React.createRef();
  }
  userType = localStorage.getItem(USER_TYPE);
  userData = this.props.userData;
  //  const { userData } = useSelector((state) => state.loginSignPopUp);
  // userData = JSON.parse(localStorage.getItem('userData')) || {}
  state = {
    modalState: false,
    events: [],
    ip: []
  };
  componentDidMount() {
    this._events();
    window.scrollTo(0, 0)

    setTimeout(
      () => this.setState({ip: 'res.data'}), 
      100
    );
  }

  // const [ip, setIp] = useState([]);

   
  _events = () => {
    console.log("userData11", this.userData);

    getAllEvents(this.userData._id ? `?user=${this.userData._id}&userType=${this.userType.toUpperCase()}` : "")
      .then((res) => {
        console.log(res);
        if (res.statusCode === 200) {
          this.setState({
            events: res.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  openModal = () => {
    this.setState({ modalState: true });
  };
  closeModal = () => {
    this.setState({ modalState: false });
  };

  // componentWillUnmount() {
  //   document.body.classList.add("sh-section--green")
  // }


  scrollHandler = () => {
    this.secondSection.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  render() {
    const bg = {
      modal: { borderRadius: 16 },
      overlay: {},
    };
    let { events } = this.state;


    return (
      <Layout additionalClass="">
                    <Helmet>
                    <title>Shevolve Events: Exclusive Conversations & Interactive Learning</title>
                    <meta name="description" content="Engage in exclusive conversations and interactive learning experiences with industry leaders at Shevolve's Events. Elevate your professional journey with insights and connections that drive success." />
                    <meta name="keywords" content="Shevolve events, exclusive conversations, interactive learning, industry leaders, professional development, networking events" />


                    <meta property="og:title" content="Shevolve Events: Exclusive Conversations & Interactive Learning" />
<meta property="og:description" content="Engage in exclusive conversations and interactive learning experiences with industry leaders at Shevolve's Events. Elevate your professional journey with insights and connections that drive success." />
<meta property="og:image" content="https://shevolve.in/images/home/slide/slide1.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Shevolve Events: Exclusive Conversations & Interactive Learning" />
<meta name="twitter:description" content="Engage in exclusive conversations and interactive learning experiences with industry leaders at Shevolve's Events. Elevate your professional journey with insights and connections that drive success." />
<meta name="twitter:image" content="https://shevolve.in/images/home/slide/slide1.png" />


                    
                </Helmet>

            <AnimWrap />
          {/*=========================================
            ===================== Banner ================
            ============================================ */}
          <section className="sh-section sh-section__banner pb-0">
            <div className="sh-section__inwrap">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="sh-banner__content">
                    <h1 className="sh-banner__title sh-anim__stagger-fade-top"> Elevate Your Career: Join Shevolve's 
 <br /> Exclusive Mentorship Events for Women </h1>
                      <p className="sh-para sh-banner__para sh-banner__para--grey sh-anim__stagger-fade-top">Be a part of interactive conversations that help you Connect with and Learn from leaders in the industry</p>
                      <a className="sh-button mt-3 sh-anim__stagger-fade-top" onClick={this.scrollHandler}>View More</a>
                      {/* <div className="sh-banner__title sh-banner__title--grey">that turn ideas into action!</div> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center sh-anim__stagger-fade-right">
                      <img className="img-fluid" src="./images/events/banner-image.svg" alt="Join Shevolve's Exclusive Mentorship Events for Women | Shevolve" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="position-absolute sh-section__curved-bottom w-100">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1994.026 105.786">
                <g id="download5" transform="translate(1994.026 105.786) rotate(180)">
                  <path id="Path_11814" data-name="Path 11814" d="M997.013,105.786C446.382,105.786,0,0,0,0H1994.026S1547.645,105.786,997.013,105.786Z"/>
                </g>
              </svg>
            </div>
          </section>

          {/*=========================================
            ===================== Featured Events ================
            ============================================ */}
          <section ref={this.secondSection} className="sh-section sh-section__events sh-section--green">
            <div className="sh-section__inwrap">
              <div className="container">
                <div className="text-center sh-section__title-wrap">
                  <div className="sh-title sh-title__underline sh-anim__stagger-fade-top">Featured Events</div>
                  {/* <p className="sh-para sh-anim__stagger-fade-top">Shevolve is proud to collaborate with mentors across the globe who play a vital role in not only.</p> */}
                </div>
                <div className="row">
                {events.length > 0
                  ? events.map((e) => (
                      <div className="col-md-6 col-lg-6 sh-event__card sh-anim__stagger-fade-top mb-4">
                        <div style={{ cursor: !e.isExpired && "pointer" }} className="sh-card sh-card__sharp mb-0 w-100 h-100"
                          onClick={(h) => {
                            if (!e.isExpired) {
                              this.props.history.push(`/eventDetail/${e._id}`);
                            }
                          }}
                        >
                            <div className="sh-card__image d-flex align-items-end justify-content-end position-relative overflow-hidden">
                              <LazyLoadImage
                                    class="img-fluid sh-card__event-image sh-card__event-image--ghost"
                                    alt={e.name}
                                    src={e.image}
                                />
                                <LazyLoadImage
                                    class="img-fluid sh-card__event-image"
                                    alt={e.name + " | Shevolve Event"}
                                    src={e.image}
                                />
                                <div className="sh-card__catagory sh-card__catagory-event position-relative">
                                  <a href="javascript:void(0);">
                                      {e.eventType === "ONLINE" && "Online"}
                                      {e.eventType === "OFFLINE" && "Offline"} Event
                                    </a> 
                                </div>
                            </div>
                            <div className="sh-card__content px-3 pb-3">
                                <div className="sh-card__date-time sh-card__date-time--filled d-flex flex-wrap align-items-center justify-content-between mb-3">
                                    <div className="sh-card__date">
                                      <div className="sh-card__date-info">
                                        <a href="javascript:void(0);">
                                          {moment(e.startTime).format("MMM Do YYYY")}
                                        </a>
                                      </div>
                                    </div>
                                    <div className="sh-card__time text-md-right">
                                      <div className={`sh-card__status`}>
                                        {e.isExpired ? "Expired" : e.isSoldOut ? "Sold Out" : e.isPurchased ? "Attending" : "Reserve"}
                                      </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="sh-card__name mb-2"> {e.name} </div>
                                    <p className="sh-card__para sh-card__para-event sh-card__para--grey">{e.lable}</p>
                                </div>
                                {
                                  !e.isExpired && (
                                    <div>
                                        <div className="sh-button sh-button__no-radius text-center sh-button__radius">
                                          <span className="">View Event</span>
                                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                          </svg> */}
                                        </div>
                                    </div>
                                  )
                                }
                            </div>
                        </div>
                      </div>
                      ))
                  : null}
                </div>
              </div>
            </div>
          </section>

          {/* <div
            className="banner-wrap blog-banner-wrap youtube-blog-banner"
            style={{ backgroundImage: 'url("assets/images/event-banner.png")' }}
          >
            <div className="banner-overlay">
              <div className="container">
                <div className="banner-content" />
              </div>
            </div>
          </div> */}
         
          {/* <Footer props={this.props} /> */}
          <Modal open={this.state.modalState} onClose={this.closeModal} center styles={bg}>
            <div
              style={{ display: "flex" }}
              // className={"modal fade"}
              id="news-modal"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-xl form-size">
                <div className="modal-content form-content email-modal-content">
                  <div
                    //style={{borderRadius : 5}}
                    className="news-wrap"
                  >
                    <div className="row no-gutters">
                      <div className="col-md-12 form-padlr">
                        <div className="form-block">
                          {/* <div className="form-close-btn" data-dismiss="modal">
                          <button
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ modalState: false });
                            }}
                            className="close"
                            data-dismiss="modal"
                          >
                            ×
                          </button>
                        </div> */}

                          <div className="row no-gutters">
                            <div className="col-md-12">
                              <div className="news-text-content">
                                <div className="row">
                                  <div className="col-12 col-md-12 col-lg-12">
                                    <div className="news-img">
                                      <img src="assets/images/undraw-news-img.png" />
                                    </div>
                                    <div className="news-sec-title">
                                      <h5>Thank you for choosing Shevolve to further your career. </h5>
                                      <p>Our welcome gift, the ebook “32 Ways to Uplift Your Career” is sent to your email address.</p>
                                      <p className="text-danger">
                                        Note: If you cannot find the eBook in your inbox, please check your spam folder.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="btn-wrap">
                                      <button
                                        type="submit"
                                        className="btn btn-primary w-100"
                                        // data-dismiss="modal"
                                        onClick={this.closeModal}
                                      >
                                        Okay
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {/* footer-wrap close*/}
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return { userData: state.loginSignPopUp.userData };
}

export default connect(mapStateToProps)(Events);