import React, { Component } from "react";
// import Header from "./static/header";
import { getAllblogs, getBlogsByCategory } from "../../NetworkRequest/requests/blogs";
import moment from "moment";
import Layout from "../layout/default";
// import ReactHtmlParser, {
//   processNodes,
//   convertNodeToElement,
//   htmlparser2,
// } from "react-html-parser";

import { toast } from 'react-toastify';
import { getSubsctiption } from "../../adminPanel/networkRequests/requests/subscription";
import {Helmet} from "react-helmet";
// import SubscriptionForm from "../Components/home/subscription-form";
import {AnimWrap, AnimTop} from "./animation-gsap/Animation"
// import EBook from "./ebookDownload";
import EbookForm from "./home/ebook-form";
import { LazyLoadImage } from 'react-lazy-load-image-component';


function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

class BlogTab extends Component {
  constructor(props) {
    super(props);
    this.secondSection = React.createRef();
  }
  state = {
    blogs: [],
    category:"shevolve_desk",
    email: "",
    ip: []
  };
  componentDidMount() {
    window.scrollTo(0, 0);

    getAllblogs().then((res) => {
      console.log("hello world", res);
      this.setState({ blogs: res });
    });

    setTimeout(
      () => this.setState({ip: 'res.data'}), 
      100
    );
    // getAllblogs(this.state.category).then((res) => {
    //   if (res.statusCode === 200) {
    //     this.setState({ blogs: res });
    //   }
    //   console.log({ res });
    // }); 
  }
  static getDerivedStateFromProps() {
    // window.scrollTo(0, 0);
  }

  blogClick = (b) => {
    console.log("hello");
    // localStorage.setItem("blog", JSON.stringify(b));
    this.props.history.push("/blogDetail/" + b._id);
  };
  _getBlogsByCategory=(c)=>{
    console.log(c);
    this.setState({category:c.category})
   getBlogsByCategory(c.category).then(res=>{
      if (res.statusCode===200) {
        this.setState({blogs:res.data})
      }
      console.log({res});
    }) 
  }

  handleEmailChange =(e) => {
      this.setState({setEmail: e.target.value})
  }

  onSubscription = () => {
      if(validateEmail()) {
          getSubsctiption().then(res => {
              console.log(res);
              toast.success('Your subscription is successful');
          })
      }
  }

  scrollHandler = () => {
    this.secondSection.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
  

  // document.body.classList.add("sh-section--green");

  render() {
    let { blogs , category } = this.state;
    return (
      <Layout>
            <Helmet>
                    <title>SheWrites | Knowledge Base for Empowering Women's Career | Shevolve</title>
                    <meta name="description" content="Dive into Shevolve's insightful content covering topics for student, fresher, mid-level employee, returnee, or woman entrepreneur. Join us as we celebrate women's achievements, and offer expert insights on digital skills for success in 2024" />
                    <meta name="keywords" content="Shevolve, SheWrites, Knowledge Base, National Startup Day, Vision Board, Productivity, Mentoring, Empowerment, Women in Leadership, Female Entrepreneurship, Digital Skills, 2024, Gender, Equality, Innovation, Technology" />

                    <meta property="og:title" content="SheWrites | Knowledge Base for Empowering Women's Career | Shevolve" />
<meta property="og:description" content="Dive into Shevolve's insightful content covering topics for student, fresher, mid-level employee, returnee, or woman entrepreneur. Join us as we celebrate women's achievements, and offer expert insights on digital skills for success in 2024" />
<meta property="og:image" content="https://shevolve.in/images/home/slide/slide1.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="SheWrites | Knowledge Base for Empowering Women's Career | Shevolve" />
<meta name="twitter:description" content="Dive into Shevolve's insightful content covering topics for student, fresher, mid-level employee, returnee, or woman entrepreneur. Join us as we celebrate women's achievements, and offer expert insights on digital skills for success in 2024" />
<meta name="twitter:image" content="https://shevolve.in/images/home/slide/slide1.png" />

                </Helmet>


          <AnimWrap />
          {/*=========================================
            ===================== Banner ================
            ============================================ */}
          <section className="sh-section sh-section__banner pb-0">
            <div className="sh-section__inwrap">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="sh-banner__content">
                      <h1 className="sh-banner__title sh-anim__stagger-fade-top">A journal of interesting reads <br /> for conversations that matter!</h1>
                      <p className="sh-para sh-banner__para sh-banner__para--grey sh-anim__stagger-fade-top">Instilling values to your professional journey <br /> with our creative channel of intriguing reads</p>
                      <a className="sh-button mt-3 sh-anim__stagger-fade-top" onClick={this.scrollHandler}>View More</a>
                      {/* <div className="sh-banner__title sh-banner__title--grey">that turn ideas into action!</div> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center sh-anim__stagger-fade-right">
                      <LazyLoadImage
                          class="img-fluid"
                          alt="Shewrites | Instilling values to your professional journey with our creative channel of intriguing reads | Shevolve"
                          src="./assets/blog/banner-image.svg"
                      />
                      {/* <img className="img-fluid" src="./assets/blog/banner-image.svg" alt="" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="position-absolute sh-section__curved-bottom w-100">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1994.026 105.786">
                <g id="download5" transform="translate(1994.026 105.786) rotate(180)">
                  <path id="Path_11814" data-name="Path 11814" d="M997.013,105.786C446.382,105.786,0,0,0,0H1994.026S1547.645,105.786,997.013,105.786Z"/>
                </g>
              </svg>
            </div>
          </section>

          {/*=========================================
            ===================== Our Blog ================
            ============================================ */}
          <section ref={this.secondSection} id={`blogs`} className="sh-section sh-section--green sh-section__events">
            <div className="sh-section__inwrap">
              <div className="container">
                <div className="text-center sh-section__title-wrap">
                  <div className="sh-title sh-title__underline sh-anim__stagger-fade-top">Our Blog</div>
                  {/* <p className="sh-para sh-anim__stagger-fade-top">Shevolve is proud to collaborate with mentors across the globe who play a vital role in not only.</p> */}
                </div>
                <div className="row">
                {blogs
                  ? blogs.map((b) => (
                    <div className="col-md-6 col-lg-4 sh-blog">
                      <div style={{ border: 0 }} className="sh-card sh-card__blog sh-card__sharp p-0 sh-anim__stagger-fade-top" onClick={() => this.blogClick(b)}>
                          <div className="sh-card__image position-relative">
                            <LazyLoadImage
                                class="img-fluid sh-card__event-image sh-card__blog-image"
                                alt={b.title + " | Shevolve"}
                                src={b.image}
                            />
                          </div>
                          <div className="sh-card__content">
                              <div className="mb-4">
                                  <div className="sh-card__name font-weight-bold mb-3">{b.title}</div>
                                  <p className="sh-card__para sh-card__para--grey">{b.lable ? b.lable : null}</p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="sh-card__date w-50">{moment(b.created_on).format("ll")}</div>
                                <div style={{ cursor: "pointer" }} className="sh-button sh-button__plain-arrow" href="">
                                  <span className="mr-2">Read More</span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                  </svg>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
                    ))
                  : null}
                </div>
              </div>
            </div>
          </section>

          {/*=========================================
            ===================== Newsletter ================
            ============================================ */}
          {/* <section id="newsletters" className="sh-section sh-section__events pb-0">
            <div className="sh-section__inwrap">
              <div className="container">
                <div className="text-center sh-section__title-wrap">
                  <div className="sh-title sh-title__underline">Newsletter</div>
                  <p className="sh-para">Shevolve is proud to collaborate with mentors across the globe who play a vital role in not only.</p>
                </div>
              </div>
              <div className="sh-subscription">
                <div className="container">
                  <div className="row align-items-end">
                    <div className="col-lg-6">
                      <div className="sh-subscription__content">
                        <div className="sh-title sh-subscription__title">Subscribe for our Newsletter</div>
                        <p className="sh-para sh-subscription__para mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do </p>
                        <SubscriptionForm additionalClass="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="sh-subscription__image"><img className="img-fluid" src="./assets/blog/news-letter-image.svg" alt="" /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/*=========================================
            ===================== Ebook ================
            ============================================ */}
          <section id={`ebooks`} className="sh-section sh-section__events pb-0">
            <div className="sh-section__inwrap">
              <div className="container">
                <div className="text-center sh-section__title-wrap">
                  <div className="sh-title sh-title__underline sh-anim__stagger-fade-top">Ebook</div>
                  <p className="sh-para sh-anim__stagger-fade-top">Subscribe to our e-Books &amp; gain insights on how to chart your career path</p>
                </div>
              </div>
              <div className="container">
                <div className="sh-subscription sh-subscription__ebook sh-anim__stagger-fade-top" style={{ backgroundImage: "url(./assets/blog/scbscription-background.png)"}}>
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="sh-subscription__content sh-subscription__content-ebook">
                        <div className="sh-title sh-subscription__title mb-0">Subscribe for our e-Books</div>
                        {/* <p className="sh-para sh-subscription__para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do </p> */}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      {/* <SubscriptionForm additionalClass="" /> */}
                      <EbookForm additionalClass="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      </Layout>
    );
  }
}

export default BlogTab;
