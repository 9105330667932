import React from "react";
import { /*  Redirect, */ Route, Switch } from "react-router-dom";
import AboutUs from "./website/Components/about/aboutUs";
import Offering from "./website/Components/offering/offering.jsx"
import BeAMentor from "./website/Components/be-a-mentor/be-a-mentor.jsx"
// import BlogDetail from "./website/Components/blogDetail.jsx";
import BlogDetails1 from "./website/Components/blogDetail1.jsx";
// import Entry from "./Components/entry.js";
// import Home from "./Components/home.jsx";
import Login from "./website/Components/login.jsx";
// import OnBoardPackages from "./website/Components/onBoardPackages.jsx";
// import ProgramInfo from "./Components/programInfo.jsx";
// import RazorPay from "./Components/razorpay.jsx";
// import SelectedPakage from "./Components/selectedPakage.jsx";
import Signup from "./website/Components/signup.jsx";
import SurveyQuestionnair from "./website/Components/surveyQuestionnaire";
import BlogTab from "./website/Components/blogTab";
import CourseInfo from "./website/Components/courseInfo";
import ExperienceSubmit from "./website/Components/Modals/experienceSubmit";
import Program from "./website/Components/program/program";
// import LiveSession from "./Components/liveSession";
// import Schedule from "./Components/schedule";
// import SessionSubmit from "./Components/Modals/sessionSubmit";
// import Todo from "./Components/todo";
// import Calender from "./Components/calender.js";
// import Assignment from "./Components/assignment.jsx";
import NewHomePage from "./website/Components/newHomePage.jsx";
import TermsCondition from "./website/Components/t-c.jsx";
import PrivacyPolicy from "./website/Components/privacy-policy.jsx";
import AddBlogs from "./website/Components/addBlogs.jsx";
// import Styles from "./styles.js";
import YTVideos from "./website/Components/ytVideos.jsx";
import Events from "./website/Components/events.jsx";
import EventDetail from "./website/Components/eventDetail.jsx";
import EventModal1 from "./website/Components/Modals/eventModal1.jsx";
import NewSignUp from "./website/Components/newSignUp.jsx";
import WhoIsSigning from "./website/Components/whoIsSigning";
import EventModal2 from "./website/Components/Modals/eventModal2";
import EventModal3 from "./website/Components/Modals/eventModal3";
import Example from "./website/Components/ytPlayer";
import Courses from "./website/Components/couses";
import Success from "./website/Components/success";
import ContactUs from "./website/Components/contactUs.js";
import Forget from "./website/Components/forget.jsx";
import Reset from "./website/Components/reset.jsx";
import CoursesDetails from "./website/Components/coursesDetails.jsx";
import Profile from "./website/Components/profile.jsx";
import MyCourseDetails from "./website/Components/myCourseDetails.jsx";
// import PrivateRoute from "./website/privateAuth/PrivateAuth.js";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import Survey from "./website/Components/suggestion/survey.jsx";
import ProfileHome from "./website/Components/home/profileHome.jsx";
import Questions from "./website/Components/suggestion/question.jsx";
import Recommended from "./website/Components/suggestion/recommended.jsx";
import FirstQuestion from "./website/Components/suggestion/firstQuestion.jsx";
import availabilityCalender from "./website/Components/mentor/availability.jsx";
import PackagePayment from "./website/Components/Modals/packagePayment.jsx";
import EditProfile from "./website/Components/mentor/editProfile.jsx";
import MenteeDetails from "./website/Components/mentor/menteeDetail.jsx";
import { USER_TYPE } from "./website/Constants.js";
import VideoBox from "./website/Components/videoCalling/main.js";
import Home from "./website/Components/home/newHome.jsx";
import { NewMentor } from "./website/Components/New Mentor/newMentor";
import Footer from "./website/Components/home/footer";
import { useLocation } from "react-router-dom";
import ListOfSessions from "./website/Components/mentor/listOfSessions";
// import MentorHome from "./website/Components/home/montorHome.jsx";

const Routes = () => {
  // let user = JSON.parse(localStorage.getItem("userData"));
  // let payment = JSON.parse(localStorage.getItem("paymentDone"));
  const location = useLocation();
  const userType = localStorage.getItem(USER_TYPE);

  return (
    <div>
      <Switch>
        {/* <Route exact path="/mentee" component={!payment ? Entry : Home}></Route> */}
        {/* <Route exact path="/home" component={Home}></Route> */}
        {/*  <Route exact path="/signup" component={Signup} />*/}
        <Route exact path="/login" component={Login} />  {/*  Add Title */}
        <Route exact path="/newSignUp" component={NewSignUp} />
        <Route exact path="/whoIsSigning" component={WhoIsSigning} />  {/*  Add Title */}
        <Route exact path="/linkedin" component={LinkedInPopUp} />
        <Route exact path="/recommendation" component={Recommended} />
        <Route exact path="/subscription/individual" component={Program} />  {/*  Add Title */}

        {/* <Route exact path="/admin/login" component={AdminLogin}></Route> */}
        {/* <Route exact path="/admin/dashboard" component={Dashboard}></Route> */}

        {/* <Route exact path="/mentorLogin" component={Login}></Route> */}
        <Route
          exact
          path="/surveyQuestionnaire"
          component={SurveyQuestionnair}
        ></Route>
        {/* <Route exact path="/selectedPackage" component={SelectedPakage}></Route> */}
        {/* <Route exact path="/onBoardPackages" component={OnBoardPackages}></Route> */}
        {/* <Route exact path="/programInfo" component={ProgramInfo}></Route> */}
        <Route exact path="/blogDetail/:blogId" component={BlogDetails1} />
        <Route exact path="/blogDetail" component={BlogDetails1} />
        <Route exact path="/shewrites" component={BlogTab} />  {/*  Add Title */}
        <Route exact path="/courseInfo" component={CourseInfo} />
        {/* <Route exact path="/razorPay" component={RazorPay}></Route> */}
        <Route exact path="/" component={Home} />  {/*  Add Title */}
        <Route exact path="/forget" component={Forget} />  {/*  Add Title */}
        <Route exact path="/reset" component={Reset} />
        <Route exact path="/survey" component={Survey} />
        <Route exact path="/contact-us" component={ContactUs} />  {/*  Add Title */}
        <Route exact path="/about" component={AboutUs} />  {/*  Add Title */}
        <Route exact path="/subscription/corporate" component={Offering} />  {/*  Add Title */}
        <Route exact path="/hermentor" component={BeAMentor} />  {/*  Add Title */}
        <Route exact path="/terms-condition" component={TermsCondition} />  {/*  Add Title */}
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />   {/*  Add Title */}
        <Route exact path="/addBlog" component={AddBlogs} />
        <Route exact path="/youtubeVideos" component={YTVideos} />
        <Route exact path="/events" component={Events} />  {/*  Add Title */}
        <Route exact path="/courses" component={Courses} />
        <Route path="/courses-details/:courseId" component={CoursesDetails} />
        <Route exact path="/success" component={Success} />
        <Route exact path="/eventDetail/:eventId" component={EventDetail} />
        <Route exact path="/eventModal1" component={EventModal1} />
        <Route exact path="/eventModal2" component={EventModal2} />
        <Route exact path="/eventModal3" component={EventModal3} />
        <Route exact path="/home" component={ProfileHome} />
        <Route exact path="/ytPlayer" component={Example} />
        {/* <PrivateRoute> */}
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/profile/edit" component={EditProfile} />
        <Route exact path="/profile/view-sessions" component={ListOfSessions} />
        <Route path="/preview/:courseId" component={MyCourseDetails} />
        {/* </PrivateRoute> */}
        <Route
          exact
          path="/experienceSubmit"
          component={ExperienceSubmit}
        ></Route>
        {/* <Route exact path="/question" component={Questions}></Route> */}
        {/* <Route exact path="/knowMore" component={FirstQuestion}></Route> */}
        {/* <Route path="/mentorHome" component={MentorHome}></Route> */}
        <Route exact path="/availablity" component={availabilityCalender} />
        <Route exact path="/pay" component={PackagePayment} />
        <Route exact path="/menteeDetails/:id" component={MenteeDetails} />
        <Route exact path="/session/:id" component={VideoBox} />
        <Route exact path="/ourmentors" component={NewMentor} />  {/*  Add Title */}
        {/* <Route exact path="/mentorHome" component={MentorHome} /> */}
        {/* <Route exact path="/liveSession" component={LiveSession}></Route>
      <Route exact path="/schedule" component={Schedule}></Route>
      <Route exact path="/sessionSubmit" component={SessionSubmit}></Route>
      <Route exact path="/todo" component={Todo}></Route>
      <Route exact path="/todo" component={Todo}></Route>
      <Route exact path="/calender" component={Calender}></Route>
      <Route exact path="/assignment" component={Assignment}></Route>  */}
        {/* <Redirect exact from="/" to="/mentee"></Redirect> */}
        {/* <Redirect exact from="/mentor" to="/mentorLogin"></Redirect> */}
        {/* <Redirect exact from="/home" to="/"></Redirect> */}
      </Switch>
      {!(location.pathname === "/WhoIsSigning" ||
        location.pathname === "/newSignUp" ||
        location.pathname === "/login" ||
        location.pathname === "/forget" ||
        location.pathname === "/knowMore" ||
        location.pathname === "/question" ||
        location.pathname === "/experienceSubmit" ||
        location.pathname === "/reset" ||
        location.pathname.indexOf("admin") !== -1)
         && (<Footer  />)}

    </div>
  );
};

export default Routes;
