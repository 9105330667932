import { useState, useEffect } from 'react'
import Modal from 'react-responsive-modal';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSubsctiption } from '../../../adminPanel/networkRequests/requests/subscription';

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

const SubscriptionForm = (props) => {
    const [email, setEmail] =useState('');

    const handleEmailChange =(e) => {
        setEmail(e.target.value)
    }

    const onSubscription = () => {
        if(validateEmail(email)) {
            getSubsctiption(email).then(res => {
                console.log(res);
                setEmail("");
                toast.success('You have successfully subscribed to our newsletter');
            })
            .catch((err) => {
                console.log("error", err);
                toast.error('Something went wrong!');
            });
        } else {
            toast.error('Please Enter Valid Email ID');
        }       
    }

    const history = useHistory();
    return (
        <>
            <div className='sh-subscription__form d-md-flex justify-content-between'>
                <input className={`sh-subscription__form-input ${props.additionalClass}`} type="email" name="email" placeholder="Enter your email id " value={email} onChange={handleEmailChange} />
                <div className="sh-subscription__form-btn sh-button hover-color"  onClick={onSubscription}>Subscribe now</div>
            </div>
        </>
        

    )
}

export default SubscriptionForm;