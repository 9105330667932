import AxiosBase from "../Axiosbase";

export const getPurchasedCourses = (userType) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/${userType}/getPurchasedCourses`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const purchaseCourse = (userType, courseId) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/${userType}/purchaseCourse`, { course: courseId })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const updateProgressApi = ({ userType, courseId, contentId, progress }) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/${userType}/contentProgress`, {
      course: courseId,
      contentId,
      progress,
    })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const getPurchasedCoursesDetail = (userType, courseId) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/${userType}/getPurchase?course=${courseId}`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const addPackageCourse = (course, packagePurchaseId) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/addPackageCourse", { course, packagePurchaseId })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};


