import React, { Component } from 'react'


class SocialMedia extends Component {
    state = {  }
    render() { 
        return (
          <div className="social-media-wrap sec-sm-pad">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="social-media-content">
                    <h4 className="title">Follow us on Social Media</h4>
                    <div className="social-media-image">
                      <div className="flex-content">
                        <figure
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/shevolve3/?hl=en"
                            )
                          }
                        >
                          <img width={35} src="assets/images/instagram.png" />
                        </figure>
                        <figure
                          onClick={() =>
                            window.open(
                              "https://www.linkedin.com/company/shevolve/"
                            )
                          }
                        >
                          <img width={35} src="assets/images/linkedin.png" />
                        </figure>
                        <figure
                          onClick={() =>
                            window.open(
                              "https://www.youtube.com/channel/UCZtBvF6_7DatfMzZgEzddSQ/featured"
                            )
                          }
                        >
                          <img width={35} src="assets/images/youtube.png" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}
 
export default SocialMedia;
