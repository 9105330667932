import React, { Component } from "react";
import Styles from "../../styles";
import { toast } from "react-toastify";
import { SignUpMentor } from "../../NetworkRequest/requests/login";
import { Link } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import { connect } from "react-redux";
import { domains, industries, USER_TYPE } from "../Constants";
import { LOGIN } from "../../reducer/action";
import { Helmet } from "react-helmet";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const mentorOptions = [
  "Mentoring",
  "Coaching",
  "Conduct Seminars / workshop",
  "Volunteering",
  "Course Authoring",
  "Course Delivery",
  "Branding",
];

class NewSignUp extends Component {
  state = {
    userType: "",
    name: "",
    email: "",
    phoneNumber: "",
    industry: "",
    experience: "",
    role: "",
    domain: "",
    takeBreak: "",
    noOfYears: "",
    breakNow: "",
    //for montor
    mentoring: "",
    coaching: "",
    conductSeminar: "",
    volunteering: "",
    courseAuthoring: "",
    courseDelievry: "",
    linkedInLink: "",
    branding: "",
    password: "",
    confirmPassword: "",
    selectedOption: [],
    success: false,

    errorName: false,
    errorEmail: false,
    errorIndustry: false,
    errorExperience: false,
    errorDomain: false,
    errorCareerBreak: false,
    errorCareerBreakYears: false,
    errorCareerBreakconformation: false,
    errorPasswordLength: false,
    errorPasswordConformed: false,
    errorLinkedName: false,
    errorPhone: false,

  };

  componentDidMount() {
    let userType = localStorage.getItem("userType");
    this.setState({ userType });
  }

  handleTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDomainChange = (e) => {
    this.setState({ domain: e.target.value });
  };


  validateForm = () => {
    const phone_regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    let validate = true;
    if (this.state.name === "") {
      this.setState({ errorName: true })
      validate = false;
      // toast.error("Please enter your name");
    } else {
      this.setState({ errorName: false })
    }

    if (!validateEmail(this.state.email)) {
      this.setState({ errorEmail: true })
      validate = false;
      // toast.error(" Enter valid email ID");
    } else {
      this.setState({ errorEmail: false })
    }

    if (this.state.phoneNumber.length < 7 || this.state.phoneNumber.length > 15 || !phone_regex.test(this.state.phoneNumber)) {
      this.setState({ errorPhone: true })
      validate = false;
      // toast.error(" Industry should not be blank");
      // return false;
    } else {
      this.setState({ errorPhone: false })
    }

    console.log("erphone", this.state.errorPhone)

    if (this.state.industry === "") {
      this.setState({ errorIndustry: true })
      validate = false;
      // toast.error(" Industry should not be blank");
      // return false;
    } else {
      this.setState({ errorIndustry: false })
    }

    if (this.state.experience === "") {
      this.setState({ errorExperience: true })
      validate = false;
      // toast.error("Experience should not be blank");
      // return false;
    } else {
      this.setState({ errorExperience: false })
    }

    if (this.state.domain === "") {
      this.setState({ errorDomain: true })
      validate = false;
      // toast.error("Domain should not be blank");
      // return false;
    } else {
      this.setState({ errorDomain: false })
    }

    if (this.state.password.length < 7) {
      this.setState({ errorPasswordLength: true })
      validate = false;
      // toast.error("The password entered is not valid (either password didn't match or less than 8 characters)");
      // return false;
    } else {
      this.setState({ errorPasswordLength: false })
    }

    if (!(this.state.password === this.state.confirmPassword)) {
      this.setState({ errorPasswordConformed: true })
      validate = false;
      // toast.error("The password entered is not valid (either password didn't match or less than 8 characters)");
      // return false;
    } else {
      this.setState({ errorPasswordConformed: false })
    }

    if (this.state.userType === "mentor" && this.state.linkedInLink === "") {
      // toast.error(" Please enter your LinkedIn profile link");
      this.setState({ errorLinkedName: true })
      validate = false;
      // return false;
    } else {
      this.setState({ errorLinkedName: false })
    }

    if (this.state.userType === "mentee") {
      if (this.state.takeBreak === "") {
        this.setState({ errorCareerBreak: true })
        validate = false;
        // toast.error("Please choose whether you taken career break?");
        // return false;
      } else {
        if (this.state.takeBreak === "yes") {
          if (this.state.breakNow === "") {
            this.setState({ errorCareerBreakconformation: true })
            validate = false;
            // toast.error("Are you on a career break?");
            // return false;
          } else {
            this.setState({ errorCareerBreakconformation: false })
          }

          if (this.state.noOfYears === "") {
            this.setState({ errorCareerBreakYears: true })
            validate = false;
            // toast.error("Please complete how long was your career break?");
            // return false;
          } else {
            this.setState({ errorCareerBreakYears: false })
          }

        }
      }
    }

    return validate;
  };

  handleFormSubmit = (e) => {
    if (this.validateForm()) {
      SignUpMentor(this.state)
        .then((res) => {
          if (res.data.isVerified) {
            localStorage.setItem(USER_TYPE, this.state.userType);
            localStorage.setItem("userData", JSON.stringify(res.data));
            localStorage.setItem("accessToken", res.accessToken);
            this.props.dispatch({
              type: LOGIN,
              payload: res.data,
              accessToken: res.accessToken,
              userType: "mentee",
            });
            toast.success("Registration successfully added ");
            this.props.history.push("/");
          } else {
            this.setState({ success: true });
          }
        })
        .catch((e) => toast.error(e));
    }
  };

  handleAddOption = (event) => {
    if (!this.state.selectedOption.includes(event.target.value)) {
      this.setState({ selectedOption: [...this.state.selectedOption, event.target.value] });
    }
  };

  handleRemoveOption = (index) => {
    const options = [...this.state.selectedOption];
    options.splice(index, 1);
    this.setState({ selectedOption: options });
  };

  render() {
    const bg = {
      modal: { borderRadius: 16 },
      overlay: {},
    };
    return (
      <div>
        <Helmet>
          <title>Shevolve | NewSignUp | Career Mentor for Women</title>
          <meta name="description" content="Shevolve is an initiative for and by women. Become a part of India's first mentoring platform built exclusively for women." />
        </Helmet>

        <Styles />
        <div className="wrapper">
          <section className="login-page signup-wrap signup-wrap-3">
            <div className="login-bg">
              <figure className="login-image mb-0 w-100">
                <img
                  className="img-fluid"
                  src="/images/entry/entry-image.png"
                  alt="login image"
                  style={{ height: "100vh", objectFit: "cover" }}
                />
              </figure>
            </div>
            <div className="login-form">
              <form onSubmit={this.handleFormSubmit}>
                <div className="form-content">
                  <div className="login-form-content mb-0">
                    <Link to="/">
                      <div className="logo-img-bottom mb-4">
                        <img src="/images/logo.svg" width={120} height={48} alt="header-logo" />
                      </div>
                    </Link>
                    <div className="sec-title flex-content m-b-md">
                      <h5 className="title mb-2 sh-para ">Great! Enter your basic details</h5>
                    </div>
                    <div className="sec-content mb-2">
                      <div className="row">
                        <div className="col-12 col-md-9">
                          <p>
                            {/* Provide just provide your basic details to
                                                        authorization who you are and you are good to go! */}
                            Please provide your basic details to join us and you are good to go!
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="signup-wrap-content basic-detail m-3 m-b-2 ">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>
                              Name <sup>*</sup>
                            </label>
                            <input
                              className="form-control"
                              // placeholder="John Doe"
                              type="text"
                              name="name"
                              value={this.state.name}
                              onChange={this.handleTextChange}
                            />
                            {this.state.errorName === true &&
                              <span className="sh-entry__error-text mt-2">Please enter your name</span>
                            }
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>
                              Email ID<sup>*</sup>
                            </label>
                            <input
                              className="form-control"
                              //  placeholder="Yogeshnpl@gmail.com"
                              type="email"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleTextChange}
                            />
                            {this.state.errorEmail === true &&
                              <span className="sh-entry__error-text mt-2">Enter valid email ID</span>
                            }
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>Phone Number <sup>*</sup></label>
                            <input
                              className="form-control"
                              // placeholder={9815984207}
                              type="number"
                              name="phoneNumber"
                              value={this.state.phoneNumber}
                              onChange={this.handleTextChange}
                            />
                            {this.state.errorPhone === true &&
                              <span className="sh-entry__error-text mt-2">Please enter a valid Phone number in these format: 1234567890, +31636363634, 075-63546725</span>
                            }
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group select-group">
                            <label htmlFor="industry1">
                              Industry <sup>*</sup>
                            </label>
                            <select
                              className="form-control"
                              id="industry1"
                              //  placeholder="Entry value 1"
                              name="industry"
                              value={this.state.industry}
                              onChange={this.handleTextChange}
                            >
                              <option value='' disabled>--select--</option>
                              {industries.map((industry) => (
                                <option key={industry} value={industry}>
                                  {industry}
                                </option>
                              ))}
                            </select>
                            {this.state.errorIndustry === true &&
                              <span className="sh-entry__error-text mt-2">Industry should not be blank</span>
                            }
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group select-group">
                            <label htmlFor="exampleFormControlSelect12">
                              Years of Experience <sup>*</sup>
                            </label>
                            <select
                              className="form-control"
                              id="exampleFormControlSelect12"
                              name="experience"
                              value={this.state.experience}
                              onChange={this.handleTextChange}
                            >
                              <option value="">Select Option</option>
                              <option value="0-8">0-8</option>
                              <option value="8-12">8-12</option>
                              <option value="More than 12">{">12"}</option>
                              {/* <option value="8">8-12</option>
                              <option value="12">{">"}15</option> */}
                            </select>
                            {this.state.errorExperience === true &&
                              <span className="sh-entry__error-text mt-2">Experience should not be blank</span>
                            }
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>Role</label>
                            <input
                              className="form-control"
                              // placeholder="Role "
                              type="text"
                              name="role"
                              value={this.state.role}
                              onChange={this.handleTextChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group select-group">
                            <label htmlFor="domain1">
                              Domain <sup>*</sup>
                            </label>
                            <select
                              id="domain1"
                              className="form-control"
                              //  placeholder="Domain"
                              type="text"
                              name="domain"
                              value={this.state.domain}
                              onChange={this.handleDomainChange}
                            >
                              <option value='' disabled>--select--</option>
                              {domains.map((d) => (
                                <option key={d} value={d}>
                                  {d}
                                </option>
                              ))}
                            </select>
                            {this.state.errorDomain === true &&
                              <span className="sh-entry__error-text mt-2">Domain should not be blank</span>
                            }
                          </div>
                        </div>

                        {this.state.userType === "mentor" && (
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label>
                                LinkedIn Profile Link <sup>*</sup>
                              </label>
                              <input
                                className="form-control"
                                // placeholder="https://www.linkedin.com/feed/"
                                type="text"
                                name="linkedInLink"
                                value={this.state.linkedInLink}
                                onChange={this.handleTextChange}
                              />
                            </div>
                          </div>
                        )}

                        {/*{this.state.userType === 'mentor' ?*/}
                        {/*    <div className="col-12 col-md-6">*/}
                        {/*        <div className="form-group select-group">*/}
                        {/*            <label htmlFor="exampleFormControlSelect13">*/}
                        {/*                Branding<sup>*</sup>*/}
                        {/*            </label>*/}
                        {/*            <select*/}
                        {/*                className="form-control"*/}
                        {/*                id="exampleFormControlSelect13"*/}
                        {/*                onClick={(e) =>*/}
                        {/*                    this.setState({branding: e.target.value})*/}
                        {/*                }*/}
                        {/*            >*/}
                        {/*                <option value=''>Select Option</option>*/}
                        {/*                <option value="yes">Yes</option>*/}
                        {/*                <option value="no">No</option>*/}
                        {/*            </select>*/}
                        {/*        </div>*/}
                        {/*    </div> : null*/}
                        {/*}*/}

                        {this.state.userType === "mentee" ? (
                          <div className="col-12 col-md-6">
                            <div className="form-group select-group">
                              <label htmlFor="exampleFormControlSelect11">
                                Career Break?<sup>*</sup>
                              </label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect11"
                                onChange={(e) => this.setState({ takeBreak: e.target.value })}
                              >
                                <option value="">Select Option</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                              {this.state.errorPasswordLength === true &&
                                <span className="sh-entry__error-text mt-2">Please choose whether you taken career break?</span>
                              }
                            </div>
                          </div>
                        ) : null}

                        {this.state.takeBreak === "yes" ? (
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label>Number Of Years</label>
                              <input
                                className="form-control"
                                //  placeholder="years"
                                type="number"
                                name="noOfYears"
                                value={this.state.noOfYears}
                                onChange={this.handleTextChange}
                              />
                              {this.state.errorCareerBreakYears === true &&
                                <span className="sh-entry__error-text mt-2">Please complete how long was your career break?</span>
                              }
                            </div>
                          </div>
                        ) : null}
                        {this.state.takeBreak === "yes" ? (
                          <div className="col-12 col-md-6">
                            <div className="form-group select-group">
                              <label htmlFor="exampleFormControlSelect1">Are You On Break Now</label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                name="breakNow"
                                value={this.state.breakNow}
                                onChange={this.handleTextChange}
                              >
                                <option value="">Select Option</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>

                              {this.state.errorCareerBreakconformation === true &&
                                <span className="sh-entry__error-text mt-2">Are you on a career break?</span>
                              }
                            </div>
                          </div>
                        ) : null}

                        {this.state.userType === "mentor" ? (
                          <div className="col-12 col-md-12">
                            <div className="form-group select-group">
                              <label htmlFor="exampleFormControlSelect1">
                                As a part of Shevolve, how would you like to contribute to the community?
                              </label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                name="breakNow"
                                value={""}
                                onChange={this.handleAddOption}
                              >
                                <option value="">Select all options that are applicable</option>
                                {mentorOptions.map((option, index) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : null}

                        <div className="col-12 no-gutters mb-2">
                          {this.state.selectedOption.map((option, i) => (
                            <button
                              className="btn btn-gradient-primary mr-1 mb-1"
                              onClick={(e) => this.handleRemoveOption(i)}
                              key={`${option}${i}`}
                              style={{ fontSize: 12 }}
                            >
                              {option} <i className="fas fa-times" />
                            </button>
                          ))}
                        </div>

                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>
                              Password <sup>*</sup>
                            </label>
                            <input
                              className="form-control"
                              // placeholder="Password"
                              type="password"
                              name="password"
                              value={this.state.password}
                              onChange={this.handleTextChange}
                            />
                            {this.state.errorPasswordLength === true &&
                              <span className="sh-entry__error-text mt-2">The password entered is not valid (either password didn't match or less than 8 characters</span>
                            }
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>
                              Confirm Password <sup>*</sup>
                            </label>
                            <input
                              className="form-control"
                              //  placeholder="Confirm Password"
                              type="password"
                              name="confirmPassword"
                              value={this.state.confirmPassword}
                              onChange={this.handleTextChange}
                            />
                            {this.state.errorPasswordConformed === true &&
                              <span className="sh-entry__error-text mt-2">The password entered is not valid (either password didn't match or less than 8 characters</span>
                            }
                          </div>
                        </div>
                        {/* {this.state.userType === "mentor" ? (
                          <div
                            className="col-12 col-md-12"
                            style={{ marginBottom: 60 }}
                          >
                            <div className="form-group select-group">
                              <label htmlFor="exampleFormControlSelect1">
                                you wish to join Sheovle community and support -
                                [select the options way you like to contribute)
                              </label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                name="contribute"
                              >
                                <option>Select Options</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </div>
                        ) : null} */}
                        {/* :null} */}
                        {this.state.userType === "mentee" ? (
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <label>LinkedIn Profile Link</label>
                              <input
                                className="form-control"
                                // placeholder="https://www.linkedin.com/feed/"
                                type="text"
                                name="linkedInLink"
                                value={this.state.linkedInLink}
                                onChange={this.handleTextChange}
                              />
                              {/* {this.state.errorLinkedName === true &&
                                <span className="sh-entry__error-text mt-2">Please enter your LinkedIn profile link</span>
                              } */}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <a href="javascript:void(0);" className="sh-button sh-button__plain-arrow mr-5" onClick={() => this.props.history.push("/whoIsSigning")}>
                        <i className="fas fa-arrow-left mr-3" />
                        Previous
                      </a>
                      <a href="javascript:void(0);" onClick={this.handleFormSubmit} className="sh-button">
                        Submit
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>

        <Modal
          open={this.state.success}
          onClose={() => this.props.history.push("/")}
          closeOnOverlayClick={true}
          showCloseIcon={false}
          center
          styles={bg}
        >
          <div
            style={{ display: "flex" }}
            // className={"modal fade"}
            id="news-modal"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl form-size">
              <div className="modal-content form-content email-modal-content">
                <div
                  //style={{borderRadius : 5}}
                  className="news-wrap"
                >
                  <div className="row no-gutters">
                    <div className="col-md-12 form-padlr">
                      <div className="form-block">
                        {/* <div className="form-close-btn" data-dismiss="modal">
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ modalState: false });
                          }}
                          className="close"
                          data-dismiss="modal"
                        >
                          ×
                        </button>
                      </div> */}

                        <div className="row no-gutters">
                          <div className="col-md-12">
                            <div className="news-text-content">
                              <div className="row">
                                <div className="col-12 col-md-12 col-lg-12">
                                  <div className="news-img">
                                    <img src="assets/images/undraw-news-img.png" />
                                  </div>
                                  <div className="news-sec-title">
                                    {this.state.userType === "mentor" && (
                                      <h5>
                                        Thank you for signing up to help other women’s career aspirations. Please verify your email to continue..{" "}
                                      </h5>
                                    )}
                                    {this.state.userType === "mentee" && (
                                      <h5>
                                        Thank you for taking the first step to evolve. To continue signing up as a mentee, please verify your email.{" "}
                                      </h5>
                                    )}
                                    {/* <p>Your email has been verified. Welcome to the Shevolve family.</p> */}
                                    <p className="text-danger">
                                      Note: If you cannot find the confirmation mail in your inbox, please check your spam folder.
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="btn-wrap">
                                    <button
                                      type="submit"
                                      className="sh-button sh-button__no-radius w-100"
                                      // data-dismiss="modal"
                                      onClick={(e) => this.props.history.push("/")}
                                    >
                                      Okay
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect()(NewSignUp);
