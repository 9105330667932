import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import { BASE_URL, ROZER_PAY_KEY } from "../../../constant";
import { getSortedPackage, purchasePackage } from "../../../NetworkRequest/requests/question";

const bg = {
  modal: { borderRadius: 16 },
  overlay: {},
};

const PackagePayment = ({open, onClose}) => {
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedPackageInd, setSelectedPackageInd] = useState(0);

  useEffect(() => {
    getSortedPackage().then((res) => setPackages(res));
  }, []);

  // useEffect(() =>{
  //   window.scroll(0, 0);
  // },[open])

  const handlePackageBuy = (e) => {
    e.preventDefault();

    setLoading(true);
    purchasePackage(packages[selectedPackageInd]._id)
      .then((res) => {
        setLoading(false);
        localStorage.setItem("from", "/");
        var options = {
          key: ROZER_PAY_KEY,
          amount: res.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          order_id: res.id,
          callback_url: `${BASE_URL}mentee/packagePaymentSuccess`,
          theme: {
            color: "#3399cc",
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      //   showCloseIcon={false}
      center
      styles={bg}
    >
      <div
        style={{ display: "flex" }}
        // className={"modal fade"}
        id="news-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl form-size my-0">
          <div className="modal-content form-content basic-modal-content email-modal-content">
            <div
              //style={{borderRadius : 5}}
              className="news-wrap"
            >
              <div className="row no-gutters">
                <div className="col-md-12 form-padlr">
                  <div className="form-block">
                    <div className="basic-detail-header" >
                      <div className="flex-space">
                        <h6 className="sec-title">Add availability</h6>
                        {/* <a href="javascript:void(0);">
                                          <i className="fas fa-times" />
                                      </a> */}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <div className="col-md-12">
                        <div className="email-text-content detail-text-content">
                          <div className="row">
                            <div className="col-md-12">
                              <form onSubmit={handlePackageBuy}>
                                <div className="login-form">
                                  <div className="form-content" style={{ minWidth: "60%" }}>
                                    <div className="login-form-content mb-3">
                                      <div className="sec-title flex-content m-b-md mt-2 text-center">
                                        <h5 className="sec-title mb-0" style={{ color: "#0C4B6C", fontSize: 29 }}>
                                          You are just One Payment away to avail all the benifits
                                        </h5>
                                      </div>
                                      <div>
                                        <p className="text-center">
                                          Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying. Lorem ipsum, or
                                          lipsum as it is sometimes known, is dummy text used in laying
                                        </p>
                                      </div>
                                      <div>
                                        {packages.length > 0 &&
                                          packages.map((pack, i) => (
                                            <div
                                              key={`pack${i}`}
                                              onClick={(e) => setSelectedPackageInd(i)}
                                              className="recommended-courses-container"
                                              style={{
                                                border: selectedPackageInd === i ? "1px solid #082550" : "1px solid rgba(0, 0, 0, 0.22)",
                                              }}
                                            >
                                              <div className="d-flex justify-content-between">
                                                <p style={{ color: selectedPackageInd === i ? "#000" : "" }}>
                                                  <b>{pack.title} (Recommended)</b>
                                                </p>
                                                <p style={{ color: selectedPackageInd === i ? "#000" : "" }}>
                                                  <b>₹{pack.cost}/-</b>{" "}
                                                </p>
                                              </div>
                                              <p className="mb-0">{pack.packageDuration} months program</p>
                                              {selectedPackageInd === i && (
                                                <ul style={{ listStyle: "none" }}>
                                                  {pack.keysOfPackage.map((keys, j) => (
                                                    <li key={`keys${j}`}>{keys}</li>
                                                  ))}
                                                </ul>
                                              )}
                                            </div>
                                          ))}

                                        <button type="submit" className="btn  btn-gradient-primary w-100 mt-5 py-3 btn-lg">
                                          Continue to Pay
                                        </button>

                                        <div className="sec-content text-align-center mt-4" style={{ fontSize: 14 }}>
                                          <p>
                                            Don’t want to pay now?
                                            <span onClick={onClose}  style={{ fontSize: 14, color: "#007a87", paddingLeft: 6 }}>
                                              {" "}
                                              Skip
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PackagePayment;
