import React from "react";
import Styles from "../../styles";
import { useHistory } from "react-router-dom";

const Success = () => {
  const history = useHistory();
  const link = localStorage.getItem("from");
  console.log("link recieved is", link);
  return (
    <div className="container h-100">
      <Styles />
      <div className="row vh-100 justify-content-center align-items-center">
        <div className="text-align-center justify-content-center">
          <img
            src="/assets/images/check-circle-regular.svg"
            style={{ width: 200, marginLeft: "auto", marginRight: "auto" }}
            alt="success"
          />
          <p className="mt-2"> Payment recieved successfully</p>
          {link ?
           <button className="btn btn-gradient-primary" onClick={e => history.push(link)}>Go Back</button>:
           <button className="btn btn-gradient-primary" onClick={e => history.push('/')}>back to home</button>
          }
        </div>
      </div>
    </div>
  );
};

export default Success;
