import { Modal } from 'react-responsive-modal'
const ConfirmationModal = ({ open,  onClose = () => { }, title, description, onAccept, onCancel}) => {
   const bg = {
    modal: { borderRadius: 16 },
    overlay: {},
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={bg}
    >
      <div
        style={{ display: "flex" }}
        id="news-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl form-size my-0">
          <div className="modal-content form-content basic-modal-content email-modal-content">
            <div
              className="news-wrap"
            >
              <div className="row no-gutters">
                <div className="col-md-12 form-padlr">
                  <div className="form-block">
                    <div className="basic-detail-header" style={{ marginTop: "-1px" }}>
                      <div className="flex-space">
                        <h6 className="sec-title">{title}</h6>
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <div className="col-md-12">
                        <div className="email-text-content detail-text-content text-align-center">
                          <p >{description}</p>
                         
                          <div className="d-flex justify-content-end">
                           <button className="btn btn-gradient-primary mr-3" onClick={onAccept}>Ok</button>
                           <button className="btn btn-outline-info mt-3" onClick={onCancel}>Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}


export default ConfirmationModal;
