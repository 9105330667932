import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import PackagePayment from "../Modals/packagePayment";

const TakeSurvey = (props) => {
  // const userData=

  const [open, setopen] = useState(false);

  const { isSurveyDone } = useSelector((state) => state.loginSignPopUp.userData);
  const history = useHistory();
  return (
    <div className="row my-5">
      {open && <PackagePayment open={true} onClose={(e) => setopen(false)} />}
      
      <div className="col-sm-6 pr-3 pt-5">
        <h4>Take a quick survey and get your life on track</h4>
        <p style={{ lineHeight: "28px", color: "#525252" }} className="pt-4">
          Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying. Lorem ipsum, or lipsum as it is sometimes known, is
          dummy text used in layingLorem ipsum, or lipsum as it is sometimes known, is dummy text used in layingLorem ipsum, or lipsum as it
          is sometimes known, is dummy text used in layingLorem ipsum, or lipsum as it is some
        </p>

        {/* <button
          onClick={() => {
            isSurveyDone ? setopen(true) : history.push("/survey");
          }}
          className="btn btn-gradient-primary py-3 px-5 mt-5"
        >
          {isSurveyDone ? "Make Payment" : "Take Survey"}
        </button> */}
      </div>
      <div className="col-sm-6">
        <img src="./assets/images/survey_home.svg" alt="img" />
      </div>
    </div>
  );
};

export default TakeSurvey;
