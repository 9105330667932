import AxiosBase from "../Axiosbase";

export const getAllQuestion = () => {
  return new Promise((resolve, reject) => {
    AxiosBase.get("/mentee/getAllQuestion")
      .then((res) => {
        console.log(res);
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addResponseOfQuestion = ({ questionId, answerId, packageType, skip, nextQuestionId, surveyProgress, isSurveyDone }) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/addResponseOfQuestion", {
      questionId,
      answerId,
      packageType,
      skip,
      nextQuestionId,
      surveyProgress,
      isSurveyDone,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getSortedPackage = () => {
  return new Promise((resolve, reject) => {
    AxiosBase.get("/mentee/getSortedPackages")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => reject(e));
  });
};

export const purchasePackage = (packageId) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/purchasePackage", { packageId })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => reject(e));
  });
};
