import ReactS3 from "react-s3";

// AWS_ID                   = 'AKIAXAYW5Q5P362DNDLI';
// AWS_SECRET               = '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';
// AWS_BUCKET_NAME          = 'shevolve';

const config = {
  bucketName: "shevolve",
  dirName: "courses" /* optional */,
  region: "ap-south-1",
  accessKeyId: "AKIAXAYW5Q5P362DNDLI",
  secretAccessKey: "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",
};

export const uploadFileOnAWS = (file) => {
  return new Promise((resolve, reject) => {
    ReactS3.uploadFile(file, config)
      .then((data) => {
        console.log("response is", data);
        resolve(data.location);
      })
      .catch((err) => {
        console.log("error iss", err);
        reject(err);
      });
  });
};
