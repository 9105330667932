//import React, { useState } from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import NewHeader from "./static/newHeader";
import React, { Component } from "react";
import {Helmet} from "react-helmet";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

// export default function Sample() {

//   const [file, setFile] = useState(
//     "/assets/images/684864789749564168489478974894489478974987498789wfg8er4gf984erg4fre94g89re7946dsw4cvf6sde4f8.pdf"
//   );
//   const [numPages, setNumPages] = useState(null);

//   function onFileChange(event) {
//     setFile(event.target.files[0]);
//   }

//   function onDocumentLoadSuccess({ numPages: nextNumPages }) {
//     setNumPages(nextNumPages);
//   }

//   return (
//     <div className="Example">
//       {/* <NewHeader  /> */}
//       <div className="Example__container" style={{display : "flex",justifyContent : "center"}}>
//         <div className="Example__container__document">
//           <Document

//             file={file}
//             onLoadSuccess={onDocumentLoadSuccess}
//             options={options}
//             style={{ color: "white", textAlign: "center", margin: 30 ,width : 500}}
//           >
//             {Array.from(new Array(numPages), (el, index) => (
//               <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//             ))}
//           </Document>
//         </div>
//       </div>

//     </div>
//   );
// }

class PrivacyPolicy extends Component {
  state = {
    file:
      "/assets/images/Privacy_policy-converted.pdf",
    numPages: null,
  };

  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };

  onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    this.setState({
      numPages: nextNumPages,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { file, numPages } = this.state;
    return (
      
      <div>
        <Helmet>
            <title>Shevolve | Privacy Policy</title>
            <meta name="description" content="Privacy Policy for Shevolve Platform" />
            <meta name="keywords" content="Terms & conditons, Mentoring platform for women, Women's mentorship, Career growth for women, Women empowerment, Personalized mentorship, Professional development for women, Female leadership, Mentorship programs, Women in business, Career advancement for women, Women's career support, Empowering women in the workforce, Leadership development for women, Professional mentorship for females, Women's career guidance" />

            <meta property="og:title" content="Shevolve | Privacy Policy" />
<meta property="og:description" content="Privacy Policy for Shevolve Platform" />
<meta property="og:image" content="https://shevolve.in/images/home/slide/slide1.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Shevolve | Privacy Policy" />
<meta name="twitter:description" content="Privacy Policy for Shevolve Platform" />
<meta name="twitter:image" content="https://shevolve.in/images/home/slide/slide1.png" />


        </Helmet>
        <NewHeader props={this.props} />
        <div
          className="Example__container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {/* <section className="sh-section">
            <div className="Example__container__document">
              <Document
                file={file}
                onLoadSuccess={this.onDocumentLoadSuccess}
                options={options}
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: 20,
                  margin: 30,
                  width: 900,
                }}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          </section> */}

          <div className="container mt-5">
            <section className="sh-section pb-0">
              <h1 className="sh-title text-center">PRIVACY POLICY</h1>
              <p className="sh-text">Posted as of 6th March 2021</p>
              <p className="sh-text">Last updated as of 29th September 2022</p>

              <h2 className="mt-5 text-center sh-terms__section-title">Welcome to Shevolve’s Privacy Policy</h2>
              <p className="sh-terms__para mb-0">This Website is created and operated by <b>M/s Shevolve Innovations Private Limited</b> having its
                registered office at <b>“Ag4, 16 Radhakrishnan Salai, Palaniappa Nagar, Valasarwakkam, Tamil Nadu India”</b>
                represented by its Directors, hereinafter referred to as the <b>“Company”</b> (where such expression shall,
                unless repugnant to the context thereof, be deemed to include its respective legal heirs, representatives,
                administrators, permitted successors and assigns).
              </p>
              <p className="sh-terms__para">This legal agreement is an electronic record in terms of Indian Information Technology Act, 2000 and rules
                  there under as applicable and the amended provisions about electronic records in various statutes as amended
                  by the Indian Information Technology Act, 2000. This electronic record is generated by a computer system and
                  does not require any physical or digital signatures.
              </p>
              <p className="sh-terms__para">This  legal  document  is  published  in  accordance  with  the  provisions  of  Rule  3  (1)  of  the
                Indian Information  Technology  (Intermediaries  guidelines)  Rules,  2011  and  Rule  4  of  the  Information
                Technology (Reasonable security practices and procedures and sensitive personal data or information)  Rules,
                2011  of  Information  Technology  Act,  2000  amended  through  Information Technology Amendment Act, 2008 that
                require publishing the Terms of Services and practices for access or usage of <b>“www.shevolve.in” (“Website/platform”)</b>
              </p>
              <p className="sh-terms__para">The creator of this Privacy Policy ensures a steady commitment to Your privacy with regard to the protection
                of your invaluable information that you may share across on this platform. This privacy policy contains
                information about the Website <b>“www.shevolve.in”</b> (hereinafter referred to as the <b>“Platform”</b>)
              </p>
              <p className="sh-terms__para">To  provide  You  with  Our  uninterrupted  use  of  the  platform,  We  may  collect  and,  in  some circumstances,
                disclose information about you with your permission. To ensure better protection of  Your  privacy,  We  provide  this
                 notice  explaining  Our  information  collection  and  disclosure policies, and the choices You make about the way Your
                 information is collected and used.
              </p>

              <p className="sh-terms__para"><b>This  Privacy  Policy  shall  be  in  compliance  with  the  General  Data  Protection  Regulation (GDPR)  in
                 effect  from  May  25,  2018,  and  any  and  all  provisions  that  may  read  to  the contrary shall be deemed
                  to be void and unenforceable as of that date. If you do not agree with the terms and conditions of our Privacy
                   Policy, including in relation to the manner of collection or use of your information, please do not use or
                    access the Site. If you have any questions or concerns regarding this Privacy Policy, you should contact our
                   Support Desk at <a href="mailto:reachus@shevolve.in">reachus@shevolve.in</a> </b>
              </p>

              <div className="sh-terms__divider"></div>

              <p className="sh-terms__para text-uppercase">ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO THEM UNDER THIS AGREEMENT.
                FURTHER, ALL HEADING USED  HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE
                 AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATORS OF THIS  PRIVACY  POLICY  MAY  USE  THE  HEADING
                 TO  INTERPRET  THE  PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.
              </p>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">INFORMATION WE COLLECT</h2>
                <p className="sh-terms__para">
                  We  are  committed  to  respecting  Your  online  privacy.  We  further  recognize  Your  need  for appropriate protection and management of any Personal Information You share with us. We may collect the following information:
                </p>
                <ul className="sh-terms__list">
                  <li>Personal data including but not limited to Name, Mobile No, Email Id.</li>
                  <li>Tracking  Information  such  as,  but  not  limited  to  the  IP  address  of  your  device  and Device ID when connected to the Internet. This information may include the URL that you just came from (whether this URL is on the Platform or not), which URL you next go to (whether this URL is on the Platform or not), your computer or device browser information, and other information associated with your interaction with the Platform; </li>
                  <li>Details of Platform usage for analytics.</li>
                </ul>
                <p className="sh-terms__para">This privacy policy also applies to data we collect from users who are not registered as members of  this
                  Platform,  including,  but  not  limited  to,  browsing  behaviour,  pages  viewed  etc.  We  also collect
                  and  store  personal  information  provided  by  You  from  time  to  time  on  the  Platform. We only  collect
                  and  use  such  information  from  you  that  we  consider  necessary  for  achieving  a seamless,
                  efficient and safe experience, customized to your needs including:
                </p>
                <ul className="sh-terms__list">
                  <li>To enable the provision of services opted for by you;</li>
                  <li>To enable the viewing of content in your interest; </li>
                  <li>To communicate the necessary  account and service-related information from time to time; </li>
                  <li>To allow you to receive quality customer care services and data Collection;</li>
                  <li>To comply with applicable laws, rules and regulations;</li>
                </ul>
                <p className="sh-terms__para">Where  any  service  requested  by  You  involves  a  third  party,  such  information  as  is
                  reasonably necessary by the Company to carry out Your service request may be shared with such third party.
                  The  Company  may  also  use  contact  information  internally  to  direct  its  efforts  for  service
                  improvement but shall immediately delete information as requested. Only requested services shall be withdrawn
                   and all other services shall be available to the Users. Further upon withdrawal of your consent for the same
                   through the <b>‘unsubscribe’</b> button or through an email to be sent to <a href="mailto:reachus@shevolve.in">reachus@shevolve.in </a>
                </p>
                <p className="sh-terms__para">To the extent possible, we provide You with the option of not divulging any specific information that you wish for
                  us not to collect, store or use. You may also choose not to use a particular service or feature on the Platform and
                  opt-out of any non-essential communications from the platform.  Further,  transacting  over  the  internet  has  inherent
                  risks  which  can  only  be  avoided  by  you following security practices yourself, such  as not revealing
                  account/login related information to any  other  person  and  informing  our  customer  care  team  about  any
                  suspicious  activity  or  where your account has/may have been compromised.
                </p>
              </div>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">OUR USE OF YOUR INFORMATION</h2>
                <p className="sh-terms__para">The information provided by you shall be used to provide and improve the service
                  for you and all users.
                </p>
                <ul className="sh-terms__list">
                  <li>To provide you with services on your request. </li>
                  <li>For maintaining an internal record</li>
                  <li>For enhancing the Services provided. </li>
                  <li>For maintaining record under the legal and statutory provisions.</li>
                </ul>
                <p className="sh-terms__para">For more details about the nature of such communications, please refer to our
                  Terms of Service. Further,  your  data  and  Sensitive  Personal  data  may  be  collected  and  stored  by
                  Us  for  internal record.
                </p>
                <p className="sh-terms__para">We use Your tracking information such as IP addresses, and or Device ID to help
                  identify you and to gather broad demographic information and make further services available to you.  We  will
                  not  sell,  license  or  trade  Your  personal  information.  We  will  not  share  your  personal information
                  with others unless they are acting under our instructions or we are required to do so by law.
                </p>
                <p className="sh-terms__para">Information collected via Our server logs includes users' IP addresses and the pages visited; this will  be
                  used  to  manage  the  web  system  and  troubleshoot  problems.  We  also  use  third-party analytics,
                  tracking,  optimization  and  targeting  tools  to  understand  how  users  engage  with  our Platform  so
                  that  we  can  improve  it  and  cater  personalized  content/ads  according  to  their preferences.
                </p>
              </div>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">HOW INFORMATION IS COLLECTED</h2>
                <p className="sh-terms__para">Before or at the time of collecting personal information, we will identify the purposes for which
                  information is being collected. If the same is not identified to you, you have the right to request the
                  Company  to  elucidate  the  purpose  of  collection  of  said  personal  information,  pending  the fulfilment
                  of which you shall not be mandated to disclose any information whatsoever.
                </p>
                <p className="sh-terms__para">We will collect and use your personal information solely to fulfil those purposes specified by us, within the
                  scope of the consent of the individual concerned or as required by law. We will only retain  personal
                  information  as  long  as  necessary  for  the  fulfilment  of  those  purposes.  We  will collect personal
                  information by lawful and fair means and with the knowledge and consent of the individual concerned.
                </p>
                <p className="sh-terms__para">Personal  data  should  be  relevant  to  the  purposes  for  which  it  is  to  be  used,  and,  to  the
                  extent necessary for those purposes, should be accurate, complete, and up-to-date.
                </p>
              </div>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">EXTERNAL LINKS ON THE PLATFORM</h2>
                <p className="sh-terms__para">The Platform may include advertisements, hyperlinks to other Websites or resources.
                  We have no control over any other Website or resources or contents available on these other Websites,
                  which are provided by companies or persons other than Us. You acknowledge and agree that we are not
                  responsible  for  the  availability  of  any  such  external  sites  or  resources,  and  do  not  endorse
                  any advertising, services/services or other materials on or available from such platform or resources. You
                  acknowledge and agree that We are not liable for any loss or damage which may be incurred by you as a result
                  of the availability of those external sites or resources, or as a result of any reliance placed  by  you  on
                  the  completeness,  accuracy  or  existence  of  any  advertising,  services  or  other materials on, or
                  available from, such Websites. These external third-party Websites and resource providers  may  have  their
                  privacy  policies  governing  the  collection,  storage,  retention  and disclosure of Your Personal
                  Information that You may be subject to. We recommend that you enter the Website and review their
                  privacy policy.
                </p>
              </div>
              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">YOUR RIGHTS</h2>
                <p className="sh-terms__para">Unless subject to an exemption, you have the following rights concerning your data: </p>
                <ul className="sh-terms__list">
                  <li>The right to request a copy of your data which we hold about you; </li>
                  <li>The  right  to  request  for  any  correction  to  any  personal  data  if  it  is  found  to beinaccurate or out of date;</li>
                  <li>The right to withdraw Your consent to the processing at any time;</li>
                  <li>The right to object to the processing of personal data;</li>
                  <li>The right to complain about a supervisory authority. </li>
                  <li>The right to obtain information as to whether personal data are transferred toa  third country or an international organization.</li>
                </ul>
                <p className="sh-terms__para">Where you hold an account with any of our services, you are entitled to a copy of all personal data
                  which we hold concerning you. You are also entitled to request that we restrict how we use your data in
                  your account when you log in.
                </p>
              </div>
              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">CONFIDENTIALITY</h2>
                <p className="sh-terms__para">You  further  acknowledge  that  the  Platform  may  contain  information  which  is  designated confidential
                   by  us  and  that  you  shall  not  disclose  such  information  without  our  prior  written consent.  Your
                   information  is  regarded  as  confidential  and  therefore  will  not  be  divulged  to  any third party,
                   unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your
                   personal information to any third party or use your e-mail address for unsolicited mail. Any emails sent by
                   us will only be in connection with the provision of agreed services, and you retain sole discretion to seek
                   for discontinuation of such communications at any point of time.
                </p>
              </div>
              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">OTHER INFORMATION COLLECTORS</h2>
                <p className="sh-terms__para">Except as otherwise expressly included in this Privacy Policy, this document only addresses the use  and
                  disclosure  of  information  we  collect  from  you.  To  the  extent  that  you  disclose  your information
                  to other parties, whether they are on our Platform or other sites throughout the Internet, different rules may
                  apply to their use or disclosure of the information you disclose to them. To the extent that we use third party
                  advertisers, they adhere to their privacy policies. Since we do not control the privacy policies of the third
                  parties, you are subject to ask questions before you disclose your personal information to others.
                </p>
              </div>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">COOKIES</h2>
                <p className="sh-terms__para">We use data collection devices such as “cookies” on certain pages of our Website. “Cookies” are small files
                  sited on your hard drive that assist us in providing customized services. We also offer certain features that
                  are only available through the use of a “cookie”. Cookies can also help us provide information which is
                  targeted to your interests. Cookies may be used to identify logged in or registered users. Our Website uses
                  session cookies to ensure that you have a good experience. These cookies contain a unique number, your 'session
                  ID', which allows our server to recognize your computer and 'remember' what you've done on the site. The benefits
                  of this are: a. You only need  to  log  in  once  if  you're  navigating  secure  areas  of  the  site  b.  Our
                  server  can  distinguish between your computer and other users, so you can see the information that you have
                  requested. You  can  choose  to  accept  or  decline  cookies  by  modifying  your  browser  settings  if  you
                  prefer. This may prevent you from taking full advantage of the Website. We also use various third-party cookies
                  for usage, behavioral, analytics and preferences data.
                </p>
                <p className="sh-terms__para mb-0">The following are the different types of cookies used on the Website: </p>
                <p className="sh-terms__para">a. Authentication cookies: To identify the user and share the content that he or she requested.
                  b. Functionality cookies: For the customized user experience and resuming past course progress.
                  c. Tracking, optimization, and targeting cookies: To capture usage metric on the device, operating system,
                  browser,  etc.  To  capture  behavioral  metrics  for  better  content  delivery.  To  cater  and suggest
                  most suited services and services.
                </p>
              </div>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">GOOGLE ANALYTICS</h2>
                <p className="sh-terms__para">We use Google Analytics to help us to understand how you make use of our content and work out how  we  can
                  make  things  better.  These  cookies  follow  your  progress  through  us,  collecting anonymous data on where
                  you have come from, which pages you visit, and how long you spend on the site. This data is then stored by
                  Google to create reports. These cookies do not store your personal data.
                </p>
                <p className="sh-terms__para">The information generated by the cookie about your use of the Website, including your IP address, may be
                  transmitted to and stored by Google on servers in the United States. Google may use this information for the
                  purpose of evaluating your use of the Website, compiling reports on Website activity for us and providing other
                  services relating to Website activity and internet usage. Google may also transfer this information to third
                  parties where required to do so by law, or where such third parties process the information on Google's behalf.
                  Google will not associate your IP address with any other data held by Google. By using this Website, you consent
                  to the processing of data about you by Google in the manner and for the purposes set out above.
                </p>
                <p className="sh-terms__para">The Google Website contains further information about Analytics and a copy of Google's privacy policy pages. </p>
              </div>
              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">GOOGLE ADSENSE</h2>
                <p className="sh-terms__para">Google AdSense is a tool that allows Website publishers to deliver advertisements to site visitors in exchange
                  for revenue calculated on a per-click or per-impression basis. To do this, Google <b>uses cookies  and  tracking
                  technology</b> to  deliver  ads  personalized  to  a  Website  user/visitor.  In  this regard the following terms are
                  specified to the Users:
                </p>
                <ul className="sh-terms__list">
                  <li>Third-party vendors, including Google, use cookies to serve ads based on your prior visits to our Website or other Websites.</li>
                  <li>Google's  use  of  advertising  cookies  enables  us  and  our  partners  to  serve  advertisements  to  you based on their visit to our Platform and/or other Websites on the Internet.</li>
                  <li>You may opt-out of personalized advertising by visiting Ads Settings.</li>
                  <li>All advertisements of third parties on our Platform are for informative purposes only and neither the Platform nor the Company guarantees or bears liability for the authenticity of the advertisements.</li>
                  <li>At no point will the Company permit its Competitors to advertise on the Platform </li>
                  <li>You may visit the links in the advertisements at your own risk or choose to not accept the cookies permitting third parties to display their advertisements. </li>
                </ul>
              </div>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">OUR DISCLOSURE OF YOUR INFORMATION</h2>
                <p className="sh-terms__para">We may host surveys for survey creators for our platform who are the owners and users of your
                survey responses. We do not own or sell your responses. Anything you expressly disclose in your responses will be disclosed
                to survey creators. Please contact the survey creator directly to better understand how they might share your survey
                responses.</p>
                <p className="sh-terms__para">Information collected will not be considered as sensitive if it is freely available
                  and accessible in the  public  domain  or  is  furnished  under  the  Right  to  Information  Act,  2005,  any
                  rules  made thereunder or any other law for the time being in force.
                </p>
                <p className="sh-terms__para">Due to the existing regulatory environment, we cannot ensure that all of your private communications and other
                  personally identifiable information will never be disclosed in ways not otherwise described in this
                  Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to
                  the government, law enforcement agencies or third parties. Therefore, although we use industry-standard
                  practices to protect your privacy, we do not promise,  and  you  should  not  expect,  that  your  personally
                  identifiable  information  or  private communications would always remain private. We do however assure you that
                  any disclosure of your personally identifiable information shall be personally intimated to you through an email
                  sent to your provided email address.
                </p>
                <p className="sh-terms__para">As a matter of policy, we do not sell or rent any personally identifiable information about you to any  third
                   party.  However,  the  following  describes  some  of  the  ways  that  your  personally identifiable
                   information may be disclosed:
                </p>
                <ul className="sh-terms__list">
                  <li>External  Service  Providers:  There  may  be  several  services  offered  by  external  service providers
                    that help you use our Platform. If you choose to use these optional services, and in the  course  of  doing
                    so,  disclose  information  to  the  external  service  providers,  and/or  permit them to collect
                    information about you, then their use of your information is governed by their privacy policy. </li>
                  <li>Law and Order: We cooperate with law enforcement inquiries, as well as other third parties to enforce
                    laws,  such  as  intellectual  property  rights,  fraud  and  other  rights.  We  can  (and  you authorize us
                    to) disclose any information about you to law enforcement and other government officials as we, in our sole
                    discretion, believe necessary or appropriate, in connection with an investigation  of  fraud,  intellectual
                    property  infringements,  or  other  activity  that  is  illegal  or may expose us or you to legal liability.
                  </li>
                </ul>
              </div>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</h2>
                <p className="sh-terms__para">Following registration, you can review and change the information you submitted at the stage of except Email ID
                   and mobile number. An option for facilitating such change shall be present on the Platform and such change shall
                   be facilitated by the User. If you change any information, we may or may not keep track of your old information.
                   We will not retain in our files information you have requested to remove for certain circumstances, such as to
                   resolve disputes, troubleshoot problems and enforce our terms and conditions. Such prior information shall be
                   completely removed from our databases, including stored ‘back up’ systems. If you believe that any information,
                   we are holding on you is incorrect or incomplete, or to remove your profile so that others cannot view it,
                   the User needs to remediate, and promptly correct any such incorrect information.
                </p>
              </div>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">SECURITY</h2>
                <p className="sh-terms__para">We treat data as an asset that must be protected against loss and unauthorized
                  access. We employ many  different  security  techniques  to  protect  such  data  from  unauthorized  access
                  by  members inside and outside the Company. We follow generally accepted industry standards to protect the
                  Personal Information submitted to us and information that we have accessed.</p>
                <p className="sh-terms__para">However,  as  effective  as  encryption  technology  is,  no  security  system
                  is  impenetrable.  Our Company  cannot  guarantee  the  security  of  our  database,  nor  can  we  guarantee
                  that  information you provide won’t be intercepted while being transmitted to the Company over the Internet.
                </p>
              </div>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">SEVERABILITY</h2>
                <p className="sh-terms__para">Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from
                  all and any other paragraphs herein except where otherwise expressly indicated or indicated  by  the  context
                  of  the  agreement.  The  decision  or  declaration  that  one  or  more  of  the paragraphs are null and void
                  shall not affect the remaining paragraphs of this privacy policy.
                </p>
              </div>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">AMENDMENT</h2>
                <p className="sh-terms__para">Our  Privacy  Policy  may  change  from  time  to  time.  The  most  current
                  version  of  the  policy  will govern our use of your information and will always be at the Platform.
                  Any amendments to this Policy shall be deemed as accepted by the User on their continued use of the Platform.
                </p>
              </div>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL REQUESTS</h2>
                <p className="sh-terms__para">To withdraw your consent, or to request the download or delete your data with us for any or all
                  our services at any time, please email to <a href="mailto:reachus@shevolve.in">reachus@shevolve.in</a>
                </p>
              </div>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">DISCLAIMER</h2>
                <p className="sh-terms__para">The  Platform  only  aims  in  providing  necessary  motivation  and  training  in  order  to  help  them women
                  boost their career. Platform does not promise or guarantee to provide placement services.
                </p>
              </div>

              <div>
                <h2 className="mt-5 text-center sh-terms__section-title">CONTACT US</h2>
                <p className="sh-terms__para">If  you  have  any  questions  or  concerns  regarding  this  privacy  policy,
                 you  should  contact  us  by sending an e-mail to please email to
                 <a href="mailto:reachus@shevolve.in">reachus@shevolve.in</a>
                </p>
                <p className="sh-terms__para">Information provided on the Platform may not be accurate and may be provided for promotional purposes of the business. </p>
              </div>
            </section>
          </div>

        </div>

      </div>
    );
  }
}

export default PrivacyPolicy;
