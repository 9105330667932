import React, { useState, useEffect } from "react";
import NewHeader from "./static/newHeader";
import { useHistory, useParams } from "react-router-dom";
import { getCourseDetail } from "../../NetworkRequest/requests/courses";
import { USER_TYPE } from "../Constants";
import { addPackageCourse, purchaseCourse } from "../../NetworkRequest/requests/profile";
import { toast } from "react-toastify";
import { BASE_URL, ROZER_PAY_KEY } from "../../constant";
import { Modal } from "react-responsive-modal";
import LoginPopUp from "./loginPopUp";
import { useSelector } from "react-redux";
import Loading from "./small/Loading";

const bg = {
  modal: { borderRadius: 16 },
  overlay: {},
};

const query = (search) => {
  var q = {};
  search
    .split("?")[1]
    .split("&")
    .forEach(function (i) {
      q[i.split("=")[0]] = i.split("=")[1];
    });
  return q;
};

export const contentTotalDuration = (course) => {
  let hour = 0;
  let minute = 0;
  let second = 0;
  for (let i of course.content) {
    const splitTime = i.videoDuration.split(":");
    if (splitTime.length >= 2) {
      hour += parseInt(splitTime[0]);
      minute += parseInt(splitTime[1]);
      second += parseInt(splitTime[2]);
    }
  }
  if (second > 59) {
    minute += Math.floor(second / 60);
    // minute = minute % 60;
  }
  if (minute > 59) {
    hour += Math.floor(minute / 60);
    minute = minute % 60;
  }
  return { hour, minute };
};

const CoursesDetails = (props) => {
  const history = useHistory();
  const { packageId } = history.location.search !== "" ? query(history.location.search) : "";
  const userDatalocal = JSON.parse(localStorage.getItem('userData'))
  const userId =  userDatalocal ? userDatalocal._id : ''
  const { login, userData } = useSelector((state) => state.loginSignPopUp);
  const { courseId } = useParams();
  const userType = localStorage.getItem(USER_TYPE);
  //  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const isLogin = login;
  window.scroll(0, 0);
  const [course, setCourse] = useState({ content: [] });
  const [success, setSuccess] = useState(false);
  const [orderData, setOrderData] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    console.log("userData111", packageId, "hasd", userData, userData._id);
    if (courseId) {
      getCourseDetail({ courseId, userType, userId}).then((res) => {
        if (res.data) {
          setCourse(res.data);
        }
        console.log("res", res);
      });
    } else {
      history.push("/courses");
    }
  }, []);

  const startPayment = (res) => {
    localStorage.setItem("from", history.location.pathname);
    var options = {
      key: ROZER_PAY_KEY,
      amount: res.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      order_id: res.id,
      callback_url: `${BASE_URL}${userType || "mentee"}/courseSuccess`,
      theme: {
        color: "#3399cc",
      },
      // redirect: true
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handlePurchageCourse = (userType, id) => {
    setLoading(true);
    if (packageId) {
      addPackageCourse(course._id, packageId)
        .then((res) => {
          setLoading(false);
          toast.success(res.customMessage || 'successfully added');
          history.push('/');
        })
        .catch((e) => setLoading(false));
    } else {
      purchaseCourse(userType, id)
        .then((res) => {
          setLoading(false);
          console.log("handle gg", res);
          if (res.customMessage) {
            toast.info("Course already purchased");
          }
          if (Object.keys(res).length === 0) {
            setSuccess(true);
            // return toast.success("Enrolled successfully");
          } else if (res.id) {
            setOrderData(res);
          }
          //toast.success('Course updated successfully');
        })
        .catch((e) => setLoading(false));
    }
  };

  const onGoogleLogin = () => {
    setShowDialog(false);
    handlePurchageCourse("mentee", course._id);
  };

  return (
    <div className="wrapper container-width">
      <NewHeader props={props} />
      <LoginPopUp
        open={showDialog}
        onClose={(e) => setShowDialog(false)}
        googleSuccess={onGoogleLogin}
        routeName={history.location.pathname}
      />
      <Loading open={loading}/>
      <div className="container">
        <div className="event-blog-detail mt-5">
          <div className="event-about-wrap">
            <div className="event-about-content">
              <div className="d-flex">
                <div className="mr-auto d-flex align-items-center">
                  <img src="/assets/images/Groupbooks.svg" alt="" />
                  <h6 className="ml-3 mb-0">{course.courseName}</h6>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={(e) => {
                      if (isLogin) {
                        if (course.isPurchased) {
                          history.push("/profile");
                        } else {
                          handlePurchageCourse(userType, course._id);
                        }
                      } else {
                        setShowDialog(true);
                      }
                    }}
                    className="btn btn-info"
                    style={{ backgroundColor: "#00E8B0" }}
                  >
                    {course.isPurchased ? "view Course" : course.isFree ? "ENROLL" : " BUY COURSES"}
                  </button>
                </div>
              </div>
              <p className="text-white my-4 f-size-14">{course.courseTitle}</p>
              <div>
                <div className="d-flex f-size-14 align-items-center mb-3">
                  <img style={{ height: 24, width: "auto", marginRight: 16 }} src="/assets/images/youtube-icon.png" alt="" />
                  <p className="mb-0 text-white">{course.content && course.content.length} Modules</p>
                </div>

                <div className="d-flex f-size-14 align-items-center mb-3">
                  <img style={{ height: 24, width: "auto", marginRight: 16 }} src="/assets/images/tag.svg" alt="" />
                  <p className="mb-0 text-white"> {course.isFree ? "Free" : `₹${course.price}`}</p>
                </div>

                <div className="d-flex f-size-14 align-items-center mb-3">
                  <img style={{ height: 24, width: "auto", marginRight: 16 }} src="/assets/images/clock.svg" alt="" />
                  <p className="mb-0 text-white">
                    {contentTotalDuration(course).hour}hr {contentTotalDuration(course).minute}min
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="courses-second-banner">
            <h6>About this course</h6>
            {/* <p>Learn iOS 11 App Development From Beginning to End. Using Xcode 9 and Swift 4. Includes Full ARKit and CoreML Modules!</p> */}
            <p className="text-white f-size-14 my-4" dangerouslySetInnerHTML={{ __html: course.aboutCourse }} />
          </div>
          <div className="shadow mb-4  courses-third-banner">
            <div className="courses-third-banner-title py-4 px-5">
              <h6 className="mb-0 text-white">Course Modules</h6>
            </div>

            {course.content &&
              course.content.map((content, i) => (
                <div className="d-flex px-4 py-3 align-items-center" key={`content${i}`}>
                  <div className="d-flex align-items-center mr-auto">
                    <div className="green-youtube-background ">
                      <img src="/assets/images/you.svg" alt="" />
                    </div>
                    <div className="courses-three-para mr-auto">
                      <span>{content.videoTitle}</span>
                      {/* <p className='mb-0' style={{ fontSize: 12 }}>3/12</p> */}
                    </div>
                  </div>
                  <div>
                    <span style={{ fontSize: 12 }}>{content.videoDuration} min</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Modal
        open={success || orderData.id}
        onClose={(e) => {
          setOrderData("");
          window.location.reload(false);
        }}
        showCloseIcon={false}
        center
        styles={bg}
      >
        <div
          style={{ display: "flex" }}
          // className={"modal fade"}

          id="news-modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl form-size">
            <div className="modal-content form-content email-modal-content">
              <div className="news-wrap">
                <div className="row no-gutters">
                  <div className="col-md-12 form-padlr">
                    <div className="form-block">
                      <div className="row no-gutters">
                        <div className="col-md-12">
                          <div className="news-text-content">
                            <div className="row">
                              <div className="col-12 col-md-12 col-lg-12">
                                <div className="news-img">
                                  <img src="assets/images/undraw-news-img.png" />
                                </div>
                                <div className="news-sec-title">
                                  {orderData.id ? (
                                    <h5>
                                      You have successfully registered for {course.courseName}! You will now be directed to the payment
                                      portal!
                                    </h5>
                                  ) : (
                                    <h5>You have successfully registered for {course.courseName} ! Happy Learning</h5>
                                  )}
                                  {/* <p>Your email has been verified. Welcome to the Shevolve family.</p> */}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="btn-wrap">
                                  <button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    // data-dismiss="modal"
                                    onClick={(e) => {
                                      if (orderData.id) {
                                        startPayment(orderData);
                                      } else {
                                        history.push("/profile");
                                      }
                                    }}
                                  >
                                    {orderData.id ? "Start Payment" : "Go to My courses"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CoursesDetails;
