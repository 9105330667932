import { useState, useLayoutEffect, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
// import NewHeader from '../static/newHeader';
import './about.scss'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Layout from '../../layout/default';
import {Helmet} from "react-helmet";
import {AnimWrap} from "../animation-gsap/Animation";
import { easeQuadInOut } from 'd3-ease';
import AnimatedProgressProvider from './AnimatedProgressProvider';
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";


export default function AboutUs() {

    const [modal, setModal] = useState(false);

    const modalboxShow = () => {
        setModal(!modal)
    }

    const [ip, setIp] = useState([]);


    useEffect(() => {
        setTimeout(
            () => setIp('res.data'), 
            100
          );
        
    }, []);

    console.log(modal)

    const ratingData = [
        {
            percentage: 4,
            para: "Only 3.7% of CEOs and Managing Directors of NSE-listed companies were women in 2019"
        },
        {
            percentage: 6,
            para: "As of 2019, just 29 companies (5.8%) on the Fortune India 500 list had women in executive roles."
        },
        {
            percentage: 31,
            para: "Women make up only 31% of Chief Human Resources Officers (CHROs), a role overrepresented by women in other countries such as the US and South Africa."
        },
        {
            percentage: 14,
            para: "Women account for only 13.8% of board directors from listed companies in India"
        },
    ]

    const leaderShip = [
        {
            img: "deepa",
            name: 'Deepa Vijayraghavan',
            role: "Founder",
            quote: "With 20+ years of corporate experience in agile program, project and portfolio management, a D&I Certified Leader leading women support groups started a new journey with a phone call to a friend on supporting women with the right career goals and direction which led to the development of India’s First Mentoring Platform for Women.",
            linkedIn: ""
        },
        {
            img: "bhaduri",
            name: 'Bhaduri Saravanakumar',
            role: "Co-Founder and Chief Technology Officer",
            quote: "As a Leader in Technology through 25+ years of experience and leading various programs for D&I , Shevolve was conceptualised as an engaging tech platform to enable direct interactions with mentors for self-motivated and dynamic women overcoming challenges of gender stereotypes with an objective to Lift as We Rise.",
            linkedIn: ""
        }
    
    ]

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    return (
        <Layout className="sh-about">
           <Helmet>
                    <title>About Shevolve - Unlock Your Career Potential: Shevolve Mentoring Platform</title>
                    <meta name="description" content="Shevolve offers personalized career mentoring programs designed to empower women, elevate diversity, and foster leadership.Join over 20,000 members on a journey to unlock your true potential with Shevolve's mentorship platform." />
                    <meta name="keywords" content="shevolve, career mentoring, women empowerment, diversity, leadership, mentorship, career development, women leaders, allyship, career growth" />

                    <meta property="og:title" content="About Shevolve - Unlock Your Career Potential: Shevolve Mentoring Platform" />
<meta property="og:description" content="Shevolve offers personalized career mentoring programs designed to empower women, elevate diversity, and foster leadership.Join over 20,000 members on a journey to unlock your true potential with Shevolve's mentorship platform." />
<meta property="og:image" content="https://shevolve.in/images/home/slide/slide1.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="About Shevolve - Unlock Your Career Potential: Shevolve Mentoring Platform" />
<meta name="twitter:description" content="Shevolve offers personalized career mentoring programs designed to empower women, elevate diversity, and foster leadership.Join over 20,000 members on a journey to unlock your true potential with Shevolve's mentorship platform." />
<meta name="twitter:image" content="https://shevolve.in/images/home/slide/slide1.png" />

                    
                </Helmet>
            <AnimWrap />
            <div className="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document" style={{top: "100px"}}>
                    <div className="modal-content">
                        <button type="button" className="close sh-modal__btn-close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body p-0">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=pBGBK_65gCs"
                                height={570}
                                playing={false}
                                allowFullScreen
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                width="100%"
                                // controls="false"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/*=========================================
            ===================== Banner ================
            ============================================ */}
            <section className="sh-section sh-about__section position-relative" style={{ backgroundImage: "url()" }}>
                <div className="position-absolute sh-about__banner-background"><img rel="preload" className='img-fluid' src="./images/about/about-us-banner.jpg" alt="About Us | Shevolve" /></div>
                <div className="sh-section__inwrap sh-about__banner-inwrap">
                    <div className="container">
                        <div className="sh-about__row row align-items-center justify-content-end px-3 p-md-0">
                            <div className="sh-about__banner-left col-md-12">
                                <div className="sh-about__banner-content position-relative">
                                    <div className="sh-about__banner-title sh-anim__stagger-fade-top">
                                        <h1 className="sh-banner__title mt-0 sh-banner__title--white">About Shevolve </h1>
                                    </div>
                                    {/* <div className="sh-about__banner-title overflow-hidden position-relative">
                                        <div className="sh-banner__title">Evolve & navigate</div>
                                        <div className="sh-banner__title sh-banner__title--grey">your career with Shevolve</div>
                                    </div> */}
                                    <div className="sh-button__video mt-4 sh-anim__stagger-fade-top" data-toggle="modal" data-target="#exampleModal" onClick={modalboxShow}>
                                        <div className="sh-button__video-circle d-flex flex-wrap">
                                            <div className="sh-button__video-icon"><span className="arrow"></span></div>
                                        </div>
                                        <span className="sh-button__video-text sh-text">Watch video</span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="sh-about__banner-right">
                                <div className="sh-about__banner-image cursor-pointer">
                                    <img className="img-fluid" data-toggle="modal" data-target="#exampleModal" src="./images/about/about-banner.jpg" alt="about-banner" />
                                </div>
                               
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            {/*=========================================
            ===================== Lorem Ipsum dollar amet ================
            ============================================ */}
            <section className="sh-section">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div>
                                    <div className="sh-title sh-title--light sh-anim__stagger-fade-top"> <b>Evolve & Navigate</b> your career with Shevolve</div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="sh-text">
                                    <p className="mb-3 sh-anim__stagger-fade-top">Shevolve is India’s first mentoring platform for women with over 20000+ members focusing on elevating diversity and women leaders with our simple objective to “Lift as we rise.” Our customized programs are designed based on real-world problems, understanding workplace culture, upskill hard & soft skills to kick-start their new career journey with confidence and vigor. Shevolve is an initiative by women for women. We believe that women are powerful allies for other women, and with Shevolve, we aim to celebrate and advocate for women everywhere.</p>
                                    <p className="sh-anim__stagger-fade-top mb-3">Our platform promotes true allyship leading to an intentional commitment to using your voice, credibility, knowledge, place, or power to support women in leveling the playing field. With access to mentors from around the globe, your career or entrepreneurship journey can begin or restart the right way with the proper guidance. Choose the right career path to grow, navigate obstacles and reach your true potential with Shevolve.</p>
                                    {/* <a className="sh-button" onClick={() => window.location.replace("/program/#allPakages")}>Know More</a> */}
                                    <Link className="sh-button sh-anim__stagger-fade-top mt-3" onClick={() => window.location.replace("/subscription/individual#allPackages")}>Know More</Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*=========================================
            ===================== Women Employment Success Rate ================
            ============================================ */}
            <section className="sh-section sh-section--blue sh-section__progress">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="sh-title sh-title__underline text-center sh-section__title-wrap sh-anim__stagger-fade-top w-100">Women Employment Success Rate</div>
                        <div className="row">

                            {ratingData.map((data, index) => {
                                    return (
                                        <div key={index} className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                                            <div className="sh-rating sh-anim__stagger-fade-top">
                                                <div className="sh-rating__value-wrap">
                                                    <div className="sh-rating__value">
                                                        <VisibilitySensor>
                                                            {({ isVisible }) => {
                                                                return (
                                                                    <AnimatedProgressProvider
                                                                        valueStart={0}
                                                                        valueEnd={isVisible ? data.percentage : 0}
                                                                        duration={1.4}
                                                                        easingFunction={easeQuadInOut}
                                                                        // repeat
                                                                    >
                                                                        {value => {
                                                                            const roundedValue = Math.round(value);
                                                                            return (
                                                                                <CircularProgressbar
                                                                                    value={value}
                                                                                    text={`${roundedValue}%`}
                                                                                    strokeWidth="3"
                                                                                    styles={buildStyles({

                                                                                        strokeLinecap: 'butt',

                                                                                        textSize: '20px',

                                                                                        pathTransitionDuration: 0.5,


                                                                                        pathColor: "#007A87",
                                                                                        textColor: '#007A87',
                                                                                        trailColor: '#CCE4E7',
                                                                                        backgroundColor: '#007A87',
                                                                                    })}
                                                                                />
                                                                            );
                                                                        }}
                                                                    </AnimatedProgressProvider>
                                                                );
                                                            }}
                                                        </VisibilitySensor>
                                                    </div>
                                                </div>
                                                <p className="sh-rating__para">{data.para}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>

            {/*=========================================
            ===================== Leadership Team ================
            ============================================ */}
            <section className="sh-section pb-0 overflow-hidden">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="sh-title sh-title__underline text-center sh-section__title-wrap sh-anim__stagger-fade-top">Leadership Team</div>
                        {/* Implemented continous looping with 5 seconds delay */}
                        {/* Update: Removed Vandana Profile From Leadership Team */}
                        <OwlCarousel className="owl-theme sh-slider sh-leadership__slider" loop={true} autoplay={true} autoplayTimeout={5000} items={1} margin={30}>
                            {leaderShip.map((data, index) => {
                                return (
                                    <div className="sh-leadership__slide d-flex flex-wrap align-items-end sh-anim__stagger-fade-top">
                                        <div className="sh-leadership__content position-relative text-center">
                                            <div className="sh-leadership__quotation mb-4"><img style={{ height: 50 }} src="./images/icons/right-quote.svg" alt="" /></div>
                                            <p className="sh-leadership__para sh-text">{data.quote}</p>
                                            <div className="mt-4">
                                                <div className="sh-leadership__name sh-para">{data.name}</div>
                                                <div className="sh-leadership__job sh-text">{data.role}</div>
                                            </div>
                                        </div>
                                        <div className="sh-leadership__images position-relative">
                                            {/* <div className="position-absolute sh-leadership__image-leaf"><img className="img-fluid" src="./assets/home/testimonial-leaf.svg" alt="" /></div> */}
                                            <div className="sh-leadership__image-profile"><img className="img-fluid" src={`./images/about/leadership/${data.img}.png`} alt={data.name + " | " + data.role + " | Shevolve Leadership Team"}
 /></div>
                                        </div>
                                    </div>
                                )
                            })}
                        </OwlCarousel>
                        {/* <div className="col-md-4">
                            
                            <div className="sh-leadership">
                                <div className="sh-leadership__top">
                                    <div className="sh-leadership__image">
                                        <img src="" alt="" />
                                    </div>
                                    <div className="sh-leadership__top-content">
                                        <div className="sh-leadership__name">Depa</div>
                                        <div className="sh-leadership__role">Product Manager</div>
                                    </div>
                                </div>
                                <div className="sh-leadership__bottom position-relative">
                                    <div className="sh-leadership__quotation position-absolute">
                                        <img src="./assets/about/quote.svg" alt="" />
                                    </div>
                                    <p className="sh-leadership__para sh-text">Thanks guys, keep up the good work! Chamer should be 
                                        nominated for service of the year. You won't regret it. Chamer 
                                        was worth a fortune to my company.
                                    </p>
                                    <div className="sh-leadership__social-wrap">
                                        <div className="sh-leadership__social-link">

                                        </div>
                                        <a href=""><img src="" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </Layout>
    )
}