import { useState, useEffect } from "react";
import Styles from "../../../styles";
import NewHeader from "../static/newHeader";
import MentorProfile from "./mentorProfile";
import Divider from "@material-ui/core/Divider";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { addNote, getNote } from "../../../NetworkRequest/requests/mentee";
import moment from "moment";
import Assignments from "../small/assignment";
import QA from "../small/QA";
import PackageDetails from "./packageDetails";
import { useParams, useHistory } from "react-router-dom";
import { addMenteeNotes, getMenteeNotes, getMenteePackageInfo } from "../../../NetworkRequest/requests/mentor";
import { authenticated } from "../../privateAuth/tokenExists";

const tabs = ["Package Content", "Notes", "Assignments", "Q&A"];

const MenteeDetails = () => {
  const { id } = useParams();
  const history = useHistory();

  const [active, setactive] = useState("Package Content");
  const [notesList, setNotesList] = useState([]);
  const [mentee, setMentee] = useState({});
  const [purchasedPackage, setPurchasedPackage] = useState({
    courses: [],
    mentoringSessions: [],
    workshops: [],
  });
  const [noteText, setNoteText] = useState({
    title: "",
    description: "",
  });

  const _getNotes = () => {
    getMenteeNotes(id).then((res) => {
      setNotesList(res);
    });
  };

  useEffect(() => {
    if (!authenticated()) {
      history.push("/login");
    }
    if (!id) {
      history.push("/");
    }
    _getNotes();
    _getMenteePackageInfo();
  }, []);

  const _getMenteePackageInfo = () => {
    getMenteePackageInfo(id).then((res) => {
      if (res) {
        
        setPurchasedPackage(res[0]);
        setMentee(res[0].mentee[0])
      }
    });
  }
    

  const handlePost = () => {
    if (noteText.title !== "" && noteText.description !== "") {
      addMenteeNotes({ menteeId: id, ...noteText }).then((res) => {
        setNoteText({ title: "", description: "" });
        _getNotes();
      });
    }
  };
  const handleNoteText = (evt) => {
    const s = { ...noteText };
    s[evt.target.name] = evt.target.value;
    setNoteText(s);
  };
  return (
    <div>
      <NewHeader />
      <MentorProfile userData={mentee} noEdit={true} />
      <Styles />
      <div className="container">
        <div className="mentee-detail-tabs">
          <ul>
            {tabs.map((tab) => (
              <li key={tab} onClick={() => setactive(tab)} style={{ color: tab === active ? "#007A87" : "" }}>
                {tab}
              </li>
            ))}
          </ul>
          <Divider />
          {active === "Package Content" && <PackageDetails purchasedPackage={purchasedPackage} />}

          {active === "Notes" && (
            <div style={{ width: "75%" }}>
              <h3 className="my-5">Notes</h3>
              <div class="form-group">
                <label for="title" className="f-size-12">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  value={noteText.title}
                  onChange={handleNoteText}
                  class="form-control"
                  id="title"
                  aria-describedby="emailHelp"
                  placeholder="this is my title"
                />
              </div>

              <div className="form-group">
                <label htmlFor="title" className="f-size-12">
                  Your Notes
                </label>
                <div style={{ border: "1px solid #c4c4c4", borderRadius: "0.625rem", padding: 10 }}>
                  <TextareaAutosize value={noteText.description} name="description" onChange={handleNoteText} className="notes-textArea" />
                  <div className="d-flex justify-content-end split">
                    <button onClick={handlePost} className="btn btn-gradient-primary">
                      Post
                    </button>
                  </div>
                </div>
                <div style={{ border: "1px solid #EDEDED", height: "2px", width: "100%", marginBlock: 30 }}></div>
              </div>

              <div className="">
                {notesList.map((no) => (
                  <div key={no._id}>
                    <p style={{ fontSize: 16, color: "#525252", marginBottom: 0 }}>{no.title}</p>
                    <p className="f-size-12">Posted on : {moment(no.createdAt).format("DD MMM YYYY")}</p>
                    <p className="f-size-12" style={{ color: "#727272" }}>
                      {no.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {active === "Assignments" && <Assignments />}
          {active === "Q&A" && <QA />}
        </div>
      </div>
    </div>
  );
};

export default MenteeDetails;
