import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch   } from 'react-router-dom';
import {ToastContainer} from 'react-toastify'
import { createBrowserHistory } from 'history';
import { icons } from "./assets/icons";
import 'react-toastify/dist/ReactToastify.css'
import './scss/style.scss';
import { Provider } from "react-redux";
import store from "./store";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)


React.icons = icons;

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const history = createBrowserHistory();

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
// const AddDevice = React.lazy(() => import('./views/pages/addDevice'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
// import AddDevice from './views/pages/addDevice';

class AdminApp extends Component {
  render() {
    const authenticated = localStorage.getItem('adminAccessToken');
    console.log("props" , history);
    return (
      <Provider store={store}>
        <Router>
          <React.Suspense fallback={loading}>
            <ToastContainer />
            <Switch>
              {!authenticated && (
                <Route
                  exact
                  path="/admin/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
              )}
              {/* {!authenticated && <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />} */}
              {/* <Route exact path="/admin/addDevice" name="Login Page" render={props => <AddDevice {...props}/>} /> */}
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              {authenticated && (
                <Route
                  path="/admin"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
              )}
              {!authenticated /* history.location.pathname === "/admin" && */ /* history.location.pathname === "/admin/dashboard" && */ && (
                <Route
                  path="/admin"
                  render={() => {
                    history.push({ pathname: "/admin/login" });
                    history.go("/admin/login");
                    // return null;
                  }}
                />
              )}
            </Switch>
          </React.Suspense>
        </Router>
      </Provider>
    );
  }
}

export default AdminApp;
