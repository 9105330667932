import React, { Component } from 'react';
// import './video.css';
import Participant from './Participant';
import IconButton from '@material-ui/core/IconButton'

class Room extends Component {
  mute = false;
  constructor(props) {
    super(props);

    this.state = {
      remoteParticipants: Array.from(this.props.room.participants.values())
    }

    this.leaveRoom = this.leaveRoom.bind(this);
  }
  componentDidMount() {
    // Add event listeners for future remote participants coming or going
    this.props.room.on('participantConnected', participant => this.addParticipant(participant));
    this.props.room.on('participantDisconnected', participant => this.removeParticipant(participant));

    window.addEventListener("beforeunload", this.leaveRoom);
  }

  componentWillUnmount() {
    this.leaveRoom();
  }

  addParticipant = (participant) => {
    console.log(`${participant.identity} has joined the room.`);

    this.setState({
      remoteParticipants: [...this.state.remoteParticipants, participant]
    });
  }

  removeParticipant = (participant) => {
    console.log(`${participant.identity} has left the room`);

    this.setState({
      remoteParticipants: this.state.remoteParticipants.filter(p => p.identity !== participant.identity)
    });
  }

  handleMute = () => {
    this.props.room.localParticipant.audioTracks.forEach(publication => {
      publication.disable();
    });
  }

  handleUnMute = () => {
    this.props.room.localParticipant.audioTracks.forEach(publication => {
      publication.enable();
    });
  }

  muteToggle = () => {
    if(this.mute) {
      this.handleUnMute();
      console.log('handle unmute is called')
    } else {
      this.handleMute();
      console.log('handle mute is called')
    }
    this.mute = !this.mute
  }



  leaveRoom = () => {
    // this.props.room.participants.forEach(track => track.stop());
    this.props.room.disconnect();
    this.props.returnToLobby();
  }
  render() {
    return (
      <div className="room position-relative">
        <div className="participants">
          <div className="position-relative">
            {
              this.state.remoteParticipants.map(participant =>
                <Participant key={participant.identity} participant={participant} />
              )
            }
            <div className={`${this.state.remoteParticipants.length === 0 ? '' : 'position-absolute right-0 w-25'} `} style={{ bottom: 0 }}>
              <Participant key={this.props.room.localParticipant.identity} localParticipant="true" participant={this.props.room.localParticipant} />
            </div>
          </div>


        </div>
        <div >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>

            <IconButton id="leaveRoom" variant="rounded" onClick={this.muteToggle} className="mr-3"><i class="fas fa-volume-mute"></i></IconButton>

            <IconButton id="leaveRoom" variant="rounded" onClick={this.leaveRoom}><i class="fas fa-phone"></i></IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default Room;
