import React, { useState, useEffect } from "react";
import Styles from "../../styles";
import NewHeader from "./static/newHeader";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { getPurchasedCoursesDetail, updateProgressApi } from "../../NetworkRequest/requests/profile";
import { USER_TYPE } from "../Constants";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { authenticated } from "../privateAuth/tokenExists";
import {useSelector} from 'react-redux';


const MyCourseDetails = (props) => {
  localStorage.removeItem("from");
  const {  userData } = useSelector((state) => state.loginSignPopUp);

  const history = useHistory();
  const userType = localStorage.getItem(USER_TYPE);
  const { courseId } = useParams();
  const [course, setCourse] = useState({ content: [] });
  const [content, setContent] = useState({});
  const [overview, setOverview] = useState("course");
  const [quiz, setQuiz] = useState(null);
  const [contentIndex, setContentIndex] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [rIndex, setRIndex] = useState("");
  const [contentProgress, setContentProgress] = useState([]);
  console.log('hello 1')
  useEffect(() => {
    console.log('hello 2')
    if (!authenticated()) {
      history.push({
        pathname: "/login",
        state: { from: history.location.pathname },
      });
    }

    if (courseId) {
      getPurchasedCoursesDetail(userType, courseId)
        .then((res) => {
          console.log("response", res.course);
          if (res.course.contentProgress) {
            setContentProgress(res.course.contentProgress);
          }
          setCourse(res.course.course);
          setContent(res.course.course.content[contentIndex]);
        })
        .catch((e) => {
          console.log("error occured", e);
        });
    } else {
      console.log("invalid course");
    }
  }, [userData]);

  useEffect(() => {
    if (course.content && course.content.length > 0) {
      setContent(course.content[contentIndex]);
    }
  }, [contentIndex]);

  const progressCalculator = () => {
    const contentLength = course.content.length;
    let compeletedContent = 0;
    for (let c of contentProgress) {
      if (c.progress === 100) {
        compeletedContent += 1;
      }
    }
    console.log("progress", compeletedContent, contentLength);
    return Math.floor((compeletedContent / contentLength) * 100);
  };

  const handleSkip = () => {
    setCurrentAnswer("");
    setRIndex("");
    const currentIndex = content.quiz.findIndex((x) => x.question === quiz.question);
    console.log("currentIndex is ", currentIndex);
    const quizLength = content.quiz.length;
    if (quizLength - 1 > currentIndex) {
      setQuiz(content.quiz[currentIndex + 1]);
    } else {
      // setQuiz(null);
      handleNext();
    }
  };

  const handleNext = () => {
    setCurrentAnswer("");
    setRIndex("");
    setQuiz(null);
    if (course.content.length - 1 > contentIndex) {
      setContentIndex(contentIndex + 1);
    }
  };

  const handleSubmitQuiz = () => {
    if (currentAnswer === "") {
      toast.info("select option for submit");
    } else {
      handleSkip();
    }
  };

  const updateProgress = async () => {
    await updateProgressApi({
      userType,
      courseId: course._id,
      contentId: content._id,
      progress: 100,
    });
    // progress: 100, _id: "60accaa303edd635b3be8da5", contentId: "60a755fdca8179566c8b4273"
    const found = contentProgress.find((x) => x.contentId === content._id);
    if (!found) {
      setContentProgress([
        ...contentProgress,
        {
          courseId: course._id,
          contentId: content._id,
          progress: 100,
        },
      ]);
    }
  };

  const extractFilename = (url) => {
    const urlParts = url.split("/");
    return urlParts.pop();
  };

  return (
    <div className="wrapper container-width">
      <Styles />
      <NewHeader props={props} />
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-8">
            {quiz ? (
              <div
                style={{
                  height: 515,
                  backgroundColor: "black",
                  width: "96.5%",
                  borderRadius: "10px",
                }}
              >
                <div className="px-lg-3 py-lg-5 p-3">
                  <div>
                    <p>{quiz.question}</p>
                    <div className="d-flex flex-wrap align-items-between popup-options-btn">
                      {quiz.options.map((x, i) => (
                        <div
                          key={`answer${i}`}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              currentAnswer !== "" &&
                              (rIndex === i || x.answerOptions === quiz.correctAnswer) &&
                              (x.answerOptions === quiz.correctAnswer ? "#00FA9A" : "#FFA07A"),
                          }}
                          onClick={(e) => {
                            setCurrentAnswer(x.answerOptions);
                            setRIndex(i);
                          }}
                        >
                          {x.answerOptions} <i class="far fa-check-circle"></i>
                        </div>
                      ))}
                    </div>

                    <div className="d-flex justify-content-end mt-3">
                      <button
                        className="btn btn-lg btn-secondary"
                        onClick={handleNext}
                        style={{ backgroundColor: "#4F4F4F", border: "none" }}
                      >
                        Skip <i class="fas fa-step-forward"></i>
                      </button>
                    </div>

                    <button
                      className="btn btn-lg btn-secondary w-100 mt-3"
                      onClick={handleSubmitQuiz}
                      style={{ backgroundColor: "#4F4F4F", border: "none" }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <ReactPlayer
                url={content.videoUrl}
                height={515}
                allowFullScreen
                playing
                controls
                style={{ backgroundColor: "black" }}
                onEnded={() => {
                  if (content.quiz && content.quiz.length > 0) {
                    setQuiz(content.quiz[0]);
                  }
                  updateProgress();
                  handleSkip()
                  console.log("video ended is called");
                }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                width="100%"
              />
            )}

            {/* <img src="/assets/images/youtube-thumbnail.png" className='img-thumbnail' alt="" /> */}
          </div>
          <div className="col-lg-4">
            <div className="card shadow-sm rounded h-100" style={{ overflowY: "auto" }}>
              <div className="rounded p-4 course-list-header">
                <div className="title">{course.courseName}</div>
                <div className="progress1 mt-2">
                  <p>Progress {progressCalculator()}%</p>
                  <div class="progress" style={{ height: 5 }}>
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style={{ width: `${progressCalculator()}%` }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>

              <div style={{ maxHeight: 400 }}>
                {course.content &&
                  course.content.map((x, i) => (
                    <div
                      className="d-flex px-3 py-2"
                      style={{
                        cursor: "pointer",
                        backgroundColor: contentIndex === i ? "rgba(171, 210, 214, 0.14)" : "",
                      }}
                      onClick={(e) => {
                        setContentIndex(i);
                      }}
                    >
                      <div className="thumbnail-background" style={{ minHeight: 45, minWidth: 45 }}>
                        <img src="/assets/images/file-icon.svg" alt="content" />
                      </div>

                      <div className="courses-content-list">
                        <div className="title">{x.videoTitle}</div>
                        <div className="description">
                          {contentIndex === i ? "" : contentProgress.find((y) => y.contentId === x._id) && "Completed"}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <section className="my-5">
          <ul className="list-unstyled d-flex">
            <li
              style={{
                color: overview === "course" ? "#007A87" : "",
                cursor: "pointer",
              }}
              onClick={(e) => setOverview("course")}
            >
              Course Overview
            </li>
            <li
              style={{
                color: overview !== "course" ? "#007A87" : "",
                cursor: "pointer",
              }}
              className="ml-4"
              onClick={(e) => setOverview("video")}
            >
              Video Details
            </li>
          </ul>
        </section>

        <div className="row ">
          <div className="col-lg-8">
            {/* <div style={{ backgroundColor: '#EDEDED', width: '100%', height: '2px' }} /> */}
            <div className="mb-5 mt-2">
              {overview === "course" ? (
                <div dangerouslySetInnerHTML={{ __html: course.aboutCourse }}></div>
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.videoDescription,
                    }}
                  ></div>
                  <div className="d-flex" style={{ marginLeft: -10 }}>
                    {content.attachments.map((attachment, i) => (
                      <div
                        className="rounded"
                        style={{
                          width: "150px",
                          margin: 10,
                          backgroundColor: "rgba(0, 122, 135, 0.15)",
                        }}
                      >
                        <div className="text-center p-3 ">
                          <div
                            style={{
                              backgroundColor: "rgba(0, 122, 135, 0.2",
                              padding: "10px 15px",
                              borderRadius: 5,
                              display: "inline-flex",
                            }}
                          >
                            <img src="/assets/images/attachment.svg" alt="" />
                          </div>
                          <p
                            style={{
                              fontSize: 14,
                              marginTop: 10,
                              marginBottom: 0,
                              color: "rgba(62, 62, 62, 1)",
                              overflow: "hidden",
                            }}
                          >
                            {extractFilename(attachment)}
                          </p>
                        </div>

                        <a
                          //   target="_blank"
                          href={attachment}
                          download={true}
                          className="btn btn-primary w-100 btn-sm"
                          style={{ fontSize: 12 }}
                        >
                          Download
                        </a>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCourseDetails;
