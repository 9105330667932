import "./App.css";
import React from "react";
import Routes from "./route";
import "react-toastify/dist/ReactToastify.css";
function App() {
    
    // const counter = useSelector(state => state);
    // const dispatch = useDispatch();
    // let history = useHistory();

    return (
      <div>
            {/* <Helmet>
        <title>
          Empower Women | Career Growth Plan | Career Guidance Coach
        </title>
        <meta
          name="description"
          content="Shevolve is a platform to empower women along their career path to grow.
          Our experts have the experience and knowledge to guide you to your dream career."
        />
      </Helmet> */}
            {/* <Styles/> */}
            {/* <UserContext.Provider value={[userData, setUserData]}> */}
                {/*<Styles/>*/}
                {/*<ToastContainer  closeOnClick={true}/>*/}
                <Routes/>
            {/* </UserContext.Provider> */}

        </div>
    );
}

export default App;
