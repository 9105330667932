import moment from "moment";
import React, { Component } from "react";
import { GetYTVideo } from "../../adminPanel/networkRequests/requests/event";

import NewHeader from "./static/newHeader";

class YTVideos extends Component {
  state = {
    selectedVideo: "",
    ytVideos: [],
    videoIds:[]
  };
  componentDidMount() {
    this._ytVideos();
  }
  _ytVideos = () => {
    GetYTVideo()
      .then((res) => {
        console.log(res);
        // if (res.statusCode === 200) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        let videoIds=res.map(v=>{
           var match = v.videoId.match(regExp);
            if (match && match[2].length == 11) {
           return match[2];
        }
        })
       

        this.setState({
          ytVideos: res,
          videoIds
        });
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    let { ytVideos, selectedVideo, videoIds } = this.state;
    let regex = "/^.*(youtu.be/|v/|u/w/|embed/|watch?v=|&v=)([^#&?]*).*/";
    // console.log(ytVideos.length>0?ytVideos[3].videoDuration.split(":"):"hello");
    return (
      <div className="wrapper container-width">
        {/* header wrap */}
        <NewHeader props={this.props} />
        {/* header close */}
        {/* banner-wrap */}
        <div
          className="banner-wrap blog-banner-wrap youtube-blog-banner"
          style={{ backgroundImage: 'url("/assets/images/play-video-bg.png")' }}
        >
          <div className="banner-overlay">
            <div className="container">
              <div className="banner-content" />
            </div>
          </div>
        </div>
        {/* banner sec close */}
        <div className="content pt-0">
          <div className="blog-tab-wrap">
            <div className="youtube-blog-wrap sec-pad pb-0">
              <div className="container">
                <div className="youtube-blog-content">
                  <div className="row">
                    {ytVideos.length > 0
                      ? ytVideos.map((v, i) => (
                          <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-lg-0 mb-3">
                            <div className="blog-content-wrap">
                              <figure className="image-wrap">
                                <img
                                  className="img-fluid"
                                  src={`https://img.youtube.com/vi/${videoIds[i]}/0.jpg`}
                                />
                                <div className="play-icon">
                                  <a
                                    className="play-icon-link video-popup"
                                    // href={v.videoId}
                                    onClick={() => window.open(v.videoId)}
                                  >
                                    <img
                                      className="img-fluid"
                                      src={"assets/images/youtube-icon.png"}
                                    />
                                  </a>
                                </div>
                              </figure>
                              <div className="blog-text-content">
                                <p className="sec-title text-black-color" style={{
                                    display: '-webkit-box',
                                    WebkitLineClamp:  1,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden'

                                }} >
                                  {v.title}
                                </p>
                                <p className="sec-content" style={{minHeight: 53,fontSize: 12}}>{v.description}</p>
                                <div className="meta-info flex-space">
                                  <div className="time">
                                    <span style={{fontSize: 10}}>
                                      {v.videoDuration &&
                                      v.videoDuration.split(":")[0] === "00"
                                        ? `${v.videoDuration.split(":")[1]}:${
                                            v.videoDuration.split(":")[2]
                                          }`
                                        : v.videoDuration}
                                    </span>
                                  </div>
                                  <div className="days" style={{fontSize: 12}}>
                                    {moment(v.created_on, "YYYYMMDD").fromNow()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    );
  }
}

export default YTVideos;
