import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class AddBlogs extends Component {
  // state = {  }
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      htmlCode: "",
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  //  handleEditorChange = (state) => {
  //   // setEditorState(state);
  //   convertContentToHTML();
  // }
  //  convertContentToHTML = () => {
  //   let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
  //   setConvertedContent(currentContentAsHTML);
  // }
 
  render() {
    const { editorState, htmlCode, contentState } = this.state;
    console.log(
      "editor",
      convertToHTML(editorState.getCurrentContent()),
      "contnet",
      contentState
    );
    return (
      <div>
        {/* <input></input> */}
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={this.onEditorStateChange}
        />
        <textarea
          //   disabled
          style={{
            width: "80%",
            height: 300,
            marginTop: 300,
            display: "flex",
            flexDirection: "column",
            marginBottom: 10,
          }}
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
         
        />
        <button className="btn btn-primary">Save</button>
      </div>
    );
  }
}

export default AddBlogs;
