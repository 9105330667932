import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { addCoachingSession, addMentoringSession } from "../../../NetworkRequest/requests/package";
import AddAvailabilityDialog from "../Modals/addAvailabilityDialog";
import { useDispatch } from "react-redux";
import { updatePackageInfo } from "../../../reducer/action";
import moment from "moment";
import {
  confirmCoachingSession,
  confirmMentoringSession,
  rescheduleCoachingSession,
  rescheduleMentoringSession,
  startSession,
} from "../../../NetworkRequest/requests/mentor";
import { USER_TYPE } from "../../Constants";
import { startSessionMentee } from "../../../NetworkRequest/requests/mentee";
import { useHistory } from 'react-router-dom'
import ExperienceSubmit from "../Modals/experienceSubmit";

const SessionCard = (props) => {
  const history = useHistory();
  const { index, type, packageId, status, date, mentor, _id, mentee, menteeRating } = props;
  const sessionType = type === 'session' ? 'MENTORING' : 'COACHING'
  const userType = localStorage.getItem(USER_TYPE);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const [openAvailablity, setOpenAvailability] = useState(false);
  const [reschedule, setReschedule] = useState(false);

  const handleSchedule = () => {
    setOpenAvailability(true);
  };

  const handleSessionJoin = async () => {
    try {
      const res = userType === 'mentee' ? await startSessionMentee({ sessionId: _id, sessionType }) :
        await startSession({ sessionId: _id, sessionType })

      if (res.customMessage || res.status === 400) {
        toast.success(res.customMessage || 'Error')
      } else {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
          .then(r => {
            history.push(`/session/${mentee}`, res)
          })
          .catch(e => {
            console.log(e)
            toast.success('Audio Video Not Found');
          })
      }
    } catch (e) {
      console.log(e)
    }

  }

  const updatePackage = () => {
    dispatch(updatePackageInfo());
  }


  const handleAddSession = async (date) => {
    try {
      if (type === "session") {
        const res = await addMentoringSession({
          packagePurchaseId: packageId,
          date: moment(date).format("DD/MM/YYYY HH:mm"),
          additionalMessage: "",
        });
        if (res.statusCode === 400) {
          toast(res.customMessage);
        } else {
          updatePackage();
          setOpenAvailability(false);
        }
      } else {
        const res = await addCoachingSession({
          packagePurchaseId: packageId,
          date: moment(date).format("DD/MM/YYYY HH:mm"),
          additionalMessage: "",
        });
        if (res.statusCode === 400) {
          toast(res.customMessage);
        } else {
          updatePackage();
          setOpenAvailability(false);
        }
      }
    } catch (e) {
      toast("failed");
    }
  };

  const handleRescheduleCoachingSession = async (date) => {
    try {
      type === "session"
        ? await rescheduleMentoringSession({ mentorId: mentor, mentoringSessionId: _id, date: moment(date).format("DD/MM/YYYY HH:mm") })
        : await rescheduleCoachingSession({ mentorId: mentor, mentoringSessionId: _id, date: moment(date).format("DD/MM/YYYY HH:mm") });
      setOpenAvailability(false);
      dispatch(updatePackageInfo());
      setReschedule(false);
    } catch (e) {
      toast("error");
      setReschedule(false);
    }
  };

  const handleConfirm = async () => {
    try {
      type === "session"
        ? await confirmMentoringSession({ mentorId: mentor, mentoringSessionId: _id, date: moment(date).format("DD/MM/YYYY HH:mm") })
        : await confirmCoachingSession({ mentorId: mentor, mentoringSessionId: _id, date: moment(date).format("DD/MM/YYYY HH:mm") });
      dispatch(updatePackageInfo());
      toast.success('Confirmed')
    } catch (e) {
      toast("error");
    }
  };

  const handleReschedule = () => {
    setReschedule(true);
    setOpenAvailability(true);
  };

  const handleSubmitRating = () => {
    setOpen(true);
  }

  return (
    <div
      className="blog-content-wrap"
      style={{ cursor: "pointer" }}
    >
      {open && <ExperienceSubmit open={true} reload={e => updatePackage()} sessionId={_id} sessionType={sessionType} onClose={e => setOpen(false)} />}
      <AddAvailabilityDialog
        open={openAvailablity}
        type="single"
        title="Request session"
        onDate={reschedule ? handleRescheduleCoachingSession : handleAddSession}
        onClose={(e) => setOpenAvailability(false)}
      />
      <figure className="image-wrap">
        <img className="img-fluid" src="/assets/images/mentoring.png" style={{ objectFit: "cover", maxHeight: 200 }} />
      </figure>
      <div className="blog-text-content">
        <p
          className="sec-title text-black-color"
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {type === 'session' ? 'Mentoring' : 'Coaching'} Session {index}
        </p>

        {(status === "APPROVED_ADMIN" || status === "APPROVED_MENTEE") && (
          <>
            <p className="f-size-12 text-black-color mb-2">
              <b>{moment.utc(date).format("DD MMMM YYYY ")}</b> &#9679; <b>{moment.utc(date).format("h:mm")}</b>
            </p>
            <p
              className="sec-content mt-1 mb-2"
              style={{
                color: "#525252",
                fontSize: 12,
              }}
            >
              {status === "APPROVED_MENTEE" ? "todo" : "Confirm or Reschedule your mentoring session"}
            </p>
          </>
        )}
        {status === "ONGOING" && (
          <>
            <p className="f-size-12 text-black-color mb-2">
              <b>{moment.utc(date).format("DD MMMM YYYY ")}</b> &#9679; <b>{moment.utc(date).format("h:mm")}</b>
            </p>
            <p
              className="sec-content mt-1 mb-2"
              style={{
                color: "#525252",
                fontSize: 12,
              }}
            >
              {status === "ONGOING" && "Session is onGoing"}
            </p>
          </>
        )}

        {(!status || status === "REQUESTED") && (
          <p
            className="sec-content"
            style={{
              color: "#525252",
              fontSize: 12,
            }}
          >
            Schedule your self a session and get answers to all your queries
          </p>
        )}

        {status ? (
          <>
            {status === "APPROVED_ADMIN" && (
              <div className="meta-info d-flex justify-content-between">
                <button className="btn btn-gradient-primary" style={{ width: "48%" }} onClick={handleReschedule}>
                  <i class="fas fa-undo mr-1"></i>Reschedule
                </button>

                <button className="btn btn-gradient-primary" style={{ width: "48%" }} onClick={handleConfirm}>
                  <i class="fas fa-check mr-1"></i> Confirm
                </button>
              </div>
            )}

            {status === "REQUESTED" && (
              <div className="meta-info ">
                <button className="btn btn-gradient-primary w-100">Requested</button>
              </div>
            )}
          </>
        ) : (
          <div className="meta-info ">
            <button className="btn btn-gradient-primary w-100" onClick={handleSchedule}>
              Schedule
            </button>
          </div>
        )}

        {/* {status === "APPROVED_MENTEE" && (
          <div className="meta-info ">
            <button className="btn btn-gradient-primary w-100" onClick={handleSessionJoin}>join</button>
          </div>
        )} */}

        {(status === "APPROVED_MENTEE" || status === "ONGOING") && (
          <div className="meta-info ">
            <button className="btn btn-gradient-primary w-100" onClick={handleSessionJoin}>{status === "ONGOING" ? 'Join' : 'Start Session'} </button>
          </div>
        )}

        {status === "COMPLETED" && (
          <>

            <p
              className="sec-content mt-1 mb-2"
              style={{
                color: "#525252",
                fontSize: 12,
                height: 42.2
              }}
            >
              Session Completed Successfully
            </p>
            <div className="meta-info ">
              {menteeRating === 0 ?
                <button className="btn btn-gradient-primary w-100" onClick={handleSubmitRating}>Submit Rating</button> :
                <button className='btn btn-outline-info w-100 ' style={{paddingBlock:7}} >

                  <div className="d-flex justify-content-center align-items-center">
                    <span className="mr-3">You Rated</span>
                    {[...new Array(5)].map((star, i) => (
                      menteeRating > i ? (<img key={`img${i}`} src="./assets/images/star-active.jpg" alt="star" />) : (<img key={`img${i}`} src="./assets/images/star-inactive.jpg" alt="star" />)
                    ))}
                  </div>
                </button>

              }
            </div>
          </>

        )}
      </div>
    </div>
  );
};

export default SessionCard;
