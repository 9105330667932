// import { CNav } from '@coreui/react';
import React, { useRef, useLayoutEffect, useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { submitContactUs } from '../../../adminPanel/networkRequests/requests/subscription';
import Layout from "../../layout/default";
import {Helmet} from "react-helmet";
import {AnimWrap} from "../animation-gsap/Animation"

export default function Offering() {
    const secondSection = useRef(null);
    const modelClose = useRef(null);
    const [ip, setIp] = useState([]);
    const [form, setForm] = useState({name: '', company_name: '', email: '', phone: '',  from_page: 'Offering Page' });

    console.log("form", form)

    const [formError, setFormError] = useState({
        // name: false,
        // email: false,
        // emailVal: false,
        // phone: false
    })

    const scrollHandler = () => {
        secondSection.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        setTimeout(
            () => setIp('res.data'), 
            100
          );
        
    }, []);

    const handleInputChange = e => {
        const { name, value } = e.target;
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const formValidate = () => {

        var re = /\S+@\S+\.\S+/;
        const phone_regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        setFormError({name: false, phone: false, email: false})

        if(form.name == "" || form.phone == "" || !phone_regex.test(form.phone) || form.email == "" || !re.test(form.email)){
            if(form.name == "") { 
                setFormError(prevState => ({
                    ...prevState,
                    name: true
                }))
            }
            if(form.phone == "" || !phone_regex.test(form.phone)) {
                setFormError(prevState => ({
                    ...prevState,
                    phone: true
                }))
            }
            if(form.email == "" || !re.test(form.email)) {
                setFormError(prevState => ({
                    ...prevState,
                    email: true
                }))
            }
            return false
        } else {            
            return true
        }

    }


    const onSubmiting = () => {
        
        if(formValidate()) {
            submitContactUs(form).then(res => {
                console.log(res);
                toast.success('Our team will reach you shortly');
                setForm({name: '', company_name: '', email: '', phone: '', from_page: 'Offering Page'});
                modelClose.current.click();
            })
            .catch((err) => {
                toast.error('Something went wrong!');
            });
        }
    }



    return (
        <Layout>
            
            <Helmet>
                    <title>Corporate Subscription Programs | Shevolve's Corporate Career Guidance</title>
                    <meta name="description" content="Elevate your company's workforce with Shevolve's corporate career guidance program based on our patented career evaluation index and each offering is tailored to fostering women's career advancement and leadership skills." />
                    <meta name="keywords" content="Corporate subscription, Career guidance program, Women empowerment, Leadership development, Workforce diversity, Women leadership, Mentorship programs, Women entrepreneurs, Workplace empowerment, Professional development, Career advancement, Corporate mentorship, Women in business, Leadership skills, Workforce empowerment, Workplace diversity, Women's career growth, Employee development, Career mentorship, Corporate training programs" />

                    <meta property="og:title" content="Corporate Subscription Programs | Shevolve's Corporate Career Guidance" />
<meta property="og:description" content="Elevate your company's workforce with Shevolve's corporate career guidance program based on our patented career evaluation index and each offering is tailored to fostering women's career advancement and leadership skills" />
<meta property="og:image" content="https://shevolve.in/images/home/slide/slide1.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Corporate Subscription Programs | Shevolve's Corporate Career Guidance" />
<meta name="twitter:description" content="Elevate your company's workforce with Shevolve's corporate career guidance program based on our patented career evaluation index and each offering is tailored to fostering women's career advancement and leadership skills" />
<meta name="twitter:image" content="https://shevolve.in/images/home/slide/slide1.png"/>

                </Helmet>

            <AnimWrap />

            <div className="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document" style={{top: "100px"}}>
                    <div className="modal-content">
                        <button type="button" className="close sh-modal__btn-close" data-dismiss="modal" aria-label="Close" ref={modelClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body sh-offering__modal-body">
                            <div className="sh-title">Reach out to us !</div>
                                <div className="row">
                                    <div className="sh-offering__modal-input col-md-6 mb-4">
                                        <input className="w-100" type="text" name="name" placeholder='Your Name*' value={form.name} onChange={handleInputChange}/>
                                        {formError.name === true &&
                                            <span className="sh-entry__error-text mt-2">Please enter required filled</span>
                                        }
                                    </div>
                                    <div className="sh-offering__modal-input col-md-6 mb-4">
                                        <input className="w-100" type="text" name="company_name" id="" placeholder='Company Name' value={form.company_name} onChange={handleInputChange} />
                                    </div>
                                    <div className="sh-offering__modal-input col-md-6 mb-4">
                                        <input className="w-100" type="email" name="email" id="" placeholder='Work Email*' value={form.email} onChange={handleInputChange} />
                                        {console.log("errorEmailPara", formError.email)}
                                        {formError.email === true &&
                                            <span className="sh-entry__error-text mt-2">Please enter a valid email address</span>
                                        }
                                    </div>
                                    <div className="sh-offering__modal-input col-md-6 mb-4">
                                        <input className="w-100" type="tel" name="phone" id="" placeholder='Phone*' value={form.phone} onChange={handleInputChange} />
                                        {formError.phone === true &&
                                            <span className="sh-entry__error-text mt-2">Please enter a valid Phone number</span>
                                        }
                                    </div>
                                </div>
                                <div className="text-right pt-3">
                                    <button className="sh-button" onClick={onSubmiting}>Submit</button>
                                </div>
                            <div className="sh-offering__modal-image">
                                <img src="./images/offering/offering-form-butterfly.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*=========================================
            ===================== Banner ================
            ============================================ */}
            <section className="sh-section sh-section__banner pb-0">
                <div className="sh-section__inwrap">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="sh-banner__content">
                            <h1 className="sh-banner__title sh-anim__stagger-fade-top">
                            Empowering Corporate Growth: Shevolve's Subscription Programs 
                                {/* <div className="sh-banner__title-medium">that builds a community </div> */}
                            </h1>
                            <div className="sh-para sh-banner__para sh-banner__para--grey sh-anim__stagger-fade-top">Corporate Programs for Students, Women after Career Break, Mid-Level Women Leaders and Women Entrepreneurs</div>
                            <a className="sh-button mt-3 sh-anim__stagger-fade-top" onClick={scrollHandler}>Read More</a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="text-center sh-anim__stagger-fade-top">
                        <img className="img-fluid" src="./images/offering/banner-image.svg" alt="Campus to Corporate - Corporate Programs for Students, Women after Career Break, Mid-Level Women Leaders and Women Entrepreneurs | Shevolve" />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="position-absolute sh-section__curved-bottom w-100"><img className="img-fluid w-100" src="./images/home/curve-divider.png" /></div>
            </section>

            {/*=========================================
            ===================== Lorem Ipsum dollar amet ================
            ============================================ */}
            <section ref={secondSection} className="sh-section pb-0">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="sh-content__wrap sh-section__title-wrap">
                                    {/* <div className="sh-subtitle">What is HerMentor</div> */}
                                    <div className="sh-title sh-title--light sh-anim__stagger-fade-top"> Our <b>Offerings</b></div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="sh-text">
                                    <p className="mb-3 sh-anim__stagger-fade-top">Shevolve’s Corporate Programs focus on increasing the women workforce 
                                        through dedicated mentorship programs, cohorts and initiatives that promote diversity, 
                                        women leadership and entrepreneurship.
                                    </p>
                                    <p className="sh-anim__stagger-fade-top">Starting from final year students and reaching out to women in Tier II and Rural Areas, 
                                        Shevolve programs are aimed at levelling the playing field for women who are at a 
                                        disadvantage in the society, creating major impact for communities and economies and 
                                        driving towards positive, sustainable global development. Lean in & take the lead on a 
                                        rising movement!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*=========================================
            ===================== Offerings ================
            ============================================ */}
            <section className="sh-section sh-offering__section">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="text-center sh-section__title-wrap">
                            <div className="sh-title sh-title__underline sh-anim__stagger-fade-top">To Lift as We Rise!</div>
                            <p className="sh-para sh-anim__stagger-fade-top">Our mentorship programs are streamlined to a minimum of ten women and a maximum of hundred women employees focusing on 1:1 interactions, leadership development, and capability tracker.</p>
                        </div>

                        <div className="row align-items-center sh-offering__row-styled sh-anim__stagger-fade-top">
                            <div className="col-md-6 order-2 order-md-1">
                                <div className="sh-offering__content">
                                    <h2 className="sh-offering__title sh-title sh-anim__stagger-fade-top">Campus to Corporate</h2>
                                    <p className="sh-offering__para sh-text sh-anim__stagger-fade-top">For the first time in India, Shevolve’s Copyright Workplace 
                                        Readiness Certification Program for final year students focusing on transferable skills and industry
                                        knowledge during their transition from student to an employee.
                                     </p>
                                    <a className="sh-button sh-anim__stagger-fade-top" data-toggle="modal" data-target="#exampleModal">Talk to us</a>
                                </div>
                            </div>
                            <div className="col-md-6 order-1 order-md-2">
                                <div className="sh-offering__image sh-anim__stagger-fade">
                                    <img className="img-fluid position-relative" src="./images/offering/phoenix.svg" alt="Shevolve’s Certification Program for final year students focusing on transferable skills and industry knowledge during their transition from student to an employee | Shevolve" />
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center sh-offering__row sh-anim__stagger-fade-top">
                            <div className="col-md-6">
                                <div className="sh-offering__image sh-anim__stagger-fade">
                                    <img className="img-fluid position-relative" src="./images/offering/retrieve.svg" alt="Fly High - This program provides working women with tools and opportunities to Grow, Navigate and Reach their highest potential through Mentoring, Personalized handouts and Networking opportunities. | Shevolve" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="sh-offering__content">
                                    <h2 className="sh-offering__title sh-title sh-anim__stagger-fade-top">Fly High</h2>
                                    <p className="sh-offering__para sh-text">This program provides working women with tools and opportunities to Grow, Navigate and Reach their highest potential through Mentoring, Personalized handouts and Networking opportunities. It enhances their leadership skills, and also enables critical thinking during projects that support individual development.
                                    </p>
                                    <a className="sh-button" data-toggle="modal" data-target="#exampleModal">Talk to us</a>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center sh-offering__row-styled">
                            <div className="col-md-6 order-2 order-md-1">
                                <div className="sh-offering__content">
                                    <h2 className="sh-offering__title sh-title sh-anim__stagger-fade-top">MindMe Program</h2>
                                    <p className="sh-offering__para sh-text sh-anim__stagger-fade-top"><div className='font-weight-bold'></div> 
                                    It is a unique mentorship immersion program in transforming potential leaders from organizations to be mindful mentors through a structural framework of sessions, evaluation and personalized activities. Our program consists of mindfulness workshops, training mentor sessions, and a pre- and post- evaluation index to document the outcome of the program.
                                     </p>
                                    <a className="sh-button sh-anim__stagger-fade-top" data-toggle="modal" data-target="#exampleModal">Talk to us</a>
                                </div>
                            </div>
                            <div className="col-md-6 order-1 order-md-2">
                                <div className="sh-offering__image sh-anim__stagger-fade-top">
                                    <img className="img-fluid position-relative" src="./images/offering/triple-e-program.svg" alt="MindMe Program - Mentorship immersion program in transforming potential leaders from organizations to be mindful mentors through a structural framework of sessions, evaluation and personalized activities | Shevolve" />
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center sh-offering__row">
                            <div className="col-md-6">
                                <div className="sh-offering__image sh-anim__stagger-fade-top">
                                    <img className="img-fluid position-relative w-90" src="./images/offering/public-speaking.svg" alt="Public Speaking Decoded - This program provides you an opportunity to evolve into a confident public speaker by learning via workshops, 1:1 mentoring, personalised handouts, curated activities, and practice sessions | Shevolve" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="sh-offering__content">
                                    <h2 className="sh-offering__title sh-title sh-anim__stagger-fade-top">PUBLIC SPEAKING DECODED</h2>
                                    <p className="sh-offering__para sh-text sh-anim__stagger-fade-top">This program provides you an opportunity to evolve into a confident public speaker by learning via workshops, 1:1 mentoring, personalised handouts, curated activities, and practice sessions. Expert Facilitator led workshops to learn tools, techniques and frameworks to hone your public speaking skills.</p>
                                    <a className="sh-button sh-anim__stagger-fade-top" data-toggle="modal" data-target="#exampleModal">Talk to us</a>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center sh-offering__row-styled">
                            <div className="col-md-6 order-2 order-md-1">
                                <div className="sh-offering__content">
                                    <h2 className="sh-offering__title sh-title sh-anim__stagger-fade-top">Grow the Leader</h2>
                                    <p className="sh-offering__para sh-text sh-anim__stagger-fade-top">It is a 2-day bootcamp program that helps to sharpen the leadership skills for Senior Women who have been identified to move into senior roles and make a larger impact to their organization. Along with the workshop you will be mentored by industry leaders.
                                    </p>
                                    <a className="sh-button sh-anim__stagger-fade-top" data-toggle="modal" data-target="#exampleModal">Talk to us</a>
                                </div>
                            </div>
                            <div className="col-md-6 order-1 order-md-2">
                                <div className="sh-offering__image sh-anim__stagger-fade-top">
                                    <img className="img-fluid position-relative w-90" src="./images/offering/grow-the-leader.svg" alt="Grow the Leader - It is a 2-day bootcamp program that helps to sharpen the leadership skills for Senior Women who have been identified to move into senior roles and make a larger impact to their organization | Shevolve"  />
                                </div>
                            </div>
                        </div>

                        {/* <div className="row align-items-center sh-offering__row">
                            <div className="col-md-6">
                                <div className="sh-offering__image sh-anim__stagger-fade-top">
                                    <img className="img-fluid position-relative" src="./images/offering/rise-rural-women.svg" alt="SAF" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="sh-offering__content">
                                    <h2 className="sh-offering__title sh-title sh-anim__stagger-fade-top">Rise Rural Women</h2>
                                    <p className="sh-offering__para sh-text sh-anim__stagger-fade-top">The RRW program fosters and opens new doors for women 
                                    entrepreneurs from Tier two &amp; three cities. Focusing on women SMEs, corporates and individuals sponsor our mentorship program 
                                    for women carefully selected with successful revenue models and innovative ideas to expand and build their business.</p>
                                    <a className="sh-button sh-anim__stagger-fade-top" data-toggle="modal" data-target="#exampleModal">Talk to us</a>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>

            {/*=========================================
            ===================== Whatsapp CTA ================
            ============================================ */}
            <section className="sh-section pb-0 pt-0">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="sh-whatsapp__cta-section sh-anim__stagger-fade-left" style={{ backgroundImage: "url(./images/whatsapp-cta/whatsapp-background.png)" }}>
                            <div className="row sh-whatsapp__cta-row align-items-lg-center align-items-end">
                                <div className="col-md-4 sh-whatsapp__cta-left order-2 order-md-1">
                                    <div className="text-center">
                                        <img className="img-fluid" src="./images/whatsapp-cta/whatsapp.svg" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-8 sh-whatsapp__cta-right order-1 order-md-2">
                                    <div className="text-center py-5">
                                        <div className="sh-whatsapp__cta-content">
                                            <div className="sh-whatsapp__cta-title mb-1">Want to have a word with us ?</div>
                                            <div className="sh-whatsapp__cta-subtitle">Chat with us on <span className="sh-whatsapp__cta-subtitle--whatsapp">Whatsapp</span></div>
                                        </div>
                                        <a className="sh-whatsapp__cta-button--whatsapp d-inline-flex align-items-center" href="https://wa.me/919841003516">
                                            <img style={{ height: 30 }} src="./images/program/whatsapp.png" alt="whatsapp" />
                                            <span className="ml-2">Chat with us</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <div ref={secondSection}></div> */}
        </Layout>
    )
}