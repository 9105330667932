import React, { Component } from "react";
import Styles from "../../styles";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

class WhoIsSigning extends Component {
  state = {
    userType: ""
  };
  handleNextChange = () => {
    localStorage.setItem("userType", this.state.userType)
    this.props.history.push("/newSignUp");
  }
  render() {
    return (
      <div>
             <Helmet>
                    <title>Join Shevolve's Global Mentorship Network and Empower Women Worldwide</title>
                    <meta name="description" content="Signup with Shevolve's global mentorship network, empowering women to achieve their career aspirations with resilience and motivation. Join us in fostering leadership and workforce participation worldwide!" />
                    <meta name="keywords" content="Mentorship, women empowerment, global network, career goals, leadership development, workforce participation, perseverance, resilience, motivation, Shevolve" />
                    
                    <meta property="og:title" content="Join Shevolve's Global Mentorship Network and Empower Women Worldwide" />
<meta property="og:description" content="Signup with Shevolve's global mentorship network, empowering women to achieve their career aspirations with resilience and motivation. Join us in fostering leadership and workforce participation worldwide!"/>
<meta property="og:image" content="https://shevolve.in/images/home/slide/slide1.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Join Shevolve's Global Mentorship Network and Empower Women Worldwide" />
<meta name="twitter:description" content="Signup with Shevolve's global mentorship network, empowering women to achieve their career aspirations with resilience and motivation. Join us in fostering leadership and workforce participation worldwide!" />
<meta name="twitter:image" content="https://shevolve.in/images/home/slide/slide1.png" />


                </Helmet>

        <Styles />
        <div className="wrapper">
          {/*--- signup page wrap ----*/}
          <section className="login-page signup-wrap">
            <div className="login-bg">
              <figure className="login-image mb-0 w-100">
                <img
                  className="img-fluid"
                  src="/images/entry/entry-image.png"
                  alt="login image"
                  style={{ objectFit: 'cover', height: '100vh' }}
                />
              </figure>
            </div>
            <div className="login-form pb-0">
              <form>
                <div className="form-content">
                  <div className="login-form-content mb-0">
                    <Link to={"/"}>
                      <div className="logo-img-bottom mb-4">
                        <img
                          src="/images/logo.svg"
                          width={120}
                          alt="header-logo"
                        />
                      </div>
                    </Link>

                    <div className="sec-title flex-content m-b-md">
                      <h1 className="title sh-para font-weight-bold">
                      Signup | Ready for a Career Makeover with Shevolve?
                      </h1>
                    </div>
                    <div className="sec-content">
                      <div className="row">
                        <div className="col-12 col-md-9">
                          <p className="">
                            Why hire a taxi when you can launch your own rocket? Why compromise
                            when you can follow your passion and excel ?
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="signup-wrap-content">
                      <div className="row">
                        <div className="col-12 col-sm-6 mb-5 mb-md-0">
                          <div
                            className={
                              this.state.userType === "mentee"
                                ? "card sh-entry__card-wrap active"
                                : "card sh-entry__card-wrap"
                            }
                            onClick={() =>
                              this.setState({ userType: "mentee" })
                            }
                          >
                            <div className="card-image">
                              <img
                                className="card-img-top"
                                src="./images/entry/want-to-be-mentee.png"
                                alt="Card image cap"
                                width={10}
                                height={10}
                              />
                            </div>
                            <div className="card-body">
                              <h6 className="card-text-title sh-text font-weight-bold">
                                I want to be a mentee
                              </h6>
                              <p className="card-text-content" style={{ fontSize: 14 }}>
                                I need guidance from experts with access to premium content. I’d love to have 1 on 1 conversation with experienced industry mentors.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div
                            className={
                              this.state.userType === "mentor"
                                ? "card sh-entry__card-wrap active"
                                : "card sh-entry__card-wrap"
                            }
                            onClick={() =>
                              this.setState({ userType: "mentor" })
                            }
                          >
                            <div className="card-image">
                              <img
                                className="card-img-top"
                                src="./images/entry/want-to-be-mentor.png"
                                alt="Card image cap"
                                width={10}
                                height={10}
                              />
                            </div>
                            <div className="card-body">
                              <h6 className="card-text-title sh-text font-weight-bold">
                                I want to be a mentor
                              </h6>
                              <p className="card-text-content" style={{ fontSize: 14 }}>
                                I want to help women grow in life and achieve their goals. I am uplifted when I add value to the lives and careers of others.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4 mb-4">
                      <div className="col-12 col-sm-12">
                        <div className="btn-wrap">
                          <a
                            href="javascript:void(0);"
                            className="sh-button sh-button__plain-arrow mr-5"
                            onClick={() => this.props.history.push("/")}
                          >
                            {/*<i className="fas fa-arrow-left mr-3"/>*/}
                            Home
                          </a>
                          <button
                            href="javascript:void(0);"
                            className={`sh-button ${this.state.userType === "" && "sh-button__disabled"}`}
                            onClick={this.handleNextChange}
                            disabled={this.state.userType === ""}
                          >
                            Next
                          </button>
                        </div>
                        {/* <div className="sec-content text-right mt-3">
                          <p>
                            Already have an account ?
                            <Link
                              to={{ pathname: "/login", from: "signup" }}
                              style={{ fontSize: 18, color: "#007a87", paddingLeft: 6 }}
                            >
                              Login
                            </Link>
                          </p>
                        </div> */}
                      </div>
                    </div>



                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default WhoIsSigning;
