import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Styles from "../../styles";
import { FaGooglePlusG } from "react-icons/fa";
import { ImLinkedin2 } from "react-icons/im";
import { login, mentorLogin, guestLogin, getLinkedInAccessToken } from "../../NetworkRequest/requests/login";
import { USER_TYPE } from "../Constants";
import { testGuestLoginUrl } from "../Constants";
import { LinkedIn } from "react-linkedin-login-oauth2";
import GoogleLogin from "react-google-login";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { LOGIN } from "../../reducer/action";
import { Modal } from "react-responsive-modal";
import {Helmet} from "react-helmet";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "", loginAs: "", loading: false };
    this.hosturl = `${window.location.origin}/linkedin`;
    console.log("this.host", this.hosturl);
  }

  location = this.props.history.location;

  async getFrom() {
    return await localStorage.getItem("from");
  }

  onLoginSuccess = (res) => {
    localStorage.setItem(USER_TYPE, "mentee");
    localStorage.setItem("userData", JSON.stringify(res.data));
    localStorage.setItem("accessToken", res.accessToken);
    this.props.dispatch({
      type: LOGIN,
      payload: res.data,
      accessToken: res.accessToken,
      userType: "mentee",
    });
    this.getFrom().then((rou) => {
   
      if (rou) {
        // if (res.data.isFirstTimeLogin) {
        //   this.props.history.push("/survey");
        // } else {
          this.props.history.push(rou);
        // }
      } else {
        // if (res.data.isFirstTimeLogin) {
        //   this.props.history.push("/survey");
        // } else {
          this.props.history.push("/");
        // }
      }
    });
  };

  responseGoogle = (response) => {
    this.setState({ loading: true });
    guestLogin(response.profileObj.email, response.profileObj.name, response.profileObj.googleId, "GOOGLE")
      .then((res) => {
        this.setState({ loading: false });
        this.onLoginSuccess(res);
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log("google login err", err);
      });
  };

  componentDidMount() {
    if (this.location.state) {
      localStorage.setItem("from", this.location.state.from);
    }
  }

  _login = (e) => {
    e.preventDefault();
    console.log("props totle", this.props);

    let { email, password, loginAs } = this.state;
    localStorage.setItem(USER_TYPE, loginAs);
    if (email === "") {
      toast.error("Please enter your email ID");
      return;
    } else if (password === "") {
      toast.error("Please enter your password");
      return;
    }

    // let {pathname} = this.props.location
    if (loginAs === "mentee") {
      this.setState({ loading: true });
      localStorage.setItem(USER_TYPE, 'mentee');
      login(email, password)
        .then((res) => {
          this.setState({ loading: false });
          if (res.statusCode === 200) {
            if (res.data.isVerified) {
              this.onLoginSuccess(res);
            } else {
              return toast.info("Your email has not been verified yet");
            }
            return toast.info(res.customMessage);
          }
          this.setState({ loading: false });
          return toast.error(res.customMessage);
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error("login failed");
          console.log(err);
        });
    } else if (loginAs === "mentor") {
      this.setState({ loading: true });
      localStorage.setItem(USER_TYPE, 'mentor');
      mentorLogin(email, password).then((res) => {
        if (res.statusCode === 200) {
          if (res.data.isVerified) {
            
            localStorage.setItem("userData", JSON.stringify(res.data));
            localStorage.setItem("accessToken", res.accessToken);
            this.setState({ loading: false });
            this.props.dispatch({
              type: LOGIN,
              payload: res.data,
              accessToken: res.accessToken,
              userType: loginAs,
            });
            if (this.location.state) {
              this.props.history.push(this.location.state.from);
            } else {
              console.log("localstorage token is called");
              this.getFrom().then((res) => {
                if (res) {
                  console.log("previous path from localstorage is", res);
                  this.props.history.push(res);
                } else {
                  this.props.history.push("/");
                }
              });
            }
          } else {
            return toast.info("Email not Verified ");
          }
          return toast.info(res.customMessage);
        }
        this.setState({ loading: false });
        return toast.error(res.customMessage);
      });
    } else {
      toast.error("Select Login As");
    }
  };

  handleEmailChange = (e) => {
    e.preventDefault();
    let email = e.target.value;
    this.setState({ email });
    console.log(email);
  };
  handlePasswordChange = (e) => {
    e.preventDefault();
    let password = e.target.value;
    this.setState({ password });
    console.log(password);
  };

  handleSuccess = (response) => {
    console.log("success", response);
    this.setState({ loading: true });
    getLinkedInAccessToken(response.code, this.hosturl, "78w0f1y4y16908", "Wtm7zwsSuQD08Cpz")
      .then((res) => {
        console.log("linkedin response", res);
        if (res.statusCode === 200) {
          this.setState({ loading: false });
          this.onLoginSuccess(res);
        } else {
          this.setState({ loading: false });
          return toast.info("Invalid Linkedin ID");
        }
      })

      .catch((err) => {
        this.setState({ loading: false });
        return toast.info("Invalid Linkedin ID");
      });
  };

  handleFailure = (error) => {
    alert("error");
    console.log("linkedin error", error.errorMessage);
  };

  render() {
    console.log("props", this.props.location.pathname, window.screen.width);
    return (
      <section className="wrapper">
        <Helmet>
            <title>Shevolve Login  - Empowering Women Worldwide</title>
            <meta name="description" content="Sign in to Shevolve's global network of mentors dedicated to empowering women, fostering leadership, and guiding career journeys with perseverance, resilience, and motivation." />
            <meta name="keywords" content="Women empowerment, mentorship program, career development, leadership, workforce participation, resilience, motivation, global collaboration, Shevolve" />

            <meta property="og:title" content="Shevolve Login  - Empowering Women Worldwide" />
<meta property="og:description" content="Sign in to Shevolve's global network of mentors dedicated to empowering women, fostering leadership, and guiding career journeys with perseverance, resilience, and motivation." />
<meta property="og:image" content="https://shevolve.in/images/home/slide/slide1.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Shevolve Login  - Empowering Women Worldwide" />
<meta name="twitter:description" content="Sign in to Shevolve's global network of mentors dedicated to empowering women, fostering leadership, and guiding career journeys with perseverance, resilience, and motivation." />
<meta name="twitter:image" content="https://shevolve.in/images/home/slide/slide1.png" />


        </Helmet>
        <Styles />
        <Modal
          open={this.state.loading}
          onClose={(e) => {}}
          styles={{ modalContainer: { background: "transparent", boxShadow: "none", padding: 12 } }}
          showCloseIcon={false}
          center
        >
          {" "}
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            // timeout={3000} //3 secs
          />
        </Modal>
        <section className="login-page signup-wrap">
          <div className="login-bg">
            <figure className="login-image mb-0 w-100">
              <img
                className="img-fluid"
                src={
                  this.props.location.pathname === "/login" ? "/images/entry/entry-image.png" : "/assets/images/Singup Image mentor.png"
                }
                alt="login"
                style={{ height: "100vh", objectFit: "cover" }}
              />
            </figure>
          </div>
          <div className="login-form">
            <form>
              <div className="form-content">
                <div className="login-form-content mb-3">
                  <Link to="/">
                    <div className="logo-img-bottom mb-4">
                      <img src="/images/logo.svg" width={120} alt="header-logo" />
                    </div>
                  </Link>
                  <div className="sec-title flex-content m-b-md">
                    <img className="mr-3" src="/assets/images/butterfly.svg" alt="banner" />
                    {/*<h5 className="sec-title mb-0">Hey, Welcome back!</h5>*/}
                    <h1 className="sec-title sh-para font-weight-bold">Login to Shevolve</h1>
                  </div>
                  <div className="sec-content">
                    <p>
                      Welcome back to Shevolve! <br />
                      Sign in to open your personal account and evolve your career as you wish to.
                    </p>
                  </div>

                  <div className="form-group">
                    <label className="fz-12">Email</label>
                    <input
                      className="form-control"
                      // placeholder="Lokeshnpl3213@gmail.com"
                      type="text"
                      onChange={this.handleEmailChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      className="form-control"
                      //  placeholder="28HJG88293"
                      type="password"
                      onChange={this.handlePasswordChange}
                    />
                  </div>
                  <div className="form-group select-group">
                    <label>Login as</label>
                    <select
                      className="form-control"
                      //  placeholder="Lokeshnpl3213@gmail.com"
                      type="text"
                      value={this.state.loginAs}
                      onChange={(e) => this.setState({ loginAs: e.target.value })}
                    >
                      <option value="">Choose</option>
                      <option value="mentor">Mentor</option>
                      <option value="mentee">Mentee</option>
                    </select>
                  </div>
                  <div className="btn-grp mt-4">
                    <button className="sh-button sh-button__no-radius btn-block" onClick={this._login}>
                      Login
                    </button>
                  </div>
                  <p style={{ fontSize: "14px", color: "#727272", textAlign: "center", margin: "20px 0px" }}>Or log In as guest using</p>
                  <div className="Social_btn" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <GoogleLogin
                      clientId="636947503335-kqiir94o0ua8t33mbohqrulpocrdo5qi.apps.googleusercontent.com"
                      buttonText="google"
                      render={(renderProps) => (
                        <button
                          className="google_btn"
                          style={{
                            outline: "none",
                            backgroundColor: "#0E76A8",
                            color: "#fff",
                            border: "none",
                            borderRadius: "6px",
                            Outline: "none",
                          }}
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <FaGooglePlusG style={{ fontSize: "23px", marginRight: " 5px", height: "38px" }} /> Google
                        </button>
                      )}
                      onSuccess={this.responseGoogle}
                      onFailure={(e) => console.log("google error is called", e)}
                      cookiePolicy={"single_host_origin"}
                      className="google_btn"
                    />
                    <LinkedIn
                      clientId="78w0f1y4y16908"
                      onFailure={this.handleFailure}
                      onSuccess={this.handleSuccess}
                      redirectUri={this.hosturl}
                      scope="r_emailaddress,r_liteprofile"
                      state="testingLinkedinShevolve"
                      renderElement={({ onClick, disabled }) => (
                        <button
                          style={{
                            outline: "none",
                            backgroundColor: "#0E76A8",
                            color: "#fff",
                            border: "none",
                            borderRadius: "6px",
                            Outline: "none",
                          }}
                          onClick={onClick}
                          disabled={disabled}
                        >
                          <ImLinkedin2 style={{ fontSize: "18px", marginRight: "3px", verticalAlign: "baseline" }} /> Linkedin
                        </button>
                      )}
                    />
                    {/* <button onClick={this.LinkedIn} style={{backgroundColor : "#0E76A8"  , color : "#fff" , border : "none" , borderRadius :"6px" , Outline:"none" }}><ImLinkedin2     style={{ fontSize:"23px" , marginRight :" 5px" , verticalAlign :"inherit"}}/>  Linked In</button> */}
                  </div>
                </div>
                {/* <div className="sec-content text-align-center">
                                    <p>Forget password ?  <Link to="/forget" style={{fontSize: 18, color: "#007a87", paddingLeft: 6}}> click here</Link></p>
                                </div> */}
                <div className="sec-content text-center mb-3">
                  <p>
                    Don’t have an account? Lets create one!
                    <Link to="/WhoIsSigning" style={{ fontSize: 18, color: "#007a87", paddingLeft: 6 }}>
                      {" "}
                      Signup
                    </Link>
                  </p>
                </div>

                <div className="sec-content text-align-center">
                  <p>
                    Forgot your password? No problem,{" "}
                    <Link to="/forget" style={{ fontSize: 18, color: "#007a87", paddingLeft: 6 }}>
                      {" "}
                      click here
                    </Link>{" "}
                    to reset it from your email ID.
                  </p>
                </div>
              </div>
            </form>
          </div>
        </section>
      </section>
    );
  }
}

// const mapDispatchToProps = (dispatch) => {
//     return {
//       // dispatching plain actions
//       login: (userData) => dispatch({ type: LOGIN, payload: userData}),
//       decrement: () => dispatch({ type: 'DECREMENT' }),
//       reset: () => dispatch({ type: 'RESET' }),
//     }
//   }

function mapStateToProps(state) {
  return { todoList: state.oginSignPopUp };
}

export default connect(mapStateToProps)(Login);
