import { useState, useEffect } from 'react'
import Modal from 'react-responsive-modal';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSubsctiption } from '../../../adminPanel/networkRequests/requests/subscription';
import { sendEBookToMail } from '../../../NetworkRequest/requests/freeUser';

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

const EbookForm = (props) => {
    const [email, setEmail] =useState('');
    const [openModel, setOpenModel] =useState(false);


    const handleEmailChange =(e) => {
        setEmail(e.target.value)
    }

    const onSubscription = () => {
        // if(validateEmail(email)) {
        //     getSubsctiption(email).then(res => {
        //         console.log(res);
        //         toast.success('successfully subscribed');
        //     })
        //     .catch((err) => {
        //         console.log("error", err);
        //         toast.error('Something went wrong!');
        //     });
        // } else {
        //     toast.error('Please Enter Valid Email ID');
        // }

        if(validateEmail(email)) {
            sendEBookToMail(email).then((res) => {
                setEmail('')
                setOpenModel(true)
            }).catch((err) => {
                console.log("error", err);
                toast.error('Something went wrong!');
            });
        }else {
            toast.error('Please Enter Valid Email ID');
        }
    }

    // onClick = (e) => {
    //     e.preventDefault();
    //     let { email, name, phoneNumber } = this.state;
    //     sendEBookToMail(email, name, `${phoneNumber}`).then((res) => {
    //       console.log({ res });
    //       if (res.statusCode === 200) {
    //         if(JSON.parse(localStorage.getItem('userData'))) {
    //           this.setState({
    //             modalState: true,
    //           });
    //         } else {
    //           this.setState({
    //             modalState: true,
    //             name: "",
    //             email: "",
    //             phoneNumber: null
    //           });
    //         }
            
    //       } else {
    //         toast.info(res.customMessage);
    //       }
    //     });
    //   };



    const history = useHistory();
    return (
        <>
            <div className='sh-subscription__form d-md-flex justify-content-between'>
                <input className={`sh-subscription__form-input ${props.additionalClass}`} type="email" name="email" placeholder="Enter your email id " value={email} onChange={handleEmailChange} />
                <div className="sh-subscription__form-btn sh-button hover-color"  onClick={onSubscription}>Subscribe now</div>
            </div>

            <Modal
                open={openModel}
                onClose={()=>setOpenModel(false)}
                center
                showCloseIcon={false}
                styles={{
                    modal: { borderRadius: 16, boxShadow: 'none' },
                    overlay: {},
                  }}
                >
                <div
                    style={{ display: "flex" }}
                    // className={"modal fade"}
                    id="news-modal"
                    tabIndex="-1"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-xl form-size">
                    <div className="modal-content form-content email-modal-content">
                        <div
                        //style={{borderRadius : 5}}
                        className="news-wrap"
                        >
                        <div className="row no-gutters">
                            <div className="col-md-12 form-padlr">
                            <div className="form-block">
                                {/* <div className="form-close-btn" data-dismiss="modal">
                                <button
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ modalState: false });
                                }}
                                className="close"
                                data-dismiss="modal"
                                >
                                ×
                                </button>
                            </div> */}

                                <div className="row no-gutters">
                                <div className="col-md-12">
                                    <div className="news-text-content">
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-12">
                                        <div className="news-img">
                                            <img src="assets/images/undraw-news-img.png" />
                                        </div>
                                        <div className="news-sec-title">
                                            {/* <h5>Thank you for choosing Shevolve to further your career.</h5> */}
                                            <h5>You have successfully subscribed to our eBooks</h5>
                                            {/* <p className="text-danger">
                                            Note: If you cannot find the eBook in your inbox, please check the spam folder.
                                            </p> */}
                                        </div>
                                        </div>
                                        <div className="col-md-12">
                                        <div className="btn-wrap">
                                            <button
                                            type="submit"
                                            className="sh-button sh-button__no-radius w-100"
                                            // data-dismiss="modal"
                                            onClick={()=>setOpenModel(false)}
                                            >
                                            Okay
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </Modal>
        </>
        

    )
}

export default EbookForm;