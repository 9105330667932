import { useEffect, useRef, useState, useLayoutEffect, lazy, Suspense } from 'react';
import { useHistory } from 'react-router-dom'

// import NewHeader from "../static/newHeader";
import Loading from "../small/Loading";
import './home.scss'
import moment from 'moment';
import { getAllblogs } from '../../../NetworkRequest/requests/blogs';
import { getAllEvents } from "../../../adminPanel/networkRequests/requests/event";
// import { BiArrowBack } from 'react-icons/bi';
// import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import { allMentorsHome as allMentors } from '../../Constants';
import { Testimonials } from '../testimonials';
import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import Layout from '../../layout/default';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import {AnimWrap} from "../animation-gsap/Animation"
import { LazyLoadImage } from 'react-lazy-load-image-component';


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


// const NewHeader = lazy(() => import('../static/newHeader'));
// const Layout = lazy(() => import('../../layout/default'));
// const { getAllblogs } = lazy(() => import('../../../NetworkRequest/requests/blogs'));

// const renderLoader = () => <Loading />;

const careers = [
    {
        title: "White Elephant Tech",
        logo: "white-elephant",
        opening: "5",
        url: "https://shevolvecareers.com/index.php/employer/white-elephant-tech/"
    },
    {
        title: "Confidential",
        logo: "confidential",
        opening: "6",
        url: "https://shevolvecareers.com/index.php/employer/she/"
    },
    {
        title: "Shevolve",
        opening: "1",
        url: "https://shevolvecareers.com/index.php/employer/shevolve/"
    }
]

const testimonial = [
    {
        name: 'Scindia Balasingh',
        quote: 'I  faced gender bias , lack of inspiration and motivation from fellow women colleagues/leaders in my very own experience.This pushed me to take women empowerment in my personal agenda, I always wanted to see women shining in their career. When I interviewed Deepa in my podcast LeadHERs, I got to relate a lot of my interests aligned with Shevolve. It started from there, I got the opportunity to do a cohort session on Career in partnerships , then an insta live sharing my experience to the fellow women community. I am grateful to be part of the shevolve community and looking forward to contributing and learning more from shevolve.',
        img: 'scindia-balasingh'
    },
    {
        name: 'Shreedevi Ramesh',
        quote: 'I have been associated with Shevolve as a mentor since its inception. This is a very powerful community that really wants to empower women! Truly amazed to see how this community has evolved through the variety of initiatives and the truly accomplished women leaders who provide their valuable insights to those who seek. Through the mentoring platform I have had the opportunity to mentor a handful of women by sharing my experiences and providing guidance for their questions. Mentoring has a fantastic learning experience for me personally too! Shevolve is doing a phenomenal job in providing this platform for mid career women especially to those on a career break and telling them they are not alone. Best wishes!! I am proud to be a tiny part of the team! "',
        img: 'shreedevi-ramesh'
    },
    {
        name: 'Kaivalya',
        quote: 'Shevolve is an amazing community that gives a platform to all women irrespective of their work experience levels. This enables women across multiple experience levels to connect with each other. In a constantly changing environment, it is important to have such forums where women support women. As the saying goes, when women support women; incredible things happen. Looking forward to Shevolve making the desired impact in the near future.',
        img: 'kaivalya-gundu'
    }, {
        name: 'Samiksha Seth',
        quote: '"I was so excited to be part of the Shevolve Community, and when I interacted with mentees, they all had similar questions of what I had a few years back.Its an awesome feeling to connect and help and mostly give it back to the community.Shevolve has made sure to match with the right set of mentees and the ground work they do to aboard mentors and mentees is commendable.Great to be part of such an exciting journey."',
        img: 'kaivalya-gundu'
    }, {
        name: 'Manvi Tyagi',
        quote: '"I served as a Mentor for ""Thrive with Shevolve"" Program which aimed to guide women who had been on a career break for long periods of time to come back to the industry.I talked to one woman who had a 97% score in 12th class in 2008, a good cgpa in bachelors and a great start to her career at a reputed organisation. She had been on a career break for many years and found it difficult to make her place again. I am super happy that they could be helped and guided with this program and hence very grateful to Shevolve not only for just onboarding me but for putting the foundation of Shevolve in first place."',
        // img: 'kaivalya-gundu'
    }, {
        name: 'Neeraja',
        quote: '"I had the opportunity to conduct a session on ""Networking For Results"" for the ""Thrive with Shevolve"" programme. It was impressive to see how this platform has so quickly identified the needs of women professionals(be it women who are new in the workforce, women on a career break or women in careers) and curated this beautiful programme. I also had the opportunity to be part of a panel discussion at the ""Mentorship for Women Conference"". I could see the same professionalism, thought process in putting together the various programmes, their outreach both to mentors as well as participants. I would urge every women to follow Shevolve on their website and social media and participate in all the programmes conducted by them. It will definitely take them to heights in their careers"',
        // img: 'kaivalya-gundu'
    }
]



const Home = (props) => {

    const userData = props.userData;

    const ref = useRef(null);

    const [blog, setBlog] = useState([]);
    const [events, setEvents] = useState([]);
    const [ind, setIndex] = useState(1);
    const history = useHistory();

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        getAllblogs().then(res => {
            if (res) {
                setBlog(res.slice(0, 3))
            }
        })
        
        getAllEvents().then(res => {
            if (res.data) {
                const events = res.data.filter((event) => {
                    return event.isExpired == false;
                  })
                setEvents(events)
            }
        })
    }, []);

    // useEffect(()=>{
    //     var script = document.createElement("script");
    //     script.type = 'text/javascript';
    //     script.addEventListener("load", function(event) {

    //     });
    //     script.src = "./assets/js/slick.js";
    //     document.getElementsByTagName("script")[0].parentNode.appendChild(script);
    // },[])


    function slideNext() {

        if (ind === 5) {
            // setIndex(0)
        } else {
            setIndex(ind + 1)
            ref.current.scrollLeft += 300;
        }
    }

    function slidePrev() {
        if (ind === 0) {
            // setIndex(0)

        } else {
            ref.current.scrollLeft -= 300;
            setIndex(ind - 1)
        }

    }

    const secondSection = useRef(null)

    const scrollHandler = () => {
        secondSection.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
    

    return (
        <>
                <Helmet>
                    <title>Elevate Your Career with Shevolve - A Mentoring Platform for Women</title>
                    <meta name="description" content="Elevate your career with Shevolve, India's first mentoring platform for women. Connect, grow, and succeed with personalized mentoring sessions tailored to your needs." />
                    <meta name="keywords" content="Mentoring platform for women, Women's mentorship, Career growth for women, Women empowerment, Personalized mentorship, Professional development for women, Female leadership, Mentorship programs, Women in business, Career advancement for women, Women's career support, Empowering women in the workforce, Leadership development for women, Professional mentorship for females, Women's career guidance" />
                   
                    <meta property="og:title" content="Elevate Your Career with Shevolve - A Mentoring Platform for Women" />
<meta property="og:description" content="Elevate your career with Shevolve, India's first mentoring platform for women. Connect, grow, and succeed with personalized mentoring sessions tailored to your needs." />
<meta property="og:image" content="https://shevolve.in/images/home/slide/slide1.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Elevate Your Career with Shevolve - A Mentoring Platform for Women" />
<meta name="twitter:description" content="Elevate your career with Shevolve, India's first mentoring platform for women. Connect, grow, and succeed with personalized mentoring sessions tailored to your needs." />
<meta name="twitter:image" content="https://shevolve.in/images/home/slide/slide1.png" />



                    
                </Helmet>
            <Loading open={false} />
            <Layout className="sh-home">                

                {/*=========================================
                ===================== Hero ================
                ============================================ */}
                <AnimWrap />
                <section className="sh-section sh-hero pb-3" style={{ backgroundImage: "url(./images/home/slider-background.jpg)" }}>
                    <div className="sh-section__inwrap">
                        <div className="container-fluid p-lg-0">
                            <OwlCarousel className="owl-theme sh-hero__slider sh-slider" 
                                items={1} 
                                margin={0} 
                                // dots={false} 
                                autoplay={true} 
                                autoplayTimeout={8000} 
                                loop={true} 
                                autoplaySpeed={800} 
                                lazyLoad={true}
                            >
                                <div className="sh-hero__slide">
                                    <div className="position-absolute w-100 sh-hero__slide-line sh-hero__slide-line1"><img className="img-fluid" src="./images/home/slide/line1.svg" alt="" /></div>
                                    <div className="row align-items-center justify-content-end">
                                        <div className="sh-hero__slide-left order-2 order-lg-1 mt-3 mt-mb-0">
                                            <div className="sh-hero__slide-content">
                                                <h1>
                                                    <div className="sh-hero__slide-title sh-banner__title mt-0">To Empower Women</div>
                                                    <div className="sh-hero__slide-subtitle sh-banner__subtitle mb-4">Through Shevolve Mentorship Program</div>
                                                </h1>
                                                <Link className="sh-button" to="/about">Know More</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-lg-7 sh-hero__slide-right order-1 order-lg-2">
                                            <div className="">
                                                <LazyLoadImage
                                                    class="img-fluid"
                                                    alt="To Lift as We Rise India’s First Mentoring Platform for Women | Shevolve"
                                                    // height={image.height}
                                                    src="./images/home/slide/slide1.png" // use normal <img> attributes as props
                                                    // width={image.width} 
                                                />
                                                {/* <img className="img-fluid" src="./images/home/slide/slide1.png" alt="slide-image" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sh-hero__slide">
                                    <div className="position-absolute w-100 sh-hero__slide-line ">
                                        <img className="img-fluid " src="./images/home/slide/line2.svg" alt="" />
                                    </div>
                                    <div className="row align-items-center justify-content-end">
                                        <div className="sh-hero__slide-left order-2 order-lg-1 mt-3 mt-mb-0">
                                            <div className="sh-hero__slide-content">
                                                <h2 className="sh-hero__slide-title sh-banner__title mt-0 ">
                                                    {/* <div className="sh-hero__slide-title--gray">Proprietary</div> */}
                                                    Become HerMentor
                                                </h2>
                                                <p className="sh-para ">Be a part of her evolving journey by signing up to be a mentor</p>
                                                {/* <div className="sh-hero__slide-subtitle sh-banner__subtitle mb-4">Proprietary</div> */}
                                                <div className="sh-text sh-hero__slide-text sh-hero__slide-text--green mt-2 mb-4 ">Fresher | Returnee | Mid-Level | Entrepreneur</div>
                                                <Link className="sh-button " to="/hermentor">Get Started</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-lg-7 sh-hero__slide-right order-1 order-lg-2">
                                            <div className='sh-anim__stagger-fade'>
                                                <LazyLoadImage
                                                    class="img-fluid"
                                                    alt="Be a part of her evolving journey by signing up to be a mentor | Shevolve"
                                                    // height={image.height}
                                                    src="./images/home/slide/slide2.png" // use normal <img> attributes as props
                                                    // width={image.width} 
                                                />
                                                {/* <img className="img-fluid sh-anim__stagger-fade" src="./images/home/slide/slide2.png" alt="slide-image" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sh-hero__slide">
                                    <div className="position-absolute sh-hero__slide-line sh-anim__stagger-fade">
                                        <img className="img-fluid" src="./images/home/slide/line3.svg" alt="" />
                                    </div>
                                    <div className="row align-items-center justify-content-end">
                                        <div className="sh-hero__slide-left order-2 order-lg-1 mt-3 mt-mb-0">
                                            <div className="sh-hero__slide-content">
                                                
                                                <ul className="">
                                                    <li className="d-flex align-items-center mb-3"> 
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24.442" height="39.399" viewBox="0 0 24.442 39.399">
                                                                <g id="Group_62921" data-name="Group 62921" transform="translate(-140 -3491.119)">
                                                                    <path id="Path_84765" data-name="Path 84765" d="M17.016,12.525A18.432,18.432,0,0,0,4.53,0a18.451,18.451,0,0,0,8.7,29.8,18.432,18.432,0,0,0,3.787-17.276" transform="translate(140 3495.416) rotate(-14)" fill="#007a87"/>
                                                                    <g id="Group_61026" data-name="Group 61026" transform="translate(140 3520.441)">
                                                                    <path id="Path_84764" data-name="Path 84764" d="M6.694,74.618A10.308,10.308,0,0,0,0,81.9a10.317,10.317,0,0,0,16.437-5.583,10.306,10.306,0,0,0-9.743-1.7" transform="translate(0 -74.067)" fill="#007a87"/>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span className="sh-hero__slide-list">1:1 Mentoring</span>
                                                    </li>
                                                    <li className="d-flex align-items-center mb-3"> 
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24.442" height="39.399" viewBox="0 0 24.442 39.399">
                                                                <g id="Group_62921" data-name="Group 62921" transform="translate(-140 -3491.119)">
                                                                    <path id="Path_84765" data-name="Path 84765" d="M17.016,12.525A18.432,18.432,0,0,0,4.53,0a18.451,18.451,0,0,0,8.7,29.8,18.432,18.432,0,0,0,3.787-17.276" transform="translate(140 3495.416) rotate(-14)" fill="#007a87"/>
                                                                    <g id="Group_61026" data-name="Group 61026" transform="translate(140 3520.441)">
                                                                    <path id="Path_84764" data-name="Path 84764" d="M6.694,74.618A10.308,10.308,0,0,0,0,81.9a10.317,10.317,0,0,0,16.437-5.583,10.306,10.306,0,0,0-9.743-1.7" transform="translate(0 -74.067)" fill="#007a87"/>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span className="sh-hero__slide-list">Capability Tracker</span>
                                                    </li>
                                                    <li className="d-flex align-items-center mb-3"> 
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24.442" height="39.399" viewBox="0 0 24.442 39.399">
                                                                <g id="Group_62921" data-name="Group 62921" transform="translate(-140 -3491.119)">
                                                                    <path id="Path_84765" data-name="Path 84765" d="M17.016,12.525A18.432,18.432,0,0,0,4.53,0a18.451,18.451,0,0,0,8.7,29.8,18.432,18.432,0,0,0,3.787-17.276" transform="translate(140 3495.416) rotate(-14)" fill="#007a87"/>
                                                                    <g id="Group_61026" data-name="Group 61026" transform="translate(140 3520.441)">
                                                                    <path id="Path_84764" data-name="Path 84764" d="M6.694,74.618A10.308,10.308,0,0,0,0,81.9a10.317,10.317,0,0,0,16.437-5.583,10.306,10.306,0,0,0-9.743-1.7" transform="translate(0 -74.067)" fill="#007a87"/>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span className="sh-hero__slide-list">Career Evaluation Index</span>
                                                    </li>
                                                    <li className="d-flex align-items-center mb-3"> 
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24.442" height="39.399" viewBox="0 0 24.442 39.399">
                                                                <g id="Group_62921" data-name="Group 62921" transform="translate(-140 -3491.119)">
                                                                    <path id="Path_84765" data-name="Path 84765" d="M17.016,12.525A18.432,18.432,0,0,0,4.53,0a18.451,18.451,0,0,0,8.7,29.8,18.432,18.432,0,0,0,3.787-17.276" transform="translate(140 3495.416) rotate(-14)" fill="#007a87"/>
                                                                    <g id="Group_61026" data-name="Group 61026" transform="translate(140 3520.441)">
                                                                    <path id="Path_84764" data-name="Path 84764" d="M6.694,74.618A10.308,10.308,0,0,0,0,81.9a10.317,10.317,0,0,0,16.437-5.583,10.306,10.306,0,0,0-9.743-1.7" transform="translate(0 -74.067)" fill="#007a87"/>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span className="sh-hero__slide-list">Accountability & Mental Health Coaches</span>
                                                    </li>
                                                </ul>
                                                <p className="sh-para mb-4 ">Be a part of the Shevolve Community</p>
                                                {/* <div className="sh-hero__slide-subtitle sh-banner__subtitle mb-4">Proprietary</div> */}
                                                <Link className="sh-button " to="/subscription/individual">Subscribe Now</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-lg-7 sh-hero__slide-right order-1 order-lg-2">
                                            <div className="sh-anim__stagger-fade">
                                                <LazyLoadImage
                                                    class="img-fluid"
                                                    alt="Be a part of the Shevolve Community | Shevolve"
                                                    src="./images/home/slide/slide3.png" // use normal <img> attributes as props
                                                />
                                                {/* <img className="img-fluid" src="./images/home/slide/slide3.png" alt="slide-image" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sh-hero__slide">
                                    <div className="position-absolute w-100 sh-hero__slide-line ">
                                        <img className="img-fluid " src="./images/home/slide/line4.svg" alt="" />
                                    </div>
                                    <div className="row align-items-center justify-content-end">
                                        <div className="sh-hero__slide-left order-2 order-lg-1 mt-3 mt-mb-0">
                                            <div className="sh-hero__slide-content">
                                                <h2 className="sh-hero__slide-title sh-banner__title mt-0 ">
                                                    Trusted to Rise Up with US!
                                                </h2>
                                                <p className="sh-hero__slide-subtitle sh-banner__subtitle mt-2 mb-4">Hear what Shevolve Women say about us</p>
                                                <a className="sh-button " onClick={scrollHandler}>Know More</a>
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-lg-7 sh-hero__slide-right order-1 order-lg-2">
                                            <div className='sh-anim__stagger-fade'>
                                                <LazyLoadImage
                                                    class="img-fluid"
                                                    alt="Testimonials of what Shevolve Women say about us | Shevolve"
                                                    // height={image.height}
                                                    src="./images/home/slide/slide4.png" // use normal <img> attributes as props
                                                    // width={image.width} 
                                                />
                                                {/* <img className="img-fluid sh-anim__stagger-fade" src="./images/home/slide/slide2.png" alt="slide-image" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </section>
                {/*=========================================
                ============== How does it work ? ============
                ============================================ */}
                    <section className="sh-section">
                        <div className="sh-section__inwrap">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-xl-4">
                                        <div className="sh-content__wrap sh-section__title-wrap">
                                            <div className="sh-subtitle sh-anim__stagger-fade-top">So She Can Evolve</div>
                                            <div className="sh-title sh-anim__stagger-fade-top">How does it work ?</div>
                                            <p className="sh-para sh-anim__stagger-fade-top">Shevolve is India’s first mentoring platform for women focusing 
                                                on 1:1 sessions with mentors that help you through career growth, returnship after 
                                                a break or expanding your business as an entrepreneur.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-xl-8">
                                        <div className="position-absolute w-100 d-none d-md-flex justify-content-center sh-process__card-line sh-anim__stagger-process-line"><img src="./images/home/leaf-with-line.svg" alt="" /></div>
                                        <div className="position-absolute w-100 d-none d-md-flex justify-content-center sh-process__card-line sh-process__card-line--bottom sh-anim__stagger-process-line2"><img src="./images/home/leaf-with-line-bottom.svg" alt="" /></div>
                                        <div className="d-flex flex-wrap">
                                            <div className="sh-process sh-anim__stagger-process-card1"> 
                                                <div className="sh-process__card d-flex align-items-start align-items-md-end">
                                                    <div className="sh-process__card-image">
                                                        <LazyLoadImage
                                                        class="img-fluid"
                                                        alt="Support women leadership by turning your knowledge into impact and income by signing up as a mentor | Shevolve"
                                                        src="./images/home/process/step1.svg" // use normal <img> attributes as props
                                                        />
                                                    {/* <img src="./images/home/process/step1.svg" alt="" /> */}
                                                </div>
                                                    <div className="sh-process__card-content">
                                                        <div className="sh-process__card-title">Become HerMentor</div>
                                                        <p className="sh-process__card-para">Support women leadership by turning your knowledge into impact and income by signing up as a mentor.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sh-process sh-anim__stagger-process-card2"> 
                                                <div className="sh-process__card d-flex align-items-start align-items-md-end">
                                                    <div className="sh-process__card-image">
                                                        <LazyLoadImage
                                                        class="img-fluid"
                                                        alt="With 3 personalized mentoring subscriptions. Fill up our proprietary career evaluation form to help align you with the right mentor | Shevolve"
                                                        src="./images/home/process/step2.svg" // use normal <img> attributes as props
                                                        />
                                                    {/* <img src="./images/home/process/step2.svg" alt="" /> */}
                                                </div>
                                                    <div className="sh-process__card-content">
                                                        <div className="sh-process__card-title">Be a Femtee</div>
                                                        <p className="sh-process__card-para">
                                                            <span className="font-weight-bold">With 3 personalized mentoring subscriptions</span> Fill up our proprietary career evaluation form to help align you with the right mentor.</p>
                                                        {/* <p className="sh-process__card-para">Fill up our proprietary career evaluation form to help align</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sh-process position-relative sh-anim__stagger-process-card3"> 
                                                <div className="sh-process__card d-flex align-items-start">
                                                    <div className="sh-process__card-image">
                                                        <LazyLoadImage
                                                        class="img-fluid"
                                                        alt="Partner with shevolve to support & champion women through their career | Shevolve"
                                                        src="./images/home/process/step3.svg" // use normal <img> attributes as props
                                                        />
                                                    {/* <img src="./images/home/process/step3.svg" alt="" /> */}
                                                </div>
                                                    <div className="sh-process__card-content">
                                                        <div className="sh-process__card-title">Partnerships and Alliances</div>
                                                        <p className="sh-process__card-para">Partner with us to support & champion women through their career.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                {/*=========================================
                ============== Featured Mentors ============
                ============================================ */}
                    <section className="sh-section sh-section--green sh-section__curved">
                        <div className="position-absolute sh-section__curved-top w-100">
                            <img className="img-fluid w-100" src="./images/home/curve-divider.png" />
                        </div>
                        <div className="sh-section__inwrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="sh-content__wrap sh-section__title-wrap text-center sh-section__title-paddingx">
                                            <div className="sh-subtitle sh-anim__stagger-fade-top">Thrive With Shevolve</div>
                                            <div className="sh-title sh-anim__stagger-fade-top">Featured Mentors</div>
                                            <p className="sh-para sh-anim__stagger-fade-top">Shevolve is proud to collaborate with mentors across the globe who play a vital role in increasing the workforce participation of women and aspire women to become leaders while reaching their career goals with perseverance, resilience, and motivation!</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                    <OwlCarousel className="owl-theme sh-slider " 
                                        // width={90} 
                                        // items={3} 
                                        margin={80}
                                        lazyLoad={true}
                                        responsive={{
                                            "0": {
                                                items: "1"
                                            },
                                            "640": {
                                                items: "2"
                                            },
                                            "1170": {
                                                items: "3"
                                            }
                                            
                                        }}
                                        
                                    >
                                        {allMentors.map((mentor, index) => {
                                            return (
                                                <div key={index} className="sh-mentor__slide sh-pointer sh-anim__stagger-fade-top" onClick={() => { history.push('/ourmentors') }}>
                                                    <div className="sh-mentor__slide-image mb-4" style={{ backgroundImage: `url( '${mentor.image}' )` }}></div>
                                                    <div className="sh-mentor__slide-details d-flex flex-wrap align-items-center justify-content-between">
                                                        <div className="sh-mentor__slide-name w-100"> {mentor.name} </div>
                                                        <div className="sh-mentor__slide-details-left">
                                                            <div className="sh-mentor__slide-position"> {mentor.role} </div>
                                                        </div>
                                                        <div className="sh-mentor__slide-details-right text-right">
                                                            {mentor.company &&
                                                                <LazyLoadImage
                                                                    class="sh-mentor__slide-company"
                                                                    alt={`${mentor.name} - ${mentor.company} | Shevolve`}

                                                                    src={mentor.company}
                                                                />  
                                                            }
                                                            {/* <img className=" sh-mentor__slide-company" src={mentor.company} alt="" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) }
                                    </OwlCarousel>
                                    </div>
                                    <div className="sh-button__center-align"><Link class="sh-button mt-4 sh-anim__stagger-fade-top" to="/ourmentors">View More</Link></div>
                                </div>
                            </div>
                        </div>
                        <div className="position-absolute sh-section__curved-bottom w-100"><img className="img-fluid w-100" src="./images/home/curve-divider.png" /></div>
                    </section>

                {/* =========================================
                ============== UpComing Events ============
                ============================================ */}
                    <section className="sh-section pb-0">
                        <div className="sh-section__inwrap overflow-hidden">
                            <div className="container-fluid pr-md-0">
                                <div className="row justify-content-end">
                                    <div className="sh-card__left">
                                        <div className="sh-content__wrap sh-section__title-wrap mb-xl-0 text-center text-xl-left">
                                            <div className="sh-title sh-anim__stagger-fade-top">Upcoming Events</div>
                                            <p className="sh-para mb-2 sh-anim__stagger-fade-top mb-3">Be a part of interactive conversations that help you connect with and learn from leaders in the industry.</p>
                                            <Link class="sh-button sh-anim__stagger-fade-top" to="/events">View All</Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-7">
                                        {events.length > 0 ?
                                            (
                                                <OwlCarousel className="owl-theme sh-slider sh-slider__event sh-anim__stagger-fade-right" 
                                                    // items={2} 
                                                    margin={30} 
                                                    dots={false} 
                                                    autoplay={false} 
                                                    lazyLoad={true}
                                                    autoplayTimeout={8000} 
                                                    loop={false} 
                                                    autoplaySpeed={800} 
                                                    // stagePadding= {30}
                                                    // autoWidth= {true}

                                                    responsive={{
                                                        "0": {
                                                            items: "1",

                                                        },
                                                        "640": {
                                                            items: "2",
                                                            autoWidth: "true",
                                                            stagePadding: "30"
                                                        }
                                                        
                                                    }}
                                                >
                                                    {events.slice(0, 5).map((e, i) => (
                                                                <div className="sh-card sh-home__event mb-0"
                                                                    onClick={(h) => {
                                                                        if (!e.isExpired) {
                                                                            props.history.push(`/eventDetail/${e._id}`);
                                                                        }
                                                                    }}
                                                                    key={i}
                                                                >
                                                                <div className="sh-card__image position-relative overflow-hidden">
                                                                    <LazyLoadImage
                                                                        class="img-fluid sh-card__event-image home sh-card__event-image--ghost"
                                                                        alt={e.name}
                                                                        src={e.image}
                                                                    />
                                                                    <LazyLoadImage
                                                                        class="img-fluid sh-card__event-image"
                                                                        alt={e.name}
                                                                        src={e.image}
                                                                    />
                                                                    <div className="sh-card__catagory">
                                                                        {e.eventType === "ONLINE" && "Online"}
                                                                        {e.eventType === "OFFLINE" && "Offline"} Event
                                                                    </div>
                                                                </div>
                                                                <div className="sh-card__content">
                                                                    <div className="sh-card__date-time d-md-flex justify-content-between mb-3">
                                                                        <div className="sh-card__date">{moment(e.startTime).format("MMM Do YYYY")}</div>
                                                                        {/* <div className="sh-card__time text-md-right">{e.isExpired ? "Expired" : e.isSoldOut ? "Sold Out" : e.isPurchased ? "Attending" : "Reserve"}</div> */}
                                                                        <div className="sh-card__status sh-card__status--red">
                                                                            {e.isExpired ? "Expired" : e.isSoldOut ? "Sold Out" : e.isPurchased ? "Attending" : "Reserve"}
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-4">
                                                                        <div className="sh-card__name mb-3">{e.name}</div>
                                                                        <p className="sh-card__para sh-card__para">{e.lable}</p>
                                                                    </div>
                                                                    {!e.isExpired && (
                                                                        <div className="text-right">
                                                                            <a className="sh-button sh-button__plain-arrow"  
                                                                                onClick={(h) => {
                                                                                    if (!e.isExpired) {
                                                                                    this.props.history.push(`/eventDetail/${e._id}`);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <span className="mr-2">Know more</span>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path></svg>
                                                                            </a>
                                                                        </div>
                                                                    )}
                                                                    
                                                                </div>
                                                            </div>
                                                            ))
                                                    }
                                                    {/* {event.length > 0 ?
                                                        event.map((data, index) => {
                                                            <div className="sh-card sh-home__event mb-0">
                                                                <div className="sh-card__image">
                                                                    <div className="sh-card__catagory">
                                                                        {data.eventType === "ONLINE" && "Online"}
                                                                        {data.eventType === "OFFLINE" && "Offline"}
                                                                    </div>
                                                                </div>
                                                                <div className="sh-card__content">
                                                                    <div className="sh-card__date-time d-md-flex justify-content-between mb-3">
                                                                        <div className="sh-card__date">30 Nov 2021, Fri | 4:00 PM</div>
                                                                        <div className="sh-card__time text-md-right">6 Days ago</div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <div className="sh-card__name mb-1">Men As Allies Initiative</div>
                                                                        <p className="sh-card__para sh-card__para">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui cupidatat non</p>
                                                                    </div>
                                                                    <div className="text-right">
                                                                        <a className="sh-button sh-button--lined" href="">Know more</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }) : ""
                                                    } */}
                                                </OwlCarousel>
                                            )  : (
                                                <div className="sh-anim__stagger-fade-top text-center">
                                                    <LazyLoadImage
                                                        class="img-fluid"
                                                        alt="There are no Upcoming events at this time..."
                                                        src="./images/home/no-events.svg"
                                                    />  
                                                    {/* <img className='img-fluid' src="./images/home/no-events.svg" /> */}
                                                    <p class="mt-3 sh-home__events-text--noupcoming">There are no Upcoming events at this time...</p>
                                                </div> 
                                            )                                 
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                {/* =========================================
                ============== Featured Jobs ============
                ============================================ */}
                {/* <section className="sh-section pb-4">
                    <div className="sh-section__inwrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sh-content__wrap sh-section__title-wrap text-center">
                                    <div className="sh-subtitle sh-anim__stagger-fade-top">So she can Evolve</div>
                                        <div className="sh-title sh-anim__stagger-fade-top">Featured Jobs</div>
                                        <p className="sh-para sh-anim__stagger-fade-top">Connecting our Shevolve Community with  corporate partners. Register with Shevolve Careers and Apply for Jobs today! 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {careers.map((e, i) => (
                                    <div className="col-md-6 col-xl-3 mb-5 mb-xl-0" key={4}>
                                        <div className="sh-job sh-anim__stagger-fade-top">
                                            <div className="sh-job__logo">
                                                <LazyLoadImage
                                                    class="img-fluid"
                                                    alt={e.title}
                                                    src={`./images/home/jobs/${!e.logo ? "placeholder" : e.logo }.jpg`}
                                                /> 
                                            </div>
                                            <div className="sh-job__company-name">{e.title}</div>
                                            <div className="sh-job__openings">Total Job Openings: <span className="sh-job__openings-count">{e.opening}</span></div>
                                            <a class="sh-button sh-button--lined" target="_blank"  href={e.url}>Apply</a>
                                        </div>
                                    </div>
                                    )
                                )}                                
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* =========================================
                ============== Testimonials ============
                ============================================ */}
                <section className="sh-section pb-0 overflow-hidden" ref={secondSection}>
                    <div className="sh-section__inwrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sh-content__wrap sh-section__title-wrap text-center sh-section__title-paddingx">
                                    <div className="sh-subtitle sh-anim__stagger-fade-top">Testimonials</div>
                                        <div className="sh-title sh-anim__stagger-fade-top">What People Say About Us?</div>
                                        <p className="sh-para sh-anim__stagger-fade-top">Learn more about our program from our Femtees who have been a part of Shevolve in their Career Journey.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <OwlCarousel className="owl-theme sh-slider sh-home__testimonial-slider sh-testimonial__slider" 
                                    items={1} 
                                    margin={30} 
                                    lazyLoad={true}
                                    responsive={{
                                        "0": {
                                            items: "1",
                                        },
                                        "1100": {
                                            items: "2",
                                            autoWidth: "true",
                                        }
                                        
                                    }}
                                >
                                        {Testimonials.mentees.map((data, index) => {
                                            return (
                                                <div className="sh-testimonial d-flex flex-wrap align-items-xl-end sh-anim__stagger-fade-top">
                                                    <div className="sh-testimonial__content position-relative">
                                                        <div className="sh-testimonial__quotation position-absolute"><img src="./images/home/quoteIcon.svg" alt="" /></div>
                                                        <p className="sh-testimonial__para font-weight-normal" dangerouslySetInnerHTML={{ __html: data.quote }}></p>
                                                        <div className="mt-4">
                                                            <div className="sh-testimonial__name">{data.name}</div>
                                                            <div className="sh-testimonial__job sh-text">{data.role}</div>
                                                        </div>
                                                    </div>
                                                    <div className="sh-testimonial__images position-relative d-flex align-items-end">
                                                        <div className="position-absolute sh-testimonial__image-leaf">
                                                            <LazyLoadImage
                                                                class="img-fluid"
                                                                alt="testimonial-leaf"
                                                                src="./images/home/testimonial-leaf.svg"
                                                            /> 
                                                            {/* <img className="img-fluid" src="./images/home/testimonial-leaf.svg" alt="" /> */}
                                                        </div>
                                                        <div className="sh-testimonial__image-profile">
                                                            <LazyLoadImage
                                                                class="img-fluid"
                                                                alt={`${data.name} - ${data.role} | Shevolve`}
                                                                src={`./images/testimonials/${data.img}.png`}
                                                            /> 
                                                            {/* <img className="img-fluid" src={`./images/testimonials/${data.img}.png`} alt="" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </Layout>
        </>
    );
}
export default Home;
