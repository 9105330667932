import React, { Component } from "react";
import Styles from "../../../styles";
import { Modal } from "react-responsive-modal";

class EventModal2 extends Component {
  state = {
    modalState: false,
  };
  openModal = () => {
    this.setState({ modalState: true });
  };
  closeModal = () => {
    this.setState({ modalState: false });
  };

  render() {
    const bg = {
      modal: { borderRadius: 16 },
      overlay: {},
    };
    return (
      <div>
        <Styles />
        <button onClick={this.openModal}>submit</button>
        <Modal
          open={this.state.modalState}
          onClose={this.closeModal}
          center
          styles={bg}
        >
          <div
            style={{ display: "flex" }}
            // className={"modal fade"}
            id="news-modal"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl form-size my-0">
              <div className="modal-content form-content basic-modal-content email-modal-content">
                <div
                  //style={{borderRadius : 5}}
                  className="news-wrap"
                >
                  <div className="row no-gutters">
                    <div className="col-md-12 form-padlr">
                      <div className="form-block">
                        <div className="basic-detail-header">
                          <div className="flex-space">
                            <h6 className="sec-title">Basic Details</h6>
                            {/* <a href="javascript:void(0);">
											<i className="fas fa-times" />
										</a> */}
                          </div>
                        </div>
                        <div className="row no-gutters">
                          <div className="col-md-12">
                            <div className="email-text-content detail-text-content">
											<div className="link-wrap flex-space">
												<a className="btn btn-gradient-primary" href="javascript:void(0);">
                                        Back
												</a>
												<a className="btn btn-gradient-primary" href="javascript:void(0);">
                                        Next
												</a>
											</div>
                                  <form>
                                    <div className="form-group">
                                      {/* <input
                                        type="password"
                                        className="form-control"
                                        id="exampleInputName"
                                        placeholder="John Doe"
                                      /> */}
                                      <a
                                        className="inout-box-field"
                                        href="javascript:void(0);"
                                      >
                                        <span className="title">Yes</span>
                                        <i class="far fa-check-circle"></i>
                                      </a>
                                    </div>
                                    <div className="form-group">
                                      {/* <input
                                        type="email"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="dineshshnpl3213@gmail.com"
                                      /> */}
                                      <a
                                        className="inout-box-field active"
                                        href="javascript:void(0);"
                                      >
                                        <span className="title">No</span>
                                        <i class="far fa-check-circle"></i>
                                      </a>
                                    </div>
                                    <div className="form-group">
                                      {/* <input
                                        type="number"
                                        className="form-control"
                                        id="exampleInputNumber"
                                        placeholder="+91 92373246783"
                                      /> */}
                                      <a
                                        className="inout-box-field"
                                        href="javascript:void(0);"
                                      >
                                        <span className="title">Maybe Yes</span>
                                        <i class="far fa-check-circle"></i>
                                      </a>
                                    </div>
                                    <div className="form-group">
                                      {/* <input
                                        type="number"
                                        className="form-control"
                                        id="exampleInputNumber"
                                        placeholder="+91 92373246783"
                                      /> */}
                                      <a
                                        className="inout-box-field"
                                        href="javascript:void(0);"
                                      >
                                        <span className="title">
                                          I'm not sure
                                        </span>
                                        <i class="far fa-check-circle"></i>
                                      </a>
                                    </div>
                                    <div className="btn-wrap">
                                      <button
                                        type="submit"
                                        className="btn btn-gradient-primary w-100"
                                        // data-dismiss="modal"
                                        onClick={this.closeModal}
                                      >
                                        Proceed to Payment
                                      </button>
                                    </div>
                                  </form>
                               
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EventModal2;
