import AxiosBase from "../Axiosbase";

export const getPackages = () =>
  new Promise((resolve, reject) => {
    AxiosBase.get("/mentee/getSortedPackages")
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });

export const order = (amount) =>
  new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/order", { amount })
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });

export const updatePaymentStatus = (packageId, packageName, packagePurchasedOn, packageExpireOn) =>
  new Promise((resolve, reject) => {
    AxiosBase.put("/mentee/updatePaymentStatus", {
      packageId,
      packageName,
      packagePurchasedOn,
      packageExpireOn,
    })
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });

export const getMentee = () =>
  new Promise((resolve, reject) => {
    AxiosBase.get("/mentee/getMentee")
      .then((res) => {
        console.log(" response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });

export const getMenteePackageInfo = () =>
  new Promise((resolve, reject) => {
    AxiosBase.get("/mentee/getMenteePackageInfo")
      .then((res) => {
        console.log("response", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });

// export const updateMenteePackage = (packageId,packageName,packagePurchasedOn,packageExpireOn ) =>
// new Promise((resolve, reject) => {
//    AxiosBase.post("/mentee/updateMenteePackage" , {packageId,packageName,packagePurchasedOn,packageExpireOn})
//     .then((res) => {
//       console.log(" response", res.data);
//       resolve(res.data);
//     })
//     .catch((err) => {
//       console.log("error", err);
//       reject(err);
//     });
// });

export const addMentoringSession = ({ packagePurchaseId, date, additionalMessage }) => {
  // packagePurchaseId, date(DD-MM-YYYY HH:mm), additionalMessage
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/addMentoringSession", { packagePurchaseId, date, additionalMessage })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};

export const AddPackageWorkshop = ({ event, attendeeName, attendeePhone, attendeeEmail, questionnaireAnswered, packagePurchaseId }) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/addPackageWorkshop", {
      event,
      attendeeName,
      attendeePhone,
      attendeeEmail,
      questionnaireAnswered,
      packagePurchaseId,
    })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};


export const addCoachingSession = ({ packagePurchaseId, date, additionalMessage }) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/mentee/addCoachingSession", { packagePurchaseId, date, additionalMessage })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
};
