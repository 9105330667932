import React, { useState, useEffect } from "react";
import Styles from "../../../styles";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Modal } from "react-responsive-modal";
import { getSortedPackage, purchasePackage } from "../../../NetworkRequest/requests/question";
import { BASE_URL, ROZER_PAY_KEY } from "../../../constant";
import { useDispatch } from "react-redux";
import { updateProfileAction } from "../../../reducer/action";
import { useHistory } from "react-router-dom";

const Recommended = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedPackageInd, setSelectedPackageInd] = useState(0);
  useEffect(() => {
    setLoading(true);
    getSortedPackage()
      .then((res) => {
        setPackages(res);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  const handlePaymentSkip = () => {
    dispatch(updateProfileAction());
    history.push('/')
  };

  const handlePackageBuy = () => {
    setLoading(true);
    purchasePackage(packages[selectedPackageInd]._id)
      .then((res) => {
        setLoading(false);
        localStorage.setItem("from", "/");
        var options = {
          key: ROZER_PAY_KEY,
          amount: res.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          order_id: res.id,
          callback_url: `${BASE_URL}mentee/packagePaymentSuccess`,
          theme: {
            color: "#3399cc",
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  return (
    <section className="wrapper">
      <Styles />
      <Modal
        open={loading}
        onClose={(e) => {}}
        styles={{ modalContainer: { background: "transparent", boxShadow: "none", padding: 12 } }}
        showCloseIcon={false}
        center
      >
        {" "}
        <Loader
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
          // timeout={3000} //3 secs
        />
      </Modal>
      <section className="login-page signup-wrap">
        <div className="login-bg">
          <figure className="login-image mb-0">
            <img className="img-fluid" src={"/assets/images/login-image.png"} alt="login" style={{ height: "100vh", objectFit: "cover" }} />
          </figure>
        </div>
        <div className="login-form">
          <div className="form-content" style={{ minWidth: "60%" }}>
            <div className="login-form-content mb-3">
              <div className="sec-title flex-content m-b-md mt-5">
                <img className="mr-3" src="/assets/images/butterfly.svg" style={{ width: 22, height: 27.42 }} alt="banner" />
                <h5 className="sec-title mb-0" style={{ color: "#0C4B6C", fontSize: 29 }}>
                  Quick survey and you are all set!
                </h5>
              </div>
              <div>
                {packages.length > 0 &&
                  packages.map((pack, i) => (
                    <div
                      key={`pack${i}`}
                      onClick={(e) => setSelectedPackageInd(i)}
                      className="recommended-courses-container"
                      style={{ border: selectedPackageInd === i ? "1px solid #082550" : "1px solid rgba(0, 0, 0, 0.22)" }}
                    >
                      <div className="d-flex justify-content-between">
                        <p style={{ color: selectedPackageInd === i ? "#000" : "" }}>
                          <b>{pack.title} (Recommended)</b>
                        </p>
                        <p style={{ color: selectedPackageInd === i ? "#000" : "" }}>
                          <b>₹{pack.cost}/-</b>{" "}
                        </p>
                      </div>
                      <p className="mb-0">{pack.packageDuration} months program</p>
                      {selectedPackageInd === i && (
                        <ul style={{ listStyle: "none" }}>
                          {pack.keysOfPackage.map((keys, j) => (
                            <li key={`keys${j}`}>{keys}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}

                <button onClick={handlePackageBuy} className="btn  btn-gradient-primary w-100 mt-5 py-3 btn-lg">
                  Continue to Pay
                </button>

                <div className="sec-content text-align-center mt-4" style={{ fontSize: 14 }}>
                  <p>
                    Don’t want to pay now?
                    <span onClick={handlePaymentSkip} style={{ fontSize: 14, color: "#007a87", paddingLeft: 6 }}>
                      {" "}
                      Skip
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Recommended;
