import moment from "moment";
import React from "react";

const EventCard = (props) => {
  const { image, name, lable, eventType, startTime } = props;
  console.log("props1111", props);
  return (
    <div className="blog-content-wrap">
      <figure className="image-wrap">
        <img className="img-fluid" src={image} style={{ objectFit: "cover", minHeight: 200 }} />
      </figure>
      <div className="blog-text-content">
        <p
          className="sec-title text-black-color"
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {name}
        </p>
        <p className="sec-content" style={{ height: 62.4 }}>
          {lable}
        </p>
        <div className="event-time flex-space">
          <a href="javascript:void(0);" style={{ fontSize: 12 }}>
            {" "}
            {eventType} Event
          </a>
          <a href="javascript:void(0);" style={{ fontSize: 12 }}>
          {moment(startTime).format('LL')}
          </a>
        </div>
        <div className="meta-info flex-space">
          <div className="btn-wrap">
            <a href="javascript:void(0);" className="btn btn-wrap btn-gradient-primary">
              Join
            </a>
          </div>
          <div className="days" style={{ fontSize: 12 }}>
            {moment(startTime).format('YYYY')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
