import AxiosBase from "../Axiosbase";

export const SignUp = (name, email, experience, industry, domain, password) =>
    new Promise((resolve, reject) => {
        const data = { name, email, experience, industry, domain, password };

        AxiosBase.post("/mentee/signup", data)
            .then((res) => {
                console.log(" response", res.data);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });

export const SignUpMentor = (data) => {
    let d;
    const {
        userType,
        name,
        email,
        phoneNumber,
        industry,
        experience,
        role,
        domain,
        takeBreak,
        noOfYears,
        breakNow,
        //for montor
        selectedOption,
        linkedInLink,
        password,
    } = data;
    if (data.userType === 'mentee') {
        d = {
            name,
            email,
            phoneNumber: +phoneNumber,
            password,
            industry,
            experience,
            role,
            domain,
            takenBreak: takeBreak === 'yes',
            userType,
            linkedInLink
        }
        if (takeBreak === 'yes') {
            d['noOfYearsOnBreak'] = +noOfYears;
            d['onBreakNow'] = breakNow === 'yes'
        }
    } else {
        d = {
            name,
            email,
            phoneNumber: +phoneNumber,
            password,
            industry,
            experience,
            role,
            domain,
            userType,
            contribution: selectedOption.toString(),
            mentoring: selectedOption.includes("Mentoring"),
            conductSeminars: selectedOption.includes('Conduct Seminars / workshop'),
            volunteering: selectedOption.includes('Volunteering'),
            courseAuthoring: selectedOption.includes('Course authoring'),
            courseDelivery: selectedOption.includes('course delivery'),
            linkedInLink,
            branding: selectedOption.includes('Branding'),
            coaching: selectedOption.includes('Coaching')
        }
    }
    // const d = {...data, phoneNumber: + data.phoneNumber}
    return new Promise((resolve, reject) => {
        AxiosBase.post("/common/signUp", d)
            .then(res => {
                if (res.data.statusCode === 200) {
                    // localStorage.setItem('accessToken', res.data.accessToken);
                    resolve(res.data)
                } else {
                    reject(res.data.customMessage)
                }
                console.log('response', res.data);
                console.log('token', res.data.accessToken, res);

            })
            .catch(e => {
                console.log("error");
                reject(e)
            })
    })
}


export const login = (email, password) =>
    new Promise((resolve, reject) => {
        const data = { email, password };
        AxiosBase.post("/mentee/login", data)
            .then((res) => {
                console.log("response1", res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });


export const mentorLogin = (email, password) =>
    new Promise((resolve, reject) => {
        const data = { email, password };

        AxiosBase.post("/mentor/login", data)
            .then((res) => {
                console.log("response2", res.data);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("error", err);
                reject(err);
            });
    });


export const getProfile = () => {
    return new Promise((resolve, reject) => {
        AxiosBase.get('/mentee/getMentee')
            .then(res => {
                resolve(res.data)
                console.log(res)
            })
            .catch(e => {
                reject(e)
                console.log(e);
            })
    })
}

export const getProfileMentor = () => {
    return new Promise((resolve, reject) => {
        AxiosBase.get('/mentor/getMentor')
            .then(res => {
                resolve(res.data)
                console.log(res)
            })
            .catch(e => {
                reject(e)
                console.log(e);
            })
    })
}

export const forgetPassword = (email, userType) => {
    return new Promise((resolve, reject) => {
        AxiosBase.get(`/common/forgotPassword?email=${email}&userType=${userType}`)
            .then(res => {
                resolve(res.data)
                console.log(res)
            })
            .catch(e => {
                reject(e)
                console.log(e);
            })
    })
}

export const resetPassword = (email, userType, token, password) => {
    return new Promise((resolve, reject) => {
        AxiosBase.put(`/common/resetPassword`, { email, userType, token, newPassword: password })
            .then(res => {
                resolve(res.data)
                console.log(res, 'res')
            })
            .catch(e => {
                reject(e)
                console.log(e)
            })
    })

}

export const guestLogin = (email, name, socialId, socialType) => {
    return new Promise((resolve, reject) => {
        AxiosBase.post(`/mentee/guestLogin`, { email, name, socialId, socialType })
            .then(res => {
                resolve(res.data)
                console.log(res, 'res')
            })
            .catch(e => {
                reject(e)
                console.log(e)
            })
    })
}

export const getLinkedInAccessToken = (linkedinCode, redirectURI, clientId, clientSecret) => {
    return new Promise((resolve, reject) => {
        AxiosBase.post( '/mentee/linkedInGuestLogin', { authorizationCode: linkedinCode, linkedInURL: window.location.origin } )
            .then(res => {
                resolve(res.data)
                console.log(res, 'res')
            })
            .catch(e => {
                reject(e)
                console.log(e)
            })
    })
}