import React, { Component } from "react";
import EBook from "./ebookDownload";
import SocialMedia from "./socialMedia";
import NewHeader from "./static/newHeader";
import ReactPlayer from "react-player";
import { USER_TYPE } from "../Constants";
import { connect } from "react-redux";
import Loading from "./small/Loading";
import ProfileHome from "./home/profileHome";
import TakeSurvey from "./small/takeSurvey";
import { updatePackageInfo } from "../../reducer/action";
import MentorHome from "./mentor/montorHome";

class NewHomePage extends Component {
  isLoggedIn = localStorage.getItem("accessToken");
  userType = localStorage.getItem(USER_TYPE) || "";
  state = {
    loading: false,
    package: [],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.isLoggedIn && this.userType === "mentee") {
      this.props.updatePackageInfo();
    }
    // return this.setState({loading: false})
  }
  static getDerivedStateFromProps() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Loading open={this.state.loading} />
        {this.userType === "mentor" && localStorage.getItem("accessToken") ? (
          <MentorHome />
        ) : this.props.userData.packageInfo ? (
          <ProfileHome {...this.props} />
        ) : (
          <div className="wrapper container-width">
            {/* header wrap */}
            <NewHeader props={this.props} />
            {/* header close */}
            {/* banner-wrap */}
            <div className="banner-wrap calander-banner-wrap homepage-banner">
              <div className="banner-overlay">
                {/* <div class="container"> */}
                <div className="banner-content">
                  {/* <iframe
             src="https://www.youtube.com/embed/FzcfZyEhOoI"
             frameBorder={0}
             height={515}
             allowFullScreen
             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
             style={{ width: "100%" }}
           /> */}
                  <ReactPlayer
                    url="https://youtu.be/pBGBK_65gCs"
                    height={515}
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    width="100%"
                  />
                </div>

                {/* </div> */}
              </div>
            </div>
            {/* banner sec close */}
            <div className="content py-0">
              <div className="homepage-wrap">
                <div className="about-us-content sec-sm-pad">
                  <div className="container">
                    <div className="sec-heading-title">
                      <h4 className="title">About Shevolve</h4>
                      <p className="sec-content">
                        If you have ever hated a Monday morning thinking of going to work, if you ever feel that your effort is not utilized
                        and your contribution is not appreciated, if you feel that you need help but fear being judged as weak, don’t worry,
                        you are not alone.
                      </p>
                      <p className="sec-content">
                        Shevolve is a platform to empower women along their career path to grow, navigate obstacles and reach their true
                        potential.
                      </p>
                      <p className="sec-content">
                        Our industry experts have the experience and knowledge to guide you to your dream career and with the support of our
                        life coaches, you’ll be mentally prepared to face all kinds of challenges.
                      </p>
                      <p className="sec-content">
                        Our handcrafted courses are to ensure that you stay abreast with the expectations of the industry.
                      </p>
                      <p className="sec-content">
                        The regular webinars/workshops we conduct are designed to keep you updated with the changes in the industry
                        standards.
                      </p>
                    </div>
                    {this.userType === "mentee" && this.props.userData && !this.props.userData.packageInfo && (
                      <TakeSurvey {...this.props.userData} />
                    )}
                  </div>
                </div>
                <EBook props={this.props} />
                <SocialMedia />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { userData: state.loginSignPopUp.userData };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching actions returned by action creators
    updatePackageInfo: () => dispatch(updatePackageInfo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewHomePage);
