import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Styles from "../../../styles";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT, updateProfileAction } from "../../../reducer/action";
import { useHistory, useRouteMatch } from "react-router-dom";
import './header.scss'
import { toast } from 'react-toastify';
import { submitFreeTrial } from "../../../adminPanel/networkRequests/requests/subscription";
import { industries } from "../../Constants";

const NewHeader = (props) => {
  const { location, push } = useHistory();
  const userType = localStorage.getItem("userType");
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { login, userData } = useSelector((state) => state.loginSignPopUp);

  // Whatsapp Redirect Timer Statex
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    if (!login && localStorage.getItem("accessToken")) {
      dispatch(updateProfileAction());
    }
  }, []);

  const [formSend, setFormSend] = useState(false)
  const [scheduledDemo, setScheduledDemo] = useState(false)
  const [userTypeMentorMentee, setUserTypeMentorMentee] = useState('')

  const initialFormState = {want_to_be: '', working_professional: '', schedule_demo: '',career_break: '', name: '', email: '', phone: '', industry: '', schedule_date: '', schedule_demo_time: ''}
  // Form Field State Variable Definition
  const [form, setForm] = useState(initialFormState);
    const [formError, setFormError] = useState({
        // name: false,
        // email: false,
        // emailVal: false,
        // phone: false
    })

    const modelClose = useRef(null);

  const handleLogOut = () => {
    localStorage.clear();
    dispatch({ type: LOGOUT });
    window.location.reload(false);
  };

  // const executeToScroll = (elementId) => {
  //   push('/program');
  //   window.scrollTo(0, elementId);

  //   console.log("elementIDVal", elementId);

  // }

  let { pathname } = location;

  // const [position, setPosition] = useState(window.pageYOffset)
  //   const [visible, setVisible] = useState(true) 

  //   console.log("prevPos", position)

  //   useEffect(()=> {
  //       const handleScroll = () => {
  //          let moving = window.pageYOffset
           
  //          setVisible(position > moving);
  //          setPosition(moving)
  //       };
  //       window.addEventListener("scroll", handleScroll);
  //       return(() => {
  //          window.removeEventListener("scroll", handleScroll);
  //       })
  //   })

  // const cls = visible ? "visible" : "hidden";

  const formValidate = () => {

    var re = /\S+@\S+\.\S+/;
    const phone_regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    setFormError({name: false, phone: false, email: false, want_to_be: false, career_break: false, schedule_demo: false, industry: false, schedule_date: false, schedule_demo_time: false})

    // Form Field Validation
    if(form.name == "" || form.phone == "" || !phone_regex.test(form.phone) || form.email == "" || !re.test(form.email) || form.want_to_be == "" || form.schedule_demo == "" || (form.schedule_demo == "yes" && form.schedule_date == "") || (form.schedule_demo == "yes" && form.schedule_demo_time == "")){
        if(form.name == "") { 
            setFormError(prevState => ({
                ...prevState,
                name: true
            }))
        }
        if(form.phone == "" || !phone_regex.test(form.phone)) {
            setFormError(prevState => ({
                ...prevState,
                phone: true
            }))
        }
        if(form.email == "" || !re.test(form.email)) {
            setFormError(prevState => ({
                ...prevState,
                email: true
            }))
        }
        if(form.want_to_be == "") { 
          setFormError(prevState => ({
              ...prevState,
              want_to_be: true
          }))
        }
        // if(form.working_professional == "") { 
        //   setFormError(prevState => ({
        //       ...prevState,
        //       working_professional: true
        //   }))
        // }
        if(form.schedule_demo == "") { 
          setFormError(prevState => ({
              ...prevState,
              schedule_demo: true
          }))
        }
        if(form.industry == "") { 
          setFormError(prevState => ({
              ...prevState,
              industry: true
          }))
        }
        if(form.schedule_demo == "yes" && form.schedule_date == "") { 
          setFormError(prevState => ({
              ...prevState,
              schedule_date: true
          }))
        }
        if(form.schedule_demo == "yes" && form.schedule_demo_time == "") { 
          setFormError(prevState => ({
              ...prevState,
              schedule_demo_time: true
          }))
        }

        return false
    } else {            
        return true
    }

}

  const onSubmiting = (e) => {
    e.preventDefault();
    if(formValidate()) {
      submitFreeTrial(form).then(res => {
          console.log(res);
          setFormSend(true)

          if(form.schedule_demo == 'yes')
            setScheduledDemo(true)
          else
            setScheduledDemo(false)
            
          const userType = form.want_to_be;
          setUserTypeMentorMentee(userType)

          setForm(initialFormState);
          // modelClose.current.click();

          // After form submitted, start the WhatsApp Redirect Timer
          let redirectTimer = 6;
          const interval = setInterval(() => {
            setSeconds(--redirectTimer);

            // When the timer hits zero, clear the Interval state from loop and open the Whatsapp in new tab.
            if(redirectTimer == 0){
              clearInterval(interval)
              if(userType == 'mentee')
                window.open('https://wa.me/919841003516', '_blank');
              else if(userType == 'mentor')
                window.open('https://wa.me/919790991864', '_blank');
            }
          }, 1000);

      })
      .catch((err) => {
          console.log("error", err);
          toast.error('Something went wrong!');
      });
    }

  }

  const handleInputChange = e => {

    const { name, value } = e.target;
    if(name == "want_to_be"){ 
      setForm(prevState => ({
          ...initialFormState,
          [name]: value
      }));
    }else{
      setForm(prevState => ({
          ...prevState,
          [name]: value
      }));
    }
};

// TimeSlots for the Free Trial Demo Session
const timeSlots = ['10:00AM - 10:30AM' , '10:30AM - 11:00AM' , '11:00AM - 11:30AM' , '11:30AM - 12:00PM', '12:00PM - 12:30PM', '12:30PM - 01:00PM', '01:00PM - 01:30PM', '01:30PM - 02:00PM', '02:00PM - 02:30PM', '02:30PM - 03:00PM', '03:00PM - 03:30PM', '03:30PM - 04:00PM', '04:00PM - 04:30PM', '04:30PM - 05:00PM'];
  
  return (
    <>
      <Styles />

        <div className="modal fade bd-example-modal-xl" id="exampleModalFreeTrial" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ zIndex: "10000" }}>
          <div className="modal-dialog modal-xl sh-modal__trails" role="document" >
              <div className="modal-content">
                  <button type="button" className="close sh-modal__btn-close" data-dismiss="modal" aria-label="Close" ref={modelClose} style={{right: 10, top: 10, fontSize: "2rem"}}>
                  <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="sh-modal__trails-title sh-title font-weight-light my-4">
                                    <span className="sh-offering__modal-header sh-title--primary d-inline">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="22.853" height="30.957" viewBox="0 0 32.853 60.957"><g id="Group_62910" data-name="Group 62910" transform="translate(0 0)"><path id="Path_84765" data-name="Path 84765" d="M22.872,16.835A24.774,24.774,0,0,0,6.089,0,24.8,24.8,0,0,0,17.782,40.055a24.775,24.775,0,0,0,5.09-23.22" transform="translate(0 5.775) rotate(-14)" fill="#007a87"></path><g id="Group_61026" data-name="Group 61026" transform="translate(0 39.412)"><path id="Path_84764" data-name="Path 84764" d="M9,74.808a13.855,13.855,0,0,0-9,9.785,13.868,13.868,0,0,0,22.093-7.5A13.853,13.853,0,0,0,9,74.808" transform="translate(0 -74.067)" fill="#007a87"></path></g></g></svg>
                                      Free Trial Sign Up  
                                    </span><br />
                                  </div>
                  <div className="modal-body sh-offering__modal-body p-0">
                      {/* <div className="sh-title">Reach out to us !</div> */}
                        {!formSend ?
                          <div className="row m-0">
                              <div className="sh-offering__modal-left align-items-center col-lg-6 p-4">
                                  <div className="sh-offering__modal-title">
                                      Fill in the details and Sign Up for a 
                                      <span className="sh-title--primary d-inline font-weight-bold mx-2">
                                        free trial of mentoring  
                                      </span> 
                                      from the Shevolve Team
                                  </div>
                                    
                                <img className="img-fluid w-75" src="./images/header/free-trial.png" alt="Free Trail" />
                              </div>
                              <div className="col-lg-6 py-4">
                                <div className="px-5 pt-5 pt-xl-0 py-4">
                                  {/* <div className="sh-modal__trails-title sh-title font-weight-light my-4">
                                    <span className="sh-offering__modal-header sh-title--primary d-inline">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="22.853" height="30.957" viewBox="0 0 32.853 60.957"><g id="Group_62910" data-name="Group 62910" transform="translate(0 0)"><path id="Path_84765" data-name="Path 84765" d="M22.872,16.835A24.774,24.774,0,0,0,6.089,0,24.8,24.8,0,0,0,17.782,40.055a24.775,24.775,0,0,0,5.09-23.22" transform="translate(0 5.775) rotate(-14)" fill="#007a87"></path><g id="Group_61026" data-name="Group 61026" transform="translate(0 39.412)"><path id="Path_84764" data-name="Path 84764" d="M9,74.808a13.855,13.855,0,0,0-9,9.785,13.868,13.868,0,0,0,22.093-7.5A13.853,13.853,0,0,0,9,74.808" transform="translate(0 -74.067)" fill="#007a87"></path></g></g></svg>
                                      Free Trial Sign Up  
                                    </span><br />
                                  </div> */}
                                  <form onSubmit={onSubmiting}>
                                      <div className="sh-offering__modal-input mb-2">
                                          <span className="sh-offering__modal-label mb-2 mr-4" style={{display: 'inline'}}>I want to be a :</span>

                                          <label for="mentee" className="sh-offering__modal-form-control mr-2">
                                            Mentee
                                            <input type="radio" id="mentee" name="want_to_be" value="mentee" onChange={handleInputChange}/>
                                          </label>
                                          <label for="mentor" className="sh-offering__modal-form-control">
                                            Mentor
                                            <input type="radio" id="mentor" name="want_to_be" value="mentor" onChange={handleInputChange}/>
                                          </label>
                                          {formError.want_to_be === true &&
                                              <span className="sh-entry__error-text d-block">Please choose one option</span>
                                          }
                                      </div>

                                      {/* {
                                        form.want_to_be == 'mentor' && 
                                        <div className="sh-offering__modal-input mb-3">
                                            <span className="sh-offering__modal-label mb-2">Are you working professional?</span>

                                            <label className="sh-offering__modal-form-control mr-4">
                                              Yes
                                              <input type="radio" name="working_professional" value="yes" onChange={handleInputChange}/>
                                            </label>
                                            <label className="sh-offering__modal-form-control">
                                              No
                                              <input type="radio" name="working_professional" value="no" onChange={handleInputChange} />
                                            </label>
                                            {formError.working_professional === true &&
                                                <span className="sh-entry__error-text d-block">Please choose one option</span>
                                            }
                                        </div>
                                      } */}

                                      {
                                        form.want_to_be == 'mentee' && 
                                          <div className="sh-offering__modal-input mb-2">
                                              <span className="sh-offering__modal-label mb-2 mr-4" style={{display: 'inline'}}>Are you on Career Break?</span>

                                              <label className="sh-offering__modal-form-control mr-4">
                                                Yes
                                                <input type="radio" name="career_break" value="yes" onChange={handleInputChange}/>
                                              </label>
                                              <label className="sh-offering__modal-form-control">
                                                No
                                                <input type="radio" name="career_break" value="no" onChange={handleInputChange} />
                                              </label>
                                              {formError.career_break === true &&
                                                  <span className="sh-entry__error-text d-block">Please choose one option</span>
                                              }
                                          </div>
                                        }

                                      {/* Only if the user is a working professional, enable the option to choose the industry */}
                                      {
                                        // (form.working_professional == 'yes' || (form.career_break == 'no' || form.career_break == 'yes')) && 
                                        // (form.working_professional != 'no' || (form.working_professional == 'no' && form.want_to_be == 'mentee') ) &&
                                        ((form.want_to_be == 'mentee' && (form.career_break == 'no') ) || (form.want_to_be == 'mentor') ) &&
                                          <div className="sh-offering__modal-input select-group mb-2">
                                            <span className="sh-offering__modal-label mb-3">Industry / Domain?</span>
                                            <select
                                              className="form-control mb-3"
                                              id="industry"
                                              name="industry"
                                              value={form.industry}
                                              onChange={handleInputChange}
                                            >
                                              {industries.map((industry) => (
                                                <option key={industry} value={industry}>
                                                  {industry}
                                                </option>
                                              ))}
                                            </select>
                                            {formError.industry === true &&
                                                <span className="sh-entry__error-text d-block">Industry should not be blank</span>
                                            }
                                          </div>
                                      }
                                      <div className="sh-offering__modal-input mb-3">
                                          <input className="w-100" type="text" name="name" placeholder='Name' value={form.name} onChange={handleInputChange}/>
                                          {formError.name === true &&
                                              <span className="sh-entry__error-text d-block">Please enter required filled</span>
                                          }
                                      </div>
                                      <div className="sh-offering__modal-input mb-3">
                                          <input className="w-100" type="email" name="email" placeholder='Email Address' value={form.email} onChange={handleInputChange} />
                                          {formError.email === true &&
                                              <span className="sh-entry__error-text d-block">Please enter a valid email address</span>
                                          }
                                      </div>
                                      <div className="sh-offering__modal-input mb-3">
                                          <input className="w-100" type="tel" name="phone" placeholder='Phone Number' value={form.phone} onChange={handleInputChange} />
                                          {formError.phone === true &&
                                              <span className="sh-entry__error-text d-block">Please enter a valid Phone number in these format: 1234567890, +31636363634, 075-63546725</span>
                                          }
                                      </div>

                                      {
                                        ((form.want_to_be == 'mentee' && form.career_break == 'no') || (form.want_to_be == 'mentor')) &&
                                          <div className="sh-offering__modal-input mb-3">
                                              <input className="w-100" type="text" name="current_role" placeholder='Current Role' value={form.current_role} onChange={handleInputChange}/>
                                              {formError.career_break === true &&
                                                  <span className="sh-entry__error-text d-block">Please choose one option</span>
                                              }
                                          </div>
                                      }

                                      <div className="sh-offering__modal-input mb-3">
                                          <span className="sh-offering__modal-label mb-2 d-inline mr-4" style={{display: 'inline'}}>Do you like to schedule a free trial?</span>

                                          <label className="sh-offering__modal-form-control mr-4">
                                            Yes
                                            <input type="radio" name="schedule_demo" value="yes" onChange={handleInputChange}/>
                                          </label>
                                          <label className="sh-offering__modal-form-control">
                                            No
                                            <input type="radio" name="schedule_demo" value="no" onChange={handleInputChange} />
                                          </label>
                                          {formError.schedule_demo === true &&
                                              <span className="sh-entry__error-text d-block">Please enter required filled</span>
                                          }
                                      </div>
                                      {/* If the user wantes to opt-in for Free Demo, ask them to choose their availabile Date and Time  */}
                                      {
                                        form.schedule_demo == 'yes' &&
                                        <>
                                          <div className="sh-offering__modal-input mb-4">
                                              <span className="sh-offering__modal-label mb-2">Choose your availability:</span>
                                              <input className="w-100" type="date" name="schedule_date" placeholder='22/12/2023' min={new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().slice(0, 10)} onChange={handleInputChange}/>
                                              {formError.schedule_date === true &&
                                                  <span className="sh-entry__error-text d-block">Please enter required filled</span>
                                              }
                                          </div>

                                          <div className="sh-offering__modal-input mb-3">
                                              <span className="sh-offering__modal-label mb-3">Choose your time:</span>

                                              <select
                                                className="form-control mb-3"
                                                id="schedule_demo_time"
                                                name="schedule_demo_time"
                                                value={form.schedule_demo_time}
                                                onChange={handleInputChange}
                                              >
                                                {timeSlots.map((timeSlot, i) => (
                                                  <option key={i} value={timeSlot}>
                                                    {timeSlot}
                                                  </option>
                                                ))}
                                              </select>                

                                              {formError.schedule_demo_time === true &&
                                                  <span className="sh-entry__error-text d-block">Please enter required filled</span>
                                              }
                                          </div>

                                          {/* <div className="sh-offering__modal-input mb-3">
                                              <span className="sh-offering__modal-label mb-3">Choose your time:</span>

                                              {
                                                timeSlots.map((time) => {
                                                  return (
                                                    <label className="sh-offering__modal-form-time">
                                                      {time}
                                                      <input type="radio" name="schedule_demo_time" value={time} onChange={handleInputChange}/>
                                                    </label>
                                                  )
                                                })
                                              }                                         

                                              {formError.schedule_demo_time === true &&
                                                  <span className="sh-entry__error-text d-block">Please enter required filled</span>
                                              }
                                          </div> */}
                                        </>
                                      }
                                      

                                      <div className="text-right pb-4">
                                          <button className="sh-button" type="submit">Submit</button>
                                      </div>
                                  </form>
                                </div>
                              </div>
                          </div> :
                          
                          <div className="py-5 d-flex align-items-center justify-content-center" style={{ height: 654 }}>
                            <div className="text-center">
                              <div className="mb-3"><img src="./images/header/success.svg" alt="" /></div>

                              {
                                scheduledDemo == true ? 
                                <>                                  
                                  <p className="sh-para sh-modal__trail-para">Thanks for signing up for Free trial and scheduling the slot. You have taken the right first step for yourself.</p>
                                  <p className="sh-para sh-modal__trail-para">You will receive an email shortly with the meeting link. Please use the link to join the call at the scheduled time.</p>   
                                </>
                                :
                                <>
                                  <div className="sh-para sh-title--primary">Great that you have taken the first step to sign up for Free Trial. </div>
                                  <p className="sh-para sh-modal__trail-para">Shevolve Team will contact you shortly for next steps.</p>
                                </>
                              }
                              
                              
                              {/* When user submis the Form, after 5 seconnds, they will be redirected to WhatsApp and for the immediate redirect. they can click on the hyperlink */}                                                         
                              <p className="sh-para sh-modal__trail-para mt-3">You will be redirected to WhatsApp shortly in <span className="sh-title--primary font-weight-bold">0{seconds}</span> seconds! <br />or to redirect immediately click 
                                {userTypeMentorMentee == 'mentee' ? 
                                  <a href="https://wa.me/919841003516" target="_blank" className="sh-title--primary ml-2 font-weight-bold"> here</a> 
                                :
                                  <a href="https://wa.me/919790991864" target="_blank" className="sh-title--primary ml-2 font-weight-bold"> here</a> 
                                }
                                
                              </p>
                            </div>
                          </div>
                        }
                      {/* <div className="sh-offering__modal-image">
                          <img src="./images/offering/offering-form-butterfly.svg" alt="" />
                      </div> */}
                  </div>
              </div>
          </div>
        </div>
        
        <header id="header-sec" className={`sh-header`}>
          <div className="header-wrap">
            <div>
              <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark theme-navbar pl-0">
                  <Link className="navbar-brand" to="/">
                    <img src="./images/logo.svg" width={137} alt="header-logo" />
                  </Link>
                  <button
                    className="navbar-toggler collapsed px-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#main-header"
                    aria-controls="main-header"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    {/* <span className="navbar-toggler-icon" /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                      <path fill="#000000" fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                  </button>
                  <div className="navbar-collapse collapse justify-content-end" id="main-header">
                    {/* {(localStorage.getItem('accessToken') || userType !== "mentor") && ( */}
                      <ul className="navbar-nav ml-auto">
                        <li
                          className={
                            pathname === `/program/${match.params.blogId}` || pathname === "/subscription/individual" ? "nav-item active programe-link sh-header__menu-dropdown" : "nav-item programe-link sh-header__menu-dropdown"
                          }
                        >
                          <a class={`nav-link dropdown-toggle sh-header__menu-link d-flex align-items-center ${pathname === "/subscription/individual" || pathname === "/subscription/corporate" ? "active" : ""}`} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Subscription
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down ml-2" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                          </svg>
                          </a>
                          <div class="dropdown-menu mt-0" aria-labelledby="navbarDropdown">
                            <a class={`sh-header__menu-link dropdown-item px-4 py-2 ${pathname === "/subscription/individual" ? "active" : ""}`} onClick={() => push('/subscription/individual')}>Individual</a>
                            <a class={`sh-header__menu-link dropdown-item px-4 py-2 ${pathname === "/subscription/corporate" ? "active" : ""}`} onClick={() => push('/subscription/corporate')}>Corporate</a>
                          </div>

                        </li>
                        
                        {/* <li className={pathname === "/" || pathname === "/home" ? "nav-item active" : "nav-item"}>
                          <Link className="nav-link sh-header__menu-link" to="/">
                            Home
                          </Link>
                        </li> */}
                        <li className={pathname === "/" || pathname === "/hermentor" ? "nav-item active" : "nav-item"}>
                          <Link className={`nav-link sh-header__menu-link ${pathname === "/hermentor" ? "active" : ""}`} to="/hermentor">
                            HerMentor
                          </Link>
                        </li>
                        <li className={pathname === "/" || pathname === "/events" ? "nav-item active" : "nav-item"}>
                          <Link className={`nav-link sh-header__menu-link ${pathname === "/events" ? "active" : ""}`} to="/events">
                            Events
                          </Link>
                        </li>

                        <li
                          className={
                            pathname === `/blogTab/${match.params.blogId}` || pathname === "/shewrites" ? "nav-item active" : "nav-item"
                          }
                        >
                          <Link className={`nav-link sh-header__menu-link  ${pathname === "/shewrites" || pathname === `/blogDetail/${match.params.blogId}` ? "active" : ""}`} to="/shewrites">
                            SheWrites
                          </Link>
                        </li>

                        <li
                          className={
                            pathname === `/about/${match.params.blogId}` || pathname === "/about" ? "nav-item active" : "nav-item"
                          }
                        >
                          <Link className={`nav-link sh-header__menu-link ${pathname === "/about" ? "active" : ""}`} to="/about">
                            About
                          </Link>
                        </li>

                        <li
                          className={
                            pathname === `/mentors/${match.params.blogId}` || pathname === "/ourmentors" ? "nav-item active" : "nav-item"
                          }
                        >
                          <Link className={`nav-link sh-header__menu-link ${pathname === "/ourmentors" ? "active" : ""}`} to="/ourmentors">
                            OurMentors
                          </Link>
                        </li>

                        

                        {/* <li
                          className={
                            pathname === `/blogTab/${match.params.blogId}` || pathname === "/blogTab" ? "nav-item active" : "nav-item"
                          }
                        >
                          <Link className="nav-link sh-header__menu-link" to="/blogTab">
                            Media
                          </Link>
                        </li> */}

                      
{/* 
                        <li
                          className={
                            pathname === `/career/${match.params.blogId}` || pathname === "/career" ? "nav-item active" : "nav-item"
                          }
                        > */}
                          {/* <Link className="nav-link" to="/team"> */}
                            {/* <a className='nav-link sh-header__menu-link' href='https://shevolvecareers.com/' target='_blank'>
                            Career
                            </a> */}
                            
                          {/* </Link> */}
                        {/* </li> */}
                        {/* <li
                          className={
                            pathname === `/youtubeVideos/${match.params.videoId}` || pathname === "/youtubeVideos"
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/youtubeVideos">
                            Videos
                          </Link>
                        </li> */}
                        {/* <li
                          className={
                            pathname === `/eventDetail/${match.params.eventId}` || pathname === "/events" ? "nav-item active" : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/events">
                            Events
                          </Link>
                        </li> */}

                        {/* <li
                          className={
                            pathname === `/courses-details/${match.params.eventId}` || pathname === "/courses"
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/courses">
                            Courses
                          </Link>
                        </li> */}
                        {/* <li className={pathname === "/about-us" ? "nav-item active" : "nav-item"}>
                          <Link className="nav-link" to="/about-us">
                            About Us
                          </Link>
                        </li> */}
                        {/* <li className={pathname === "/contact-us" ? "nav-item active" : "nav-item"}>
                          <Link className="nav-link" to="/contact-us">
                            Contact Us
                          </Link>
                        </li> */}
                      </ul>
                    {/* )} */}
                    {Object.keys(userData).length === 0 ? (
                      <div className="btn-wrap">
                        {/* <Link
                          className="sh-header__menu-register hover-color"
                          // style={{fontSize: 14, padding: '5px 10px'}}
                          to={{
                            pathname: "/WhoIsSigning",
                            state: { from: pathname },
                          }}
                        >
                        Free Trial
                        </Link> */}
                        <a className="sh-header__menu-register hover-color" data-toggle="modal" data-target="#exampleModalFreeTrial">Free Trial</a>

                        <Link
                          className="sh-button--lined sh-header__menu-register hover-color ml-2"
                          // style={{fontSize: 14, padding: '5px 10px'}}
                          to={{
                            pathname: "/login",
                            state: { from: pathname },
                          }}
                        >
                        Login / Signup
                        </Link>


                      </div>
                    ) : (
                      <div className="dropdown sh-header__menu-dropdown">
                        <button
                          className="btn dropdown-toggle p-0 shadow-none sh-header__menu-profile"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="head-name-text green-bg-light-color" style={{ padding: "12px 20px" }}>
                            <div className="flex-content d-flex align-items-center">
                              <p className="sec-title sec-ti mb-0 " style={{ fontSize: 12, fontWeight: 600, color: "#007A87" }}>
                                {userData.name}
                              </p>
                              <i class="fas fa-user ml-2 f-size-12"></i>

                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down ml-4 " viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg>
                            </div>
                          </div>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a href="javascript:void(0)" className="dropdown-item sh-header__menu-link" onClick={(e) => push("/profile")}>
                            My Profile
                          </a>

                          {/* <Link to="/profile/edit">
                            <a
                              href="javascript:void(0)"
                              className="dropdown-item"
                              // onClick={e =>  props.history.push('/profile')}
                            >
                              Edit Profile
                            </a>
                          </Link> */}
                          <a href="javascript:void(0)" className="dropdown-item sh-header__menu-link" onClick={handleLogOut}>
                            {" "}
                            Log Out
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
    </>
  );
};

export default NewHeader;