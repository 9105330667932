import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const AnimWrap = (props) => {

    const sections = gsap.utils.toArray(".sh-section");
    const tl = useRef();

    useEffect(() => {

          // console.log("erer", sections[1]);

          // tl.current = gsap.timeline(sections[1], {
          //     scrollTrigger: {
          //         trigger: sections[1],
          //         start: 'top bottom',
          //       }
          // });




          sections.forEach((section) => {


            tl.current = gsap.timeline({
                  scrollTrigger: {
                      trigger: section,
                      start: 'top 80%',
                    }
              });

              let stagger_fade = section.querySelectorAll(".sh-anim__stagger-fade")
              let stagger_top = section.querySelectorAll(".sh-anim__stagger-fade-top")
              let stagger_bottom = section.querySelectorAll(".sh-anim__stagger-fade-bottom")
              let stagger_left = section.querySelectorAll(".sh-anim__stagger-fade-left")
              let stagger_right = section.querySelectorAll(".sh-anim__stagger-fade-right")
              // console.log("sfade", stagger_top)


              tl.current
                .to(stagger_fade, {opacity:1, duration: 0.2, delay: 0.1, stagger: 0},)
                .to(stagger_top, {opacity:1, y: 0, duration: 0.2, delay: 0.1, stagger: 0.1}, 0)
                .to(stagger_bottom, {opacity:1, y: 0, duration: 0.2, delay: 0.1, stagger: 0.1}, 0) 
                .to(stagger_left, {opacity:1, x: 0, duration: 0.2, delay: 0.1, stagger: 0.1}, 0)
                .to(stagger_right, {opacity:1, x: 0, duration: 0.2, delay: 0.1, stagger: 0.1}, 0)


                //HOME PAGE PROCESS ANIMATION
                let process_card1 = section.querySelectorAll(".sh-anim__stagger-process-card1")
                let process_card2 = section.querySelectorAll(".sh-anim__stagger-process-card2")
                let process_card3 = section.querySelectorAll(".sh-anim__stagger-process-card3")
                let process_line = section.querySelectorAll(".sh-anim__stagger-process-line")
                let process_line2 = section.querySelectorAll(".sh-anim__stagger-process-line2")

                tl.current
                  .to(process_card1, {opacity:1, scale: 1, duration: 0.2})
                  .to(process_line, {opacity:1, duration: 0.2})
                  .to(process_card2, {opacity:1, scale: 1, duration: 0.2})
                  .to(process_line2, {opacity:1, duration: 0.2})
                  .to(process_card3, {opacity:1, scale: 1, duration: 0.2})
                
          });


    
        // tl.current = gsap.timeline()
        //   .to(sections(".sh-anim__stagger-fade"), {opacity:1, delay: 0, stagger: 0})
        //   .to(sections(".sh-anim__stagger-fade-top"), {opacity:1, y: 0, delay: 0.1, stagger: 0.1})
        //   .to(sections(".sh-anim__stagger-fade-bottom"), {opacity:1});
    
    }, [sections]);

    return (
      <div className={`sh-anim__wrap ${props.additionalClass}`}>
        {props.children}
      </div>
    );
}

// export const AnimTop = ({children}) => {
//   return (
//       <div className="sh-anim__stagger-fade-top">{children}</div>
//   )
// }

// export const AnimBottom = ({children}) => {
//   return (
//       <div className="sh-anim__stagger-fade-bottom">{children}</div>
//   )
// }
