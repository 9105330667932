import axios from 'axios'
import { BASE_URL } from '../../../constant';
const AxiosBase = axios.create({
    baseURL: BASE_URL
  // baseURL:"http://13.233.214.122:8000/",

  // process.env.NODE_ENV === "development"
  //   ?
  // "http://localhost:8000/"
  //   :
   // baseURL: "https://api.shevolve.in/"
  // $web.BIT-BuCKet@AAtiVA@909$
});
AxiosBase.interceptors.request.use(
    async(config) => {
       config.headers['adminAccessToken'] = await localStorage.getItem('adminAccessToken');
    //    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        return config;
    },
    error => {
        Promise.reject(error);
    }
)
AxiosBase.interceptors.response.use((response) => response, (error) => {
    if (error && error.response && error.response.status === 401) {
     // localStorage.removeItem('adminAccessToken');
     // window.location.href = '/'
     // window.location = 'localhost:3000';
    }
    return error;
  });


export default AxiosBase;

export const handleError = (error) => {
    alert('handle Error')
    console.log('HandleError : ',JSON.stringify(error))
    if(error.response.status === 404){
        // showMessage(error.response.data.Message)
    }
}