import React, { useRef, useLayoutEffect, useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import Layout from "../../layout/default";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Testimonials } from "../testimonials";
import {Helmet} from "react-helmet"
import {AnimWrap} from "../animation-gsap/Animation"
import { toast } from 'react-toastify';
import { submitContactUs } from '../../../adminPanel/networkRequests/requests/subscription';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import MentorTestimonials from './mentor-testimonials';

SwiperCore.use([Autoplay]);

export default function BeAMentor() {
    const history = useHistory();


    // const myRef = useRef(null);

    const [ip, setIp] = useState([]);

    const sectionId = document.getElementById("subscriptionSection");
    // const secTopVal = sectionId

    const modelClose = useRef(null);

    const [form, setForm] = useState({name: '', company_name: '', email: '', phone: '',  from_page: 'Her Mentor Page'});
    const [formError, setFormError] = useState({
        // name: false,
        // email: false,
        // emailVal: false,
        // phone: false
    })


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        setTimeout(
            () => setIp('res.data'), 
            100
          );
        
    }, []);

    const faq_data = [
        {
            question: "How much time does this require?",
            answer: "Based on the rate card that you pick, you will require a minimum of 30/45 minutes per week."
        },
        {
            question: "How long is a session?",
            answer: "A trial session is 30 minutes and a normal session is 45-60 minutes."
        },
        {
            question: "Will my personal details be shared with the femtees?",
            answer: "No. We will create a group where your number will be hidden and all sessions will be conducted via ZOOM."
        },
        {
            question: "How much do mentors get paid?",
            answer: "Depending on the mentor rate card that you pick, mentors can either go for pro-bono sessions or be paid based on time spent and number of sessions."
        },
        {
            question: "Do I have to accept every session?",
            answer: "No. Your options for every session request are to accept, reschedule or decline."
        },
        {
            question: "What type of femtees will reach out to me?",
            answer: "All our femtees will be vetted and prepped before the session. Women are typically working, women on a break or CXOs of women-led startups."
        }
    ]

    const secondSection = useRef(null)

    const scrollHandler = () => {
        secondSection.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const handleInputChange = e => {
        const { name, value } = e.target;
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const formValidate = () => {

        var re = /\S+@\S+\.\S+/;
        const phone_regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        setFormError({name: false, phone: false, email: false})

        if(form.name == "" || form.phone == "" || !phone_regex.test(form.phone) || form.email == "" || !re.test(form.email)){
            if(form.name == "") { 
                setFormError(prevState => ({
                    ...prevState,
                    name: true
                }))
            }
            if(form.phone == "" || !phone_regex.test(form.phone)) {
                setFormError(prevState => ({
                    ...prevState,
                    phone: true
                }))
            }
            if(form.email == "" || !re.test(form.email)) {
                setFormError(prevState => ({
                    ...prevState,
                    email: true
                }))
            }
            return false
        } else {            
            return true
        }

    }


    const onSubmiting = () => {
        
        if(formValidate()) {
            submitContactUs(form).then(res => {
                console.log(res);
                toast.success('Our team will reach you shortly');
                setForm({name: '', company_name: '', email: '', phone: '', from_page: 'Her Mentor'});
                modelClose.current.click();
            })
            .catch((err) => {
                console.log("error", err);
                toast.error('Something went wrong!');
            });
        }
    }

    const [selectedTestimonial, setSelectedTestimonial] = useState(Testimonials.mentors[0]);

    return (
        <Layout>

<Helmet>
                    <title>HerMentor | Building a Community of Extraordinary Women | Shevolve</title>
                    <meta name="description" content="Discover Shevolve's HerMentor program, offering you the step by step guide for your successful future and being creative in your desired field." />
                    <meta name="keywords" content="Shevolve, HerMentor, Online career guidance, Women empowerment, Mentorship program, Career advancement, Women in careers, Professional development, Female mentors, Mentorship network, Career mentoring, Women's community, Empowerment platform, Personalized mentorship, Career growth opportunities, Women's leadership, Career support, Women's career development, Online mentoring, Female professionals" />

                    <meta property="og:title" content="HerMentor | Building a Community of Extraordinary Women | Shevolve" />
<meta property="og:description" content="Discover Shevolve's HerMentor program, offering you the step by step guide for your successful future and being creative in your desired field." />
<meta property="og:image" content="https://shevolve.in/images/home/slide/slide1.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="HerMentor | Building a Community of Extraordinary Women | Shevolve" />
<meta name="twitter:description" content="Discover Shevolve's HerMentor program, offering you the step by step guide for your successful future and being creative in your desired field." />
<meta name="twitter:image" content="https://shevolve.in/images/home/slide/slide1.png" />

                </Helmet>


            <AnimWrap />

            <div className="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document" style={{top: "100px"}}>
                    <div className="modal-content">
                        <button type="button" className="close sh-modal__btn-close" data-dismiss="modal" aria-label="Close" ref={modelClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body sh-offering__modal-body">
                            <div className="sh-title">Reach out to us !</div>
                                <div className="row">
                                    <div className="sh-offering__modal-input col-md-6 mb-4">
                                        <input className="w-100" type="text" name="name" placeholder='Your Name*' value={form.name} onChange={handleInputChange}/>
                                        {formError.name === true &&
                                            <span className="sh-entry__error-text mt-2">Please enter required filled</span>
                                        }
                                    </div>
                                    <div className="sh-offering__modal-input col-md-6 mb-4">
                                        <input className="w-100" type="text" name="company_name" id="" placeholder='Company Name' value={form.company_name} onChange={handleInputChange} />
                                    </div>
                                    <div className="sh-offering__modal-input col-md-6 mb-4">
                                        <input className="w-100" type="email" name="email" id="" placeholder='Work Email*' value={form.email} onChange={handleInputChange} />
                                        {formError.email === true &&
                                            <span className="sh-entry__error-text mt-2">Please enter a valid email address</span>
                                        }
                                    </div>
                                    <div className="sh-offering__modal-input col-md-6 mb-4">
                                        <input className="w-100" type="tel" name="phone" id="" placeholder='Phone*' value={form.phone} onChange={handleInputChange} />
                                        {formError.phone === true &&
                                            <span className="sh-entry__error-text mt-2">Please enter a valid Phone number</span>
                                        }
                                    </div>
                                </div>
                                <div className="text-right pt-3">
                                    <button className="sh-button" onClick={onSubmiting}>Submit</button>
                                </div>
                            <div className="sh-offering__modal-image">
                                <img src="./images/offering/offering-form-butterfly.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/*=========================================
            ===================== Banner ================
            ============================================ */}
            <section className="sh-section sh-section__banner pb-0">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb-3 mb-md-0">
                                <div className="sh-banner__content">
                                    <h1 className="sh-banner__title sh-anim__stagger-fade-top">
                                        Be the foundation that builds a community of extraordinary women
                                        {/* <div className="sh-banner__title-medium">that empowers women to navigate </div> */}
                                    </h1>
                                    {/* <div className="sh-banner__title sh-banner__title--grey">successful futures!</div> */}
                                    <p className="sh-para sh-banner__para--grey my-3 sh-anim__stagger-fade-top">Channel your extensive experience to help women advance in their careers</p>
                                    <a className="sh-button sh-anim__stagger-fade-top" onClick={scrollHandler}>Read More</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="text-center sh-anim__stagger-fade-right">
                                    <LazyLoadImage
                                        class="img-fluid"
                                        alt="Be the foundation that builds a community of extraordinary women | Shevolve"
                                        src="./images/be-a-mentor/banner-image.svg" // use normal <img> attributes as props
                                    />
                                    {/* <img className="img-fluid" src="./images/be-a-mentor/banner-image.svg" alt="" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="position-absolute sh-section__curved-bottom w-100"><img className="img-fluid w-100" src="./images/home/curve-divider.png" /></div>
            </section>

             {/* ================ Redesigned the Entrie "Why mentors love us?" Section ================ */}
            <MentorTestimonials />

            {/*=============================================================================
            ===================== A centre for teaching & Enhance your personal brand ================
            ================================================================================ */}
            <section ref={secondSection} className="sh-section">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="row align-items-center sh-mentor__row mb-4">
                            <div className="col-md-4 order-2 order-md-1">
                                <div className="sh-anim__stagger-fade-left">
                                    <img className="img-fluid" src="./images/be-a-mentor/a-centre-for-teaching.svg" alt="A Hub for Femtees - Shevolve Career Evaluation Index will help vet and prep your femtees | Shevolve" />
                                </div>
                            </div>
                            <div className="col-md-6 offset-md-1 order-1 order-md-2">
                                <div className="sh-content__wrap sh-section__title-wrap">
                                    <div className="sh-subtitle sh-anim__stagger-fade-top">A Hub for Femtees</div>
                                    <div className="sh-title sh-title--light sh-anim__stagger-fade-top"> Upskill <span className="font-weight-bold">Unstoppable</span> women.</div>
                                    <ul className="sh-list__items mt-3 mt-md-4">
                                        <li className="sh-list__item d-flex align-items-start sh-anim__stagger-fade-top">
                                            <div className="sh-list__item-icon mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26.853" height="50.957" viewBox="0 0 32.853 50.957">
                                                    <g id="Group_62910" data-name="Group 62910" transform="translate(0 0)">
                                                        <path id="Path_84765" data-name="Path 84765" d="M22.872,16.835A24.774,24.774,0,0,0,6.089,0,24.8,24.8,0,0,0,17.782,40.055a24.775,24.775,0,0,0,5.09-23.22" transform="translate(0 5.775) rotate(-14)" fill="#007a87"/>
                                                        <g id="Group_61026" data-name="Group 61026" transform="translate(0 39.412)">
                                                        <path id="Path_84764" data-name="Path 84764" d="M9,74.808a13.855,13.855,0,0,0-9,9.785,13.868,13.868,0,0,0,22.093-7.5A13.853,13.853,0,0,0,9,74.808" transform="translate(0 -74.067)" fill="#007a87"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="sh-list__item-label sh-text mt-4">Shevolve Career Evaluation Index will help vet and prep your femtees.</div>
                                        </li>
                                        <li className="sh-list__item d-flex align-items-start sh-anim__stagger-fade-top">
                                            <div className="sh-list__item-icon mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26.853" height="50.957" viewBox="0 0 32.853 50.957">
                                                    <g id="Group_62910" data-name="Group 62910" transform="translate(0 0)">
                                                        <path id="Path_84765" data-name="Path 84765" d="M22.872,16.835A24.774,24.774,0,0,0,6.089,0,24.8,24.8,0,0,0,17.782,40.055a24.775,24.775,0,0,0,5.09-23.22" transform="translate(0 5.775) rotate(-14)" fill="#007a87"/>
                                                        <g id="Group_61026" data-name="Group 61026" transform="translate(0 39.412)">
                                                        <path id="Path_84764" data-name="Path 84764" d="M9,74.808a13.855,13.855,0,0,0-9,9.785,13.868,13.868,0,0,0,22.093-7.5A13.853,13.853,0,0,0,9,74.808" transform="translate(0 -74.067)" fill="#007a87"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="sh-list__item-label sh-text mt-4">Save time with automated scheduling according to your preference.</div>
                                        </li>
                                        <li className="sh-list__item d-flex align-items-start sh-anim__stagger-fade-top">
                                            <div className="sh-list__item-icon mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26.853" height="50.957" viewBox="0 0 32.853 50.957">
                                                    <g id="Group_62910" data-name="Group 62910" transform="translate(0 0)">
                                                        <path id="Path_84765" data-name="Path 84765" d="M22.872,16.835A24.774,24.774,0,0,0,6.089,0,24.8,24.8,0,0,0,17.782,40.055a24.775,24.775,0,0,0,5.09-23.22" transform="translate(0 5.775) rotate(-14)" fill="#007a87"/>
                                                        <g id="Group_61026" data-name="Group 61026" transform="translate(0 39.412)">
                                                        <path id="Path_84764" data-name="Path 84764" d="M9,74.808a13.855,13.855,0,0,0-9,9.785,13.868,13.868,0,0,0,22.093-7.5A13.853,13.853,0,0,0,9,74.808" transform="translate(0 -74.067)" fill="#007a87"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="sh-list__item-label sh-text mt-4">Choose the best option with our mentor rate cards.</div>
                                        </li>
                                    </ul>
                                    <div className="mt-5">
                                        <Link className="sh-button sh-anim__stagger-fade-top" to="/newSignUp">Be a Mentor</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center sh-mentor__row">
                            <div className="col-md-6">
                                <div className="sh-content__wrap sh-section__title-wrap">
                                    <div className="sh-subtitle sh-anim__stagger-fade-top">Enhance your personal brand</div>
                                    <div className="sh-title sh-title--light sh-anim__stagger-fade-top"> Level up your <span className="font-weight-bold">mentoring</span> relationship</div>
                                    <ul className="sh-list__items mt-3 mt-md-4">
                                        <li className="sh-list__item d-flex align-items-start sh-anim__stagger-fade-top">
                                            <div className="sh-list__item-icon mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26.853" height="50.957" viewBox="0 0 32.853 50.957">
                                                    <g id="Group_62910" data-name="Group 62910" transform="translate(0 0)">
                                                        <path id="Path_84765" data-name="Path 84765" d="M22.872,16.835A24.774,24.774,0,0,0,6.089,0,24.8,24.8,0,0,0,17.782,40.055a24.775,24.775,0,0,0,5.09-23.22" transform="translate(0 5.775) rotate(-14)" fill="#007a87"/>
                                                        <g id="Group_61026" data-name="Group 61026" transform="translate(0 39.412)">
                                                        <path id="Path_84764" data-name="Path 84764" d="M9,74.808a13.855,13.855,0,0,0-9,9.785,13.868,13.868,0,0,0,22.093-7.5A13.853,13.853,0,0,0,9,74.808" transform="translate(0 -74.067)" fill="#007a87"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="sh-list__item-label sh-text mt-4">Build trust as a verified mentor</div>
                                        </li>
                                        <li className="sh-list__item d-flex align-items-start sh-anim__stagger-fade-top">
                                            <div className="sh-list__item-icon mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26.853" height="50.957" viewBox="0 0 32.853 50.957">
                                                    <g id="Group_62910" data-name="Group 62910" transform="translate(0 0)">
                                                        <path id="Path_84765" data-name="Path 84765" d="M22.872,16.835A24.774,24.774,0,0,0,6.089,0,24.8,24.8,0,0,0,17.782,40.055a24.775,24.775,0,0,0,5.09-23.22" transform="translate(0 5.775) rotate(-14)" fill="#007a87"/>
                                                        <g id="Group_61026" data-name="Group 61026" transform="translate(0 39.412)">
                                                        <path id="Path_84764" data-name="Path 84764" d="M9,74.808a13.855,13.855,0,0,0-9,9.785,13.868,13.868,0,0,0,22.093-7.5A13.853,13.853,0,0,0,9,74.808" transform="translate(0 -74.067)" fill="#007a87"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="sh-list__item-label sh-text mt-4">Earn extra income by spending time on creating women leaders</div>
                                        </li>
                                        <li className="sh-list__item d-flex align-items-start sh-anim__stagger-fade-top">
                                            <div className="sh-list__item-icon mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26.853" height="50.957" viewBox="0 0 32.853 50.957">
                                                    <g id="Group_62910" data-name="Group 62910" transform="translate(0 0)">
                                                        <path id="Path_84765" data-name="Path 84765" d="M22.872,16.835A24.774,24.774,0,0,0,6.089,0,24.8,24.8,0,0,0,17.782,40.055a24.775,24.775,0,0,0,5.09-23.22" transform="translate(0 5.775) rotate(-14)" fill="#007a87"/>
                                                        <g id="Group_61026" data-name="Group 61026" transform="translate(0 39.412)">
                                                        <path id="Path_84764" data-name="Path 84764" d="M9,74.808a13.855,13.855,0,0,0-9,9.785,13.868,13.868,0,0,0,22.093-7.5A13.853,13.853,0,0,0,9,74.808" transform="translate(0 -74.067)" fill="#007a87"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="sh-list__item-label sh-text mt-4">Expand your network with automatic access to strong mentors and femtee community</div>
                                        </li>
                                    </ul>
                                    <div className="mt-5">
                                        <Link className="sh-button sh-anim__stagger-fade-top" to="/newSignUp">Be a Mentor</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 offset-md-1">
                                <div className="sh-anim__stagger-fade-right">
                                    <img className="img-fluid" src="./images/be-a-mentor/enhance-your-personal-brand.svg" alt="Enhance your personal brand - Level up your mentoring relationship | Shevolve" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/*=========================================
            ===================== Mentor Rate Card ================
            ============================================ */}
            <section className="sh-section sh-section--green">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="text-center sh-section__title-wrap">
                            <div className="sh-title sh-title__underline sh-anim__stagger-fade-top">Mentor Rate Card</div>
                            <p className="sh-para px-5 sh-anim__stagger-fade-top">Do you <b>love helping women</b> but <b>hate wasting time</b>? <br /> Shevolve will ensure you have access to the right profiles with manually matching, scheduling, & vetting your femtees.</p>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                                <div className="sh-mentor__package-card sh-mentor__package-card--blue h-100 pb-0 border-0 sh-anim__stagger-fade-top">
                                    <div className="sh-mentor__package-inwrap h-100 d-flex flex-column justify-content-between">
                                        <div className="mb-4">
                                            <div className="sh-mentor_package-title">OUR</div>
                                            <div className="sh-mentor_package-title">HerMentor </div>
                                            <div className="sh-mentor_package-title">PACKAGES</div>
                                        </div>
                                        <div className=''>
                                            <img className="img-fluid" src="./images/be-a-mentor/package-image.svg" alt="Mentor Rate Card - HerMentor Packages | Shevolve" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                                <div className="sh-mentor__package-card h-100 sh-anim__stagger-fade-top">
                                    <div className="sh-mentor__package-inwrap d-flex flex-column justify-content-between h-100">
                                        <div className="sh-mentor__package-top">
                                            <div className="sh-mentor__package-title mb-4">Rise Up</div>
                                            <ul className="sh-mentor__package-content">
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    12 Hourly Sessions <br /> for 3 months
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    3 to 5 Femtees per Session
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    Access to Femtee Details
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    HerMentor Profile
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    30 Free-Flowing Questions <br /> by Femtees
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    2 Min HerMentor Video
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    Testimonials by Femtees
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="sh-mentor__package-bottom sh-button__center-align">
                                            <a className="sh-button" data-toggle="modal" data-target="#exampleModal">Talk to Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                                <div className="sh-mentor__package-card h-100 sh-anim__stagger-fade-top">
                                    <div className="sh-mentor__package-inwrap d-flex flex-column justify-content-between h-100">
                                        <div className="sh-mentor__package-top">
                                            <div className="sh-mentor__package-badge">MOST POPULAR</div>
                                            <div className="sh-mentor__package-title mb-4">Fly High</div>
                                            <ul className="sh-mentor__package-content">
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    10 Hourly Sessions <br /> for 3 months
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    1 Femtee per Session
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    Access to Femtee Details
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    HerMentor Profile
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    10 Free-Flowing Questions <br /> by Femtees
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    2 Min HerMentor Video
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    Testimonials by Femtees
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="sh-mentor__package-bottom sh-button__center-align">
                                            <a className="sh-button" data-toggle="modal" data-target="#exampleModal">Talk to Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                                <div className="sh-mentor__package-card h-100 sh-anim__stagger-fade-top">
                                    <div className="sh-mentor__package-inwrap d-flex flex-column justify-content-between h-100">
                                        <div className="sh-mentor__package-top">
                                            <div className="sh-mentor__package-title mb-4">She Tribe</div>
                                            <ul className="sh-mentor__package-content">
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    6 Hourly Sessions <br /> for 3 months
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    1 Femtee per Session
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    Access to Femtee Details
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    HerMentor Profile
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    10 Free-Flowing Questions <br /> by Femtees
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    2 Min HerMentor Video
                                                </li>
                                                <li className="sh-mentor__package-item d-flex">
                                                    <span className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                            <g id="task_alt_black_24dp" transform="translate(0 -0.304)">
                                                                <rect id="Rectangle_43677" data-name="Rectangle 43677" width="26" height="26" transform="translate(0 0.304)" fill="none"/>
                                                                <path id="Path_42769" data-name="Path 42769" d="M23.379,5.4l-12.2,12.207L6.65,13.074l1.507-1.507,3.025,3.025L21.872,3.9Zm-2.362,5.387a8.546,8.546,0,1,1-8.327-6.649,8.467,8.467,0,0,1,4.575,1.336L18.8,3.935A10.582,10.582,0,0,0,12.689,2,10.718,10.718,0,1,0,22.737,9.066Z" transform="translate(0.138 0.138)" fill="#556e7e"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    Testimonials by Femtees
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="sh-mentor__package-bottom sh-button__center-align">
                                            <a className="sh-button" data-toggle="modal" data-target="#exampleModal">Talk to Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*=========================================
            ===================== FAQ ================
            ============================================ */}
            <section className="sh-section">
                <div className="sh-section__inwrap">
                    <div className="container">
                        <div className="sh-title sh-section__title-wrap text-center sh-anim__stagger-fade-top">FAQ</div>
                        <div className="row">
                            <div className="col-md-8 col-xl-9 sh-faq mb-3 mb-md-0">
                                <div className="sh-faq__wrap sh-anim__stagger-fade-top">
                                    {faq_data.map((data, index) => {
                                        return (
                                            <div key={index} className="sh-faq__card" data-toggle="collapse" data-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                                <div class="sh-faq__card-trigger btn-header-link w-100 sh-text text-left">
                                                    {data.question}
                                                </div>
                                                <div class="sh-faq__card-content collapse" id={`collapse${index}`}>
                                                    <div class="card-body pb-0">
                                                        {/* {data.answer} */}
                                                        <p
                                                            className="sh-faq__card-answer"
                                                            dangerouslySetInnerHTML={{ __html: data.answer }}
                                                        >
                                                            {/* {ReactHtmlParser(blog1.description)} */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col-md-4 col-xl-3 mt-4 mt-md-0">
                                <div className="sh-faq__cta sh-faq__cta--blue text-center h-100 sh-anim__stagger-fade">
                                    <div className="sh-faq__cta-image mb-2">
                                        <img className="img-fluid d-none d-md-block" src="./images/be-a-mentor/faq-cta.png" alt="" />
                                    </div>
                                    <div className="font-weight-bold mb-2 sh-faq__cta-title">Have more questions?</div>
                                    <p className="sh-text mb-2">Let us know and our experts will get in touch with you</p>
                                    <a className="sh-whatsapp__cta-button--whatsapp d-inline-flex align-items-center" href="https://wa.me/919841003516">
                                        <img style={{ height: 30 }} src="./images/program/whatsapp.png" alt="whatsapp" />
                                        <span className="ml-2">Chat with us</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*=========================================
            ===================== Why mentors love us? ================
            ============================================ */}
            {/* <section className="sh-section sh-section--green overflow-hidden">
                <div className="sh-section__inwrap">
                    <div className="container-fluid pr-md-0">
                        <div className="sh-title sh-title__underline sh-section__title-wrap text-center sh-anim__stagger-fade-top">Why mentors love us?</div>
                        <div className="row justify-content-end">
                            <div className="sh-card__left sh-section__title-wrap mb-lg-0 sh-mentor__testimonial-left">
                                <div className="sh-content__wrap text-center text-lg-left">
                                    <div className="sh-title sh-title__font-secondary font-weight-bold sh-anim__stagger-fade-top">Want to know <br /> why mentors <br /> love us ?</div>
                                    <p className="sh-para sh-anim__stagger-fade-top">There are many reasons why our <br /> mentors love to work with us.</p>
                                    <p className="sh-para sh-anim__stagger-fade-top">Here's what our mentors have to say about us!</p>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <OwlCarousel className="owl-theme sh-slider sh-mentor__testimonial sh-anim__stagger-fade-right" 
                                    items={1} 
                                    margin={30}
                                    // autoWidth= {true}
                                    // loop={true}

                                    responsive={{
                                        "0": {
                                            items: "1"
                                        },
                                        "640": {
                                            autoWidth: true
                                        },
                                        
                                    }}
                                >
                                    {Testimonials.mentors.map((data, index) => {
                                            return (
                                                <div key={index} className="sh-mentor__testimonial-slide d-flex flex-column justify-content-between sh-anim__stagger-fade-top">
                                                    <div>
                                                        <div className="mb-3">
                                                            <svg className="sh-mentor__testimonial-icon" width="34" height="44.252" viewBox="0 0 34 44.252">
                                                                <g id="Group_62912" data-name="Group 62912" transform="translate(0 0)">
                                                                    <path id="Path_82207" data-name="Path 82207" d="M51.281,42.247a.293.293,0,0,1,.135.566A12.522,12.522,0,0,0,49.2,43.951,13.249,13.249,0,0,0,46.146,46.7a17.988,17.988,0,0,0-1.183,1.664,19.239,19.239,0,0,0-.974,1.8c-.3.618-.561,1.256-.8,1.9l-.724,2.047a29.106,29.106,0,0,1-4.328,7.926c-.451.6-.954,1.169-1.452,1.731-.261.272-.521.543-.786.81s-.547.517-.82.773a16.566,16.566,0,0,1-3.663,2.573c.511-.539.976-1.106,1.441-1.665s.9-1.141,1.317-1.728S35,63.356,35.4,62.765c.187-.306.381-.606.572-.906.1-.149.191-.3.278-.457l.271-.46a45.611,45.611,0,0,0,3.61-7.679l.37-1c.134-.361.258-.725.4-1.082a20.046,20.046,0,0,1,1-2.1,16.363,16.363,0,0,1,1.266-1.966,15.093,15.093,0,0,1,1.561-1.756,11.952,11.952,0,0,1,3.93-2.5,10.094,10.094,0,0,1,2.227-.571q.2-.027.4-.046" transform="translate(-17.605 -23.674)" fill="#007a87"/>
                                                                    <path id="Path_82208" data-name="Path 82208" d="M27.7,14.3A21.046,21.046,0,0,0,13.448,0a21.068,21.068,0,0,0,9.933,34.027A21.046,21.046,0,0,0,27.7,14.3" transform="translate(-4.638 0)" fill="#007a87"/>
                                                                    <path id="Path_82209" data-name="Path 82209" d="M7.392,74.676A11.381,11.381,0,0,0,0,82.713a11.392,11.392,0,0,0,18.149-6.165A11.38,11.38,0,0,0,7.392,74.676" transform="translate(0 -41.507)" fill="#007a87"/>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <p className="sh-mentor__testimonial-para" dangerouslySetInnerHTML={{ __html: data.quote }}></p>
                                                    </div>

                                                    <div className="sh-mentor__testimonial-details d-flex align-items-center mt-5">
                                                        <div className="sh-mentor__testimonial-profile" style={{ backgroundImage: `url(./images/testimonials/${data.img}.png)` }}></div>
                                                        <div className="sh-mentor__testimonial-content">
                                                            <div className="sh-mentor__testimonial-name sh-text">{data.name}</div>
                                                            <div className="sh-mentor__testimonial-role">{data.role}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/*=========================================
            ===================== Why mentors love us? ================
            ============================================ */}
            {/* <section className="sh-section sh-section--green overflow-hidden">
                <div className="sh-section__inwrap">
                    <div className="container pr-md-0">
                        <div className="row justify-content-end">
                            <div className="col-md-7 sh-section__title-wrap mb-lg-0 sh-mentor__testimonial-left">
                                <div className="sh-content__wrap text-center text-lg-left">
                                    <div className="sh-subtitle sh-anim__stagger-fade-top">Why mentors love us?</div>
                                    <div className="sh-title sh-title__font-secondary font-weight-bold sh-anim__stagger-fade-top">Want to know why mentors <br /> love us ?</div>
                                    <p className="sh-para sh-anim__stagger-fade-top mb-5">Here's what our mentors have to say about us!</p>

                                    <OwlCarousel className="owl-theme sh-slider"
                                            // width={90}
                                            // items={3}
                                            margin={80}
                                            responsive={{
                                                "0": {
                                                    items: "1"
                                                },
                                                "640": {
                                                    items: "2"
                                                },
                                                "1170": {
                                                    items: "2"
                                                }

                                            }}

                                        >
                                            {Testimonials.mentors.map((mentor, index) => {
                                                return (
                                                    <div key={index} className="sh-mentor__slide sh-anim__stagger-fade-top">
                                                        <div className="sh-mentor__slide-details d-flex flex-wrap align-items-start justify-content-between">                                                            
                                                            <div className="sh-mentor__testimonial-details d-flex align-items-center my-3">
                                                                <div className="sh-mentor__testimonial-profile" style={{ backgroundImage: `url(./images/testimonials/${mentor.img}.png)` }}></div>
                                                                <div className="sh-mentor__testimonial-content">
                                                                    <div className="sh-mentor__testimonial-name sh-text">{mentor.name}</div>
                                                                    <div className="sh-mentor__testimonial-role">{mentor.role}</div>
                                                                </div>
                                                            </div>
                                                        </div>                                                                                                               
                                                    </div>
                                                )
                                            }) }
                                        </OwlCarousel>
                                </div>
                            </div>
                            <div className="col-md-5">                                
                                    <div className="sh-mentor__testimonial-slide d-flex flex-column justify-content-between sh-anim__stagger-fade-top active-card">
                                        <div className="sh-mentor__testimonial-details d-flex align-items-center">
                                            <div className="sh-mentor__testimonial-content">
                                                <div className="sh-mentor__testimonial-name sh-text">Scindia Balasingh</div>
                                                <div className="sh-mentor__testimonial-role">Head of Partner Marketing</div>
                                            </div>
                                            <div className="sh-mentor__testimonial-profile" style={{ backgroundImage: `url(./images/testimonials/scindia-balasingh.png)` }}></div>
                                        </div>
                                        <div>
                                            <div className="mb-3">
                                                <svg className="sh-mentor__testimonial-icon" width="34" height="44.252" viewBox="0 0 34 44.252">
                                                    <g id="Group_62912" data-name="Group 62912" transform="translate(0 0)">
                                                        <path id="Path_82207" data-name="Path 82207" d="M51.281,42.247a.293.293,0,0,1,.135.566A12.522,12.522,0,0,0,49.2,43.951,13.249,13.249,0,0,0,46.146,46.7a17.988,17.988,0,0,0-1.183,1.664,19.239,19.239,0,0,0-.974,1.8c-.3.618-.561,1.256-.8,1.9l-.724,2.047a29.106,29.106,0,0,1-4.328,7.926c-.451.6-.954,1.169-1.452,1.731-.261.272-.521.543-.786.81s-.547.517-.82.773a16.566,16.566,0,0,1-3.663,2.573c.511-.539.976-1.106,1.441-1.665s.9-1.141,1.317-1.728S35,63.356,35.4,62.765c.187-.306.381-.606.572-.906.1-.149.191-.3.278-.457l.271-.46a45.611,45.611,0,0,0,3.61-7.679l.37-1c.134-.361.258-.725.4-1.082a20.046,20.046,0,0,1,1-2.1,16.363,16.363,0,0,1,1.266-1.966,15.093,15.093,0,0,1,1.561-1.756,11.952,11.952,0,0,1,3.93-2.5,10.094,10.094,0,0,1,2.227-.571q.2-.027.4-.046" transform="translate(-17.605 -23.674)" fill="#ffffff"/>
                                                        <path id="Path_82208" data-name="Path 82208" d="M27.7,14.3A21.046,21.046,0,0,0,13.448,0a21.068,21.068,0,0,0,9.933,34.027A21.046,21.046,0,0,0,27.7,14.3" transform="translate(-4.638 0)" fill="#ffffff"/>
                                                        <path id="Path_82209" data-name="Path 82209" d="M7.392,74.676A11.381,11.381,0,0,0,0,82.713a11.392,11.392,0,0,0,18.149-6.165A11.38,11.38,0,0,0,7.392,74.676" transform="translate(0 -41.507)" fill="#ffffff"/>
                                                    </g>
                                                </svg>
                                            </div>
                                            <p className="sh-mentor__testimonial-para" dangerouslySetInnerHTML={{ __html: "I\'ve faced gender biasness, lack of inspiration, and motivation from fellow women colleagues in my life. This pushed me to take women\'s empowerment into my agenda. I\'ve always wanted to see women shining in their careers. So when I interviewed Deepa in my podcast LeadHERs, I got to relate to many of my interests aligned with Shevolve. It started there; I got the opportunity to do a cohort session on a career in partnerships, then on an Instagram live by sharing my experience with the fellow women community. I am grateful to be a part of the Shevolve community and looking forward to contributing and learning more from Shevolve." }}></p>
                                        </div>                                        
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

        </Layout>
    )
}