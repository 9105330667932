import { LOGIN, LOGOUT, UPDATE_PACKAGE_INFO } from "./action";

const initialState = {
  accessToken: "",
  userData: "",
  userType: "",
  login: false,
  loading: false,
  package: {
    mentoringSessions: [],
    coachingSessions:[],
    workshops: []
  },
};

function loginSignPopUp(state = initialState, action) {
  switch (action.type) {
    
    case LOGIN:
      return {
        ...state,
        userData: action.payload,
        accessToken: action.accessToken,
        userType: action.userType,
        // package: action.payload.packageInfo,
        login: true,
      };
    case UPDATE_PACKAGE_INFO:
      return {
        ...state,
        package: action.packageInfo,
      };
    case LOGOUT:
      return initialState;
    default:
      return initialState;
  }
}

export default loginSignPopUp;
