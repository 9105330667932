import React from "react"

const Styles = () => {
	return (
		<>
			<link rel="stylesheet" href="assets/css/all.min.css" />
			<link rel="stylesheet" href="assets/css/slick.css" />
			{/* <link rel="stylesheet" href="assets/css/style.css" /> */}
			<link rel="stylesheet" href="assets/css/magnific-popup.css" />
		</>
	)
}

export default Styles
